import { createAction, props } from '@ngrx/store';

import { Client } from '@literax/models/http/onboarding/client.model';

export const cleanRegistrationState = createAction('[Auth] Clean Registration State');

export const signUp = createAction(
  '[Auth] SignUp',
  props<{ payload: { Client: Client, token: string } }>()
);

export const signUpSuccess = createAction(
  '[Auth] SignUp Success',
  props<{ payload: boolean }>()
);

export const signUpError = createAction(
  '[Auth] SignUp Error',
  props<{ payload: any }>()
);

export const verify = createAction(
  '[Auth] Verify',
  props<{ payload: { Client: Client, token: string } }>()
);

export const verifySuccess = createAction(
  '[Auth] Verify Success',
  props<{ payload: boolean }>()
);

export const verifyError = createAction(
  '[Auth] Verify Error',
  props<{ payload: any }>()
);
