import { registerLocaleData } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { ValidatorEffects } from '@literax/store/validator/validator.effects';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { ElectronicSignersComponent } from './components/electronic-signers/electronic-signers.component';
import { LegalSignersComponent } from './components/legal-signers/legal-signers.component';
import { ValidatorSignersComponent } from './components/validator-signers/validator-signers.component';
import { ValidatorContainerComponent } from './container/validator-container/validator-container.component';
import { ValidatorComponent } from './validator/validator.component';
const modules = [SharedModule];

const components = [
  ValidatorComponent,
  ValidatorContainerComponent,
  ValidatorSignersComponent,
  ElectronicSignersComponent
];

const directives = [];

registerLocaleData(localeEsMX, 'es-MX');
@NgModule({
  imports: [modules, EffectsModule.forFeature([ValidatorEffects])],
  declarations: [components, directives, LegalSignersComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'es-MX' }],
  exports: [modules, components, directives],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ValidatorModule {}
