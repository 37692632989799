import { createAction, props } from '@ngrx/store';

import { HttpErrorResponse } from '@angular/common/http';
import { IShowSignatureResponse } from '@literax/models/http/api/signature.model';

export const ShowSignature = createAction(
  '[Signature] Show Signature',
  props<{ payload: string }>()
);

export const ShowSignatureSuccess = createAction(
  '[Signature] Show Signature Success',
  props<{ payload: IShowSignatureResponse }>()
);

export const ShowSignatureError = createAction(
  '[Signature] Show Signature Error',
  props<{ payload: HttpErrorResponse }>()
);
