import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { IActions } from '@literax/components/configurations/users/models/users.interface';
import { selectUserPermissions } from '@literax/components/configurations/users/states/users.selector';
import { IAppState } from '@literax/store';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[validatePermission]'
})
export class ValidatePermissionDirective {
  permission$ = this.store.pipe<IActions>(select(selectUserPermissions));
  input$: Subject<string> = new Subject<string>();
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private store: Store<IAppState>
  ) {
    combineLatest([this.permission$, this.input$])
      .pipe(distinctUntilChanged(), first())
      .subscribe(([permissions, input]) => {
        if (permissions.hasOwnProperty(input) && permissions[input]) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainerRef.clear();
        }
      });
  }

  @Input() set validatePermission(permission: string) {
    if (!permission) {
      throw new Error('permission is required');
    }

    this.input$.next(permission);
  }
}
