<details #envelop ngDefaultControl data-popover [class]="posicion">
  <summary>
    <i
      *ngIf="
        signer?.can_send_notification && !signer?.signed && !signer?.is_author
      "
      class="fal fa-envelope clickable"
      (click)="this.getposition()"
      [class.fa-envelope-open]="emailDelivered"
      [title]="
        'EMAIL_EVENT.STATUSES.' + lastEventEmail?.status | uppercase | translate
      "
    ></i>
  </summary>
  <div id="popover-content" [class]="posicion">
    <div class="py-2 pl-3 bottom-notification">
      <span class="notification_name">
        {{
          'DOCUMENTS.SIGNERS.HISTORY.SENDER_TITLE'
            | translate: { name: signer.name }
        }}</span
      >
    </div>
    <div class="wrapper">
      <div class="vertical col-1">
        <div class="row h-100 pl-2">
          <span class="fa-stack fa-lg icon m-auto">
            <i [class]="secondHistoyIcons[lastEventEmail?.status]"></i>
            <i [class]="histoyIcons[lastEventEmail?.status]"></i>
          </span>
        </div>
      </div>
      <span class="col-11 bottom">
        <span class="row">
          <div class="p-2 align-middle col bold m-auto">
            {{
              'EMAIL_EVENT.STATUSES.' + lastEventEmail?.status
                | uppercase
                | translate
            }}
          </div>
          <div
            class="py-2 pr-3 align-middle float-right col-5 info text-right m-auto"
          >
            {{ this.formatDate(lastEventEmail?.updated_at) }}
          </div>
        </span>
      </span>
    </div>
    <div class="wrapper">
      <div class="vertical col-1">
        <div class="row h-100 pl-2">
          <span class="fa-stack fa-lg icon m-auto">
            <i class="fa fa-circle black fa-stack-2x"></i>
            <i class="fa fa-plus fa-stack-1x"></i>
          </span>
        </div>
      </div>
      <span class="col-11 bottom">
        <span class="row">
          <div class="p-2 align-middle col bold m-auto">
            {{ 'DOCUMENTS.SIGNERS.HISTORY.DIALOG_TITTLE' | translate }}
          </div>
          <div class="pr-3 align-middle m-auto">
            <button
              class="button-history primary button-sm float-right"
              type="button"
              (click)="showHistory()"
            >
              {{ 'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.SHOW' | translate }}
            </button>
          </div>
        </span>
      </span>
    </div>
    <div>
      <button
        class="button-history primary float-right m-2 mt-4"
        type="button"
        (debounceClick)="actionClicked($event)"
        [disabled]="isDisabled"
        *ngIf='statusDocument !== "expired"'
      >
        <i class="far fa-paper-plane"></i>&nbsp;{{
          'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.RESEND' | translate
        }}
      </button>
    </div>
  </div>
</details>
