import { Action, createReducer, on } from '@ngrx/store';
import { IUserProfile, IUserProfileDashboard } from '../types/user-ptofiles';
import {
  ClearState,
  GetUsersProfilesDashboardSuccess,
  GetUsersProfilesSuccess,
  SetUserProfilesFilter,
  SetUserProfilesSortString,
  ShowUserProfileSuccess
} from './user-profiles.actions';

export interface IUserProfiles {
  dashboard: IUserProfileDashboard;
  userProfiles: IUserProfile[];
  userProfile: IUserProfile;
  currentPage: number;
  urlSorts: string;
  currentFilter: string;
}
export const initiaUserProfileslState: IUserProfiles = {
  dashboard: null,
  userProfiles: [],
  userProfile: null,
  currentPage: 1,
  urlSorts: '',
  currentFilter: ''
};

const reducers = createReducer(
  initiaUserProfileslState,
  on(GetUsersProfilesSuccess, (state: IUserProfiles, action) => {
    const userProfiles = [...state.userProfiles, ...action.payload];
    const currentPage =
      action.payload.length > 0 ? state.currentPage + 1 : state.currentPage;
    return { ...state, userProfiles, currentPage };
  }),
  on(ShowUserProfileSuccess, (state: IUserProfiles, action) => ({
    ...state,
    userProfile: action.payload
  })),
  on(ClearState, (state: IUserProfiles, action) => ({
    ...state,
    userProfiles: [],
    currentPage: 1
  })),

  on(SetUserProfilesSortString, (state: IUserProfiles, action) => ({
    ...state,
    userProfiles: [],
    currentPage: 1,
    urlSorts: action.payload
  })),
  on(SetUserProfilesFilter, (state: IUserProfiles, action) => ({
    ...state,
    userProfiles: [],
    currentPage: 1,
    currentFilter: action.payload === 'profile_all' ? '' : action.payload
  })),
  on(GetUsersProfilesDashboardSuccess, (state: IUserProfiles, action) => ({
    ...state,
    dashboard: action.payload
  }))
);

export function userProfileReducer(state: IUserProfiles, action: Action) {
  return reducers(state, action);
}
