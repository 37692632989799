import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from '@literax/components/configurations/users/models/users.interface';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISignRequest } from '@literax/store/document/document.state';

@Component({
  selector: 'literax-reviewer',
  templateUrl: './reviewer.component.html',
  styleUrls: ['./reviewer.component.scss'],
})
export class ReviewerComponent implements OnInit {
  @Input() reviewer: ISignRequest;
  @Input() status: string;
  @Input() document: IDocument;
  @Input() user: IUser;
  @Input() actionButtonEnable: boolean;
  @Input() userDocument: IUser;
  @Output() actionButton = new EventEmitter();
  @Output() historyButton: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
