<label class="switch float-right">
  <input
    class="switch-input"
    type="checkbox"
    [formControl]="form.get('enabled')"
    [checked]="isChecked"
    (change)="onChanged($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled"
  />
  <span class="switch-label" *ngIf="isChecked">
    <p class="yes">
      {{ 'TOGGLE_SWITCH.YES' | translate }}
    </p>
  </span>
  <span class="switch-label" *ngIf="!isChecked">
    <p class="no">
      {{ 'TOGGLE_SWITCH.NO' | translate }}
    </p>
  </span>

  <span class="switch-handle"></span>
</label>
