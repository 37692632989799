import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAppState } from '@literax/store';
import * as validatorActions from '@literax/store/validator/validator.actions';
import { selectShowSignatureResult } from '@literax/store/validator/validator.selector';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss'],
})
export class ValidatorComponent implements OnInit, OnDestroy {
  signature$ = this.store.pipe(
    untilDestroyed(this),
    select(selectShowSignatureResult)
  );
  apiRoot = environment.literaxUrl;
  today = new Date();
  createdAt: string;
  constructor(
    private store: Store<IAppState>,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private translate: TranslateService
  ) {
    moment.locale(this.translate.currentLang);
  }

  ngOnInit() {
    const uuid = this.route.snapshot.paramMap.get('uuid');
    this.store.dispatch(validatorActions.ShowSignature({ payload: uuid }));
  }

  onLogin() {
    this.router.navigate(['/auth/registration']);
  }

  onRegister() {
    this.router.navigate(['auth/registration']);
  }

  ngOnDestroy() {}

  onVerification(uuid: string) {
    this.store.dispatch(validatorActions.ShowSignature({ payload: uuid }));
  }

  back() {
    this.location.back();
  }
}
