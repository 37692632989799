import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

@Component({
  selector: 'literax-date-dropdown',
  templateUrl: './date-dropdown.component.html',
  styleUrls: ['./date-dropdown.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DateDropdownComponent implements OnInit {
  selectActive = false;
  hasValue = false;
  @Input() fromControl: FormControl = new FormControl('');
  @Input() toControl: FormControl = new FormControl('');
  @Input() fromLabel: string;
  @Input() toLabel: string;
  @Input() placeholder = '';
  @Input() set lang(locale: string) {
    if (['es', 'en', 'ES', 'EN'].indexOf(locale) >= 0) {
      this.adapter.setLocale(locale);
    }
  }

  toMinDate: Date;

  ngOnInit() {
    this.fromControl.valueChanges.subscribe((value) => {
      if (value) {
        this.toMinDate = new Date(value);
        this.toControl.setValue(this.toMinDate);
        this.hasValue = true;
      } else {
        this.toMinDate = null;
        this.toControl.reset();
        this.hasValue = false;
      }
    });
  }

  toggleSelect() {
    this.selectActive = !this.selectActive;
  }

  constructor(private adapter: DateAdapter<any>) {}
}
