<div class="document-wizard">
  <div class="row-close">
    <i (click)="closeModal()" class="material-icons">close</i>
  </div>

  <div class="row-title">
    {{ data.title | translate }}
  </div>
  <div class="row-sumary">
    {{ data.message | translate }}
  </div>
  <section>
    <div id="modal-content">
      <div id="document-main" class="preview-row">
        <div id="viewer">
          <mat-accordion
            *ngFor="
              let itemDelegate of selectClientDelegate$ | async | keyvalue;
              let i = index
            "
          >
            <mat-expansion-panel
              hideToggle="false"
              multi="true"
              class="custom-color"
            >
              <mat-expansion-panel-header>
                <div class="info-header">
                  <span class="signer-info">
                    {{ itemDelegate?.key | translate }}
                  </span>
                </div>
              </mat-expansion-panel-header>
              <div id="scroll">
                <div
                  class="info"
                  *ngFor="let user of itemDelegate.value"
                  [ngClass]="i % 2 == 0 ? 'even' : 'odd'"
                >
                  <mat-radio-button
                    [value]="user.id"
                    class="signer if-mr-24"
                    (change)="addThis($event, user)"
                  >
                    {{ user?.name | translate }} {{ user?.last_name | translate }}
                  </mat-radio-button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="buttons">
  <div class="left-buttons">
    <button
      gdx-button
      buttonStyle="link"
      class="interfirma-button mrr-10"
      (click)="cancelEvent()"
    >
      <i class="icon far fa-times-circle if-mr-4"> </i>
      <span class="text">
        {{ data.cancelButton | translate }}
      </span>
    </button>
  </div>
  <div class="right-buttons">
    <button class="interfirma-button primary" (click)="submitForm()">
      <i class="icon far fa-users if-mr-4"></i>
      <span class="text">
        {{ data.acceptButton | translate }}
      </span>
    </button>
  </div>
</div>
