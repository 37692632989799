import {
  IDefaultUser,
  IUser,
  IUserConfirmation,
  IUserInfo,
  IUserKindCounts,
  IUserLoginConfirmation
} from '../models/users.interface';
import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IAuthLogin } from '@literax/models/http/auth/login.model';
import { IProcess } from '@literax/components/platform/profiles/types/user-ptofiles';
import { IProfile } from '../../profiles/models/profiles';
import { UserKindEnum } from '../enums/user-kind.enum';

export const getAllUsers = createAction('[Users Component] getAllUsers');
export const getAllUsersSucces = createAction(
  '[Users Components] getAllUsersSucces',
  props<{ client_users: IUser[] }>()
);
export const errorConf = createAction(
  '[Users Component] errorConf',
  props<{ error: IAPIResponseError }>()
);
export const errorloginConf = createAction(
  '[Users Component] errorloginConf',
  props<{ error: IAPIResponseError }>()
);

export const updateUsersbyId = createAction(
  '[Users Component] updateUsersbyId',
  props<{ users: IUser }>()
);
export const updateUsersbyIdSucess = createAction(
  '[Users COmponent] updateUsersbyIdSucess'
);

export const deleteUsers = createAction(
  '[Users component] delete',
  props<{ userId: number }>()
);
export const deleteUsersSuccess = createAction(
  '[Users component] deleteSuccess'
);
export const saveUsers = createAction(
  '[Users Component] saveUsers',
  props<{ user: IUser }>()
);
export const saveUsersSucess = createAction(
  '[Users Component] saveUsersSucess'
);

export const getUserProfiles = createAction(
  '[Users Component] GetUserProfiles'
);

export const getUserProfilesSuccess = createAction(
  '[Users Component] GetUserProfilesSuccess',
  props<{ payload: { profiles: IProfile[] } }>()
);

export const registerClientUsersConfirmations = createAction(
  '[Users Component] registerClientUsersConfirmations',
  props<{ user: IUserConfirmation; token: string }>()
);
export const registerClientUsersConfirmationsSucess = createAction(
  '[Users Component] regisrterClientUsersConfirmationsSucess'
);

export const loginClientUsers = createAction(
  '[Users Component] loginClientUsers',
  props<{
    user: IAuthLogin;
    token: string;
    clientUserJoin: IUserLoginConfirmation;
  }>()
);

export const loginClientUsersConfirmations = createAction(
  '[Users Component] loginClientUsersConfirmations',
  props<{ user?: IUserLoginConfirmation; token: string; b2cToken?: string }>()
);
export const loginClientUsersConfirmationsSucess = createAction(
  '[Users Component] loginClientUsersConfirmationsSucess'
);

export const GetPermissionUserInfo = createAction(
  '[User] GetPermissionUserInfo'
);
export const GetPermissionUserInfoSuccess = createAction(
  '[User] GetPermissionUserInfoSuccess',
  props<{ payload: IUserInfo }>()
);

export const ResetPage = createAction('[User] ResetPage');

export const SetDefaultUser = createAction(
  '[User] SetDefaultUser',
  props<{ payload: IDefaultUser }>()
);
export const SetDefaultUserSuccess = createAction(
  '[User] SetDefaultUserSuccess'
);

export const changeSortColumn = createAction(
  '[User] changeSort',
  props<{ payload: string }>()
);

export const changeUserKindFilter = createAction(
  '[User] changeUserKindFilter',
  props<{ payload: UserKindEnum }>()
);

export const countUserKind = createAction('[User] countUserKind');

export const countUserKindSuccess = createAction(
  '[User] countUserKindSuccess',
  props<{ payload: IUserKindCounts }>()
);

//#region Dashboard users
export const getUsersDashboard = createAction('[User] getUsersDashboard');
export const getUsersDashboardSuccess = createAction(
  '[User] getUsersDashboardSuccess',
  props<{ payload: IProcess }>()
);
export const getUsersDashboardError = createAction(
  '[User] getUsersDashboardError',
  props<{ payload: IAPIResponseError }>()
);

export const DisableTaxMessage = createAction('[User] DisableTaxMessage');
export const DisableTaxMessageSuccess = createAction('[User] DisableTaxMessageSuccess');
export const DisableTaxMessageError = createAction(
  '[User] DisableTaxMessageError',
  props<{ payload: IAPIResponseError }>()
);
//#endregion Dashboard users
