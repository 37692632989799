import { Component, ChangeDetectorRef, Input } from '@angular/core';

@Component({
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent {
  textLabel: string;
  hide = true;
  params = {};

  @Input() set text(value: string) {
    if (value !== this.textLabel) {
      this.textLabel = value;
      this.hide = !value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
