import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IAppState } from '@literax/store';
import { Observable } from 'rxjs';
import { SignService } from '@literax/services/sign/sign.service';
import { TranslateService } from '@ngx-translate/core';
import { selectCurrentLang } from '@literax/store/config/config.selectors';
import { setLang } from '@literax/store/config/config.actions';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-auth-navbar',
  templateUrl: './auth-navbar.component.html',
  styleUrls: ['./auth-navbar.component.scss'],
})
export class AuthNavbarComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() lang: string;
  lang$: Observable<string>;

  isView = true;
  language: any;
  public deviceInfo = null;

  constructor(
    private deviceService: DeviceDetectorService,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private signService: SignService,
    private route: ActivatedRoute
  ) {
    this.detectDevice();
    this.lang$ = this.store.pipe(
      untilDestroyed(this),
      select(selectCurrentLang),
      map((lang: string) => lang || 'es'),
      tap((lang: string) => this.updateCurrentLang(lang))
    );
  }

  ngOnDestroy() {}

  updateCurrentLang(lang: string) {
    if (lang) {
      this.translate.use(lang);
      this.signService.locale = lang;
      this.language = lang;
    }
  }
  setLang(lang: string) {
    this.store.dispatch(setLang({ payload: lang }));
  }
  public detectDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let browserVersion = 0;
    if (this.deviceInfo.browser === 'Chrome') {
      browserVersion = Number(this.deviceInfo.browser_version.split('.')[0]);
      if (browserVersion >= 83) {
        this.isView = false;
      }
    } else if (this.deviceInfo.browser === 'Safari') {
      browserVersion = Number(this.deviceInfo.browser_version.substr(0, 2));

      if (browserVersion >= 11) {
        this.isView = false;
      }
    } else if (this.deviceInfo.browser === 'MS-Edge') {
      if (browserVersion >= 11) {
        this.isView = false;
      }
    } else if (this.deviceInfo.browser === 'MS-Edge-Chromium') {
      browserVersion = Number(this.deviceInfo.browser_version.substr(0, 2));

      if (browserVersion >= 11) {
        this.isView = false;
      }
    } else if (this.deviceInfo.browser === 'Opera') {
      browserVersion = Number(this.deviceInfo.browser_version.substr(0, 2));

      if (browserVersion >= 69) {
        this.isView = false;
      }
    } else if (this.deviceInfo.browser === 'Firefox') {
      browserVersion = Number(this.deviceInfo.browser_version.split('.')[0]);

      if (browserVersion >= 78) {
        this.isView = false;
      }
    }
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params['locale']) {
        this.setLang(params['locale']);
      }
    });
  }
}
