<div class="container-fluid toolbar">
  <div class="row toolbar-row">
    <div class="menu-btn-container">
      <div
        matRipple
        (click)="opened = !opened"
        class="threebar"
        [class.hamburger]="!opened"
        [class.arrow]="opened"
      >
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </div>
    <div class="col-auto logo">
      <img
        [src]="currentClient?.logo_url || logoUrl"
        class="img-responsive"
        alt="Company logo"
      />
    </div>
    <span class="spacer"></span>

    <div class="col-auto p-0 navbar-button" hidden>
      <button gdx-button matRipple class="nav-button mr-3">
        <i class="fal fa-bell"></i>&nbsp; 32
      </button>
    </div>

    <div class="search navbar-button">
      <i class="icon-search fal fa-search"></i>
      <input
        #searchBox
        type="text"
        placeholder="{{ 'DASHBOARD.NAVBAR.SEARCH' | translate }}"
        aria-label="Number"
        class="input-search"
        (blur)="clearSearch()"
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        class="search-document"
        (optionSelected)="sendToDocument($event, $event.option.value)"
        [displayWith]="displayOptionName"
      >
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          <div class="search-document-row">
            <span
              class="status-color"
              [ngStyle]="{ 'background-color': option.status_color }"
            ></span>
            <span class="document-name">{{ option.name }}</span>
          </div>
        </mat-option>
        <mat-option *ngIf="suggestion | async" [value]="suggestion | async">
          <div class="search-document-row suggestion">
            <span class="did-you-mean"
              >{{ 'DASHBOARD.NAVBAR.DID_YOU_MEAN' | translate }}:</span
            >
            <span class="suggestion">{{ suggestion | async }}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>

    <div class="col-auto p-0 d-flex profile-container">
      <literax-profile-image
        [logo]="_profileImage"
        [name]="(currentClient$ | async)?.name"
      ></literax-profile-image>
      <div class="profile mr-3" [matMenuTriggerFor]="profileMenu">
        <div class="user-info">
          <span class="name">{{ (currentClient$ | async)?.name }}</span>
          <span class="email">{{ _email }}</span>
        </div>
        <i class="far fa-chevron-down profile-arrow"></i>
      </div>
      <mat-menu #profileMenu="matMenu" class="profile-menu">
        <button
          *ngFor="let client of clients"
          mat-menu-item
          (click)="change($event, client)"
          class="company-btn"
        >
          <literax-profile-image
            [logo]="client.logo_url"
            [name]="client.name"
          ></literax-profile-image>
          <span>{{ client.name }}</span>
        </button>

        <div class="separator"></div>
        <button mat-menu-item (debounceClick)="logout()">
          {{ 'SPA.NAV.LOGOUT' | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<mat-sidenav-container class="nav-container" *ngIf="menuItems">
  <mat-sidenav
    class="drawer-absolute"
    [disableClose]="true"
    #snav
    mode="side"
    [opened]="true"
  >
    <div class="side-nav" [ngClass]="{ 'collapsed-side-nav': !opened }">
      <div *ngIf="menuItems" class="menu-items-container">
        <div class="menu">
          <div class="top-menu">
            <div class="spacer-menu"></div>
            <literax-menu-item
              *ngFor="let menuItem of menuItems.top"
              [menuItem]="menuItem"
              [menuClosed]="!opened"
              (openMenuEmitter)="opened = true"
              (closeMenuEmitter)="opened = false"
            >
            </literax-menu-item>
          </div>

          <div class="bottom-menu">
            <hr />
            <literax-menu-item
              *ngFor="let menuItem of menuItems.bottom"
              [menuItem]="menuItem"
              [menuClosed]="!opened"
              (openMenuEmitter)="opened = true"
              (closeMenuEmitter)="opened = false"
            >
            </literax-menu-item>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="{
      'opened-side-nav-content': opened,
      'closed-side-nav-content': !opened
    }"
  >
    <div class="container-fluid max-vh">
      <div
        class="disabled-content"
        *ngIf="opened"
        (click)="opened = false"
      ></div>
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div
  *ngIf="(currentClient$ | async)?.recurrence_status !== 'activate'"
  class="banner banner-small banner-overall warning navbar-banner-top"
>
  <b>{{ 'BANNER-MESSAGES.MESSAGE_NOTICE' | translate }}</b>
  {{ 'BANNER-MESSAGES.MESSAGE_SUSPENDED' | translate }}
</div>
