import * as reportPromissoryNoteActions from '@literax/store/report/report-promissory-note.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ReportPromissoryNoteService } from '@literax/services/report/report-promissory-note.service';
import { catchError, map, switchMap, tap, withLatestFrom, exhaustMap, debounceTime } from 'rxjs/operators';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IPromissory_Note_Report, IPromissory_Note_Report_Detail } from '@literax/models/report-promissory-note.model';
import { error } from '../sign-request/sign-request.actions';
import * as reportPromissoryNoteAction from '@literax/store/report/report-promissory-note.actions';
import { IPromissoryNote_Report_Beneficiaries_Filter, IPromissoryNote_Report_suscriptor_Filter } from '../../models/report-promissory-note.model';
import { GetFiltersuscriptorError } from './report-promissory-note.actions';

@Injectable()
export class ReportPromissoryNoteEffects {
  constructor(
    private store: Store<IAppState>,
    private actions$: Actions,
    private reportServices: ReportPromissoryNoteService,
    private toastr: I18nToastrService
  ) {}

  getInfoReport$ = createEffect(() =>
      this.actions$.pipe(
          ofType(reportPromissoryNoteActions.GetInfoReport),
          switchMap((action) =>
            this.reportServices.getInfoReport(action.payload.filter,action.payload.isFilter).pipe(
              
                map((response) => 
                    reportPromissoryNoteActions.GetInfoReportSuccess({
                        payload: response.promissory_note_report as IPromissory_Note_Report
                    })
                ),
                catchError((error) => 
                    of(reportPromissoryNoteAction.GetInfoReportError({payload:error}))
                )
            )
          )
      )
  );

  getReportDetail$ = createEffect( () =>
    this.actions$.pipe(
      debounceTime(800),
      ofType(reportPromissoryNoteActions.GetReportDetail),
      switchMap( (action) =>
        this.reportServices.getDetailReport(action.payload.filter,action.payload.exportFile).pipe(
          map((response) => 
            reportPromissoryNoteActions.GetReportDetailSuccess({
                payload: response.promissory_note_details
            })
          ),
          catchError((error) => 
              of(reportPromissoryNoteAction.GetReportDetailError({payload:error}))
          )
        )
      )
    )
  );

  getBeneficiariesFilter$ = createEffect( () =>
    this.actions$.pipe(
    ofType(reportPromissoryNoteActions.GetFilterBeneficiaries),
    switchMap( (action) => 
    this.reportServices.getBeneficiaries().pipe(
        map((response) =>
        reportPromissoryNoteActions.GetFilterBeneficiariesSuccess({
          payload: response as IPromissoryNote_Report_Beneficiaries_Filter
        })
      ),
      catchError((error)=>
        of(reportPromissoryNoteAction.GetFilterBeneficiariesError({payload:error}))
      )
    )))
  );

  getSuscriptorFilter$ = createEffect( () =>
    this.actions$.pipe(
    ofType(reportPromissoryNoteActions.GetFiltersuscriptor),
    switchMap( (action) => 
    this.reportServices.getSuscritor().pipe(
        map((response) =>
        reportPromissoryNoteActions.GetFiltersuscriptorSuccess({
          payload: response as IPromissoryNote_Report_suscriptor_Filter
        })
      ),
      catchError((error)=>
        of(reportPromissoryNoteAction.GetFiltersuscriptorError({payload:error}))
      )
    )))
  );

  downloadReportDetailsPromissoryNote$ = createEffect( () =>
    this.actions$.pipe(
      ofType(reportPromissoryNoteActions.DownloadReportDetailsPromissoryNote),
      switchMap( (action) =>
        this.reportServices.getDetailReportDownload(action.payload.filter,action.payload.exportFile).pipe(
          tap((response: Blob) =>
                this.reportServices.saveDownloadFileAs(
                  response,
                  'report_detail.xlsx'
                )
          )
        )
      )
    ),
    { dispatch: false }
  );

}