import * as freeSignatureActions from '@literax/store/free-signature/free-signature.actions';
import * as signatureActions from '@literax/store/signature/signature.actions';

import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SignRequest, SingRequestForm } from '@literax/models/http/api/signature.model';
import { Store, select } from '@ngrx/store';
import { asn1, md, pki, util } from 'node-forge';
import {
  selectCertificateErrors,
  selectKeyErrors,
  selectSignDataErrors,
  selectSignatureErrorsForm,
} from '@literax/store/signature/signature.selector';

import { CustomFormValidator } from '@literax/components/shared/form-lib/custom-form.validator';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISignRequest } from '@literax/store/document/document.state';
import { TranslateService } from '@ngx-translate/core';
import { getSelectAll } from '@literax/components/configurations/profiles/states/profiles.selector';
import { merge } from 'rxjs';
import { selectFreeSignatureErrorsForm } from '@literax/store/free-signature/free-signature.selector';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { uuidv4 } from '../form-lib/uuid';

@Component({
  selector: 'literax-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss'],
})
export class SignDocumentComponent implements OnInit, OnDestroy {
  form: FormGroup;
  @Input() showCancel = true;
  @Input() showSubmit = true;
  @Input() documents: IAttachment[];
  @Input() document: IDocument;
  @Input() currentCertName: string;
  @Input() token: string;
  @Output() cancelClicked = new EventEmitter();
  @Output() submitClicker = new EventEmitter();
  @ViewChild('fileForm', { static: true })
  fileForm: ElementRef<HTMLFormElement>;
  @Input() requiredCertificated: boolean;
  permittedCertificateFiles = ['.cer'];
  permittedKeyFiles = ['.key'];
  permitedImageFile = ['.jpg', '.jpeg', '.png', '.pdf'];
  identification = false;
  representativeLabel = '';
  moralLabel = 'API.SIGNATURE_TYPES.LEGAL_PERSON';

  attachmentCount: number;

  certificateAPIErrors$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCertificateErrors)
  );
  signDataAPIErrors$ = this.store.pipe(
    untilDestroyed(this),
    select(selectSignDataErrors)
  );
  keyAndSignDataAPIErrors$ = this.store.pipe(
    untilDestroyed(this),
    select(selectKeyErrors)
  );
  profileInfo$ = this.store.pipe(select(getSelectAll));
  errorsSession$ = this.store.pipe(
    untilDestroyed(this),
    select(selectSignatureErrorsForm)
  );
  errorsFree$ = this.store.pipe(
    untilDestroyed(this),
    select(selectFreeSignatureErrorsForm)
  );
  serverErrors$ = merge(this.errorsFree$, this.errorsSession$);

  isLegalRepresentative: boolean = false;

  private _validateIfTermsAccepted(): boolean {
    return this.form.controls.acceptTerms.status === 'VALID';
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress(e: KeyboardEvent): void {
    if (
      (e.code === 'enter' || e.charCode === 13 || e.keyCode === 13) &&
      !this._validateIfTermsAccepted()
    ) {
      e.preventDefault();
    }
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<IAppState>,
    private toastr: I18nToastrService,
    translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.isLegalRepresentative = this.document.user_signer.some(
      (signer: ISignRequest) =>
        signer.signature_type_name === 'legal_person' &&
        this.document.user.email === signer.email &&
        this.document.legal_representative.some(
          (representative) => representative.company_rfc === signer.company_rfc
        )
    );
    this.form = this.fb.group({
      representative: this.generateForm(this.document?.user_signer[0]),
      acceptTerms: [false, Validators.requiredTrue],
    });
    this.identification = this.document.user_signer[0].oficial_identification;
    this.validateSigned();
  }

  validateSigned() {
    if (this.requiredCertificated) {
      const signer = this.document.user_signer.find(
        (sign: ISignRequest) => sign.signature_type_id === 2
      );
      if (signer) {
        this.representativeLabel = 'AUTH.LEGAL_PERSON';
        this.form.addControl('moral', this.generateFormMoral());
      }
    }
    if (this.document && this.document.attachments) {
      this.attachmentCount = this.document.attachments.filter(
        (a) => !a.primary
      ).length;
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(signatureActions.ClearSignatureResult());
  }

  generateFormMoral() {
    const controlArray = this.fb.array([]);
    this.document.user_signer.forEach((signer) => {
      if (signer.signature_type_id === 2) {
        const form = this.generateForm(signer);
        controlArray.push(form);
      }
    });
    return controlArray;
  }

  cancel(event: Event) {
    this.cancelClicked.emit(event);
  }

  submit() {
    this.fileForm.nativeElement.dispatchEvent(
      new Event('submit', { cancelable: true })
    );
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.onSignClicked();
  }

  getPrivateKeyFromFile(keyFile: string, password: string) {
    if (!keyFile || !password || password === '' || keyFile === '') {
      return false;
    }

    const pkeyDer = util.decode64(keyFile);
    const pkeyAsn1 = asn1.fromDer(pkeyDer);

    try {
      const privateKeyInfo = pki.decryptPrivateKeyInfo(pkeyAsn1, password);
      if (!privateKeyInfo) {
        return false;
      }
      return pki.privateKeyFromAsn1(privateKeyInfo);
    } catch (error) {
      return false;
    }
  }

  sign(
    document: string,
    keyFile: string,
    password: string,
    formGroup?
  ): string {
    if (!document || !keyFile) {
      this.toastr.error('TOAST_NOTIFICATIONS.SIGN_MESSAGE_KEY', '');
      return '';
    }

    if (!this.form.get('representative').get('certificate').value) {
      this.toastr.error('TOAST_NOTIFICATIONS.SIGN_MESSAGE_CER', '');
      return '';
    }

    const privateKey = this.getPrivateKeyFromFile(keyFile, password);

    if (privateKey) {
      const messageDigest = md.sha256.create();
      messageDigest.update(document, 'utf8');
      // @ts-ignore
      const signature = privateKey.sign(messageDigest);
      return util.encode64(signature);
    } else if (formGroup) {
      formGroup.get('password').setErrors({ incorrectPassword: true });
      return '';
    } else {
      this.form
        .get('representative')
        .get('password')
        .setErrors({ incorrectPassword: true });
      return '';
    }
  }

  /**
   * 1. Gets the base64 portion of the key file string
   * 2. Decodes the base 64 file and gets the DER representation
   * 3. Loads an asn1 representation from the DER file
   * 4. This asn1 representation is wrapped by a PKCS#8 with a password, decode it
   * 5. Get private key from unwrapped asn1
   * 6. Create message digest of string to sign (base64 representation of document)
   * 7. Sign message digest with private key
   * 8. Encode binary signature to base64 and send
   */
  onSignClicked() {
    if (!this.form.get('representative').get('password').valid) {
      this.form.controls.password.setErrors({ incorrectPassword: true });
      return false;
    }

    if (this.document.attachments.length < 1) {
      this.form
        .get('representative')
        .get('password')
        .setErrors({ blankDocument: true });
      return false;
    }
    const singRequestForm = this.generateSignature();
    if (!this.form.valid) {
      return false;
    }
    if (this.token) {
      this.store.dispatch(
        freeSignatureActions.CreateSignature({
          payload: { token: this.token, params: singRequestForm },
        })
      );
    } else {
      this.store.dispatch(
        signatureActions.CreateSignature({
          payload: { documentId: this.document.id, params: singRequestForm },
        })
      );
    }
  }

  generateSignature() {
    const keyBase64Representative =
      this.form.get('representative').get('key').value &&
      this.form.get('representative').get('key').value.base64.split(',')[1];
    const passwordRepresentative = this.form
      .get('representative')
      .get('password').value;
    const signature: SingRequestForm = {
      sign_requests: [],
      cert: this.form.get('representative').get('certificate').value.base64,
    };
    if (this.form.get('moral')) {
      const arrayMoral = this.form.get('moral') as FormArray;
      for (let i = 0; i < arrayMoral.length; i++) {
        const key =
          arrayMoral.at(i).get('key').value &&
          arrayMoral.at(i).get('key').value.base64.split(',')[1];
        const password = arrayMoral.at(i).get('password').value;
        const signer = {
          id: arrayMoral.at(i).get('id').value,
          resource_uuid: arrayMoral.at(i).get('resource_uuid').value,
          signatures_attributes: this.signatureAttributes(
            keyBase64Representative,
            passwordRepresentative,
            arrayMoral.at(i),
            arrayMoral.at(i).get('certificate').value.base64,
            true,
            key,
            password
          ),
        };
        signature.sign_requests.push(signer);
      }
    }

    const signRequests = this.filterSignRequests(
      keyBase64Representative,
      passwordRepresentative,
      signature.sign_requests
    );
    signature.sign_requests = [...signature.sign_requests, ...signRequests];

    return signature;
  }

  signatureAttributes(
    key: string,
    password: string,
    formGroup?: AbstractControl,
    cert?: string,
    isMoral?: boolean,
    keyL?: string,
    passwordL?: string
  ) {
    const signaturesAttributes = this.document.attachments.map(
      (attachment: IAttachment) => {
        const signature = this.sign(
          attachment.text.replace(/^data:application\/pdf;base64,/, ''),
          key,
          password
        );
        if (isMoral) {
          const signatureLegal = this.sign(
            attachment.text.replace(/^data:application\/pdf;base64,/, ''),
            keyL,
            passwordL,
            formGroup
          );
          const legalSignatureAttributesData = {
            sign_data: signatureLegal,
            legal_cert: cert,
          };
          return {
            sign_data: signature,
            attachment_id: attachment.id,
            id_image: this.identification
              ? this.form.get('representative').get('id_image')?.value
              : null,
            legal_signature_attributes: legalSignatureAttributesData,
          };
        } else if (!isMoral && this.identification) {
          return {
            sign_data: signature,
            attachment_id: attachment.id,
            id_image: formGroup.get('id_image')?.value,
          };
        } else {
          return { sign_data: signature, attachment_id: attachment.id };
        }
      }
    );
    return signaturesAttributes;
  }

  generateForm(signer?: ISignRequest) {
    const form = this.fb.group({
      certificate: new FormControl(null, [
        CustomFormValidator.requiredFileType(...this.permittedCertificateFiles),
        CustomFormValidator.maxFileSize(70),
      ]),
      key: new FormControl(null, [
        Validators.required,
        CustomFormValidator.requiredFileType(...this.permittedKeyFiles),
        CustomFormValidator.maxFileSize(70),
      ]),
      password: new FormControl('', [Validators.required]),
      id: signer.id,
      resource_uuid: uuidv4(),
      id_image: new FormControl(null),
    });

    if (signer.oficial_identification) {
      form
        .get('id_image')
        .setValidators([
          Validators.required,
          CustomFormValidator.requiredFileType(...this.permitedImageFile),
          CustomFormValidator.maxFileSize(70),
        ]);
    }
    return form;
  }

  filterSignRequests(
    keyBase64Representative: string,
    passwordRepresentative: string,
    moralSignRequest: SignRequest[]
  ) {
    const signRequests = [];
    this.document.user_signer
      .filter((sr) => sr.rfc === this.document.user_signer[0].rfc)
      .forEach((sign_request: ISignRequest) => {
        let isMoralSR = moralSignRequest.find((sRM) => sRM.id === sign_request.id);
        if (!isMoralSR) {
          const signer = {
            id: sign_request.id,
            resource_uuid: this.form.get('representative').get('resource_uuid')
              .value,
            signatures_attributes: this.signatureAttributes(
              keyBase64Representative,
              passwordRepresentative,
              this.form.get('representative')
            ),
          };
          signRequests.push(signer);
        }
      });
    return signRequests;
  }
}
