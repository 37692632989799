import * as registrationActions from './registration.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  IRegistrationState,
  initialRegistrationState
} from './registration.state';

const registrationReducer = createReducer(
  initialRegistrationState,
  on(registrationActions.cleanRegistrationState, (state, action) => ({ 
    ...state,
    signUpSuccess: null,
    signUpErrors: null,
    verifySuccess: null,
    verifyErrors: null
  })),
  on(registrationActions.signUpSuccess, (state, action) => ({ ...state, signUpSuccess: action.payload })),
  on(registrationActions.signUpError, (state, action) => ({ ...state, signUpErrors: action.payload })),
  on(registrationActions.verifySuccess, (state, action) => ({ ...state, verifySuccess: action.payload })),
  on(registrationActions.verifyError, (state, action) => ({ ...state, verifyErrors: action.payload }))
);

export function registrationReducers(state: IRegistrationState | undefined, action: Action) {
  return registrationReducer(state, action);
}
