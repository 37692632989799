import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {
  NgbDatepickerModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { ApiErrorComponent } from './api-error/api-error.component';
import { ApplicantComponent } from '../platform/workspace/applicants/applicant/applicant.component';
import { ApplicantsListContainerComponent } from '../platform/workspace/applicants/applicants-list-container/applicants-list-container.component';
import { ApproverComponent } from '../platform/workspace/approvers/approver/approver.component';
import { ApproversListContainerComponent } from '../platform/workspace/approvers/approvers-list-container/approvers-list-container.component';
import { AssignmentDialogComponent } from './assignment-dialog/assignment-dialog.component';
import { AttachmentComponent } from '../platform/workspace/attachments/attachment/attachment.component';
import { AttachmentsComponent } from '../platform/workspace/attachments/attachments.component';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { ButtonComponent } from './button/button.component';
import { ButtonsBarComponent } from '../platform/workspace/buttons-bar/buttons-bar.component';
import { ButtonsBarV2Component } from '../platform/workspace/buttons-bar-v2/buttons-bar-v2.component';
import { CalendarActiviyComponent } from './calendar-activiy/calendar-activiy.component';
import { ChartsModule } from 'ng2-charts';
import { CircleComponent } from './circle/circle.component';
import { ClickStopPropagationDirective } from '@literax/components/shared/directives/click-stop-propagation.directive';
import { CommentsComponent } from '../platform/workspace/comments/comments.component';
import { CommentsSectionComponent } from '../platform/document-preview/comments-section/comments-section.component';
import { CommonModule } from '@angular/common';
import { ComposeAttachmentFileComponent } from './compose-attachment-file/compose-attachment-file.component';
import { ConfirmDialogApprovalComponent } from './confirm-dialog-approval/confirm-dialog-approval.component';
import { ControlErrorComponent } from './control-error/control-error.component';
import { ControlErrorContainerDirective } from './form-lib/directives/control-error-container.directive';
import { ControlErrorsDirective } from './form-lib/directives/control-errors.directive';
import { DateDropdownComponent } from './date-dropdown/date-dropdown.component';
import { DebounceClickDirective } from './form-lib/directives/debounce-click.directive';
import { DigitalSignatureFormComponent } from './digital-signature-form/digital-signature-form.component';
import { DocumentAttachmentsContainerComponent } from './document-attachments-container/document-attachments-container.component';
import { DocumentDetailInformationComponent } from './document-detail-information/document-detail-information.component';
import { DocumentPreviewModalComponent } from '../platform/document-preview/document-preview-modal/document-preview-modal.component';
import { DocumentTabComponent } from './document-tab/document-tab.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DynamicPipe } from '@literax/pipes/dynamic.pipe';
import { DynamicTabsDirective } from './tabs/dynamic-tabs.directive';
import { E404Component } from './e404/e404.component';
import { E404ValidateTokenComponent } from './e404-validate-token/e404-validate-token.component';
import { FileDndDirective } from './form-lib/directives/file-dnd.directive';
import { FileInputComponent } from './file-input/file-input.component';
import { FormSubmitDirective } from './form-lib/directives/form-submit.directive';
import { GdxFormComponentsModule } from 'gdx-form-components';
import { GdxModule } from '@literax/gdx/gdx.module';
import { HistoryComponent } from '../platform/workspace/history/history.component';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '@literax/services/translate/http-loader.factory';
import { IFPDFComponent } from './if-pdf/if-pdf.component';
import { InputComponent } from './input/input.component';
import { LegalGridComponent } from './legal-grid/legal-grid.component';
import { LegalGridV2Component } from './legal-grid-v2/legal-grid-v2.component';
import { LegalRepresentativesDialogComponent } from './legal-representatives-dialog/legal-representatives-dialog.component';
import { LenguageComponent } from './lenguage/lenguage.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoComponent } from './logo/logo.component';
import { MarkdownModule } from 'ngx-markdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MenuButtonDocumentComponent } from './menu-button-document/menu-button-document.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ModalAttachmentComponent } from './compose-attachment-file/components/modal-attachment/modal-attachment.component';
import { NameInitials } from '@literax/pipes/name-initials.pipe';
import { NavbarComponent } from './navbar/navbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotHiredComponent } from './not-hired/not-hired.component';
import { OnDragDirective } from './form-lib/directives/on-drag.directive';
import { OnDragEndDirective } from './form-lib/directives/on-drag-end.directive';
import { OnDragEnterDirective } from './form-lib/directives/on-drag-enter.directive';
import { OnDragLeaveDirective } from './form-lib/directives/on-drag-leave.directive';
import { OnDragOverDirective } from './form-lib/directives/on-drag-over.directive';
import { OnDragStartDirective } from './form-lib/directives/on-drag-start.directive';
import { OnDropDirective } from './form-lib/directives/on-drop.directive';
import { PopoverComponent } from './popover/popover.component';
import { PrettifyXML } from '@literax/pipes/prettify-xml.pipe';
import { ProcessTabComponent } from './process-tab/process-tab.component';
import { ProcessesTabBarComponent } from './processes-tab-bar/processes-tab-bar.component';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { RegisterPaymentComponent } from './register-payment/register-payment.component';
import { RegisterPaymentContainerComponent } from './register-payment-container/register-payment-container.component';
import { ReviewedComponent } from './reviewed/reviewed.component';
import { ReviewerComponent } from '../platform/workspace/reviewers/reviewer/reviewer.component';
import { ReviewersListContainerComponent } from '../platform/workspace/reviewers/reviewers-list-container/reviewers-list-container.component';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SectionToggleComponent } from './section-toggle/section-toggle.component';
import { SignDocumentComponent } from './sign-document/sign-document.component';
import { SignModalComponent } from '../platform/sign-modal/sign-modal.component';
import { SignatureCanvasComponent } from './signature-canvas/signature-canvas.component';
import { SignatureFormComponent } from './signature-form/signature-form.component';
import { SignedComponent } from './signed/signed.component';
import { SignedListContainerComponent } from './signed-list-container/signed-list-container.component';
import { SignerInfoComponent } from '../platform/document-preview/signer-info/signer-info.component';
import { SignersComponent } from '../platform/workspace/signers/signers.component';
import { SortByPipe } from '@literax/pipes/sort-by.pipe';
import { TabComponent } from './tabs/tab/tab.component';
import { TabbedContainerComponent } from './tabbed-container/tabbed-container.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from './tabs/tabs.component';
import { TaskComponent } from './calendar-activiy/task/task.component';
import { TitleNavbarComponent } from './title-navbar/title-navbar.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TranslateMomentDatePipe } from '@literax/pipes/translate-moment-date.pipe';
import { ValidatePermissionDirective } from '@literax/components/shared/directives/validate-permission.directive';
import { ValidateRfcComponent } from './validate-rfc/validate-rfc.component';
import { ValidateSignatureTypeDirective } from '@literax/directives/validateSignatureType.directive';
import { ValueFromColumn } from '@literax/pipes/value-from-column.pipe';
import { ViewerComponent } from '../platform/workspace/viewer/viewer.component';
import { VirtualScrollComponent } from './virtual-scroll/virtual-scroll.component';
import { WorkspaceComponent } from '../platform/workspace/workspace.component';
import { environment } from '@environments/environment';
import { DelegateProcessDialogComponent } from './delegate-process-dialog/delegate-process-dialog.component';
import { ConfirmDialogDelegateComponent } from './confirm-dialog-delegate/confirm-dialog-delegate.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ChartsModule,
  MatRadioModule,
  MatTabsModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatTooltipModule,
  MatCheckboxModule,
  RouterModule,
  MatButtonModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatDialogModule,
  MatNativeDateModule,
  MatStepperModule,
  MatRippleModule,
  MatButtonToggleModule,
  MatBadgeModule,
  MatInputModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  GdxModule,
  ScrollingModule,
  MarkdownModule.forChild(),
  MatExpansionModule,
  NgSelectModule,
  NgbDatepickerModule,
  NgbTooltipModule,
  GdxFormComponentsModule.forChild({
    apiUrl: environment.recordUrl,
    font: "'Roboto'",
    urlParams: {
      collection: 'oVpcUgPA9LZ7wM6cN30q5kEw_TOZHEf1SPh14TWFhKw',
    },
  }),
  TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    isolate: false,
  }),
  DragDropModule,
  MatRadioModule,
  NzEmptyModule,
];

const components = [
  AttachmentComponent,
  AttachmentsComponent,
  TabbedContainerComponent,
  ProcessTabComponent,
  DocumentTabComponent,
  ProcessesTabBarComponent,
  ControlErrorComponent,
  ApiErrorComponent,
  NotFoundComponent,
  SignedComponent,
  NavbarComponent,
  NameInitials,
  PrettifyXML,
  MenuItemComponent,
  SearchBarComponent,
  DropdownComponent,
  InputComponent,
  DateDropdownComponent,
  FileInputComponent,
  VirtualScrollComponent,
  CircleComponent,
  AuthNavbarComponent,
  LoadingComponent,
  DocumentPreviewModalComponent,
  WorkspaceComponent,
  CommentsComponent,
  SignersComponent,
  CommentsSectionComponent,
  SignDocumentComponent,
  SignerInfoComponent,
  ViewerComponent,
  ButtonsBarComponent,
  ButtonsBarV2Component,
  IFPDFComponent,
  SignModalComponent,
  SignatureFormComponent,
  TitleNavbarComponent,
  LogoComponent,
  ProfileImageComponent,
  DocumentDetailInformationComponent,
  SignedListContainerComponent,
  DocumentAttachmentsContainerComponent,
  NotHiredComponent,
  LegalGridComponent,
  LegalGridV2Component,
  ReviewersListContainerComponent,
  ReviewerComponent,
  TableComponent,
  TabsComponent,
  TabComponent,
  ReviewedComponent,
  PopoverComponent,
  HistoryComponent,
  SectionToggleComponent,
  ApproversListContainerComponent,
  ApproverComponent,
  ApplicantsListContainerComponent,
  ApplicantComponent,
  AssignmentDialogComponent,
  ButtonComponent,
  ValidateRfcComponent,
];

const directives = [
  ControlErrorsDirective,
  ControlErrorContainerDirective,
  ValidateSignatureTypeDirective,
  FormSubmitDirective,
  FileDndDirective,
  OnDragDirective,
  OnDropDirective,
  OnDragEnterDirective,
  OnDragLeaveDirective,
  OnDragStartDirective,
  OnDragEndDirective,
  OnDragOverDirective,
  DebounceClickDirective,
  ClickStopPropagationDirective,
  DynamicTabsDirective,
  ValidatePermissionDirective,
];

const pipes = [
  TranslateMomentDatePipe,
  SortByPipe,
  ValueFromColumn,
  DynamicPipe,
];

@NgModule({
  imports: [modules],
  declarations: [
    components,
    directives,
    pipes,
    SignatureCanvasComponent,
    RegisterPaymentComponent,
    RegisterPaymentContainerComponent,
    E404Component,
    DigitalSignatureFormComponent,
    ToggleSwitchComponent,
    CalendarActiviyComponent,
    TaskComponent,
    MenuButtonDocumentComponent,
    LegalRepresentativesDialogComponent,
    LenguageComponent,
    MessageDialogComponent,
    E404ValidateTokenComponent,
    ComposeAttachmentFileComponent,
    ModalAttachmentComponent,
    ConfirmDialogApprovalComponent,
    DelegateProcessDialogComponent,
    ConfirmDialogDelegateComponent,
  ],
  exports: [
    modules,
    components,
    directives,
    pipes,
    ToggleSwitchComponent,
    CalendarActiviyComponent,
    MenuButtonDocumentComponent,
    LenguageComponent,
  ],
  entryComponents: [
    ProcessTabComponent,
    ProcessesTabBarComponent,
    DocumentTabComponent,
    TabbedContainerComponent,
    ControlErrorComponent,
    ApiErrorComponent,
    SignModalComponent,
    SignatureFormComponent,
    RegisterPaymentContainerComponent,
    LegalRepresentativesDialogComponent,
    MessageDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class SharedModule {}
