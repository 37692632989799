import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ISignatureResponse } from '@literax/models/http/api/signature.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IUser } from '@literax/models/user.model';
import { ISignRequest } from '@literax/store/document/document.state';

@Component({
  selector: 'literax-reviewers-list-container',
  templateUrl: './reviewers-list-container.component.html',
  styleUrls: ['./reviewers-list-container.component.scss'],
})
export class ReviewersListContainerComponent implements OnInit {
  @Input() document: IDocument;
  @Input() reviewerRequest: ISignRequest[];
  @Input() user: IUser;

  @Output() addReviewer: EventEmitter<void> = new EventEmitter<void>();
  @Output() actionButton = new EventEmitter();
  @Output() historyButton: EventEmitter<any> = new EventEmitter<any>();
  contentVisible = true;
  signatures: { [key: string]: ISignatureResponse } = {};

  isUserLoginObserver = false;

  constructor() {}

  ngOnInit(): void {}

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }
}
