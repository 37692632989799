<div
  class="search-container"
  [ngClass]="divActive ? 'active-div' : ''"
  [class.disabled]="readOnly"
>
  <input
    matInput
    [placeholder]="searchLabel"
    (focus)="toggleActive()"
    (focusout)="toggleActive()"
    [formControl]="formControl"
    [readonly]="readOnly"
    autocomplete="off"
  />
  <div class="append" [ngClass]="divActive ? 'active-append' : ''">
    <i class='fal fa-search'></i>
  </div>
</div>
