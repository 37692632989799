<form [formGroup]="commentsForm" (ngSubmit)="onSubmit()">
	<div class="form-title d-flex justify-content-between">
		<h3>
			{{ data?.message | translate }}
		</h3>
		<button type="button" (click)="dialogRef.close(false)">
			<i class="fal fa-times"></i>
		</button>
	</div>

	<div class="col-12 px-0">
		<div class="form-group mb-0">
			<label class="label"> {{'DOCUMENTS.COMMENTS' | translate}} </label>
			<textarea
				gdxInput
				class="form-control"
				formControlName="comments"
				maxlength="255"
				rows="4"
				[placeholder]="'DOCUMENTS.COMMENTS' | translate"
			>
      </textarea>
		</div>
	</div>

	<div class="col-12 px-0">
		<div class="row buttons mx-0">
			<button
				type="button"
				class="d-block interfirma-button secondary"
				(click)="dialogRef.close(false)"
			>
				{{ data?.cancelButton | translate }}
			</button>
			<button class="d-block interfirma-button primary" type="submit">
				{{ data?.acceptButton | translate }}
			</button>
		</div>
	</div>
</form>
