import { IFolder, IPromissoryNotes } from '@literax/models/promissory-notes.model';
import { createSelector } from '@ngrx/store';
import { IAppState } from '..';
import { ISignRequest } from '../document/document.state';
import { IPromissoryNotesState } from './promissory-notes.state';
const selectPromissoryNotes = (state: IAppState) => state.promissoryNotes;

export const selectConfigData = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state && state.configData
);

export const selectPromissoryConfigData = createSelector(
  selectConfigData,
  (state: IPromissoryNotes) => state && state.configData
);

export const selectParameters = createSelector(
  selectConfigData,
  (state: IPromissoryNotes) => state && state.params
);

export const selectDefaultData = createSelector(
  selectConfigData,
  (state: IPromissoryNotes) => state && state.defaultData
);

export const selectPromissoryNote = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state && state.selectPromissoryNote
);

export const selectPromissoryNoteActions = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state?.selectPromissoryNote?.actions
);

export const selectDocumentMain = createSelector(
  selectPromissoryNote,
  (state: IFolder) => state && state.main_document
);
export const selectDocuments = createSelector(
  selectPromissoryNote,
  (state: IFolder) => state && state.documents
);

export const selectAllDocumentsSingRequest = createSelector(
  selectPromissoryNote,
  (state: IFolder) => {
    const legalRepresentatives = [];

    let arraySigner: ISignRequest[] = [];
    if (state.main_document && state.main_document.user_signer === null) {
      arraySigner = arraySigner.concat(state.main_document.sign_requests || []);
    } else {
      state.documents.forEach((document) => {
        document.legal_representative?.forEach((representative) => {
          representative.legal_representatives.forEach(
            (legalRepresentative) => {
              legalRepresentatives.push(legalRepresentative);
            }
          );
        });
      });
      state.main_document.legal_representative?.forEach((representatives) => {
        representatives.legal_representatives.forEach((representative) => {
          legalRepresentatives.push(representative);
        });
      });
      const arraySignerUserMain = [
        ...legalRepresentatives,
        ...state.main_document.sign_requests
      ];
      arraySigner = arraySigner.concat(arraySignerUserMain);
    }
    if (state.documents) {
      state.documents.forEach((document) => {
        if (document.user_signer === null) {
          arraySigner = arraySigner.concat(document.sign_requests || []);
        } else {
          const arraySignerDocuments = [...document.sign_requests];
          arraySigner = arraySigner.concat(arraySignerDocuments);
        }
      });
    }
    return arraySigner;
  }
);

export const getBeneficiaryAll = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state.promissoryNotes
);

export const getSelectFlows = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state && state.flows && state.flows.flows
);

export const getBeneficiary = createSelector(
  selectPromissoryNote,
  (state: IFolder) => state && state?.holder && state.holder.client
);

export const getCurrency = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state && state.currencys
);

export const getPayments = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => {
    if (state && state?.payments) {
      const arrayPayments = state.payments
        .filter((item) => item.document_id === null)
        .map((item) => {
          return {
            value: item.id,
            label:
              item.payment_date +
              ' | ' +
              new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'MXN'
              }).format(item.capital_amount)
          };
        });
      const notPayment = {
        value: 0,
        label: 'WORKSPACE_PROMISSORY.ACCEPT_PAYMENT.FORM.NOT_PAYMENT'
      };
      arrayPayments.push(notPayment);
      return arrayPayments;
    }
  }
);

export const getPromissoryNoteTemplate = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state.promissoryNoteTemplates
);

export const getPromissoryNoteTemplateSelect = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state.promissoryNoteTemplateSelect
);

export const getSelectPayment$ = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state && state.selectPayment
);

export const getPromissoryNoteWithTemplateSelect = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state.promissoryNoteWithData
);

export const getTemplateId = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state.templateId
);

export const hasPromissoryNoteTemplate = createSelector(
  selectPromissoryNotes,
  (state: IPromissoryNotesState) => state?.has_promissory_note_templates
);
