import { GdxErrorsMap } from '@interfactura/gdx-angular-ui';

export const GDX_ERRORS: GdxErrorsMap = {
  required: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.REQUIRED`,
  incorrectPassword: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.INCORRECT_PASSWORD`,
  email: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.EMAIL`,
  pattern: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.PATTERN`,
  min: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.MIN`,
  max: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.MAX`,
  minlength: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.MINLENGTH`,
  maxlength: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.MAXLENGTH`,
  rfc: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.RFC`,
  phone: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.PHONE`,
  clabe: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.CLABE`,
  emailTaken: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.EMAIL_TAKEN`,
  confirmedValidator: `GDX_ANGULAR_UI.FORM_CONTROLS.ERRORS.PASSWORD_MATCH`
};
