import {
  IDocument,
  ILegalRepresentative,
  ICancelatioReason
} from '@literax/models/http/document/document.model';

import { IAppState } from '../';
import { IFreeDocumentState } from './free-document.state';
import { createSelector } from '@ngrx/store';

const selectDocuments = (state: IAppState) => state.freeDocuments;

const selectErrors = (state: IFreeDocumentState, key: string): any => {
  return state[key] && state[key].error && state[key].error.detail;
};

function singRequestLegalRepresentative(
  legalRepresentative: ILegalRepresentative[]
) {
  const plainLegalRepresentatives = [];
  if (legalRepresentative) {
    legalRepresentative.forEach((legal) => {
      legal.legal_representatives.forEach((legalPerson) => {
        plainLegalRepresentatives.push(legalPerson);
      });
    });
  }
  return plainLegalRepresentatives;
}

export const selectDocumentList = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state.documents
);

export const selectDocument = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state.selectedDocument
);

export const selectAllDocumentSignRequests = createSelector(
  selectDocument,
  (state: IDocument) => {
    if (state.user_signer === null) {
      return (state && state.sign_requests) || [];
    } else {
      return [...state.user_signer, ...state.sign_requests];
    }
  }
);

export const selectAllSignersRequests = createSelector(
  selectDocument,
  (state: IDocument) => {
    const plainLegalRepresentatives = singRequestLegalRepresentative(
      state?.legal_representative
    );
    return (
      state &&
      [...state.sign_requests, ...plainLegalRepresentatives].sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      })
    );
  }
);

export const selectCreateDocumentNameError = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => {
    const errors = selectErrors(state, 'uploadErrors');
    return errors && errors.name;
  }
);

export const selectCreateDocumentFileError = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => {
    const errors = selectErrors(state, 'uploadErrors');
    return errors && errors.file;
  }
);

export const selectCreateDocumentUploadSuccess = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) =>
    state.uploadErrors && state.uploadErrors.success
);

export const selectLastDocumentUploaded = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state.lastDocument
);

export const selectCurrentPage = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state.currentPage
);

export const selectGetDocumentError = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => {
    return state.getDocumentError;
  }
);

export const getBeneficiaryFree = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state?.holder?.client
);

export const getCurrenciesFree = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state?.currencies
);

export const selectDocumentFree = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state.selectedDocument
);

export const selectAttachment = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state?.selectAttachment
);

export const selectAttachments = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state?.attachments
);

export const selectOnlyOfficeCredentials = createSelector(
  selectDocuments,
  (state: IFreeDocumentState) => state?.onlyoffice_token
);

export const selectCommentRejected = createSelector(
  selectDocuments,
  (state) => state?.commentRejected
);
