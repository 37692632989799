import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IShowSignatureResponse } from '@literax/models/http/api/signature.model';
import { IAppState } from '@literax/store';
import { selectShowSignatureResult } from '@literax/store/validator/validator.selector';
import { select, Store } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-legal-signers',
  templateUrl: './legal-signers.component.html',
  styleUrls: ['./legal-signers.component.scss'],
})
export class LegalSignersComponent implements OnInit, OnDestroy {
  signatureLegal$ = this.store.pipe(
    untilDestroyed(this),
    select(selectShowSignatureResult)
  );
  constructor(private store: Store<IAppState>) {}

  ngOnInit() {}
  ngOnDestroy() {}
}
