import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AuthState, initialAuthState } from './auth/auth.state';
import {
  ConfigState,
  initialConfigState
} from '@literax/store/config/config.state';
import {
  IApiConfigurationsInterface,
  initialApiConfigurationsState
} from '@literax/components/configurations/api/config/store/api.state';
import {
  IApproverRequestWorkspaceState,
  initialApproverRequestWorkspaceState
} from './approve-request/approve-request.state';
import {
  IAttachmentState,
  initialAttachmentState
} from './attachment/attachment.state';
import {
  ICLientUsersState,
  clientUserReducer,
  initialClientUserState
} from '@literax/components/configurations/users/states/users.reducer';
import { ICatalogState, initialCatalogState } from './catalog/catalog.state';
import { IClientState, initialClientState } from './client/client.state';
import {
  ICompanyState,
  initialCompanyState
} from '@literax/store/company/company.state';
import {
  IDashboardState,
  initialDashboardState
} from './dashboard/dashboard.state';
import {
  IDepartmentState,
  initialDepartmentState
} from '@literax/store/department/department.state';
import {
  IDocumentState,
  initialDocumentState
} from './document/document.state';
import {
  IFreeDocumentState,
  initialFreeDocumentState
} from './free-document/free-document.state';
import {
  IFreeSignatureState,
  initialFreeSignatureState
} from './free-signature/free-signature.state';
import {
  IProfilesState,
  initiaProfileslState,
  profileReducer
} from '@literax/components/configurations/profiles/states/profiles.reducer';
import {
  IPromissoryNotesState,
  initialPromissoryNotesState
} from './promissory-notes/promissory-notes.state';
import {
  IRegistrationState,
  initialRegistrationState
} from './registration/registration.state';
import {
  IReportState,
  initialReportState
} from '@literax/store/report/report.state';
import {
  IReviewRequestWorkspaceState,
  initialReviewRequestWorkspaceState
} from './review-request/review-request.state';
import { IRolState, initialRolState } from './rol/rol.state';
import {
  ISignRequestState,
  initialSignRequestState
} from './sign-request/sign-request.state';
import {
  ISignatureState,
  initialSignatureState
} from './signature/signature.state';
import { IStatusState, initialStatusState } from './status/status.state';
import {
  ITemplatesFieldsState,
  initialTemplatesFieldsState
} from '@literax/store/template-fields/templates-fields.state';
import {
  ITemplatesState,
  initialTemplatesState
} from '@literax/store/templates/templates.state';
import {
  ITitleNavbarState,
  initialTitleNavbarState
} from './title-navbar/title-navbar.state';
import {
  IUserProfiles,
  initiaUserProfileslState,
  userProfileReducer
} from '@literax/components/platform/profiles/store/user-profiles.reducer';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';

import { apiConfigurationsReducer } from '@literax/components/configurations/api/config/store/api.reducers';
import { approversReducer } from './approve-request/approve-request.reducer';
import { attachmentReducers } from './attachment/attachment.reducers';
import { authReducers } from './auth/auth.reducers';
import { catalogReducers } from '@literax/store/catalog/catalog.reducers';
import { clientReducers } from './client/client.reducers';
import { companyReducers } from '@literax/store/company/company.reducers';
import { configReducers } from '@literax/store/config/config.reducers';
import { dashboardReducers } from './dashboard/dashboard.reducers';
import { departmentReducers } from '@literax/store/department/department.reducers';
import { environment } from '../../environments/environment';
import { freeDocumentReducers } from './free-document/free-document.reducers';
import { freeSignatureReducer } from './free-signature/free-signature.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { promisoryNotesReducer } from './promissory-notes/promissory-notes.reducer';
import { reducer } from './document/document.reducers';
import { registrationReducers } from './registration/registration.reducers';
import { reportReducers } from '@literax/store/report/report.reducers';
import { reviewersReducer } from './review-request/review-request.reducer';
import { rolsReducers } from './rol/rol.reducers';
import { signRequestReducers } from './sign-request/sign-request.reducers';
import { signatureReducer } from './signature/signature.reducer';
import { statusReducers } from './status/status.reducers';
import { templatesFieldsReducer } from '@literax/store/template-fields/templates-fields.reducer';
import { templatesReducer } from '@literax/store/templates/templates.reducer';
import { titleNavbarReducers } from './title-navbar/title-navbar.reducer';
import { initialPromissoryNoteReportState, IPromissoryNoteReportState } from './report/report-promissory-note.state';
import { reportPromissoryNoteReducers } from './report/report-promissory-note.reducers';

export interface IAppState {
  router?: RouterReducerState;
  auth: AuthState;
  documents: IDocumentState;
  freeDocuments: IFreeDocumentState;
  registration: IRegistrationState;
  config: ConfigState;
  status: IStatusState;
  signRequests: ISignRequestState;
  signatures: ISignatureState;
  freeSignatures: IFreeSignatureState;
  dashboard: IDashboardState;
  attachments: IAttachmentState;
  users: ICLientUsersState;
  profiles: IProfilesState;
  titleNavbar: ITitleNavbarState;
  clients: IClientState;
  promissoryNotes: IPromissoryNotesState;
  templates: ITemplatesState;
  templatesFields: ITemplatesFieldsState;
  department: IDepartmentState;
  catalog: ICatalogState;
  rol: IRolState;
  reviewersWorkspace: IReviewRequestWorkspaceState;
  approversWorkspace: IApproverRequestWorkspaceState;
  apiConfigurations: IApiConfigurationsInterface;
  report: IReportState;
  company: ICompanyState;
  userProfiles: IUserProfiles;
  reportPromissoryNote: IPromissoryNoteReportState;
}

export const initialAppState: IAppState = {
  auth: initialAuthState,
  documents: initialDocumentState,
  freeDocuments: initialFreeDocumentState,
  registration: initialRegistrationState,
  config: initialConfigState,
  status: initialStatusState,
  signRequests: initialSignRequestState,
  signatures: initialSignatureState,
  freeSignatures: initialFreeSignatureState,
  dashboard: initialDashboardState,
  attachments: initialAttachmentState,
  users: initialClientUserState,
  profiles: initiaProfileslState,
  titleNavbar: initialTitleNavbarState,
  clients: initialClientState,
  promissoryNotes: initialPromissoryNotesState,
  templates: initialTemplatesState,
  templatesFields: initialTemplatesFieldsState,
  department: initialDepartmentState,
  catalog: initialCatalogState,
  rol: initialRolState,
  reviewersWorkspace: initialReviewRequestWorkspaceState,
  approversWorkspace: initialApproverRequestWorkspaceState,
  apiConfigurations: initialApiConfigurationsState,
  report: initialReportState,
  company: initialCompanyState,
  userProfiles: initiaUserProfileslState,
  reportPromissoryNote: initialPromissoryNoteReportState
};

export function getInitialState(): IAppState {
  return initialAppState;
}

export const reducers: ActionReducerMap<IAppState> = {
  router: routerReducer,
  auth: authReducers,
  documents: reducer,
  freeDocuments: freeDocumentReducers,
  registration: registrationReducers,
  config: configReducers,
  status: statusReducers,
  signRequests: signRequestReducers,
  signatures: signatureReducer,
  freeSignatures: freeSignatureReducer,
  dashboard: dashboardReducers,
  attachments: attachmentReducers,
  users: clientUserReducer,
  profiles: profileReducer,
  titleNavbar: titleNavbarReducers,
  clients: clientReducers,
  promissoryNotes: promisoryNotesReducer,
  templates: templatesReducer,
  templatesFields: templatesFieldsReducer,
  department: departmentReducers,
  catalog: catalogReducers,
  rol: rolsReducers,
  reviewersWorkspace: reviewersReducer,
  approversWorkspace: approversReducer,
  apiConfigurations: apiConfigurationsReducer,
  report: reportReducers,
  company: companyReducers,
  userProfiles: userProfileReducer,
  reportPromissoryNote: reportPromissoryNoteReducers
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth', 'clients', 'config', 'titleNavbar'],
    rehydrate: true
  })(reducer);
}

export const metaReducers: MetaReducer<IAppState>[] = !environment.production
  ? [localStorageSyncReducer]
  : [localStorageSyncReducer];
