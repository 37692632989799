import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { LegalColumns } from '@literax/models/legal-columns.model';
import { ISort } from '@literax/interfaces/sort-descriptor.interface';

@Component({
  selector: 'literax-legal-grid-v2',
  templateUrl: './legal-grid-v2.component.html',
  styleUrls: ['./legal-grid-v2.component.scss'],
})
export class LegalGridV2Component implements OnInit {
  @Input() columns: LegalColumns[];
  @Input() rowTemplate: TemplateRef<any>;
  @Input() list = [];
  @Input() height = '50vh';
  @Output() nexBatch: EventEmitter<number> = new EventEmitter<number>();
  @Output() sortChange: EventEmitter<ISort[]> = new EventEmitter();

  activeSortsCount = 0;

  sortFn = (x, y) => x.sort.order - y.sort.order;

  constructor() {}

  ngOnInit(): void {}

  onSortChange(event: MouseEvent, column: LegalColumns, index: number): void {
    if (column.sortColumn) {
      // * ctrlKey: Windows(control) || metaKey: Mac(command)
      if (event.ctrlKey || event.metaKey) {
        if (column.sort && column.sort.active) {
          if (column.sort.dir === 'desc') {
            // Rearrange order field
            column.sort = {
              ...column.sort,
              active: false,
            };
            this.rearrangeOrder(this.columns);
          } else {
            column.sort = {
              ...column.sort,
              dir: 'desc',
            };
          }
        } else {
          column.sort = {
            field: column.sort.field,
            dir: 'asc',
            order: this.countSortedCols() + 1,
            active: true,
          };
        }
      } else {
        if (column.sort && column.sort.active) {
          this.cleanSort(index);
          if (column.sort.dir === 'asc') {
            column.sort = {
              ...column.sort,
              dir: 'desc',
            };
          } else {
            this.cleanSort();
            column.sort = {
              ...column.sort,
              dir: 'asc',
            };
          }
        } else {
          this.cleanSort();
          column.sort = {
            field: column.sort.field,
            dir: 'asc',
            order: this.countSortedCols() + 1,
            active: true,
          };
        }
      }

      const sorts = this.columns
        .filter((x) => x.sort && x.sort.active)
        .sort(this.sortFn)
        .map((x) => x.sort);

      this.activeSortsCount = sorts.length;

      this.sortChange.emit(sorts);
    }
  }

  rearrangeOrder(columns: LegalColumns[]): void {
    const tempArr = columns
      .filter((column) => column.sort && column.sort.active)
      .sort(this.sortFn);
    let index = 1;
    for (let i = 0; i < tempArr.length; i++) {
      if (i === 0 && tempArr[i].sort.order !== 1) {
        index = 1;
      }
      tempArr[i].sort = {
        ...tempArr[i].sort,
        order: index,
      };
      index++;
    }
  }

  countSortedCols(): number {
    return this.columns.filter((x) => x.sort && x.sort.active).length;
  }

  cleanSort(excludeIndex?: number): void {
    this.columns
      .filter((column, index) => column.sort && index !== excludeIndex)
      .forEach((columnFilter) => {
        columnFilter = {
          ...columnFilter,
          sort: { ...columnFilter.sort, active: false },
        };
      });
  }
}
