import { createSelector } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { IRegistrationState } from './registration.state';

const selectRegistration = (state: IAppState) => state.registration;

export const selectSignUpSuccess = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.signUpSuccess
);

export const selectSignUpErrors = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.signUpErrors
);

export const selectVerifySuccess = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.verifySuccess
);

export const selectVerifyErrors = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.verifyErrors
);

export const selectUserError = createSelector(
  selectRegistration,
  (state: IRegistrationState) => {
    if (state.verifyErrors && state.verifyErrors.error.detail) {
      return state.verifyErrors.error.detail.user;
    }
  }
);

export const selectTaxIdError = createSelector(
  selectRegistration,
  (state: IRegistrationState) => {
    if (state.verifyErrors && state.verifyErrors.error.detail) {
      return state.verifyErrors.error.detail.tax_id;
    }
  }
);

export const selectEmailError = createSelector(
  selectRegistration,
  (state: IRegistrationState) => {
    if (state.signUpErrors && state.signUpErrors.error.detail) {
      return state.signUpErrors.error.detail.email;
    }
    return null;
  }
);

export const selectRFCError = createSelector(
  selectRegistration,
  (state: IRegistrationState) => {
    if (state.signUpErrors && state.signUpErrors.error.detail) {
      return state.signUpErrors.error.detail.rfc;
    }
    return null;
  }
);

export const selectPasswordError = createSelector(
  selectRegistration,
  (state: IRegistrationState) => {
    if (state.signUpErrors && state.signUpErrors.error.detail) {
      return state.signUpErrors.error.detail.password;
    }
    return null;
  }
);
