import * as documentActions from './document.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  catchError,
  concatMap,
  debounceTime,
  exhaustMap,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { AppUtils } from '@literax/utils/app.utils';
import { DashboardService } from '@literax/services/platform/dashboard.service';
import { DocumentsService } from '@literax/services/platform/documents.service';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IAppState } from '..';
import { IAttachment } from '@literax/models/http/attachment.model';
import { ICreateDocumentResponse } from '@literax/models/http/document/create-document.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IFlow } from '@literax/models/flow.model';
import { Injectable } from '@angular/core';
import { LoadingService } from '@literax/services/loading/loading.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ServiceResponse } from './../../interfaces/service-response';
import { TranslateService } from '@ngx-translate/core';
import { selectCurrentClient } from '../client/client.selectors';
import { selectCurrentLang } from '../config/config.selectors';
import { selectDocument } from './document.selectors';
import { EDocumentStatus } from '@literax/enums/document-status.enum';

function getOnlyOfficeKeyFromAttachent(attachments: Array<any>) {
  return attachments.find((a) => a.primary === true);
}

@Injectable()
export class DocumentEffects {
  getDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        documentActions.GetDocuments,
        documentActions.ChangeCurrentSort,
        documentActions.UpdateFilterState
      ),

      withLatestFrom(
        this.store$.select((state) => state.documents.currentPage)
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.activeFilter)
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.currentSort)
      ),
      withLatestFrom(this.store$.select((state) => state.documents.hidden)),
      debounceTime(400),
      switchMap(([[[[action, page], filter], sort], hidden]) => {
        return this.documentService.getDocuments(page, filter, sort, hidden);
      }),
      map((response: ServiceResponse) => {
        let res: IDocument[] = response.documents;
        res.forEach((element) => {
          if (element.status.key === 'signed') {
            element.actions.delete = false;
          }
        });
        return documentActions.GetDocumentsSuccess({
          payload: res as IDocument[],
        });
      })
    )
  );

  getDocumentsHidden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        documentActions.DocumentsHidden,
        documentActions.DocumentsHiddenTypeSuccess
      ),

      withLatestFrom(
        this.store$.select((state) => state.documents.currentPage)
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.activeFilter)
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.currentSort)
      ),
      withLatestFrom(this.store$.select((state) => state.documents.hidden)),

      switchMap(([[[[action, page], filter], sort], hidden]) => {
        return this.documentService.getDocuments(page, filter, sort, hidden);
      }),
      map((response: ServiceResponse) => {
        let res: IDocument[] = response.documents;
        res.forEach((element) => {
          if (element.status.key === 'signed') {
            element.actions.delete = false;
          }
        });
        return documentActions.DocumentsHiddenSuccess({
          payload: res as IDocument[],
        });
      })
    )
  );

  downloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DownloadDocument),
      switchMap((action) =>
        this.documentService.getDocumentUrlForDownload(action.payload.id).pipe(
          map((response: ServiceResponse) =>
            documentActions.SaveBlobDocument({ payload: response.document })
          ),
          catchError((err: HttpErrorResponse) => {
            this.getLanguage();
            forkJoin([
              this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.BODY'),
              this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.TITLE'),
            ]).subscribe(([message, title]) => {
              this.toastr.error(message, title);
            });
            return of(null);
          })
        )
      )
    )
  );

  saveBlobDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.SaveBlobDocument),
        switchMap((action) =>
          this.documentService.getBlob(action.payload.url).pipe(
            tap((blob: Blob) => {
              const extension = AppUtils.extensionFromMimeType(blob.type);
              this.documentService.saveDownloadFileAs(
                blob,
                `${action.payload.name}.${extension}`
              );
            }),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.BODY'),
                this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(null);
            })
          )
        )
      ),
    { dispatch: false }
  );

  downloadDocuments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DownloadDocuments),
        withLatestFrom(
          this.store$.select((state) => state.documents.activeFilter)
        ),
        switchMap(([action, filter]) =>
          this.documentService
            .downloadDocuments(filter)
            .pipe(
              tap((response: Blob) =>
                this.documentService.saveDownloadFileAs(
                  response,
                  'documents.xlsx'
                )
              )
            )
        ),
        catchError((err: HttpErrorResponse) => {
          this.getLanguage();
          forkJoin([
            this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.BODY'),
            this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.TITLE'),
          ]).subscribe(([message, title]) => {
            this.toastr.error(message, title);
          });
          return of(null);
        })
      ),
    { dispatch: false }
  );

  markAs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DocumentMarkAs),
        withLatestFrom(this.store$.select(selectDocument)),
        switchMap(([action, currentDocument]) =>
          this.documentService.markAs(currentDocument.id, action.payload).pipe(
            map((response: ServiceResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('TOAST_NOTIFICATIONS.PROCESS_SUCCESS'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([messageSuccess, title]) => {
                this.toastr.success(messageSuccess, title);
              });
              return documentActions.DocumentMarkAsSuccess({
                payload: response.document as IDocument,
              });
            }),
            catchError((response) => {
              this.getLanguage();
              this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                let message = '';
                response.error.error.detail?.status?.forEach(
                  (data) =>
                    (message =
                      message +
                      data.charAt(0).toUpperCase() +
                      data.slice(1) +
                      '. ')
                );
                response.error.error.detail?.signing_method?.forEach(
                  (data) =>
                    (message =
                      message +
                      data.charAt(0).toUpperCase() +
                      data.slice(1) +
                      '. ')
                );
                this.toastr.error(
                  response.error.error.detail
                    ? message
                    : response.error.error.title,
                  title
                );
              });
              return of(
                documentActions.DocumentMarkAsError({ payload: response })
              );
            }),
            finalize(() => {
              this.loading.hide();
            })
          )
        )
      ),
    { dispatch: true }
  );

  setDocumentMinApprovers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentSetMinApprovers),
      withLatestFrom(this.store$.select(selectDocument)),
      switchMap(([action, currentDocument]) =>
        this.documentService
          .setMinApprovers(currentDocument.id, action.payload)
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetDocumentSuccess({
                payload: response.document as IDocument,
              })
            ),
            catchError((response) => {
              this.getLanguage();
              this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                this.toastr.error(
                  response.error.error.detail['signing_method'][0],
                  title
                );
              });
              return of(null);
            })
          )
      )
    )
  );

  markAsReviewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DocumentMarkAsReviewed),
        withLatestFrom(this.store$.select(selectDocument)),
        switchMap(([action, currentDocument]) =>
          this.documentService
            .markAsReviewed(
              currentDocument.id,
              action.payload.action,
              action.payload.sign_request
            )
            .pipe(
              map((_: ServiceResponse) =>
                documentActions.GetDocument({ payload: currentDocument.id })
              ),
              catchError((response) => {
                this.getLanguage();
                this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                  this.toastr.error(
                    response.error.error.detail['signing_method'][0],
                    title
                  );
                });
                return of(null);
              })
            )
        )
      ),
    { dispatch: true }
  );

  updateSelectedDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UpdateSelectedDocument),
      withLatestFrom(this.store$.select(selectDocument)),
      map(([action, currentDocument]) => {
        return documentActions.GetDocument({
          payload: currentDocument && (currentDocument as IDocument).id,
        });
      })
    )
  );

  UpdateSelectedDocumentHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UpdateSelectedDocumentHierarchy),
      switchMap((action) =>
        this.documentService
          .updateSelectedDocumentHierarchy(
            action.payload.documentId,
            action.payload.hierarchy
          )
          .pipe(
            map((response: ServiceResponse) => {
              return documentActions.GetDocument({
                payload: action.payload.documentId,
              });
            }),
            catchError(() => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(null);
            })
          )
      )
    )
  );

  updateFreeDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.UpdateFreeDocument),
        tap(() => this.router.navigate(['/signed']))
      ),
    { dispatch: false }
  );

  updateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UpdateDocument),
      filter((action) => action.payload != null),
      switchMap((action) =>
        this.documentService
          .updateDocument(action.payload.documentId, action.payload.document)
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetDocumentSuccess({
                payload: response.document as IDocument,
              })
            ),

            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(
                documentActions.GetDocumentError({
                  payload: err.error as IAPIResponseError,
                })
              );
            })
          )
      )
    )
  );

  getDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetDocument),
      filter((action) => action.payload != null),
      withLatestFrom(this.store$.select(selectCurrentClient)),
      switchMap(([action, client]) => {
        return this.documentService.getDocumentDetail(action.payload).pipe(
          concatMap((response: ServiceResponse) => [
            documentActions.GetDocumentSuccess({
              payload: response.document as IDocument,
            }),
            documentActions.SetOnlyOfficeKey({
              payload: getOnlyOfficeKeyFromAttachent(
                response.document.attachments
              ).onlyoffice_key,
            }),
          ]),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              this.router.navigate(['platform', 'home']);
            }
            return of(
              documentActions.GetDocumentError({
                payload: err.error as IAPIResponseError,
              })
            );
          })
        );
      })
    )
  );

  createDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.CreateDocument),
      switchMap((action) =>
        this.documentService.createDocument(action.payload).pipe(
          map((response: ICreateDocumentResponse) =>
            documentActions.CreateDocumentSuccess({ payload: response })
          ),
          catchError((err: HttpErrorResponse) =>
            of(documentActions.CreateDocumentError({ payload: err.error }))
          )
        )
      )
    )
  );

  CreateDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.CreateDocumentSuccess),
        tap((action) =>
          this.router.navigate([
            'platform/documents/' + action.payload.document.id,
          ])
        )
      ),
    { dispatch: false }
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DeleteDocument),
      switchMap((action) =>
        this.documentService.deleteDocument(action.payload.documentId).pipe(
          map((response: ServiceResponse) =>
            documentActions.DeleteDocumentSuccess()
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              documentActions.DeleteDocumentError({ payload: err.error.error })
            )
          )
        )
      )
    )
  );

  deleteDocumentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DeleteDocumentList),
      tap(() => {
        this.loading.show();
      }),
      switchMap((action) =>
        this.documentService.deleteDocument(action.payload.documentId).pipe(
          map((response: ServiceResponse) =>
            documentActions.DeleteDocumentListSuccess()
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              documentActions.DeleteDocumentError({ payload: err.error.error })
            )
          ),
          finalize(() => {
            this.getLanguage();
            forkJoin([
              this.translate.get('TOAST_NOTIFICATIONS.DELETE_SUCCESS'),
              this.translate.get('DOCUMENTS.TITLE'),
            ]).subscribe(([messageSuccess, title]) => {
              this.toastr.success(messageSuccess, title);
            });
            this.loading.hide();
          })
        )
      )
    )
  );

  getDocumentAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetDocumentAttachment),
      tap(() => {
        this.loading.show();
      }),
      switchMap((action) =>
        this.documentService
          .getDocumentAttachment(
            action.payload.documentId,
            action.payload.attachmenId
          )
          .pipe(
            concatMap((response: ServiceResponse) => [
              documentActions.SetOnlyOfficeKey({
                payload: response.attachment.onlyoffice_key,
              }),
              documentActions.GetDocumentAttachmentSuccess({
                payload: response.attachment as IAttachment,
              }),
            ]),
            catchError((err: HttpErrorResponse) => {
              this.loading.hide();
              return of(
                documentActions.GetDocumentAttachmentError({
                  payload: err.error.error,
                })
              );
            }),
            finalize(() => {
              this.loading.hide();
            })
          )
      )
    )
  );

  DeleteDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DeleteDocumentSuccess),
        withLatestFrom(
          this.store$.select((state) => state.documents.activeFilter)
        ),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  updateDocumentSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UpdateDocumentSelected),
      filter((action) => action.payload != null),
      switchMap((action) =>
        this.documentService
          .updateDocumentSelected(
            action.payload.documentId,
            action.payload.document
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.UpdateDocumentSelectedSuccess()
            ),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(
                documentActions.GetDocumentError({
                  payload: err.error as IAPIResponseError,
                })
              );
            })
          )
      )
    )
  );

  getFlowsByProcessType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetFlowsByProcessType),
      mergeMap((action) =>
        this.dashboardService.showDashboard(action.currentProcess).pipe(
          map((response: ServiceResponse) => {
            const flows: IFlow[] = [];
            if (action.processType === 'draft') {
              response.process.flows.filter((flow) => {
                if (flow.flow_key === 'draft') {
                  flows.push(flow);
                }
              });
            } else {
              response.process.flows.filter((flow) => {
                if (flow.flow_key !== 'draft') {
                  flows.push(flow);
                }
              });
            }
            return documentActions.GetFlowsByProcessTypeSuccess({
              payload: flows,
            });
          }),
          catchError((err: HttpErrorResponse) =>
            of(
              documentActions.GetFlowsByProcessTypeError({
                payload: err?.error?.error,
              })
            )
          )
        )
      )
    )
  );

  getSearchDocumentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetNavbarDocument),
      switchMap((action) =>
        this.documentService.getSearchDocument(action.payload).pipe(
          map((response: ServiceResponse) =>
            documentActions.GetNavbarDocumentSuccess({
              payload: response.autocomplete,
            })
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              documentActions.GetNavbarDocumentError({
                payload: err?.error?.error,
              })
            )
          )
        )
      )
    )
  );

  getDocumentHiddeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentsHiddenType),
      switchMap((action) =>
        this.documentService.updateDocumentHidden(action.payload).pipe(
          map((response: ServiceResponse) =>
            documentActions.DocumentsHiddenTypeSuccess()
          ),
          catchError((err: HttpErrorResponse) => {
            this.getLanguage();
            forkJoin([
              this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
              this.translate.get('DOCUMENTS.TITLE'),
            ]).subscribe(([message, title]) => {
              this.toastr.error(message, title);
            });
            return of(documentActions.DocumentsHiddenTypeError());
          })
        )
      )
    )
  );

  documentMarkAsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentMarkAsList),
      switchMap((action) =>
        this.documentService
          .markAs(action.payload.id, action.payload.action)
          .pipe(
            map((response: ServiceResponse) => {
              if (action.payload.action === EDocumentStatus.CANCEL_SIGNATURE) {
                this.store$.dispatch(
                  documentActions.ClearDocumentAttachmentsBase64Data()
                );
              }
              return documentActions.DocumentMarkAsSuccess({
                payload: response.document as IDocument,
              });
            }),
            catchError((response) => {
              this.getLanguage();
              this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                this.toastr.error(
                  response.error.error?.detail?.signing_method
                    ? response.error.error.detail['signing_method'][0]
                    : response.error.error.title,
                  title
                );
              });
              return of(
                documentActions.DocumentMarkAsError({ payload: response })
              );
            })
          )
      )
    )
  );

  MarkAsApproved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DocumentMarkAsApproved),
        withLatestFrom(this.store$.select(selectDocument)),
        switchMap(([action, currentDocument]) =>
          this.documentService
            .markAsApproved(
              currentDocument.id,
              action.payload.action,
              action.payload.sign_request
            )
            .pipe(
              map((_: ServiceResponse) =>
                documentActions.GetDocument({ payload: currentDocument.id })
              ),
              catchError((response) => {
                this.getLanguage();
                this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                  this.toastr.error(
                    response.error.error.detail['signing_method'][0],
                    title
                  );
                });
                return of(null);
              })
            )
        )
      ),
    { dispatch: true }
  );

  DocumentCreateAssigment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentCreateAssigment),
      switchMap((action) =>
        this.documentService
          .createAssignment(
            action.payload.documentId,
            action.payload.sign_request
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetDocument({
                payload: action.payload.documentId,
              })
            ),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(documentActions.DocumentsHiddenTypeError());
            })
          )
      )
    )
  );

  hideLoad$ = createEffect(
    () =>
      this.actions$.pipe(ofType(documentActions.UpdateDocumentSelectedSuccess)),
    { dispatch: false }
  );

  CancelDocumentProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentCancelProcess),
      withLatestFrom(this.store$.select(selectDocument)),
      switchMap(([action, currentDocument]) =>
        this.documentService
          .documentCancelProcess(currentDocument.id, action.payload)
          .pipe(
            map(() => documentActions.DocumentCancelProcessSuccess()),
            catchError(() => of(documentActions.DocumentCancelProcessError()))
          )
      )
    )
  );

  GetCancellationReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetCancellationReasons),
      withLatestFrom(this.store$.select(selectDocument)),
      exhaustMap(([action, currentDocument]) =>
        this.documentService.getCommentRejected(currentDocument.id).pipe(
          map((response) =>
            documentActions.GetCancellationReasonsSuccess({
              payload: response.cancellation_reasons,
            })
          ),
          catchError(() => of(documentActions.GetCancellationReasonsError()))
        )
      )
    )
  );

  saveDateGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.SaveDateGenerate),
      filter((action) => action.payload != null),
      switchMap((action) =>
        this.documentService
          .saveDateGenerate(
            action.payload.documentId,
            action.payload.attachmentId,
            action.payload.attachment
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.SaveDateGenerateSuccess()
            ),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_GENERATE_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(
                documentActions.SaveDateGenerateError({
                  payload: err.error as IAPIResponseError,
                })
              );
            })
          )
      )
    )
  );

  getPendingMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetPendingMessages),
      exhaustMap((action) =>
        this.documentService
          .getPendingMessages(
            action.payload.chat,
            action.payload.app,
            action.payload.token
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetPendingMessagesSuccess({
                payload: response.pending_seend,
              })
            ),
            catchError((err: HttpErrorResponse) => {
              return of(
                documentActions.GetPendingMessagesError({
                  payload: err.error || err.message,
                })
              );
            })
          )
      )
    )
  );

  getLanguage = () =>
    this.store$
      .pipe(select(selectCurrentLang))
      .subscribe((lang) => this.translate.use(lang));

  constructor(
    private documentService: DocumentsService,
    private dashboardService: DashboardService,
    private translate: TranslateService,
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private toastr: I18nToastrService,
    private store$: Store<IAppState>,
    private loading: LoadingService
  ) {}
}
