<div class="main-container">
  <ng-container *ngFor="let card of cards$ | async; let i = index">
    <div
      class="card"
      [style.backgroundColor]="card.color ? card.color : 'transparent'"
      [style.color]="card.textColor"
      #item
      value="{{ (cards$ | async).length }}"
      (click)="onCardClick(card, i, i == item.value - 1)"
    >
      <div class="title">{{ card?.title | translate }}</div>
      <div class="right-icon" *ngIf="!(i == (cards$ | async).length - 1)">
        <i class="icon fal fa-chevron-right"></i>
      </div>
    </div>
  </ng-container>
</div>
