import { EAttachmentKind } from '@literax/enums/attachment-kind.enum';
import { EDocumentStatus } from '@literax/enums/document-status.enum';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IUser } from '@literax/models/user.model';

/**
 * Configuraciones de botonera para workspace
 * dividido por flujo las propiedades requeridas para agregar un nuevo flujo o transacion a la botonera sera
 * ejemplo:
 *  {
 *      * nombre del [transaction / flujo] del documento
 *      review: [
 *          {
 *              * trnasaction que se enviara a la maquina de estados si se espesifica un array el boton se muestra con menu desplegable
 *              action: 'to_reviewed' | ['action', 'action2']
 *              * estatus en el que el documento tiene que estar para poder enviar el action anterior
 *              status: 'in_process',
 *              * posicion del boton en la interfaz
 *              position: 'left' | 'right',
 *              * string de traduccion para mostrar texto en boton
 *              label: 'PATH.TO.TRNASLATION',
 *              * clase css del icono a mostrar
 *              icon: 'fal fa-user',
 *              * estatus del sign_request para habilitar u ocultar el boton
 *              signer_in_status: 'request_sent',
 *              * clase css a aplicar al boton ademas de la clase por defecto
 *              class: 'primary',
 *          }
 *      ]
 *  }
 */
export const FLOW: { [key: string]: ButtonAction[] } = {
  review: [
    {
      action: [
        {
          action: 'reviewed_to_approval',
          label: 'WORKSPACE.FOOTER.APPROVAL',
          icon: 'fal fa-check-double',
        },
        {
          action: 'reviewed_to_signature',
          label: 'WORKSPACE.FOOTER.SIGN',
          icon: 'far fa-paper-plane',
        },
      ],
      status: 'reviewed',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO',
      icon: 'fal fa-share-alt',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    {
      action: 'cancel_review',
      status: 'review',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL_REVIEW',
      icon: 'fal fa-times-circle',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    {
      action: 'to_reviewed',
      status: 'review',
      position: 'right',
      label: 'WORKSPACE.FOOTER.FINISH_REVIEW',
      icon: 'fal fa-check',
      class: 'primary',
      conditions: {
        is_reviewed: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] =
            document?.user_signer[0]?.status?.key === 'reviewed';
          return button;
        },
        author_in_participants: (
          document,
          attachment,
          user,
          button,
          { ...rest }
        ) => {
          const indexedParticipantsByEmail = document?.sign_requests?.reduce(
            (accumulator, current) => ({
              ...accumulator,
              [current.email]: current,
            }),
            {}
          );
          const authorEmail = user?.email;
          button['hidden'] =
            button.hidden || !(authorEmail in indexedParticipantsByEmail);
          return button;
        },
        hide_if_not_participants: (document, attachment, user, button) => {
          button['hidden'] = document?.user_signer?.length === 0;
          return button;
        },
      },
    },
    {
      action: 'delegate_reviewed',
      status: 'review',
      position: 'right',
      label: 'WORKSPACE.FOOTER.ACTIONS.DELEGATE_REVIEW',
      icon: 'far fa-users',
      conditions: {
        is_reviewed: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] =
            document?.user_signer[0]?.status?.key === 'reviewed';
          return button;
        },
        hide_if_not_participants: (document, attachment, user, button) => {
          button['hidden'] = document?.user_signer?.length === 0;
          return button;
        },
        show_if_author_is_participant: (document, attachment, user, button) => {
          button['hidden'] =
            !(
              document?.user_signer[0]?.email === user.email ||
              document?.user_signer[0]?.rfc === user.rfc
            ) || document?.user_signer[0]?.is_delegate;

          return button;
        },
      },
    },
    {
      action: 'send_review',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        have_participants: (document, attachment, user, button) => {
          button['disabled'] = !(
            document?.legal_representative?.length > 0 ||
            document?.sign_requests?.length > 0
          );
          return button;
        },
        signing_method: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] = isConfig;
          return button;
        },
      },
    },
    // boton de marcar como [solo owner]
    {
      action: [
        {
          action: 'reactive_review',
          label: 'WORKSPACE.FOOTER.ACTIONS.TO_DRAFT',
        },
      ],
      status: ['rejected', 'expired'],
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO',
      icon: 'fal fa-share-alt',
      action_mode: 'document',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
  ],
  signature: [
    // * boton de envio a firma en plantilla [solo owner]
    {
      action: [
        {
          action: 'mark_as_in_process',
          label: 'WORKSPACE.FOOTER.SEND_TO_SIGN',
          conditions: {
            status: (document, attachment, user, button, { ...rest }) => {
              button['disabled'] = !(
                document?.status?.key === 'draft' ||
                document?.status?.key === 'data_collection'
              );
              return button;
            },
            record_filled: (
              document,
              attachment,
              user,
              button,
              { ...rest }
            ) => {
              const { isRecordFilled } = rest;
              if (
                document?.attachments?.some(
                  (attachment) =>
                    attachment.primary &&
                    (attachment.kind === 'pdf' || attachment.kind === 'docx')
                ) &&
                document?.sign_requests?.length &&
                document?.sign_requests?.some(
                  (signer) => signer?.sign_request_signature_quotes?.length > 0
                )
              ) {
                button['disabled'] = button.disabled;
                return button;
              }

              if (
                document?.attachments?.some(
                  (attachment) => attachment.kind === 'template'
                ) &&
                !isRecordFilled
              ) {
                button['disabled'] = true;
                return button;
              }

              return button;
            },
          },
        },
        {
          action: 'send_collection',
          label: 'WORKSPACE.FOOTER.SEND_TO_COLLECT',
          conditions: {
            is_template: (document, attachment, user, button) => {
              button['hidden'] = !document?.attachments?.some(
                (obj) => obj.kind === 'template'
              );
              return button;
            },
          },
        },
      ],
      status: ['draft'],
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_template: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden ||
            !document?.attachments?.some((obj) => obj.kind === 'template');
          return button;
        },
        have_participants: (document, attachment, user, button) => {
          button['disabled'] = !(
            document?.legal_representative?.length > 0 ||
            document?.sign_requests?.length > 0
          );
          return button;
        },
        signing_method: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] =
            button.hidden ||
            (isConfig &&
            (document?.signing_method == 'electronic_signature_plus' ||
              document?.signing_method == 'electronic_signature')
              ? true
              : false);
          return button;
        },
        is_config: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] =
            button.hidden ||
            (isConfig && document?.signing_method !== 'digital_signature');
          return button;
        },
      },
    },
    // * boton de envio a firma [no template, en draft solo owner y con al menos un participante]
    {
      action: 'mark_as_in_process',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO_SIGN',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_template: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden ||
            document?.attachments?.some((obj) => obj.kind === 'template');
          return button;
        },
        have_participants: (document, attachment, user, button) => {
          button['disabled'] = !(
            document?.user_signer.length > 0 ||
            document?.legal_representative?.length > 0 ||
            document?.sign_requests?.length > 0
          );
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || document?.transaction_type === 'form';
          return button;
        },
        is_config: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] =
            button.hidden ||
            (isConfig && document?.signing_method !== 'digital_signature');
          return button;
        },
      },
    },
    //* Botón Editar pagaré
    {
      action: 'edit_promissory_note',
      status: 'draft',
      position: 'right',
      label: 'PROMISSORY_NOTES.EDIT',
      icon: 'fas fa-pen-fancy',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_promissory_note: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden ||
            !document?.attachments?.some(
              (obj) => obj.kind === 'promissory_note'
            );
          return button;
        },
        is_config: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] = button.hidden || isConfig;
          return button;
        },
      },
    },
    // * boton de firma
    {
      action: 'sign',
      status: ['in_process'],
      position: 'right',
      label: 'WORKSPACE.FOOTER.SIGN',
      icon: 'fal fa-pen-fancy',
      class: 'primary',
      conditions: {
        is_sign: (document, attachment, user, button) => {
          button['hidden'] = document?.user_signer.length === 0;
          return button;
        },
        is_signed: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden ||
            document?.user_signer.find((u) => u.email === user.email)?.status
              ?.key === 'signed';
          return button;
        },
        record_filled: (document, attachment, user, button, { ...rest }) => {
          let hasTemplateFields =
            attachment?.primary &&
            !!attachment?.form_builder_record_uid &&
            !!attachment?.form_builder_template_id;
          let hasSignatureQuote =
            document?.user_signer[0]?.sign_request_signature_quotes.length > 0;
          const { isRecordFilled } = rest;

          if (hasTemplateFields || hasSignatureQuote) {
            button['disabled'] =
              (!isRecordFilled && hasTemplateFields) ||
              (!isRecordFilled && hasSignatureQuote);
            return button;
          } else {
            button['disabled'] = false;
            return button;
          }
        },
        signing_method: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] =
            button.hidden ||
            (isConfig &&
              (document?.signing_method == 'electronic_signature_plus' ||
                document?.signing_method == 'electronic_signature'));
          return button;
        },
        order: (document, attachment, user, button) => {
          if (document.hierarchy) {
            var statusPreviusUser: boolean = true;
            var orderCurrentUser = document?.user_signer[0]?.order;
            if (orderCurrentUser > 1) {
              statusPreviusUser = document?.sign_requests.find(
                (u) => u.order === orderCurrentUser - 1
              )?.signed;
              button['disabled'] = button.disabled || !statusPreviusUser;
              return button;
            }
          }
          return button;
        },
        can_sign: (document, attachment, user, button) => {
          const unreadAttachments = document?.attachments.filter(
            (element: IAttachment) =>
              (element?.text === null || element?.text === undefined)
          );
          button['disabled'] = button.disabled || unreadAttachments?.length > 0;
          return button;
        },
        is_user_signer_with_signature_quotes: (
          document,
          attachment,
          user,
          button
        ) => {
          button['hidden'] =
            button.hidden ||
            document?.user_signer.some((signer) => {
              signer.email === user.email &&
                signer.sign_request_signature_quotes?.length > 0;
            });
          return button;
        },
      },
    },
    // * cancelar firma [solo owner]
    {
      action: 'cancel_signature',
      status: 'in_process',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL',
      icon: 'fal fa-times-circle',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    // * rechazo de firma
    {
      action: 'reject',
      status: 'in_process',
      position: 'left',
      label: 'WORKSPACE.FOOTER.REJECT',
      icon: 'fal fa-times-circle',
      conditions: {
        is_sign: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] = !(document?.user_signer.length > 0);
          return button;
        },
        is_signed: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] =
            button.hidden ||
            document?.user_signer.find((u) => u.email === user.email)?.status
              ?.key === 'signed';
          return button;
        },
        order: (document, attachment, user, button) => {
          var statusPreviusUser: boolean = true;
          var orderCurrentUser = document?.user_signer[0]?.order;
          if (orderCurrentUser > 1) {
            statusPreviusUser = document?.sign_requests.find(
              (u) => u.order === orderCurrentUser - 1
            )?.signed;
          }
          button['disabled'] = button.disabled || !statusPreviusUser;
          return button;
        },
      },
    },
    // * boton de marcar como [solo owner]
    {
      action: [
        {
          action: 'to_draft',
          label: 'WORKSPACE.FOOTER.ACTIONS.TO_DRAFT',
        },
      ],
      status: ['rejected', 'expired'],
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO',
      icon: 'fal fa-share-alt',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    // * Botón editar para documentos a partir de platillas [antes de firmar]
    {
      action: 'return_data_collection',
      status: 'in_process',
      position: 'right',
      label: 'WORKSPACE.FOOTER.EDIT',
      icon: 'fas fa-pencil-alt',
      conditions: {
        is_owner_or_signer: (document, attachment, user, button) => {
          let isOwner = document?.user?.id === user?.id;
          let isSigner = document?.user_signer?.length > 0;
          button['hidden'] = isOwner && !isSigner;
          return button;
        },
        has_next_action: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden ||
            !document?.next_actions?.includes('return_data_collection');
          return button;
        },
        is_base_template: (document, attachment, user, button) => {
          let isTemplate = document?.attachments?.some(
            (obj) => obj.kind === 'template'
          );
          let isTemplateSignature = document?.attachments?.some(
            (obj) =>
              obj.primary &&
              obj.form_builder_record_uid &&
              obj.form_builder_template_id
          );
          button['hidden'] =
            button.hidden || (!isTemplate && !isTemplateSignature);
          return button;
        },
        is_signed: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] =
            button.hidden ||
            document?.sign_requests?.some((obj) => obj.signed) ||
            !document?.action_edit;
          return button;
        },
      },
    },
    // * Boton enviar a firma para documentos con leyenda
    // * Botón guardar para documentos a partir de plantillas, tipo documento
    {
      action: 'mark_as_in_process',
      status: 'data_collection',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SAVE',
      icon: 'fal fa-save',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_type_signature: (
          document,
          attachment,
          user,
          button,
          { ...args }
        ) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'signature');
          return button;
        },
        is_sign: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] = !(document?.user_signer?.length > 0);
          return button;
        },
        is_base_template: (document, attachment, user, button) => {
          let isTemplate = document?.attachments?.some(
            (obj) => obj.kind === 'template'
          );
          let isTemplateSignature = document?.attachments?.some(
            (obj) =>
              obj.primary &&
              obj.form_builder_record_uid &&
              obj.form_builder_template_id
          );
          button['hidden'] =
            button.hidden || (!isTemplate && !isTemplateSignature);
          return button;
        },
        record_filled: (document, attachment, user, button, { ...rest }) => {
          const { isRecordFilled } = rest;
          button['disabled'] = !isRecordFilled;
          return button;
        },
      },
    },
    // * Cancelar data collection [solo owner] para documentos a partir de plantillas, tipo documento
    {
      action: 'cancel_collection',
      status: 'data_collection',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL',
      icon: 'fal fa-times-circle',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_base_template: (document, attachment, user, button) => {
          let isTemplate = document?.attachments?.some(
            (obj) => obj.kind === 'template'
          );
          let isTemplateSignature = document?.attachments?.some(
            (obj) =>
              obj.primary &&
              obj.form_builder_record_uid &&
              obj.form_builder_template_id
          );
          button['hidden'] =
            button.hidden || (!isTemplate && !isTemplateSignature);
          return button;
        },
        has_next_action: (document, attachment, user, button) => {
          let hasNextAction: boolean = document?.next_actions.some(
            (nextAction) => {
              return nextAction === EDocumentStatus.CANCEL_COLLECTION;
            }
          );
          button['hidden'] = button.hidden || !hasNextAction;
          return button;
        },
      },
    },
    //* Botón para guardar documentos docx en estado draft
    {
      action: 'save',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SAVE',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_config_screen: (document, attachment, user, button, { ...args }) => {
          button['hidden'] = button.hidden;
          return button;
        },
        is_docx: (document, attachment, user, button, { ...args }) => {
          button['hidden'] = !(
            button.hidden ||
            (document?.status?.key === 'draft' &&
              attachment?.kind === 'docx' &&
              args.isOnlyOfficeVisible)
          );
          return button;
        },
      },
    },
  ],
  approval: [
    // envia el documento a proceso de aprobación
    {
      action: 'send_approval',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        hide_on_configiguration_screen: (
          document,
          attachment,
          user,
          button,
          { ...rest }
        ) => {
          const { isConfig } = rest;
          button['hidden'] = button.hidden || isConfig;
          return button;
        },
        have_participants: (document, attachment, user, button) => {
          button['disabled'] = !(
            document?.legal_representative?.length > 0 ||
            document?.sign_requests?.length > 0
          );
          return button;
        },
        is_configuration_approval: (
          document,
          attachment,
          user,
          button,
          { ...rest }
        ) => {
          button['hidden'] =
            button.hidden || document?.sign_requests?.length > 0 ? false : true;

          return button;
        },
      },
    },
    //* Botón Aprobar
    // una vez en proceso, envia al current signer a aprobado
    {
      action: 'to_approved',
      status: 'approval',
      position: 'right',
      label: 'WORKSPACE.FOOTER.APPROVE',
      icon: 'far fa-check-double',
      class: 'primary',
      action_mode: 'sign_request',
      conditions: {
        is_user_signer: (document, attachment, user, button) => {
          button['hidden'] =
            document?.user_signer.length > 0 &&
            document?.user_signer.some(
              (user_signer) =>
                user_signer.email === user.email || user_signer.rfc === user.rfc
            )
              ? false
              : true;
          return button;
        },
        is_approved: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] =
            document?.user_signer.length > 0 &&
            document?.user_signer[0]?.status?.key !== 'approved'
              ? false
              : true;
          return button;
        },
        order: (document, attachment, user, button) => {
          let statusPreviusUser: boolean = true;
          let orderCurrentUser = document?.user_signer[0]?.order;
          if (orderCurrentUser > 1) {
            statusPreviusUser =
              document?.sign_requests.find(
                (u) => u.order === orderCurrentUser - 1
              )?.status.key === 'approved';
          }
          button['disabled'] = !statusPreviusUser;
          return button;
        },
        is_config: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] = button.hidden || isConfig;
          return button;
        },
      },
    },
    //* Botón Cancelar aprobación
    {
      action: 'cancel_approval',
      status: 'approval',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL_APPROVE',
      icon: 'fal fa-times-circle',
      action_mode: 'document',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_config: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] = button.hidden || isConfig;
          return button;
        },
      },
    },
    //* Botón Delegar aprobación
    {
      action: 'delegate_approval',
      status: 'approval',
      position: 'right',
      label: 'WORKSPACE.FOOTER.ACTIONS.DELEGATE_APPROVED',
      icon: 'far fa-users',
      action_mode: 'document',
      conditions: {
        is_author: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_config: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] = button.hidden || isConfig;
          return button;
        },
        show_if_author_is_participant: (document, attachment, user, button) => {
          button['hidden'] =
            !(
              document?.user_signer[0]?.email === user.email ||
              document?.user_signer[0]?.rfc === user.rfc
            ) || document?.user_signer[0]?.is_delegate;

          return button;
        },
      },
    },
    // * Botón de Rechazar aprobación
    {
      action: 'rejected_approval',
      status: 'approval',
      position: 'left',
      label: 'WORKSPACE.FOOTER.REJECTED_APPROVAL',
      icon: 'fal fa-times-circle',
      action_mode: 'sign_request',
      conditions: {
        is_approved: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] =
            document?.user_signer[0]?.status?.key === 'approved';
          return button;
        },
        order: (document, attachment, user, button) => {
          let statusPreviusUser: boolean = true;
          let orderCurrentUser = document?.user_signer[0]?.order;
          if (orderCurrentUser > 1) {
            statusPreviusUser =
              document?.sign_requests.find(
                (u) => u.order === orderCurrentUser - 1
              )?.status.key === 'approved';
          }
          button['disabled'] = !statusPreviusUser;
          return button;
        },
        has_next_action: (document, attachment, user, button) => {
          let hasNextAction: boolean = document?.next_actions.some(
            (nextAction) => {
              return nextAction === EDocumentStatus.REJECTED_APPROVAL;
            }
          );
          button['hidden'] = button.hidden || !hasNextAction;
          return button;
        },
        is_config: (document, attachment, user, button, { ...rest }) => {
          const { isConfig } = rest;
          button['hidden'] = button.hidden || isConfig;
          return button;
        },
        user_can_interact: (document, attachment, user, button) => {
          if (document?.user_signer.length === 0) {
            button['hidden'] = true;
          }
          return button;
        },
      },
    },
    // * Botón de Firmar
    {
      action: [
        {
          action: 'approved_to_signature',
          label: 'WORKSPACE.FOOTER.SIGN',
          icon: 'far fa-paper-plane',
        },
      ],
      status: 'approved',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO',
      icon: 'fal fa-share-alt',
      action_mode: 'document',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    // boton de marcar como [solo owner]
    {
      action: [
        {
          action: 'reactive_approval',
          label: 'WORKSPACE.FOOTER.ACTIONS.TO_DRAFT',
        },
      ],
      status: ['rejected', 'expired'],
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO',
      icon: 'fal fa-share-alt',
      action_mode: 'document',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    {
      action: 'save',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SAVE',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_config_screen: (document, attachment, user, button, { ...args }) => {
          button['hidden'] = button.hidden;
          return button;
        },
        is_docx: (document, attachment, user, button, { ...args }) => {
          button['hidden'] = !(
            button.hidden ||
            (document?.status?.key === 'draft' &&
              attachment?.kind === 'docx' &&
              args.isOnlyOfficeVisible)
          );
          return button;
        },
      },
    },
  ],
  request: [
    {
      action: 'to_send',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        record_filled: (document, attachment, user, button, { ...rest }) => {
          const { isRecordFilled } = rest;
          button['disabled'] = !isRecordFilled;
          return button;
        },
      },
    },
    {
      action: 'set_delivery_date',
      status: 'sent',
      position: 'right',
      label: 'WORKSPACE.FOOTER.ACCEPT',
      icon: 'fal fa-check',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id !== user?.id);
          return button;
        },
        delivery_date: (document, attachment, user, button) => {
          button['disabled'] = !(attachment?.delivery_date === null);
          return button;
        },
        // TODO: Eliminar condición después de Demo
        is_user_signer: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden || !(document?.user_signer?.length > 0);
          return button;
        },
      },
    },
    {
      action: [
        {
          action: 'generated_to_approval',
          label: 'WORKSPACE.FOOTER.APPROVAL',
          icon: 'fal fa-check-double',
          class: 'primary',
        },
        {
          action: 'generated_to_review',
          label: 'WORKSPACE.FOOTER.REVIEW',
          icon: 'fal fa-check',
          class: 'primary',
        },
        {
          action: 'generated_to_signature',
          label: 'WORKSPACE.FOOTER.SIGN',
          icon: 'far fa-pen-fancy',
          class: 'primary',
        },
      ],
      status: 'generated',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO',
      icon: 'fal fa-share-alt',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    {
      action: 'assign',
      status: 'sent',
      position: 'right',
      label: 'WORKSPACE.FOOTER.ASSIGN',
      icon: 'fal fa-user',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id !== user?.id);
          return button;
        },
        // TODO: Eliminar condición después de Demo
        is_user_signer: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden || !(document?.user_signer?.length > 0);
          return button;
        },
      },
    },

    {
      action: 'rejected_assignment',
      status: 'sent',
      position: 'left',
      label: 'WORKSPACE.FOOTER.REJECT_REQUEST',
      icon: 'fal fa-times',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id !== user?.id);
          return button;
        },
      },
    },
    {
      action: 'cancel_in_process',
      status: 'in_process',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL',
      icon: 'fal fa-times',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    {
      action: 'to_generated',
      status: 'in_process',
      position: 'right',
      label: 'WORKSPACE.FOOTER.DELIVERY_REQUEST',
      icon: 'fal fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id !== user?.id);
          return button;
        },
      },
    },
    //reactivacion de documento enviar a proyecto
    {
      action: [
        {
          action: 'reactive_request',
          label: 'WORKSPACE.FOOTER.ACTIONS.TO_DRAFT',
        },
      ],
      status: ['rejected'],
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND_TO',
      icon: 'fal fa-share-alt',
      action_mode: 'document',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
      },
    },
    {
      action: 'cancel_request_sent',
      status: 'sent',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL',
      icon: 'fal fa-times',
      conditions: {
        has_next_action: (document, attachment, user, button) => {
          button['hidden'] = !document.next_actions?.includes(
            'cancel_request_sent'
          );
          return button;
        },
      },
    },
    //* Botón para guardar documentos docx en estado draft
    {
      action: 'save',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SAVE',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_config_screen: (document, attachment, user, button, { ...args }) => {
          button['hidden'] = button.hidden;
          return button;
        },
        is_docx: (document, attachment, user, button, { ...args }) => {
          button['hidden'] = !(
            button.hidden ||
            (document?.status?.key === 'draft' &&
              attachment?.kind === 'docx' &&
              args.isOnlyOfficeVisible)
          );
          return button;
        },
      },
    },
  ],
  form: [
    // boton de envio a firma en plantilla tipo form [solo owner]
    {
      action: [
        {
          action: 'mark_as_in_process',
          label: 'WORKSPACE.FOOTER.SEND_TO_SIGN',
          conditions: {
            status: (document, attachment, user, button, { ...rest }) => {
              button['disabled'] =
                document?.status?.key === 'draft' ||
                document?.status?.key === 'data_collection';
              return button;
            },
            record_filled: (
              document,
              attachment,
              user,
              button,
              { ...rest }
            ) => {
              const { isRecordFilled } = rest;
              button['disabled'] = !isRecordFilled;
              return button;
            },
          },
        },
        {
          action: 'send_collection',
          label: 'WORKSPACE.FOOTER.SEND_TO_COLLECT',
          conditions: {
            is_template: (document, attachment, user, button) => {
              button['hidden'] = !document?.attachments?.some(
                (obj) => obj.kind === 'template'
              );
              return button;
            },
          },
        },
      ],
      status: ['draft'],
      position: 'right',
      label: 'WORKSPACE.FOOTER.SEND',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_template: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden ||
            !document?.attachments?.some((obj) => obj.kind === 'template');
          return button;
        },
        have_participants: (document, attachment, user, button) => {
          button['disabled'] = !(
            document?.legal_representative?.length > 0 ||
            document?.sign_requests?.length > 0
          );
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'form');
          return button;
        },
        is_config_screen: (document, attachment, user, button, { ...args }) => {
          const { isConfig } = args;
          button['hidden'] = isConfig;
          return button;
        },
      },
    },
    // boton de firma
    {
      action: 'sign',
      status: 'in_process',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SIGN',
      icon: 'fal fa-pen-fancy',
      class: 'primary',
      conditions: {
        is_signed: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] = !(document?.user_signer?.length > 0);
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'form');
          return button;
        },
      },
    },
    // cancelar firma [solo owner]
    {
      action: 'cancel_signature',
      status: 'in_process',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL',
      icon: 'fal fa-times-circle',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'form');
          return button;
        },
      },
    },
    // cancelar data collection [solo owner]
    {
      action: 'cancel_collection',
      status: 'data_collection',
      position: 'left',
      label: 'WORKSPACE.FOOTER.CANCEL',
      icon: 'fal fa-times-circle',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'form');
          return button;
        },
      },
    },
    // rechazo de firma
    {
      action: 'reject',
      status: 'in_process',
      position: 'left',
      label: 'WORKSPACE.FOOTER.REJECT',
      icon: 'fal fa-times-circle',
      conditions: {
        is_signed: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] = !(document?.user_signer?.length > 0);
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'form');
          return button;
        },
      },
    },
    // * Botón editar para documentos a partir de plantillas, tipo form [antes de firmar]
    {
      action: 'return_data_collection',
      status: 'data_collection',
      position: 'right',
      label: 'WORKSPACE.FOOTER.EDIT',
      icon: 'fas fa-pencil-alt',
      conditions: {
        is_owner_or_signer: (document, attachment, user, button) => {
          let isOwner = document?.user?.id === user?.id;
          let isSigner = document?.user_signer?.length > 0;
          button['hidden'] = isOwner && !isSigner;
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'form');
          return button;
        },
        has_next_action: (document, attachment, user, button) => {
          button['hidden'] =
            button.hidden ||
            !document?.next_actions?.includes('return_data_collection');
          return button;
        },
        is_signed: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] =
            button.hidden ||
            document?.sign_requests?.some((obj) => obj.signed) ||
            !document?.action_edit;
          return button;
        },
      },
    },
    // * Botón guardar para documentos a partir de plantillas, tipo form
    {
      action: 'mark_as_in_process',
      status: 'data_collection',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SAVE',
      icon: 'fal fa-save',
      class: 'primary',
      conditions: {
        is_owner: (document, attachment, user, button) => {
          button['hidden'] = !(document?.user?.id === user?.id);
          return button;
        },
        is_type_form: (document, attachment, user, button, { ...args }) => {
          button['hidden'] =
            button.hidden || !(document?.transaction_type === 'form');
          return button;
        },
        is_sign: (document, attachment, user, button, { ...rest }) => {
          button['hidden'] = !(document?.user_signer?.length > 0);
          return button;
        },
        record_filled: (document, attachment, user, button, { ...rest }) => {
          const { isRecordFilled } = rest;
          button['disabled'] = !isRecordFilled;
          return button;
        },
      },
    },
    //* Botón para guardar documentos docx en estado draft
    {
      action: 'save',
      status: 'draft',
      position: 'right',
      label: 'WORKSPACE.FOOTER.SAVE',
      icon: 'far fa-paper-plane',
      class: 'primary',
      conditions: {
        is_docx: (document, attachment, user, button, { ...args }) => {
          button['hidden'] = !(
            attachment?.kind === EAttachmentKind.DOCX &&
            args.isOnlyOfficeVisible
          );
          return button;
        },
      },
    },
  ],
};

export const CONFIG_BUTTONS: ButtonAction[] = [
  {
    action: 'back_to_workspace',
    status: '*',
    position: 'left',
    label: 'WORKSPACE.FOOTER.BACK',
    icon: 'fal fa-arrow-to-left',
    class: 'primary',
    conditions: {
      is_config_screen: (document, attachment, user, button, { ...args }) => {
        const { isConfig } = args;
        button['hidden'] = !isConfig;
        return button;
      },
    },
  },
  {
    action: 'download_doc',
    status: [
      'approval',
      'in_process',
      'draft',
      'approved',
      'rejected',
      'signed',
      'data_collection',
      'in_effect',
    ],
    position: 'right',
    label: 'WORKSPACE.FOOTER.DOWNLOAD',
    icon: 'far fa-cloud-download',
    conditions: {
      is_config_screen: (document, attachment, user, button, { ...args }) => {
        const { isConfig } = args;
        button['hidden'] = isConfig;
        return button;
      },
    },
  },
  {
    action: 'delete_doc',
    status: ['draft'],
    position: 'left',
    label: 'WORKSPACE.FOOTER.DELETE',
    icon: 'fal fa-trash-alt',
    conditions: {
      is_owner: (document, attachment, user, button) => {
        button['hidden'] = !(document?.user?.id === user?.id);
        return button;
      },
      is_config_screen: (document, attachment, user, button, { ...args }) => {
        const { isConfig } = args;
        button['hidden'] = button.disabled || isConfig;
        return button;
      },
    },
  },
  {
    action: 'signatures',
    status: ['in_process', 'rejected', 'signed', 'in_effect'],
    position: 'right',
    label: 'WORKSPACE.FOOTER.SIGNATURES',
    icon: 'fal fa-pen-fancy',
    class: 'primary',
    conditions: {
      valid_process: (document, attachment, user, button, { ...rest }) => {
        button['hidden'] = !(
          document?.status?.key !== 'draft' &&
          document?.status?.key !== 'data_collection' &&
          document?.transaction_type !== 'review' &&
          document?.transaction_type !== 'approval' &&
          document?.transaction_type !== 'request'
        );
        return button;
      },
    },
  },
];

export type ButtonAction = {
  action: string | Omit<ButtonAction, 'position' | 'status'>[];
  status: string | string[];
  position: 'left' | 'right';
  label: string;
  icon?: string;
  class?: string;
  disabled?: boolean;
  hidden?: boolean;
  order?: number;
  action_mode?: 'document' | 'sign_request';
  conditions?: {
    [k: string]: (
      document: IDocument,
      attachment: IAttachment,
      user: IUser,
      button: ButtonAction,
      extra?: { [x: string]: any }
    ) => ButtonAction;
  };
};
