<div class="modal-attachment-container">
  <div class="form-title d-flex justify-content-between">
    <h5 class="mx-3 mt-3 title">
      {{ data.title | translate }}
    </h5>
    <button class="mx-3" type="button" (click)="dialogRef.close()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="body">
    <form [formGroup]="attachmentForm">
      <div *ngIf="data.action === EButtonActions.UPLOAD" class="gdx-form-group">
        <div class="file-formats">
          {{ 'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE_MAX_SIZE' | translate }}
          <span style="font-weight: bold">{{
            'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE_MAX_SIZE_VALUE' | translate
          }}</span>
          {{ 'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE_FORMATS' | translate }}
          <span
            style="font-weight: bold"
            *ngIf="!data?.hasOwnProperty('kind')"
            >{{
              'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE_FORMATS_VALUE' | translate
            }}</span
          >
          <span style="font-weight: bold" *ngIf="data?.kind === 'pdf'">{{
            'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE_REPLACE_DOCUMENT' | translate
          }}</span>
        </div>
        <div id="row-form" class="gdx-form-group">
          <literax-file-input
            #fileInput
            [formControl]="attachmentForm.get('file')"
            [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
            [fileTypes]="permittedFiles$ | async"
            [labelTag]="'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE' | translate"
            buttonChangeTag="{{ 'FORM.FILE_INPUT.CHANGE_BUTTON' | translate }}"
            buttonSelectTag="{{ 'FORM.FILE_INPUT.SELECT_BUTTON' | translate }}"
            (fileType)="fileType = $event"
          >
          </literax-file-input>
          <literax-api-error [control]="attachmentForm.controls.file">
          </literax-api-error>
        </div>
      </div>

      <div class="gdx-form-group">
        <gdx-form-field
          label="{{ 'WIZARD.DOCUMENTS.FORMS.UPLOADER.NAME' | translate }}"
        >
          <input
            tabindex="-1"
            type="text"
            gdxInput
            class="form-input"
            formControlName="name"
            autocomplete="off"
          />
        </gdx-form-field>
        <literax-api-error [control]="attachmentForm.controls.name">
        </literax-api-error>
      </div>
    </form>
  </div>
  <div class="footer">
    <button class="button button--secondary" (click)="closeDialog()">
      <i class="icon far fa-times-circle if-mr-4"></i>
      {{ 'WIZARD.DOCUMENTS.BUTTONS.CANCEL' | translate }}
    </button>

    <button
      class="button button--primary"
      [disabled]="!attachmentForm.valid"
      (click)="saveAction()"
    >
      <i class="icon far fa-save if-mr-4"></i>
      {{ 'WIZARD.DOCUMENTS.BUTTONS.SAVE' | translate }}
    </button>
  </div>
</div>
