import * as documentActions from '../../../../../store/document/document.actions';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  createAttachment,
  deleteAttachment,
  updateAttachment,
} from '@literax/store/attachment/attachment.actions';
import {
  selectAttachment,
  selectuploadErrors,
} from '@literax/store/attachment/attachment.selectors';
import {
  selectCurrentAttachment,
  selectDocument,
} from '@literax/store/document/document.selectors';

import { CustomFormValidator } from '@literax/components/shared/form-lib/custom-form.validator';
import { DeleteRecordDialogComponent } from '@literax/components/platform/delete-record-dialog/delete-record-dialog.component';
import { FileInputComponent } from '@literax/components/shared/file-input/file-input.component';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISignRequest } from '@literax/store/document/document.state';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { selectAttachment as freeAttachmentSelected } from '@literax/store/free-document/free-document.selectors';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit, OnChanges, OnDestroy {
  document$ = this.store.pipe(select(selectDocument));
  stateAttachment$ = this.store.pipe(select(selectAttachment));

  @ViewChild('fileForm') fileForm: ElementRef<HTMLFormElement>;
  @ViewChild('fileInput') fileInput: FileInputComponent;
  @Input() actions: boolean;
  @Input() disabledActions: boolean;
  @Input() typePromissory: boolean;
  @Input() attachment?: IAttachment;
  @Input() delete = true;
  @Input() main = false;
  @Input() showCheckAttachment: boolean;
  @Output() attachmentId = new EventEmitter();
  @Output() erase = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() sign = new EventEmitter();
  @Output() replaceAttachment = new EventEmitter();
  @Output() checkId = new EventEmitter();
  @Output() editAttachment = new EventEmitter();
  @Input() documentSecondary: IDocument[];

  @Input() workarea: 'free' | 'account' = 'account';

  canSign: boolean;

  selectedAttachent: number;
  permittedFiles = ['.pdf', '.docx'];
  status: string;
  fileError$ = this.store.pipe(select(selectuploadErrors));
  isDocumentPath = false;
  isMainDocumentKind = false;
  form: FormGroup = new FormGroup({
    file: new FormControl(null, [
      Validators.required,
      CustomFormValidator.requiredFileType(...this.permittedFiles),
      CustomFormValidator.maxFileSize(70),
    ]),
  });

  currentAttachment$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCurrentAttachment)
  );
  freeCurrentAttachment$ = this.store.pipe(
    untilDestroyed(this),
    select(freeAttachmentSelected)
  );
  currentAttachmentId: number;

  constructor(private store: Store<IAppState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.isDocumentPath =
      location.pathname.slice(1, -1).split('/').indexOf('documents') === 1;
    this.initConfig();
  }

  ngOnDestroy() {}

  ngOnChanges() {
    if (this.attachment) {
      if (this.documentSecondary) {
        this.findUser(this.attachment);
      }
      this.isMainDocumentKind =
        ['pdf', 'md', 'xml', 'promissory_note'].indexOf(
          this.attachment?.kind
        ) >= 0;
    }
  }

  initConfig() {
    if (this.workarea === 'free') {
      this.freeCurrentAttachment$.subscribe(
        (attachment: IAttachment) => (this.currentAttachmentId = attachment?.id)
      );
    } else {
      this.currentAttachment$.subscribe(
        (attachment: IAttachment) => (this.currentAttachmentId = attachment?.id)
      );
    }
  }

  findUser(currentAttachment: IAttachment) {
    const document = this.documentSecondary.find((document: IDocument) => {
      return document.attachments.find(
        (attachment: IAttachment) => attachment.id === currentAttachment.id
      );
    });
    this.canSign =
      (currentAttachment?.documentInfo?.status?.key === 'in_process' ||
        currentAttachment?.documentInfo?.status?.key === 'draft') &&
      this.canUserSign(document?.user_signer);
  }

  canUserSign(userSigners: ISignRequest[]) {
    if (userSigners) {
      return !userSigners.reduce(
        (state, signer) => state && signer.signed,
        true
      );
    } else {
      return false;
    }
  }
  attachmentDetail() {
    this.attachmentId.emit(this.attachment.id);
  }

  deleteAttachment() {
    this.dialog.open(DeleteRecordDialogComponent, {
      width: '480px',
      height: '280px',
      disableClose: true,
      data: {
        callback: (): void => {
          this.erase.emit(this.attachment.id);
          this.document$.pipe(first()).subscribe((document) => {
            this.store.dispatch(
              deleteAttachment({
                documentId: document.id,
                attachmentId: this.attachment.id,
              })
            );
          });
        },
        cancelButton: 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.BUTTON_CANCEL',
        acceptButton: 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.BUTTON_ACCEPT',
        message: 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.DELETE_ATTACHMENT',
      },
    });
  }

  cancelEvent() {
    this.cancel.emit({ event: '[UploadFileComponent] cancel' });
  }

  submitForm() {
    this.fileForm.nativeElement.dispatchEvent(
      new Event('submit', { cancelable: true })
    );
  }

  searchAttachment(attachment: IAttachment) {
    this.document$.pipe(first()).subscribe((document) => {
      const documentForUpdate = {
        ...document,
      };

      documentForUpdate.attachments = [...document?.attachments];

      const position = documentForUpdate.attachments.findIndex(
        (el: IAttachment) => el.id === attachment.id
      );

      documentForUpdate.attachments[position] = attachment;
      this.store.dispatch(
        documentActions.UpdateLocalDocument({
          payload: { document: documentForUpdate, success: true },
        })
      );
    });
  }

  emitSign() {
    this.sign.emit(this.attachment?.documentInfo?.id);
  }

  getDocumentBase64(): string {
    return (
      this.form &&
      this.form.controls &&
      this.form.controls.file &&
      this.form.controls.file.value.base64
    );
  }

  getName(): string {
    return (
      this.form &&
      this.form.controls &&
      this.form.controls.file &&
      this.form.controls.file.value.file &&
      this.form.controls.file.value.file.name
    );
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.attachment) {
      this.attachmentId.emit(this.attachment.id);
    }
    if (this.form.valid) {
      let params: any;
      if (this.main) {
        params = {
          name: this.form.controls.file.value.name,
          file: this.form.controls.file.value,
          kind: 'pdf',
          primary: true,
        };
      } else {
        params = {
          name: this.form.controls.file.value.name,
          file: this.form.controls.file.value,
          kind: 'pdf',
        };
      }

      if (
        this.attachment &&
        this.attachment.id !== null &&
        this.attachment.id !== undefined
      ) {
        this.document$.pipe(first()).subscribe((document) => {
          this.store.dispatch(
            updateAttachment({
              documentId: document.id,
              attachmentId: this.attachment.id,
              data: params,
            })
          );
        });
      } else {
        this.document$.pipe(first()).subscribe((document) => {
          this.store.dispatch(
            createAttachment({
              documentId: document.id,
              data: params,
            })
          );
        });
      }
    }
  }

  checkAttachment() {
    if(this.showCheckAttachment){
      this.showCheckAttachment = false;
    }else{
      this.showCheckAttachment = true;
    }
    
    this.checkId.emit(this.attachment.id);
  }
}
