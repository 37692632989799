import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomFormValidator } from '@literax/components/shared/form-lib/custom-form.validator';
import * as moment from 'moment';
import {
  IAttachment,
  IAttachmentDateGenerate,
} from '../../../../../models/http/attachment.model';
import { Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import * as documentActions from '@literax/store/document/document.actions';
import { Actions, ofType } from '@ngrx/effects';
import { IDocument } from '../../../../../models/http/document/document.model';

@Component({
  selector: 'literax-add-modal-date',
  templateUrl: './add-modal-date.component.html',
  styleUrls: ['./add-modal-date.component.scss'],
})
export class AddModalDateComponent implements OnInit {
  form: FormGroup;
  title: string;
  acceptButton: string;
  cancelButton: string;
  documentId: number;
  attachment: IAttachment;
  attachmentDelivery: IAttachmentDateGenerate;

  delivery_date: Date | string = moment().add(10, 'days').format();
  minDate = moment().startOf('day').toDate();

  constructor(
    public dialogRef: MatDialogRef<AddModalDateComponent>,
    private formBuilder: FormBuilder,
    private store: Store<IAppState>,
    private actions$: Actions
  ) {
    this.generateForm();
  }

  ngOnInit(): void {}

  setExpiration(value): void {
    this.form.controls.delivery_date.setValue(
      moment(value).format('DD - MM - YYYY')
    );
  }

  generateForm() {
    this.form = this.formBuilder.group({
      delivery_date: [
        moment(this.delivery_date).format('DD - MM - YYYY'),
        [CustomFormValidator.expiryGTNow(true), Validators.required],
      ],
    });
  }

  close(action: string) {
    this.dialogRef.close({ action });
  }

  saveGenerateDate() {
    if (this.form.valid) {
      let data = this.form.value;
      const dateSplit = data.delivery_date.trim()?.split('-');
      data.delivery_date = new Date(
        parseInt(dateSplit[2], 10),
        parseInt(dateSplit[1], 10) - 1,
        parseInt(dateSplit[0], 10)
      );
      this.attachmentDelivery = { delivery_date: data.delivery_date };
      this.store.dispatch(
        documentActions.SaveDateGenerate({
          payload: {
            documentId: this.documentId,
            attachmentId: this.attachment.id,
            attachment: this.attachmentDelivery,
          },
        })
      );

      this.actions$
        .pipe(ofType(documentActions.SaveDateGenerateSuccess))
        .subscribe(() => {
          this.store.dispatch(
            documentActions.GetDocument({ payload: this.documentId })
          );
          this.close('reload');
        });
    }
  }
}
