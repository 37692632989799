<div class="main-container">
  <h3 class="mr-20">{{ 'WIZARD.SIGNERS.TITLE' | translate }}</h3>

  <h4 class="mr-20">{{ 'WIZARD.SIGNERS.PROCESS_TITLE' | translate }}:</h4>

  <div class="mr-20">
    <div class="process" *ngFor="let process of processes">
      <div
        class="option"
        [class.active]="process == selectedProcess"
        (click)="selectProcess(process)"
      >
        <i class="fal {{ processIcons[process] }}"></i>
        <span>{{
          'WIZARD.PROCESSES.' + process.toUpperCase() | translate
        }}</span>
      </div>
    </div>
  </div>

  <h4 class="mr-20">{{ 'WIZARD.SIGNERS.USERS' | translate }}:</h4>
  <literax-sign-requests
    [document]="document$ | async"
    (finish)="onFinish()"
    nextLabel="{{ 'WIZARD.SIGNERS.BUTTON.CLOSE' | translate }}"
  >
  </literax-sign-requests>
</div>
