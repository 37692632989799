import { Component, Input } from '@angular/core';

@Component({
  selector: 'literax-circle',
  templateUrl: 'circle.component.html',
  styleUrls: ['./circle.component.scss'],
})
export class CircleComponent {
  @Input() size: string;
  @Input() percentage: number;
  @Input() label: string;
  @Input() colorClass: string;
  constructor() {}
}
