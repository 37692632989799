<div class="tab-title" (click)="toggleTabContent()">
  <h3>
    {{ 'DOCUMENT-PREVIEW.SIGNERS.PARTICIPANTS' | translate }}
  </h3>
  <i
    [ngClass]="
      contentVisible ? 'fal fa-chevron-up icon' : 'fal fa-chevron-down icon'
    "
  >
  </i>
</div>
<div class="tab-component" [ngClass]="{ visible: contentVisible }">
  <ng-container *ngFor="let reviewer of reviewerRequest">
    <literax-reviewer
      [document]="document"
      [status]="document.status.key"
      [reviewer]="reviewer"
      [user]="user"
      [userDocument]="document.user"
      [actionButtonEnable]="reviewer.email !== user.email"
      (historyButton)="historyButton.emit($event)"
      (actionButton)="actionButton.emit($event)"
    >
    </literax-reviewer>
  </ng-container>
</div>
<div class="info">
  <button
    gdx-button
    id="add-reviewers"
    (debounceClick)="addReviewer.emit()"
    [class.disabled]="
      document.status.key !== 'draft' || document.user.id !== user.id
    "
    [disabled]="document.status.key !== 'draft' || document.user.id !== user.id"
  >
    {{ 'DOCUMENT-PREVIEW.SIGNERS.CONFIG' | translate }}
  </button>
</div>
