import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IActions } from '@literax/components/configurations/users/models/users.interface';

@Component({
  selector: 'literax-process-tab',
  templateUrl: './process-tab.component.html',
  styleUrls: ['./process-tab.component.scss'],
})
export class ProcessTabComponent implements OnInit {
  @Input('count') flowCount: number;
  @Input('name') flowName: string;
  @Input('key') flowKey: string;
  @Input() active: boolean;
  @Input() color: string;
  @Input() permissions: IActions;
  @Input() isPagare: boolean = false;

  @Output() getFlow = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick(key: string) {
    this.getFlow.emit(key);
  }
}
