import { IClient } from '@literax/models/http/api/client/client.model';
import { Client } from '@literax/models/http/onboarding/client.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ICompanyList } from '@literax/models/company.model';
import {
  IUserActiveDelegate,
  IUserActiveDelegateItem,
} from '@literax/models/user.model';

export interface IClientState {
  legalRepresentative?: ICompanyList[];
  clients?: IClient[];
  currentClient?: IClient;
  client: Client;
  clientError: IAPIResponseError;
  clientDelegate: { [k: string]: IUserActiveDelegateItem[] };
}

export const initialClientState: IClientState = {
  clients: [],
  currentClient: null,
  client: null,
  clientError: null,
  legalRepresentative: [],
  clientDelegate: null,
};
