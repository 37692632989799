import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUserActiveDelegateItem } from '@literax/models/user.model';
import { IAppState } from '@literax/store';
import {
  GetClientActiveDelegate,
  GetRepresentative,
} from '@literax/store/client/client.actions';
import { getLegalRepresentatives } from '@literax/store/client/client.selectors';
import { select, Store } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { getClientDelegate } from '../../../store/client/client.selectors';

@Component({
  selector: 'literax-delegate-process-dialog',
  templateUrl: './delegate-process-dialog.component.html',
  styleUrls: ['./delegate-process-dialog.component.scss'],
})
export class DelegateProcessDialogComponent implements OnInit, OnDestroy {
  selectClientDelegate$ = this.store.pipe(
    untilDestroyed(this),
    select(getClientDelegate)
  );

  clientDelegate: IUserActiveDelegateItem;

  constructor(
    private store: Store<IAppState>,
    private dialogRef: MatDialogRef<DelegateProcessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  ngOnInit(): void {
    this.store.dispatch(GetClientActiveDelegate());
  }

  ngOnDestroy(): void {}

  cancelEvent() {
    this.closeModal();
  }

  closeModal() {
    this.data.cancelCallback();
    this.dialogRef.close();
  }

  submitForm() {
    this.data.acceptCallback(this.clientDelegate);
    this.dialogRef.close();
  }

  addThis(event: any, delegate: IUserActiveDelegateItem) {
    this.clientDelegate = delegate;
  }
}

export interface ConfirmDialogData {
  acceptCallback: (args?: any) => void;
  cancelCallback: () => void;
  addThis: () => void;
  representatives: any[];
  acceptButton: string;
  cancelButton: string;
  message: string;
  title: string;
  userSignerId: number;
  documentId: number;
}
