<div class="signers-content" *ngIf="signatureLegal$ | async as signature">
  <div class="validation-participants">
    {{ 'VALIDATOR_UUID.FORM.SIGNS.LEGAL' | translate }}
  </div>
  <div *ngIf="signature?.signatures?.length > 0">
    <div *ngFor="let signatures of signature?.signatures">
      <div *ngIf="signatures.legal_signatures?.length > 0">
        <div *ngFor="let signaturesLegal of signatures?.legal_signatures">
          <div class="row">
            <mat-accordion>
              <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div>
                      <div class="signers">
                        <span class="signers"
                          ><i class="icon-signers fal fa-pen-fancy"></i>
                          {{ signaturesLegal?.company_name }}
                        </span>
                        <span class="signers">
                          {{ ' | ' }}
                        </span>
                        <span class="signers">
                          {{ signaturesLegal?.company_rfc }}
                        </span>
                      </div>
                      <div class="separator-signers"></div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="container-fluid" style="width: 100%">
                  <div class="row">
                    <div class="validation-signers-right">
                      <div>
                        <div class="validation-label">
                          {{
                            'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SERIE_NUMBER'
                              | translate
                          }}
                        </div>
                        <div class="validation-text">
                          {{ signaturesLegal?.serial_number }}
                        </div>
                      </div>
                    </div>
                    <div class="separator-width"></div>
                    <div class="validation-signers-left">
                      <div>
                        <div class="validation-label">
                          {{
                            'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.DATE_OCSP'
                              | translate
                          }}
                        </div>
                        <div class="validation-text">
                          {{ signaturesLegal?.psc_timestamp }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator"></div>
                  <div class="row">
                    <div class="validation-signers">
                      <div>
                        <div
                          *ngIf="
                            signature.signature_type === 'digital_signature'
                          "
                          class="validation-label"
                        >
                          {{
                            'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS.DIGITAL'
                              | translate
                          }}
                        </div>
                        <div class="validation-text">
                          {{ signaturesLegal?.sign_data }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator"></div>
                  <div class="row">
                    <div class="validation-signers-right">
                      <div>
                        <div class="validation-label">
                          {{
                            'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.DATE_CERT'
                              | translate
                          }}
                        </div>
                        <div class="validation-text">
                          {{ signaturesLegal?.psc_timestamp }}
                        </div>
                      </div>
                    </div>
                    <div class="separator-width"></div>
                    <div class="validation-signers-left">
                      <div>
                        <div class="validation-label">
                          {{
                            'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.PSC_POLICY'
                              | translate
                          }}
                        </div>
                        <div class="validation-text">
                          {{ signaturesLegal?.policy }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator"></div>
                  <div class="row">
                    <div class="validation-signers-right">
                      <div>
                        <div class="validation-label">
                          {{
                            'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.FINGER_PRINT'
                              | translate
                          }}
                        </div>
                        <div class="validation-text">
                          {{ signaturesLegal?.finger_print }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator"></div>
                  <div class="row">
                    <div class="validation-signers-psc">
                      <div>
                        <div class="validation-label">
                          {{
                            'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGN_PSC'
                              | translate
                          }}
                        </div>
                        <div class="validation-text">
                          {{ signaturesLegal?.psc_signature }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator"></div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
