<div class="mat-dialog-history-container">
  <h1 mat-dialog-title>
    {{ 'DOCUMENTS.SIGNERS.HISTORY.DIALOG_TITTLE' | translate }}
  </h1>
  <div
    class="wrapper"
    *ngIf="
      this.history === undefined ||
      this.history === null ||
      this.history?.length === 0
    "
  >
    <span class="col bottom text-center">
      {{ 'DOCUMENTS.SIGNERS.HISTORY.EMPTY' | translate }}
    </span>
  </div>
  <div mat-dialog-content *ngFor="let item of this.history">
    <div class="wrapper">
      <div class="vertical col-1">
        <div class="row h-100 pl-2">
          <span class="fa-stack fa-lg icon m-auto">
            <i [class]="secondHistoyIcons[item?.event]"></i>
            <i [class]="histoyIcons[item?.event]"></i>
          </span>
        </div>
      </div>
      <span class="col-11 bottom">
        <span class="row">
          <div class="p-2 align-middle col bold m-auto">
            {{ 'EMAIL_EVENT.STATUSES.' + item?.event | uppercase | translate }}
          </div>
          <div
            class="py-2 pr-1 align-middle float-right col-5 info text-right m-auto"
          >
            {{ this.formatDate(item['@timestamp']) }}
          </div>
        </span>
      </span>
    </div>
  </div>
  <div>
    <button
      class="button-history primary float-right m-2 mt-4"
      type="button"
      (debounceClick)="sendMailClicked()"
      [disabled]="isDisabled"
    >
      <i class="far fa-paper-plane"></i>&nbsp;{{
        'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.RESEND' | translate
      }}
    </button>
    <button
      class="button-close float-left m-2 mt-4"
      type="button"
      (click)="onCloseClick()"
    >
      <i class="fal fa-times"></i> &nbsp;{{
        'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.CLOSE' | translate
      }}
    </button>
  </div>
</div>
