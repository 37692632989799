import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'literax-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss'],
})
export class LoginFailedComponent implements OnInit {
  message: string;
  constructor(
    private route: ActivatedRoute,
    private msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.message = params?.message;
    });
  }

  retry(): void {
    this.msalService.instance.logoutRedirect();
  }
}
