import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Plans } from '@literax/models/http/onboarding/plan.model';
import { PlansService } from './plans.service';
import { Recurrence } from '@literax/models/http/onboarding/recurrence.model';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  constructor(private base: PlansService) {}

  /**
   * Gets all documents from the specified page
   * @param page The current page
   * @param sort The parameter to sort by, divided with a semicolon with the sorting
   * way (asc or desc). Ex: name:asc. Defaults to id:desc
   * @returns An observable of ServiceResponse
   */
  getPlans(): Observable<Plans> {
    return this.base.get(`/${environment.apiVersion}/plans`);
  }

  postRecurrence(recurrence: Recurrence): Observable<Recurrence> {
    return this.base.post(`/${environment.apiVersion}/plans/reference`, recurrence);
  }
}
