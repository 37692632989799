<div class="input-container" fileDnD>
  <label *ngIf="!minimal" class="main-label">{{ labelTag }}</label>
  <label class="file-input">
    <span class="gdx-mat-button">{{ currentButtonLabel() }}</span>

    <div class="internal-label">
      <input #input type="file" class="input" [accept]="acceptString()" />
      
      <span>{{ fileName || currentFileName }}</span>
      <i class="fal fa-arrow-alt-down"></i>
    </div>
  </label>
</div>
