import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'literax-cancel-process-document',
  templateUrl: './cancel-process-document.component.html',
  styleUrls: ['./cancel-process-document.component.scss'],
})
export class CancelProcessDocumentComponent implements OnInit {
  commentsForm = new FormGroup({
    comments: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<CancelProcessDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    this.dialogRef.close(this.commentsForm.value.comments);
  }
}
