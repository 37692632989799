<div class="top-divider">
  <div
    class="col-auto float-right"
    [lang]="lang$ | async"
    (SetLang)="setLang($event)"
  >
    <div id="divButtonTraslate">
      <button
        gdx-button
        matRipple
        [matMenuTriggerFor]="translateMenu"
        class="button--secondary"
      >
        {{ language | uppercase }}
      </button>
    </div>
    <mat-menu #translateMenu="matMenu" xPosition="before">
      <button *ngIf="lang != 'en'" mat-menu-item (click)="setLang('en')">
        ENGLISH
      </button>
      <button *ngIf="lang != 'es'" mat-menu-item (click)="setLang('es')">
        ESPAÑOL
      </button>
    </mat-menu>
  </div>
</div>

<div class="background-colorvalidator">
  <div class="title">
    {{ 'VALIDATOR_UUID.TITLE' | translate }}
  </div>
  <mat-card class="mat-elevation-z3">
    <div class="validation-content">
      <div class="validation-title">
        <p class="text-center">
          {{ 'VALIDATOR_UUID.MATCARD.SIGN_TEXT' | translate }}
        </p>
      </div>
      <div class="validation-home">
        <h4 class="validation-folio">
          {{ 'VALIDATOR_UUID.MATCARD.TITLE_BOX' | translate }}
        </h4>
        <div class="validation-input">
          <input
            type="text"
            class="wdt-input"
            #uiid
            placeholder="{{ 'VALIDATOR_UUID.MATCARD.TITLE_BOX' | translate }}"
          />
        </div>
        <div class="validation-button">
          <button
            gdx-button
            class="finish-button"
            buttonStyle="primary"
            (click)="onVerification()"
          >
            {{ 'VALIDATOR_UUID.MATCARD.BUTTON_BOX' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-card>
  <div class="footer-content">
    <div class="footer-verification">
      {{ 'VALIDATOR_UUID.FOOTER.VERIFICATION_SIGN' | translate }}
    </div>
    <div class="footer-register">
      <a class="footer-register" (click)="onRegister()">{{
        'VALIDATOR_UUID.FOOTER.REGISTER' | translate
      }}</a>
    </div>
  </div>
</div>
