<div class="container">
  <div class="row">
    <div id="sign-document">
      <form #fileForm [formGroup]="form" (ngSubmit)="onSubmit($event)">
        <div formGroupName="representative">
          <literax-digital-signature-form
            [form]="form.get('representative')"
            [identification]="identification"
            [label]="representativeLabel"
            [certificateAPIErrors]="certificateAPIErrors$ | async"
            [signDataAPIErrors]="signDataAPIErrors$ | async"
            [keyAndSignDataAPIErrors]="keyAndSignDataAPIErrors$ | async"
            [serverErrors]="serverErrors$ | async"
          >
          </literax-digital-signature-form>
        </div>
        <div *ngIf="form.get('moral')">
          <div class="separator"></div>
          <div *ngFor="let item of form.get('moral').controls">
            <literax-digital-signature-form
              [form]="item"
              [certificateAPIErrors]="certificateAPIErrors$ | async"
              [label]="moralLabel"
              [signDataAPIErrors]="signDataAPIErrors$ | async"
              [keyAndSignDataAPIErrors]="keyAndSignDataAPIErrors$ | async"
              [serverErrors]="serverErrors$ | async"
            >
            </literax-digital-signature-form>
          </div>
        </div>
        <div class="block">
          <label>
            <mat-checkbox
              type="checkbox"
              formControlName="acceptTerms"
            ></mat-checkbox>
            {{ 'PROFILE.FORM.ACEPT_TERMS' | translate }} {{ document?.name }}
            <span *ngIf="document?.attachments.length > 1"
              >{{ 'PROFILE.FORM.ARTICULE' | translate }} {{ attachmentCount }}
              {{ 'PROFILE.FORM.ANNEXES' | translate }}
            </span>
          </label>
        </div>
        <div class="buttons">
          <div class="left-buttons">
            <button
              *ngIf="showCancel"
              gdx-button
              buttonStyle="link"
              class="interfirma-button mrr-10"
              (click)="cancel($event)"
            >
              <i class="icon far fa-times-circle if-mr-4"> </i>
              <span class="text">{{
                'FORM.FILE_INPUT.CANCEL' | translate
              }}</span>
            </button>
          </div>
          <div class="right-buttons">
            <button
              *ngIf="showSubmit"
              gdx-button
              buttonStyle="link"
              class="interfirma-button primary mrr-10"
              [disabled]="!this.form.valid"
              (debounceClick)="submit()"
              matRipple
            >
              <i class="icon fal fa-pen-fancy if-mr-4"></i>
              <span class="text">
                {{ 'FORM.FILE_INPUT.SIGN' | translate }}</span
              >
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
