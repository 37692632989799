import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgModel,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { Observable } from 'rxjs';
import {
  take,
  map,
  debounce,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Select } from '@interfactura/gdx-angular-ui';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() type = 'text';
  @Input() control: FormControl = new FormControl();
  @Input() overlay = false;
  @Input() suggestions = false;
  @Input() options: Select[];
  @Input() filteredOptions: Observable<any[]>;
  @Input() optionParameter: string;
  @Input() uppercase = false;
  @Input() debounceTime = 300;
  @Input() textTransform = null;
  @Input() name: string;
  @Input() displayWith: (value: any) => string;
  hasValue = false;
  @ViewChild('input', { static: true })
  inputChild: ElementRef<HTMLInputElement>;
  @Output() change = new EventEmitter();
  @Output() autocompleteSelected: EventEmitter<number | string | any> =
    new EventEmitter();
  @Input() icon: string;
  @Input() ngModel: NgModel;
  value: any;
  disabled: boolean;

  constructor() {}

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};

  ngOnInit() {
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        debounceTime(this.debounceTime)
      )
      .subscribe((event) => this.change.emit(event));
  }
  ngOnDestroy() {}

  onFocusOut(event: any) {
    if (
      event.target.value &&
      event.target.value.length > 0 &&
      event.target.value.trim()
    ) {
      this.hasValue = true;
    }
  }

  transform(event: any) {
    if (this.textTransform === 'upperCase') {
      this.upperCase(event);
    } else if (this.textTransform === 'lowerCase') {
      this.upperCase(event);
    }
  }

  upperCase(event: any) {
    if (
      event.target.value &&
      event.target.value.length > 0 &&
      event.target.value.trim()
    ) {
      event.target.value = event.target.value.toUpperCase();
    }
  }

  lowerCase(event: any) {
    if (
      event.target.value &&
      event.target.value.length > 0 &&
      event.target.value.trim()
    ) {
      event.target.value = event.target.value.toLowerCase();
    }
  }

  onEnterUp(event: any) {
    if (
      event.target.value &&
      event.target.value.length > 0 &&
      event.target.value.trim()
    ) {
      this.hasValue = true;
    }
    this.inputChild.nativeElement.blur();
  }

  onClicked() {
    if (this.hasValue && this.overlay) {
      this.control.reset();
      this.hasValue = false;
    }
  }

  onOptionSelected(event: any) {
    this.autocompleteSelected.emit(event);
  }
}
