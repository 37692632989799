<div class="applicant-request">
  <div class="wrapper" >
    <div class="check-container">
      <div class="rectangle" *ngIf="isOwner || applicant?.status?.key !== 'rejected'">
        <i class="fal fa-cogs"></i>
      </div>
			<div
				class="rectangle rejected"
				*ngIf="applicant?.status?.key === 'rejected'"
				(click)="openCommentDetails()"
			>
				<i class="fas fa-times"></i>
			</div>
    </div>
    <div class="applicant">
      <div class="applicant-info">
        <p class="applicant name" *ngIf="isOwner">
          {{  user?.name + ' ' + user?.last_name }}
          <span class="author-label">
            - {{ 'DOCUMENT-CONFIGURATION.APPLICANT.APPLICANT' | translate }}
          </span>
        </p>
        <p class="applicant name" *ngIf="!isOwner">
          {{ applicant?.name }}
          <span class="author-label">
            - {{ 'DOCUMENT-CONFIGURATION.APPLICANT.APPLICANTTO' | translate }}
          </span>
        </p>
      </div>
      <div class="applicant-extra-info">
        <div class="info-cell">
          <span class="title">
            {{ 'DOCUMENTS.SIGNERS.EMAIL' | translate }}:
          </span>
          <span class="extra-info">{{ applicant?.email }}</span>
        </div>
        <div class="info-cell">
          <span class="title" *ngIf="!isOwner">
            {{ 'DOCUMENT-CONFIGURATION.APPLICANT.EXPIRY' | translate }}:
          </span>
          <span class="title" *ngIf="isOwner">
            {{ 'DOCUMENT-CONFIGURATION.APPLICANT.REQUEST_DATE' | translate }}:
          </span>
          <span class="extra-info">
            {{ applicant?.expiry || document?.created_at| date: 'dd/MM/yyyy' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
