<table [ngClass]="activateScrollX ? 'scroll-x' : 'full-table'">
    <thead>
        <tr>
            <th *ngFor="let header of headers; let i = index"
                [ngClass]="{ pointer: header.sort }"
                (click)="changeSort($event, header, i)"
            >
                {{ header.header }}
                <div class="sort-icons"
                    *ngIf="header.sort"
                >
                    <i
                        class="fal fa-sort-up"
                        [ngClass]="{ fas: header?.sort?.active && header?.sort?.direction === 'asc' }"
                    ></i>
                    <i
                        class="fal fa-sort-down"
                        [ngClass]="{ fas: header?.sort?.active && header?.sort?.direction === 'desc' }"
                    ></i>
                </div>
                <div
                    class="sort-order"
                    *ngIf="activeSortsCount > 1 && header?.sort?.active"
                >
                    {{ header?.sort?.order }}
                </div>
            </th>
        </tr>
    </thead>
    <tbody class="striped">
        <tr *ngFor="let rowData of rowsData">
            <td *ngFor="let header of headers">
                {{ rowData[header.header] }}
            </td>
        </tr>
    </tbody>
</table>
