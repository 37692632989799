import { createSelector } from '@ngrx/store';
import { IClientState } from './client.state';

const selectClients = (state) => state.clients;

export const selectClientList = createSelector(
  selectClients,
  (state: IClientState) => state.clients
);

export const selectCurrentClient = createSelector(
  selectClients,
  (state: IClientState) => state?.currentClient
);

export const selectCurrentClientKind = createSelector(
  selectClients,
  (state) => state?.currentClient?.kind
);

export const getClient = createSelector(
  selectClients,
  (state: IClientState) => state?.client
);

export const getSignatureConfigs = createSelector(
  selectClients,
  (state: IClientState) => state?.currentClient?.signature_configs
);

export const getLegalRepresentatives = createSelector(
  selectClients,
  (state: IClientState) => state?.legalRepresentative
);

export const isElectronicSignatureVisible = createSelector(
  selectClients,
  (state: IClientState) => {
    const electronicSignature = state?.currentClient?.signature_configs.filter(
      (item) => item.kind === 'electronic_signature'
    );
    if (electronicSignature?.length > 0) {
      return electronicSignature.pop().enabled;
    }
    return false;
  }
);

export const isElectronicSignaturePlusVisible = createSelector(
  selectClients,
  (state: IClientState) => {
    const electronicSignaturePlus =
      state?.currentClient?.signature_configs.filter(
        (item) => item.kind === 'electronic_signature_plus'
      );
    if (electronicSignaturePlus?.length > 0) {
      return electronicSignaturePlus.pop().enabled;
    }
    return false;
  }
);

export const isDigitalSignatureVisible = createSelector(
  selectClients,
  (state: IClientState) => {
    const digitalSignature = state?.currentClient?.signature_configs.filter(
      (item) => item.kind === 'digital_signature'
    );
    if (digitalSignature?.length > 0) {
      return digitalSignature.pop().enabled;
    }
    return false;
  }
);

export const getClientDelegate = createSelector(
  selectClients,
  (state: IClientState) => state?.clientDelegate
);
