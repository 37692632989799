<div class="tab-title" (click)="toggleTabContent()">
  <h3>{{ 'DOCUMENT-PREVIEW.ATTACHMENTS.TITLE' | translate }}</h3>
  <i
    [ngClass]="
      contentVisible ? 'fal fa-chevron-up icon' : 'fal fa-chevron-down icon'
    "
  ></i>
</div>
<div class="tab-component" [ngClass]="{ visible: contentVisible }">
  <div *ngIf="showAttachmentAlert" class="subtitle alert-attachment">
    <div class="row">
      <div class="col-md-1">
        <i class="fal fa-exclamation-circle"></i>
      </div>
      <div class="col-md-9">
        <label class="tex-color">{{
          'DOCUMENT-PREVIEW.DOCUMENTS-TAB.ALERT_ATTACHMENT' | translate
        }}</label>

        <p class="tex-color">
          {{
            'DOCUMENT-PREVIEW.DOCUMENTS-TAB.ALERT_ATTACHMENT_DESCRIPTION'
              | translate
          }}
        </p>
      </div>
    </div>
  </div>
  <literax-attachments
    [document]="document"
    [documentsSecondary]="documentsSecondary"
    [mainDoc]="mainDocument"
    [typePromissoryNote]="typePromissoryNote"
    class="tab-component"
    [ngClass]="{ visible: contentVisible }"
    (attachmentSelected)="attachmentSelected.emit($event)"
    (replaceAttachment)="replaceAttachment($event)"
    (editAttachment)="editAttachment.emit($event)"
    (checkAttachmentId)="checkAttachmentId.emit($event)"
  ></literax-attachments>
</div>
<div class="attachment" *ngIf="document?.status.key === 'draft'">
  <literax-compose-attachment-file
    *ngIf="document?.status.key === 'draft'"
    [visibleButtons]="buttonsAttachment"
    [documentId]="document?.id"
    [title]="'COMPOSE_ATTACHMENT_FILE.TITLE'"
  >
  </literax-compose-attachment-file>
</div>
