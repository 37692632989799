<div class="tab-title" (click)="toggleTabContent()">
  <h3>
    {{ 'DOCUMENT-PREVIEW.SIGNERS.PARTICIPANTS' | translate }}
  </h3>
  <i
    [ngClass]="
      contentVisible ? 'fal fa-chevron-up icon' : 'fal fa-chevron-down icon'
    "
  >
  </i>
</div>
<div class="tab-component" [ngClass]="{ visible: contentVisible }">
  <ng-container>
    <literax-applicant
      [document]="document"
      [applicant]="document?.user"
      [user]="user"
      [userDocument]="document?.user"
      [isOwner]="true"
    >
    </literax-applicant>
  </ng-container>
  <ng-container *ngFor="let applicant of applicantRequest">
    <literax-applicant
      [document]="document"
      [applicant]="applicant"
      [user]="user"
      [userDocument]="document?.user"
    >
    </literax-applicant>
  </ng-container>
</div>
