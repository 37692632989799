<div class="buttons-container">
  <div class="left">

		<button
			*ngIf="document?.status?.key === 'sent' && document?.next_actions.includes('rejected_assignment')"
			tabindex="1"
			type="button"
			class="interfirma-button mrr-10 primary"
			(click)="cancelClick.emit($event)"
			matRipple
		>
			<i class="fal fa-arrow-to-left"></i>
			&nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.BACK' | translate }}</span>
		</button>


    <button
      *ngIf="document?.status?.key === 'draft' && configButtons"
      tabindex="1"
      type="button"
      class="interfirma-button mrr-10 primary"
      (click)="backClick.emit($event)"
      matRipple
    >
      <i class="fal fa-arrow-to-left"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.BACK' | translate }}</span>
    </button>

    <button
      *ngIf="document?.status?.key === 'approval' && configButtons"
      tabindex="2"
      type="button"
      class="interfirma-button mrr-10 primary"
      (click)="backClick.emit($event)"
      matRipple
    >
      <i class="fal fa-arrow-to-left"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.BACK' | translate }}</span>
    </button>

    <button
      *ngIf="
        (document?.status?.key === 'draft' && isOwner && !configButtons) ||
        (document?.status?.key === 'reviewed' && isOwner)
      "
      tabindex="3"
      type="button"
      class="interfirma-button mrr-10"
      (click)="deleteFileClick.emit($event)"
      matRipple
    >
      <i class="fal fa-trash-alt"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.DELETE' | translate }}</span>
    </button>

    <button
      *ngIf="
        document?.status?.key === 'in_process' && isOwner && !configButtons
      "
      tabindex="4"
      type="button"
      class="interfirma-button mrr-10"
      (click)="cancelClick.emit('cancel_signature')"
      matRipple
    >
      <i class="fal fa-times-circle"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.CANCEL' | translate }}</span>
    </button>

    <button
      *ngIf="canSign && !configButtons"
      tabindex="5"
      type="button"
      class="interfirma-button mrr-10"
      (click)="rejectSignatureClick.emit('reject_signature')"
      matRipple
    >
      <i class="fal fa-times-circle"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.REJECT' | translate }}</span>
    </button>

    <button
      *ngIf="document?.status?.key === 'review' && isOwner"
      type="button"
      class="interfirma-button mrr-10"
      (click)="cancelReview.emit('cancel_review')"
      matRipple
    >
      <i class="fal fa-times-circle"></i>
      &nbsp;&nbsp;<span>{{
        'WORKSPACE.FOOTER.CANCEL_REVIEW' | translate
      }}</span>
    </button>

    <button
      *ngIf="document?.status?.key === 'approval' && isOwner"
      type="button"
      class="interfirma-button mrr-10"
      (click)="cancelReview.emit('cancel_approval')"
      matRipple
    >
      <i class="fal fa-times-circle"></i>
      &nbsp;&nbsp;<span>{{
        'WORKSPACE.FOOTER.CANCEL_APPROVE' | translate
      }}</span>
    </button>

    <button
      *ngIf="
        isCheckApprove && document?.status.key === 'approval' && !configButtons
      "
      tabindex="5"
      type="button"
      class="interfirma-button mrr-10"
      (click)="rejectedApproval.emit('rejected_approval')"
      matRipple
    >
      <i class="fal fa-times-circle"></i>
      &nbsp;&nbsp;<span>{{
        'WORKSPACE.FOOTER.REJECTED_APPROVAL' | translate
      }}</span>
    </button>
  </div>
  <div class="right">
    <button
      *ngIf="!configButtons && !isDraftReview && !isReview && !isDocReview"
      tabindex="6"
      type="button"
      class="interfirma-button mrr-10"
      matRipple
      (click)="downloadClick.emit($event)"
    >
      <i class="far fa-cloud-download"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.DOWNLOAD' | translate }}</span>
    </button>

    <ng-container
      *ngIf="
        isTemplate && isOwner && filteredActions.length > 0 && !configButtons
      "
    >
      <mat-menu #markAsMenu="matMenu" yPosition="above">
        <button
          mat-menu-item
          (click)="markAsClick.emit(action)"
          *ngFor="let action of filteredActions"
        >
          {{ 'WORKSPACE.FOOTER.ACTIONS.' + action.toUpperCase() | translate }}
        </button>
      </mat-menu>

      <button
        tabindex="7"
        type="button"
        class="interfirma-button mrr-10"
        matRipple
        [matMenuTriggerFor]="markAsMenu"
      >
        <i class="fal fa-share-alt"></i>
        &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SEND_TO' | translate }}</span>
      </button>
    </ng-container>

    <ng-container
      *ngIf="
        !isTemplate &&
        isOwner &&
        filteredActions.length > 0 &&
        !configButtons &&
        (transaction_type === 'signature' || transaction_type === 'approval') &&
        documentStatus === 'rejected'
      "
    >
      <mat-menu #markAsMenu="matMenu" yPosition="above">
        <button
          mat-menu-item
          (click)="markAsClick.emit(action)"
          *ngFor="let action of filteredActions"
        >
          {{ 'WORKSPACE.FOOTER.ACTIONS.' + action.toUpperCase() | translate }}
        </button>
      </mat-menu>

      <button
        tabindex="8"
        type="button"
        class="interfirma-button mrr-10"
        matRipple
        [matMenuTriggerFor]="markAsMenu"
        *ngIf="document?.status.key !== 'review'"
      >
        <i class="fal fa-share-alt"></i>
        &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SEND_TO' | translate }}</span>
      </button>
    </ng-container>

    <!-- validation for by button template and digital -->
    <ng-container
      *ngIf="
        isTemplate &&
        (document?.status?.key === 'draft' ||
          document?.status?.key === 'data_collection') &&
        (document?.user_signer.length > 0 ||
          document?.sign_requests.length > 0 ||
          document?.legal_representative.length > 0)
      "
    >
      <mat-menu #markAsMenuTemplate="matMenu" yPosition="above">
        <button
          mat-menu-item
          [disabled]="
            disabledSendToSignButton &&
            (document?.status?.key === 'draft' ||
              document?.status?.key === 'data_collection')
          "
          (click)="sendToSignClick.emit('mark_as_in_process')"
        >
          {{ 'WORKSPACE.FOOTER.SEND_TO_SIGN' | translate }}
        </button>
        <button
          *ngIf="document?.status?.key === 'draft' && isTemplate"
          mat-menu-item
          (click)="sendToCollectClick.emit('send_collection')"
        >
          {{ 'WORKSPACE.FOOTER.SEND_TO_COLLECT' | translate }}
        </button>
      </mat-menu>

      <button
        *ngIf="
          isTemplate &&
          (document?.status?.key === 'draft' ||
            document?.status?.key === 'data_collection') &&
          isOwner &&
          (document?.user_signer.length > 0 ||
            document?.sign_requests.length > 0 ||
            document?.legal_representative.length > 0) &&
          isDigital
        "
        tabindex="9"
        type="button"
        class="interfirma-button primary mrr-10"
        matRipple
        [matMenuTriggerFor]="markAsMenuTemplate"
      >
        <i class="far fa-paper-plane"></i>
        &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SEND' | translate }}</span>
      </button>
    </ng-container>

    <!-- #region botones revision y revisado -->
    <mat-menu
      #sendIfReviewAndReviewd="matMenu"
      yPosition="above"
      class="mt-1 p-1"
    >
      <button
        *ngIf="!isDocApprove"
        type="button"
        class="interfirma-button primary mb-1"
        style="height: 32px"
        (click)="reviewToApproval.emit('reviewed_to_approval')"
        matRipple
        mat-menu-item
      >
        <i class="fal fa-check-double"></i>
        &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.APPROVAL' | translate }}</span>
      </button>

      <button
        *ngIf="isDocReview"
        type="button"
        class="interfirma-button primary"
        style="height: 32px"
        (click)="approve.emit('reviewed_to_signature')"
        matRipple
        mat-menu-item
      >
        <i class="far fa-paper-plane"></i>
        &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SIGN' | translate }}</span>
      </button>

      <button
        *ngIf="isDocApprove"
        type="button"
        class="interfirma-button primary"
        style="height: 32px"
        (click)="approveToSignature.emit('approved_to_signature')"
        matRipple
        mat-menu-item
      >
        <i class="far fa-paper-plane"></i>
        &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SIGN' | translate }}</span>
      </button>
    </mat-menu>

    <button
      *ngIf="
        (isDocReview && isReviewed && isOwner) ||
        (isDocApprove && isApproved && isOwner)
      "
      tabindex="10"
      type="button"
      class="interfirma-button mrr-10"
      matRipple
      [matMenuTriggerFor]="sendIfReviewAndReviewd"
    >
      <i class="fal fa-share-alt"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SEND_TO' | translate }}</span>
    </button>
    <!-- #endregion -->

    <!-- button config document pdf -->
    <button
      *ngIf="
        !isTemplate &&
        document?.status?.key === 'draft' &&
        document?.transaction_type !== 'review' &&
        document?.transaction_type !== 'approval' &&
        isOwner &&
        (document?.user_signer.length > 0 ||
          document?.sign_requests.length > 0 ||
          document?.legal_representative.length > 0) &&
        isDigital
      "
      tabindex="11"
      [disabled]="disabledSendToSignButton"
      type="button"
      class="interfirma-button primary"
      (click)="sendToSignClick.emit('mark_as_in_process')"
      matRipple
    >
      <i class="far fa-paper-plane"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SEND_TO_SIGN' | translate }}</span>
    </button>

    <button
      *ngIf="
        document?.status?.key !== 'draft' &&
        document?.status?.key !== 'data_collection' &&
        document?.transaction_type !== 'review' &&
        document?.transaction_type !== 'approval' &&
        !configButtons
      "
      tabindex="12"
      type="button"
      class="interfirma-button primary"
      [class.mrr-10]="isSigner"
      (click)="signaturesClick.emit($event)"
      matRipple
    >
      <i class="fal fa-pen-fancy"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SIGNATURES' | translate }}</span>
    </button>
    <button
      *ngIf="canSign && !configButtons"
      tabindex="13"
      type="button"
      class="interfirma-button primary"
      [disabled]="disabledSignButton"
      (click)="signatureClick.emit($event)"
      matRipple
    >
      <i class="fal fa-pen-fancy"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SIGN' | translate }}</span>
    </button>
    &nbsp;
    <button
      *ngIf="typeDocument === 'promissory_note' && documentStatus === 'draft'"
      tabindex="14"
      type="button"
      class="interfirma-button primary"
      routerLinkActive="router-link-active"
      (click)="editPaymentclick.emit($event)"
      matRipple
    >
      <i class="fal fa-pen-fancy"></i>
      &nbsp;&nbsp;<span>{{ 'PROMISSORY_NOTES.EDIT' | translate }}</span>
    </button>

    <button
      *ngIf="
        document?.status.key === 'draft' &&
        document?.transaction_type === 'review' &&
        isOwner
      "
      tabindex="15"
      type="button"
      class="interfirma-button primary mrr-10"
      [disabled]="
        !(
          document?.sign_requests.length > 0 ||
          document?.legal_representative.length > 0
        )
      "
      (click)="sendToReview.emit('send_review')"
    >
      <i class="far fa-paper-plane"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SEND' | translate }}</span>
    </button>

    <button
      *ngIf="isCheckReview && document?.status.key === 'review'"
      tabindex="16"
      type="button"
      class="interfirma-button primary mrr-10"
      (click)="concludeReview.emit('to_reviewed')"
    >
      <i class="fal fa-check"></i>
      &nbsp;&nbsp;<span>{{
        'WORKSPACE.FOOTER.FINISH_REVIEW' | translate
      }}</span>
    </button>

    <button
      *ngIf="isCheckApprove && document?.status.key === 'approval'"
      tabindex="17"
      type="button"
      class="interfirma-button primary mrr-10"
      (click)="concludeReview.emit('to_approved')"
    >
      <i class="far fa-check-double"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.APPROVE' | translate }}</span>
    </button>

    <button
      *ngIf="
        document?.status.key === 'draft' &&
        document?.transaction_type === 'approval' &&
        isOwner
      "
      tabindex="18"
      type="button"
      class="interfirma-button primary mrr-10"
      [disabled]="
        !(
          document?.sign_requests.length > 0 ||
          document?.legal_representative.length > 0
        )
      "
      (click)="sendToReview.emit('send_approval')"
    >
      <i class="far fa-paper-plane"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SEND' | translate }}</span>
    </button>

    <button
      type="button"
      class="interfirma-button primary"
      matRipple
      *ngIf="document?.status.key === 'data_collection'"
      [disabled]="!isSigner"
      (click)="saveData.emit('mark_as_in_process')"
      tabindex="19"
    >
      <i class="fal fa-save"></i>
      &nbsp;&nbsp;<span>{{ 'WORKSPACE.FOOTER.SAVE' | translate }}</span>
    </button>
  </div>
</div>
