<literax-auth-navbar
  [title]="'AUTH.TITLE.NAVBAR' | translate"
></literax-auth-navbar>
<div class="main-container">
  <section id="stepper-modal">
    <div id="modal-content">
      <div id="stepper-main" class="preview-row">
        <mat-horizontal-stepper #stepper [linear]="false">
          <mat-step [stepControl]="form">
            <h3 class="step-title">{{ 'AUTH.STEP' | translate }}</h3>
            <h6 class="step-subtitle">{{ 'AUTH.NOTICE' | translate }}</h6>
            <div class="legal-buttons">
              <button
                gdx-button
                class="personal-btn"
                type="button"
                (click)="togglePersonType(false)"
                [ngStyle]="{
                  'background-color': !legalPerson
                    ? selectedCardColor
                    : 'transparent',
                  border: !legalPerson ? '0' : 'solid 1px #9E9E9E',
                  color: !legalPerson ? 'white' : '#757575'
                }"
              >
                <i class="fal fa-user-alt"></i>
                {{ 'AUTH.FIELDS.ACCOUNT_PERSONAL' | translate }}
              </button>
              <button
                gdx-button
                class="enterprise-btn"
                type="button"
                (click)="togglePersonType(true)"
                [ngStyle]="{
                  'background-color': legalPerson
                    ? selectedCardColor
                    : 'transparent',
                  border: legalPerson ? '0' : 'solid 1px #9E9E9E',
                  color: legalPerson ? 'white' : '#757575'
                }"
              >
                <i class="fal fa-building"></i>
                {{ 'AUTH.FIELDS.ACCOUNT_ENTERPRISE' | translate }}
              </button>
            </div>
            <form
              #registrationForm
              [formGroup]="form"
              class="mat-elevation-z6 card"
            >
              <div>
                <div class="row">
                  <div
                    formGroupName="user"
                    class="col-md-4"
                    *ngIf="!legalPerson"
                  >
                    <div class="gdx-form-group">
                      <gdx-form-field [label]="'AUTH.FIELDS.NAME' | translate">
                        <input
                          type="text"
                          gdxInput
                          class="form-input"
                          formControlName="name"
                        />
                      </gdx-form-field>
                    </div>
                  </div>

                  <div
                    formGroupName="user"
                    class="col-md-4"
                    *ngIf="!legalPerson"
                  >
                    <div class="gdx-form-group">
                      <gdx-form-field
                        [label]="'AUTH.FIELDS.LAST_NAME' | translate"
                      >
                        <input
                          type="text"
                          gdxInput
                          class="form-input"
                          formControlName="last_name"
                        />
                      </gdx-form-field>
                    </div>
                  </div>

                  <div
                    formGroupName="user"
                    class="col-md-4 col-md-3-mobile"
                    *ngIf="!legalPerson"
                  >
                    <div class="gdx-form-group">
                      <gdx-form-field [label]="'AUTH.FIELDS.RFC' | translate">
                        <input
                          type="text"
                          gdxInput
                          class="form-input"
                          formControlName="rfc"
                          (input)="textTransform($event, 'user.rfc')"
                        />
                      </gdx-form-field>
                      <literax-api-error
                        [control]="form.get('user.rfc')"
                        [errors]="taxIdError"
                      >
                      </literax-api-error>
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="legalPerson">
                    <div class="gdx-form-group">
                      <gdx-form-field
                        [label]="'AUTH.FIELDS.BUSINESS_NAME' | translate"
                      >
                        <input
                          type="text"
                          gdxInput
                          class="form-input"
                          formControlName="business_name"
                        />
                      </gdx-form-field>
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="legalPerson">
                    <div class="gdx-form-group">
                      <gdx-form-field
                        [label]="'AUTH.FIELDS.TRADE_NAME' | translate"
                      >
                        <input
                          type="text"
                          gdxInput
                          class="form-input"
                          formControlName="tradename"
                        />
                      </gdx-form-field>
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="legalPerson">
                    <div class="gdx-form-group">
                      <gdx-form-field [label]="'AUTH.FIELDS.RFC' | translate">
                        <input
                          type="text"
                          gdxInput
                          class="form-input"
                          formControlName="tax_id"
                          (input)="textTransform($event, 'tax_id')"
                        />
                      </gdx-form-field>
                      <literax-api-error
                        [control]="form.controls.tax_id"
                        [errors]="taxIdError"
                      >
                      </literax-api-error>
                    </div>
                  </div>
                </div>

                <div formGroupName="user" class="row" *ngIf="!isLoggedIn">
                  <div class="col-md-4">
                    <div class="gdx-form-group">
                      <gdx-form-field [label]="'AUTH.FIELDS.EMAIL' | translate">
                        <input
                          type="text"
                          gdxInput
                          class="form-input"
                          formControlName="email"
                          [pattern]="emailPattern"
                        />
                      </gdx-form-field>
                      <literax-api-error
                        [control]="form.get('user.email')"
                        [errors]="userError"
                      >
                      </literax-api-error>
                    </div>
                  </div>

                  <div class="col-md-4" *ngIf="!isLoggedIn">
                    <div class="gdx-form-group">
                      <gdx-form-field
                        [label]="'AUTH.FIELDS.PASSWORD' | translate"
                      >
                        <input
                          type="password"
                          gdxInput
                          class="form-input"
                          formControlName="password"
                        />
                      </gdx-form-field>
                      <literax-api-error
                        [control]="form.get('user.password')"
                        [errors]="passwordError"
                      ></literax-api-error>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-md-3-mobile-country">
                  <div class="gdx-form-group">
                    <gdx-select
                      label="{{ 'AUTH.FIELDS.COUNTRY' | translate }}"
                      [searchable]="true"
                      [options]="countries"
                      [control]="form.controls.country"
                      errors="{{ 'AUTH.ERRORS.INVALID.COUNTRY' | translate }}"
                    ></gdx-select>
                  </div>
                </div>

                <div class="col-md-3 col-md-2-mobile">
                  <div class="gdx-form-group">
                    <gdx-form-field
                      [label]="'AUTH.FIELDS.POSTAL_CODE' | translate"
                    >
                      <input
                        type="text"
                        gdxInput
                        class="form-input"
                        maxlength="5"
                        formControlName="postal_code"
                      />
                    </gdx-form-field>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="gdx-form-group">
                    <gdx-form-field [label]="'AUTH.FIELDS.PHONE' | translate">
                      <input
                        type="text"
                        gdxInput
                        mask="(000) 000-0000"
                        class="form-input"
                        formControlName="phone"
                      />
                    </gdx-form-field>
                  </div>
                </div>
              </div>

              <div class="buttons">
                <button
                  gdx-button
                  buttonStyle="link"
                  class="back-btn"
                  (click)="onCancelClicked()"
                  tabindex="5"
                >
                  {{ 'AUTH.BUTTONS.CANCEL' | translate }}
                </button>
                <button
                  gdx-button
                  buttonStyle="primary"
                  class="registration-btn button--primary"
                  [disabled]="!form.valid"
                  tabindex="4"
                  type="button"
                  (click)="verifyInfo()"
                >
                  {{ 'AUTH.BUTTONS.NEXT' | translate }}
                </button>
              </div>
            </form>
          </mat-step>

          <mat-step [stepControl]="recurrence">
            <h3 class="step-title">{{ 'PLANS.TITLE' | translate }}</h3>
            <form
              #recurrenceForm
              [formGroup]="recurrence"
              class="mat-elevation-z6 card"
            >
              <div class="container-fluid plans-container">
                <div class="row">
                  <div
                    *ngFor="let plan of plans; let i = index"
                    class="col-lg-3 col-sm-5 col-xs-12 plan-card mat-elevation-z3"
                    (click)="onCardClicked(i, plan?.productoid, plan?.istrail)"
                  >
                    <div
                      class="plan-title"
                      [ngStyle]="{
                        'background-color':
                          selectedCard == i
                            ? selectedCardColorPlanHeader
                            : defaultCardColor
                      }"
                    >
                      <div
                        [ngStyle]="{
                          color: selectedCard == i ? 'white' : newTextColor
                        }"
                        class="size"
                      >
                        {{ plan?.nombre }}
                      </div>
                    </div>
                    <div class="plan-specs">
                      <div
                        *ngFor="let product of plan?.descripcion?.split('|')"
                        class="spec"
                      >
                        <div
                          [ngStyle]="{
                            color:
                              selectedCard == i
                                ? selectedCardColorPlanDescription
                                : newArrowGray
                          }"
                        >
                          - {{ product }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="plan-price"
                      [ngStyle]="{
                        color:
                          selectedCard == i
                            ? selectedCardColorPlanDescription
                            : newTextColor
                      }"
                    >
                      <div>$ {{ plan?.precio }}</div>
                      <div
                        class="disclaimer"
                        [ngStyle]="{
                          color:
                            selectedCard == i
                              ? selectedCardColorPlanDescription
                              : newArrowGray
                        }"
                      >
                        {{ 'PLANS.DISCLAIMER' | translate }}
                      </div>
                    </div>
                    <div class="plan-buttons"></div>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <button
                  gdx-button
                  buttonStyle="link"
                  class="back-btn"
                  tabindex="5"
                  matStepperPrevious
                >
                  {{ 'PLANS.BUTTONS.BACK' | translate }}
                </button>
                <button
                  gdx-button
                  buttonStyle="primary"
                  class="registration-btn button--primary"
                  [disabled]="!recurrence.valid"
                  tabindex="4"
                  type="button"
                  (debounceClick)="onPaymentClicked()"
                  [debounceTime]="300"
                  matStepperNext
                >
                  {{ 'PLANS.BUTTONS.NEXT' | translate }}
                </button>
              </div>
            </form>
          </mat-step>

          <mat-step *ngIf="!is_trial" [completed]="paymentSuccess">
            <h3>{{ 'PAYMENT.TITLE' | translate }}</h3>
            <form #paymentForm class="mat-elevation-z6 card">
              <div class="payments-container">
                <div
                  #paymentWidget
                  id="gdx-paymentmanager-widget"
                  class="payment-widget-container"
                ></div>
              </div>
              <div class="buttons">
                <button
                  gdx-button
                  buttonStyle="link"
                  class="back-btn"
                  tabindex="5"
                  matStepperPrevious
                >
                  {{ 'PAYMENT.BUTTONS.BACK' | translate }}
                </button>
                <!-- [disabled]="!paymentSuccess" -->
                <button
                  gdx-button
                  buttonStyle="primary"
                  class="registration-btn button--primary"
                  [disabled]="!paymentSuccess"
                  tabindex="4"
                  type="button"
                  (debounceClick)="onRegistrationClicked()"
                  [debounceTime]="300"
                  matStepperNext
                >
                  {{ 'PAYMENT.BUTTONS.NEXT' | translate }}
                </button>
              </div>
            </form>
          </mat-step>

          <mat-step>
            <h3 class="step-title">{{ 'FINISH.TITLE' | translate }}</h3>
            <form class="mat-elevation-z6 card">
              <div class="finish-content">
                <div class="finish-icon" *ngIf="registrationSuccess">
                  <i class="fal fa-check-circle"></i>
                </div>
                <h3 class="finish-message" *ngIf="registrationSuccess">
                  {{ 'FINISH.MESSAGE' | translate }}
                  {{ form.get('user.email').value }}
                </h3>
                <h3 class="finish-message" *ngIf="registrationSuccess">
                  {{ 'FINISH.CONFIRM' | translate }}
                </h3>
                <div class="finish-icon" *ngIf="registrationError">
                  <i class="fal fa-exclamation"></i>
                </div>
                <h3 class="finish-message" *ngIf="registrationError">
                  {{ 'FINISH.ERROR' | translate }}
                </h3>
                <div class="finish-home" *ngIf="registrationSuccess">
                  <h4 class="finish-invitation">
                    {{ 'FINISH.INVITATION' | translate }}
                  </h4>
                  <button
                    gdx-button
                    class="finish-button"
                    buttonStyle="primary"
                    (click)="goToInterfirma()"
                  >
                    {{ 'FINISH.BUTTONS.BACK' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </section>
</div>
