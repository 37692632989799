import * as validatorActions from '@literax/store/validator/validator.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { SignatureService } from '@literax/services/platform/signatures.service';
import { of } from 'rxjs';

@Injectable()
export class ValidatorEffects {
  showSignature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validatorActions.ShowSignature),
      switchMap((action) =>
        this.signatureService.showSignature(action.payload).pipe(
          map((response: ServiceResponse) =>
            validatorActions.ShowSignatureSuccess({
              payload: response.attachment
            })
          ),
          catchError((error) =>
            of(validatorActions.ShowSignatureError({ payload: error }))
          )
        )
      )
    )
  );

  redireShowErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(validatorActions.ShowSignatureError),
        tap((action) => {
          this.router.navigate(['e404']);
        })
      ),
    { dispatch: false }
  );

  hideLoad$ = createEffect(
    () => this.actions$.pipe(ofType(validatorActions.ShowSignatureSuccess)),
    { dispatch: false }
  );

  constructor(
    private signatureService: SignatureService,
    private toastr: I18nToastrService,
    private actions$: Actions,
    private router: Router
  ) {}
}
