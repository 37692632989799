import { IClient } from '@literax/models/http/api/client/client.model';
import * as clientActions from '@literax/store/client/client.actions';
import {
  IClientState,
  initialClientState,
} from '@literax/store/client/client.state';
import { Action, createReducer, on } from '@ngrx/store';

const getNewCurrentClient = (
  state: IClientState,
  payload: IClient[]
): IClient => {
  const stateCurrentClient = state.currentClient || ({} as IClient);
  const currentClient = payload.filter(
    (client) => client.uuid === stateCurrentClient.uuid
  );
  return currentClient[0]
    ? { ...stateCurrentClient, ...currentClient[0] }
    : payload[0];
};

const reducers = createReducer(
  initialClientState,
  on(clientActions.GetClientsSucccess, (state, action) => ({
    ...state,
    clients: action.payload,
    currentClient: getNewCurrentClient(state, action.payload),
  })),
  on(clientActions.SetCurrentClient, (state, action) => {
    if (state.clients.length > 0) {
      const client = state.clients.find((c) => c.uuid === action.payload.uuid);
      return { ...state, currentClient: client };
    }
  }),
  on(clientActions.GetClientSuccess, (state, action) => ({
    ...state,
    client: action.payload,
  })),
  on(clientActions.UpdateClientSuccess, (state, action) => ({
    ...state,
    client: action.payload,
  })),
  on(clientActions.GetRepresentativeSuccess, (state, action) => ({
    ...state,
    legalRepresentative: action.payload,
  })),
  on(clientActions.GetClientActiveDelegateSuccess, (state, action) => {
    return {
      ...state,
      clientDelegate: groupBy(action.payload, 'department', 'name'),
    };
  })
);

const groupBy = <T>(
  array: T[],
  key: string,
  placeholder: string
): { [k: string]: T[] } => {
  return array?.reduce((result, currentValue) => {
    (result[currentValue[key][placeholder]] =
      result[currentValue[key][placeholder]] || []).push(currentValue);
    return result;
  }, {});
};
export function clientReducers(state: IClientState, action: Action) {
  return reducers(state, action);
}
