<div class="document-wizard">
  <div class="row-close">
    <i (click)="closeModal()" class="material-icons">close</i>
  </div>
  <div class="row-title">
    {{
      'DOCUMENT-CONFIGURATION.REASSIGMENT.ASSING' | translate
    }}
  </div>
  <div class="row-sumary">
    {{
      'DOCUMENT-CONFIGURATION.REASSIGMENT.ASSING_INFO' | translate
    }}
  </div>
  <section>
    <div id="modal-content">
      <div id="document-main" class="preview-row">
        <div id="viewer">
           <form [formGroup]="form" >
            <div>
              <div class="receiver">
                <label>{{
                  'TEMPLATES.DIALOGS.FORMS.NEW.RECEIVER' | translate
                }}</label>
                <ng-select
                  ngDefaultControl
                  formControlName="client_user_id"
                  [items]="data.users | async"
                  groupBy="department"
                  bindValue="value"
                  bindLabel="label"
                  [multiple]="false"
                  [searchable]="true"
                  [appendTo]='"body"'
                >
                </ng-select>
              </div>
            </div>

            <div class="buttons mt-5">
              <div class="left-buttons">
                <button
                  gdx-button
                  buttonStyle="link"
                  class="interfirma-button mrr-10"
                  (click)="closeModal()"
                >
                  <i class="icon far fa-times-circle if-mr-4"> </i>
                  <span class="text">
                  {{
                    'DOCUMENT-CONFIGURATION.REASSIGMENT.BUTTONS.CANCEL' | translate
                  }}
                  </span>
                </button>
              </div>
              <div class="right-buttons">
                <button class="interfirma-button primary" (click)="toAssign(form)" [disabled]="form.invalid">
                  <i class="icon far fa-save if-mr-4"></i>
                  <span class="text">
                  {{
                    'DOCUMENT-CONFIGURATION.REASSIGMENT.BUTTONS.SAVE' | translate
                  }}
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>


