import * as documentActions from './document.actions';
import * as positionSignature from '@literax/store/signature-position/singature-position.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  IDocument,
  ILegalRepresentative,
  ICancelatioReason,
} from '@literax/models/http/document/document.model';
import {
  IDocumentState,
  ISignRequest,
  ISignaturePosition,
  initialDocumentState,
} from './document.state';

import { IAPISignRequest } from '@literax/models/http/api/sign-request/sign-request.model';
import { IAttachment } from '@literax/models/http/attachment.model';
import { checkAttachmentSuccess } from '../attachment/attachment.actions';

export const initialState = initialDocumentState;

function refreshCurrentDocument(
  currentDocument: IDocument,
  newDocument: IDocument
): IDocument {
  if (currentDocument?.id !== newDocument?.id) {
    return newDocument;
  }
  const tmp = { ...newDocument, attachments: [...newDocument.attachments] };

  tmp.attachments = newDocument.attachments.map((element: IAttachment) => {
    const attachment = currentDocument.attachments.find(
      (currentAttachment: IAttachment) => element.id === currentAttachment.id
    );
    if (attachment?.updated_at === element?.updated_at) {
      return attachment;
    }
    return element;
  });
  return tmp;
}

function updateSignRequest(
  requests: IAPISignRequest[],
  request: IAPISignRequest
): IAPISignRequest[] {
  const requestList = requests.map((requestItem) =>
    requestItem?.id === request?.id ? request : requestItem
  );
  return requestList;
}

function removeSignRequest(
  requests: IAPISignRequest[],
  request: IAPISignRequest
): IAPISignRequest[] {
  const requestList = requests.filter(
    (requestItem) => requestItem.id !== request.id
  );
  return requestList;
}
function updatePositionLegalRepresentative(
  legalOriginal: ILegalRepresentative[],
  signerId: number,
  newPosition: ISignaturePosition
) {
  const newLegalRepresentative: ILegalRepresentative[] = [];
  legalOriginal.map((legalPerson: ILegalRepresentative) => {
    if (
      legalPerson.legal_representatives.filter((e) => e.id === signerId)
        .length > 0
    ) {
      const arrayLegalRepresentatives = legalPerson.legal_representatives;
      const signersLegalRepresentatives = updatePosition(
        arrayLegalRepresentatives,
        signerId,
        newPosition
      );
      newLegalRepresentative.push({
        ...legalPerson,
        legal_representatives: signersLegalRepresentatives,
      });
    } else {
      newLegalRepresentative.push(legalPerson);
    }
  });
  return newLegalRepresentative;
}
function updatePosition(
  signOriginal: ISignRequest[],
  signerId: number,
  newPosition: ISignaturePosition
) {
  const arraySigner: ISignRequest[] = [];
  const newArrayPosition: ISignaturePosition[] = [];
  signOriginal.map((signer) => {
    if (signer.id === signerId) {
      signer.signature_positions.map((position) => {
        if (position.attachment_id === newPosition.attachment_id) {
          newArrayPosition.push(newPosition);
        } else {
          newArrayPosition.push(position);
        }
      });
      const newSigner = {
        ...signer,
        signature_positions: newArrayPosition,
      };
      arraySigner.push(newSigner);
    } else {
      arraySigner.push(signer);
    }
  });

  return arraySigner;
}

function updateAttachments(
  attachments: IAttachment[],
  newAttachment: IAttachment
) {
  let localAttachmen = { ...newAttachment };

  let fileText = attachments.filter(
    (attach) => attach.id === localAttachmen.id
  )[0]?.text;

  localAttachmen.text = fileText;

  const newArrayAttachment =
    attachments.filter((attach) => attach.id !== localAttachmen.id) || [];

  newArrayAttachment.push(localAttachmen);
  newArrayAttachment.sort((a: IAttachment, b: IAttachment) => a.id - b.id);
  return newArrayAttachment;
}

const documentReducers = createReducer(
  initialState,
  on(documentActions.GetDocumentsSuccess, (state, action) => {
    const documents = [...state.documents, ...action.payload];
    const currentPage =
      action.payload.length > 0 ? state.currentPage + 1 : state.currentPage;
    return { ...state, documents, currentPage };
  }),
  on(documentActions.CleanSelectedDocumentState, (state, _) => ({
    ...state,
    selectedDocument: null,
    selectAttachment: null,
    attachments: [],
  })),
  on(documentActions.UpdateSignRequestsSuccess, (state, action) => {
    const signRequest = action.payload.sign_request;
    const userSigner = updateSignRequest(
      state.selectedDocument.user_signer as any,
      signRequest
    );
    const signRequests = updateSignRequest(
      state.selectedDocument.sign_requests as any,
      signRequest
    );
    const observers = updateSignRequest(
      state.selectedDocument.observers as any,
      signRequest
    );
    return {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        sign_requests: signRequests,
        user_signer: userSigner,
        observers,
      },
    };
  }),
  on(
    documentActions.GetDocumentSuccess || documentActions.UpdateLocalDocument,
    (state, action) => {
      const newDocument = refreshCurrentDocument(
        state.selectedDocument,
        action.payload as IDocument
      );
      return {
        ...state,
        selectedDocument: newDocument,
        selectAttachment: newDocument?.attachments?.filter((a) => a.primary)[0],
        attachments: newDocument.attachments,
      };
    }
  ),
  on(documentActions.GetDocumentError, (state, action) => ({
    ...state,
    getDocumentError: action.payload,
  })),
  on(documentActions.DeleteDocumentError, (state, action) => ({
    ...state,
    deleteDocumentError: action.payload,
  })),
  on(documentActions.CleanDocumentFormStates, (state, _) => ({
    ...state,
    lastDocument: null,
    uploadErrors: { success: false, error: null },
  })),
  on(documentActions.CreateDocumentSuccess, (state, action) => ({
    ...state,
    lastDocument: action.payload.document,
    uploadErrors: {
      ...state.uploadErrors,
      success: action.payload.success,
    },
  })),
  on(documentActions.CreateDocumentError, (state, action) => ({
    ...state,
    uploadErrors: action.payload,
  })),
  on(documentActions.CleanDocumentsState, (state, _) => ({
    ...state,
    documents: [],
    currentPage: 1,
  })),
  on(documentActions.UpdateFilterState, (state, action) => ({
    ...state,
    activeFilter: action.payload,
  })),
  on(documentActions.GetDocumentAttachmentSuccess, (state, action) => {
    if (!state.selectedDocument?.attachments) {
      return state;
    }

    const attachments = state.selectedDocument?.attachments?.map(
      (attachment: IAttachment) => {
        return attachment?.id === action.payload?.id
          ? action.payload
          : attachment;
      }
    );

    return {
      ...state,
      selectAttachment: action.payload,
      attachments,
      selectedDocument: { ...state.selectedDocument, attachments },
      getAttachmentError: null,
    };
  }),
  on(documentActions.ChangeAttachmentSelect, (state, action) => ({
    ...state,
    selectAttachment: action.payload,
  })),
  on(documentActions.GetDocumentAttachmentError, (state, action) => ({
    ...state,
    getAttachmentError: action.payload,
  })),
  on(documentActions.ChangeSort, (state, action) => ({
    ...state,
    sortColumn: action.payload,
    documents: [],
    currentPage: 1,
  })),
  on(documentActions.ChangeCurrentSort, (state, action) => ({
    ...state,
    currentSort: action.payload,
    documents: [],
    currentPage: 1,
  })),
  on(
    positionSignature.signaturePositionActions.UpdateSignaturePosition,
    (state, action) => ({ ...state, signerCoordinates: action.payload })
  ),
  on(
    positionSignature.signaturePositionActions.UpdateSignaturePositionSuccess,
    (state, action) => {
      const newPosition: ISignaturePosition = action.payload[0];
      let newPositionSignature = {};
      state?.signerCoordinates.sign_request_id.forEach((id) => {
        const signerIdChange = id;
        state.selectedDocument.sign_requests.find((signer) => {
          if (signer.id === signerIdChange) {
            const signOriginal = state.selectedDocument.sign_requests.slice();
            const array = updatePosition(
              signOriginal,
              signerIdChange,
              newPosition
            );
            newPositionSignature = {
              ...state.selectedDocument,
              sign_requests: array,
            };
          }
        });
        state.selectedDocument.legal_representative.map(
          (legal: ILegalRepresentative) =>
            legal.legal_representatives.find((legalPerson) => {
              if (legalPerson.id === signerIdChange) {
                const person =
                  state.selectedDocument.legal_representative.slice();
                const array = updatePositionLegalRepresentative(
                  person,
                  signerIdChange,
                  newPosition
                );
                newPositionSignature = {
                  ...state.selectedDocument,
                  legal_representative: array,
                };
              }
            })
        );
      });
      return { ...state, selectedDocument: newPositionSignature };
    }
  ),
  on(documentActions.GetFlowsByProcessTypeSuccess, (state, action) => {
    return { ...state, flows: action.payload };
  }),
  on(documentActions.GetNavbarDocumentSuccess, (state, action) => {
    return { ...state, navbarDocument: action.payload };
  }),

  on(documentActions.SetOnlyOfficeKey, (state, action) => {
    return Object.assign({}, state, { onlyoffice_key: action.payload });
  }),
  on(documentActions.DocumentsHidden, (state, action) => {
    return { ...state, hidden: action.payload };
  }),
  on(documentActions.DocumentsHiddenSuccess, (state, action) => {
    const documents = action?.payload;
    const currentPage =
      action?.payload?.length > 0 ? state.currentPage + 1 : state.currentPage;
    return { ...state, documents, currentPage };
  }),
  on(documentActions.DocumentsHiddenTypeSuccess, (state, action) => {
    return { ...state, currentPage: 1 };
  }),
  on(documentActions.DocumentCurrentPageInit, (state, action) => {
    return { ...state, currentPage: 1 };
  }),
  on(documentActions.DocumentMarkAsSuccess, (state, action) => {
    return {
      ...state,
      selectedDocument: refreshCurrentDocument(
        state.selectedDocument,
        action.payload as IDocument
      ),
    };
  }),
  on(documentActions.GetCancellationReasonsSuccess, (state, action) => {
    return {
      ...state,
      commentRejected: action.payload,
    };
  }),
  on(documentActions.SaveDateGenerateSuccess, (state, action) => {
    return {
      ...state,
      currentPage: 1,
    };
  }),
  on(checkAttachmentSuccess, (state, action) => {
    return {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        attachments: updateAttachments(
          state?.selectedDocument?.attachments,
          action.payload
        ),
      },
    };
  }),
  on(documentActions.SetDocumentAttachmentBase64Data, (state, action) => {
    const attachments: IAttachment[] = [...state.attachments].map(
      (attachment) => {
        if (attachment.id === action.payload.attachmentId) {
          return { ...attachment, text: action.payload.data };
        } else {
          return attachment;
        }
      }
    );

    const selectedAttachment = {
      ...state.selectAttachment,
      text: action.payload.data,
    };
    return {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        attachments: [...attachments],
      },
      attachments: [...attachments],
      selectAttachment: selectedAttachment,
    };
  }),
  on(documentActions.SetLastUploadedAttachment, (state, action) => {
    return {
      ...state,
      lastUploadedAttachment: action.payload,
    };
  }),
  on(documentActions.ClearLastUploadedAttachment, (state, action) => ({
    ...state,
    lastUploadedAttachment: null,
  })),

  on(documentActions.GetPendingMessagesSuccess, (state, action) => ({
    ...state,
    pending_seend: action.payload,
  })),
  on(documentActions.ClearDocumentAttachmentsBase64Data, (state, action) => {
    const attachments: IAttachment[] = [...state.attachments].map(
      (attachment) => ({ ...attachment, text: null })
    );
    const selectedDocument = {
      ...state.selectedDocument,
      attachments: [...attachments],
    };
    return {
      ...state,
      selectedDocument,
      attachments,
    };
  })
);

export function reducer(state: IDocumentState, action: Action) {
  return documentReducers(state, action);
}
