import { BehaviorSubject, combineLatest } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { EAttachmentKind } from '@literax/enums/attachment-kind.enum';
import { EDocTransactionType } from '@literax/enums/document-transaction-type.enum';
import { EDocumentStatus } from '@literax/enums/document-status.enum';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISignRequest } from '@literax/store/document/document.state';
import { ISignerCoordinatesUpdater } from '@literax/models/http/api/client/pdf.model';
import { IUser } from '@literax/models/user.model';
import { MsalService } from '@azure/msal-angular';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SetDocumentAttachmentBase64Data } from '@literax/store/document/document.actions';
import { SetFreeDocumentAttachmentBase64Data } from '@literax/store/free-document/free-document.actions';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { filter } from 'rxjs/operators';
import hljs from 'highlight.js';
import { selectCurrentUser } from '@literax/store/auth/auth.selectors';
import { selectOnlyOfficeCredentials } from '@literax/store/free-document/free-document.selectors';
import { untilDestroyed } from 'ngx-take-until-destroy';

const TIMEOUT = 15000;
const TIMEOUTPDF = 900;
@Component({
  selector: 'literax-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('xml', { static: false }) xmlCodeElement: ElementRef<HTMLElement>;
  @Input() document: IDocument;
  @Input() selectedAttachment: number;
  @Input() attachmentSelect: IAttachment;
  @Output()
  updatePositionEmitter = new EventEmitter<ISignerCoordinatesUpdater>();
  @Output() renderCompletePdf = new EventEmitter<boolean>();
  @Output() onlyofficeVisible = new EventEmitter<boolean>(false);
  @Input() signRequest: ISignRequest[] = [];
  visualRepresentation: SafeResourceUrl;
  showVisualRepresentation: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  isXMLAttachment = false;
  isXMLTemplate = false;
  documentData: string | ArrayBuffer;
  attachmentId = 0;
  fetchDocumentInProcess: boolean = true;
  fetchDocumentsError = false;
  @ViewChild('onlyOffice', { static: false }) containerOnlyOffice;
  onlyOffice: HTMLElement;
  @Input() attachment: IAttachment;
  @Input() user: IUser;
  @Input() kind: string;
  @Input() watchingIn: 'user' | 'guest';
  @Input() lang: string = 'es';
  @Input() useAuzureAuth: boolean = true;
  docUrl: string;
  @Output() reloadCompleted: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  onlyOfficeTimeOut: ReturnType<typeof setTimeout>;
  user$ = this.store.pipe(untilDestroyed(this), select(selectCurrentUser));
  @Input() reload: boolean;
  @Input() typeDocVisible: string = 'pdf';
  @Input() action: string = 'view';

  @Input() workarea: 'free' | 'account' = 'account';

  xmlFiles: string[] = [
    'xml',
    'promissory_note',
    'endorsement',
    'promissory_note_extinction',
    'endorsement_revocation',
    'receipt',
    'promissory_note_presentation_for_payment',
  ];
  inProcessStatus: string[] = ['approval', 'review', 'in_process'];
  documentInProcess: boolean = false;

  displayPDF: boolean = true;
  displayOnlyOffice: boolean = false;
  haveSignatureQuote: ISignRequest = null;
  displayQuote: boolean = false;
  isChangePosition: boolean = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private msalService: MsalService,
    private b2cAuthService: B2CAuthService,
    private http: HttpClient,
    private store: Store<IAppState>,
    private translate: TranslateService
  ) {}
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.lang = JSON.parse(localStorage.getItem('config')).lang;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.document?.currentValue !== null &&
      changes?.attachment?.currentValue !== null
    ) {
      this.fetchDocumentInProcess = true;
      this.documentInProcess = this.inProcessStatus.includes(
        this.document.status.key
      );

      this.displayQuote =
        this.document.status.key === EDocumentStatus.DATA_COLLECTION;

      if (this.onlyOffice) {
        this.onlyOffice.remove();
      }
      if (
        (this.document.transaction_type === EDocTransactionType.REVIEW &&
          this.attachment.kind === EAttachmentKind.DOCX) ||
        (this.attachment.kind === EAttachmentKind.DOCX &&
          this.attachment.file_path === '' &&
          this.document.status.key === EDocumentStatus.DRAFT) ||
        (this.action === 'save' &&
          this.attachment.kind === EAttachmentKind.DOCX) ||
        this.validationDocumentWithTemplate()
      ) {
        this.onlyofficeVisible.emit(false);
        this.onlyOfficeTimeOut = setTimeout(() => {
          this.detectKindAndMoutViewer(this.document, this.attachment);
        }, TIMEOUT);
      } else {
        this.onlyofficeVisible.emit(true);
        this.detectKindAndMoutViewer(this.document, this.attachment);
      }

      if (
        this.document.user_signer.some(
          (documentUserSigner) =>
            documentUserSigner?.email === this.user?.email &&
            documentUserSigner?.sign_request_signature_quotes?.length > 0
        )
      ) {
        this.haveSignatureQuote = this.document.user_signer.find(
          (documentUserSigner) => documentUserSigner.email === this.user.email
        );
      }
    }

    if (changes.reload && changes.reload.currentValue) {
      this.onlyofficeVisible.next(false);
      this.fetchDocumentInProcess = true;
      if (this.onlyOffice) {
        this.onlyOffice.remove();
      }
      this.onlyOfficeTimeOut = setTimeout(() => {
        if (this.action === 'save') {
          this.store.dispatch(
            SetDocumentAttachmentBase64Data({
              payload: {
                attachmentId: this.attachment.id,
                data: '',
              },
            })
          );
        }
        this.detectKindAndMoutViewer(this.document, this.attachment);
        this.reloadCompleted.emit(true);
      }, TIMEOUT);
    }
  }

  updatePositionCard(event) {
    this.isChangePosition = true;
  }

  detectKindAndMoutViewer(document: IDocument, attachment: IAttachment): void {
    const document_status = document?.status.key;
    if (
      (document_status === EDocumentStatus.DRAFT &&
        this.action === 'edit' &&
        attachment.kind === EAttachmentKind.DOCX) ||
      (document.transaction_type === EDocTransactionType.REVIEW &&
        attachment.kind === EAttachmentKind.DOCX) ||
      (document.transaction_type === EDocTransactionType.REQUEST &&
        [EDocumentStatus.IN_PROCESS, EDocumentStatus.GENERATED].some(
          (status) => status === document.status.key
        ) &&
        attachment.kind === EAttachmentKind.DOCX)
    ) {
      this.renderViewer(attachment, true);
    } else {
      this.renderViewer(attachment);
    }
  }

  renderViewer(attachment: IAttachment, canEditIfDocx: boolean = false): void {
    this.isXMLAttachment = this.xmlFiles.indexOf(attachment?.kind) >= 0;
    if (canEditIfDocx) {
      this.displayOnlyOffice = true;
      this.displayPDF = false;
      this.changeDetector.detectChanges();
      this.fetchDocumentInProcess = false;
      if (this.useAuzureAuth) {
        this.createOnlyOffice(true);
      } else {
        this.createOnlyOffice();
      }
    } else {
      this.displayOnlyOffice = false;
      this.displayPDF = true;
      this.onlyofficeVisible.emit(false);
      if (!attachment?.text) {
        this.fetchDocumentInProcess = true;
        this.http
          .get(attachment?.file_path, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob',
          })
          .subscribe((response) => {
            if (response.type === HttpEventType.Response) {
              const file_reader = new FileReader();
              file_reader.onloadend = (ev) => {
                if (ev.loaded && typeof file_reader.result === 'string') {
                  if (this.workarea === 'account') {
                    this.store.dispatch(
                      SetDocumentAttachmentBase64Data({
                        payload: {
                          attachmentId: attachment.id,
                          data: file_reader.result,
                        },
                      })
                    );
                  } else {
                    this.store.dispatch(
                      SetFreeDocumentAttachmentBase64Data({
                        payload: {
                          attachmentId: attachment.id,
                          data: file_reader.result,
                        },
                      })
                    );
                  }
                  this.documentData = file_reader.result.split(',').pop();
                  this.visualRepresentation = this.documentData;
                  this.fetchDocumentInProcess = false;
                }
              };
              file_reader.readAsDataURL(response.body);
            }
          });
      } else {
        if (this.isXMLAttachment) {
          this.documentData = attachment?.xml_pdf.split(',').pop();
          this.fetchDocumentInProcess = false;
        } else {
          this.documentData = attachment?.text.split(',').pop();
          this.fetchDocumentInProcess = false;
        }
        this.visualRepresentation = this.documentData;
      }
    }
  }

  createOnlyOffice(withAuth?: boolean) {
    this.onlyOffice = document.createElement('if-onlyoffice');
    const url =
      this.attachment?.file_docx_url != null
        ? this.attachment?.file_docx_url
        : '';

    if (
      (this.document.transaction_type === EDocTransactionType.REVIEW &&
        this.document?.user_signer[0]?.status.key === 'reviewed' &&
        this.document?.user_signer[0]?.is_author === false) ||
      this.document?.status?.key === EDocumentStatus.GENERATED
    ) {
      this.onlyOffice.setAttribute('mode', 'view');
    } else if (
      (this.document.transaction_type === EDocTransactionType.REVIEW &&
        this.document?.status?.key === EDocumentStatus.REVIEW) ||
      (this.document?.status.key === EDocumentStatus.REVIEWED &&
        this.document?.user_signer.length === 0)
    ) {
      this.onlyOffice.setAttribute('mode', 'collaborative');
    } else {
      this.onlyOffice.setAttribute('mode', 'edit');
    }

    this.onlyOffice.setAttribute(
      'owner',
      this.user && this.user.id === this.document?.user.id ? 'true' : 'false'
    );
    this.onlyOffice.setAttribute(
      'username',
      encodeURIComponent(
        this.watchingIn === 'guest'
          ? this.document?.user_signer[0].name
          : this.document?.user?.name
      )
    );
    this.onlyOffice.setAttribute(
      'uuid',
      encodeURIComponent(JSON.stringify(this.attachment?.id))
    );
    this.onlyOffice.setAttribute('url', encodeURIComponent(url));
    this.onlyOffice.setAttribute(
      'key',
      encodeURIComponent(
        this.attachment?.onlyoffice_key
          ? this.attachment?.onlyoffice_key
          : new Date(this.attachment?.updated_at).valueOf()
      )
    );
    this.onlyOffice.setAttribute(
      'subscription-ids',
      encodeURIComponent(this.attachment?.id_subscriber)
    );

    this.onlyOffice.setAttribute('lang', this.lang);
    this.onlyOffice.setAttribute('title', this.attachment?.name);
    if (withAuth) {
      const tokenSilentRequest = {
        scopes: environment.b2cConfig.scopes,
        account: this.b2cAuthService.getUserInfo(),
      };
      const token$ = this.msalService.acquireTokenSilent(tokenSilentRequest);
      token$
        .pipe(filter((tokenResponse) => tokenResponse !== null))
        .subscribe((b2cToken) => {
          this.onlyOffice.setAttribute(
            'token',
            encodeURIComponent(b2cToken.accessToken)
          );
          this.containerOnlyOffice.nativeElement.innerHTML = '';
          this.containerOnlyOffice.nativeElement.appendChild(this.onlyOffice);
          this.fetchDocumentInProcess = false;
          this.onlyofficeVisible.next(true);
        });
    } else if (this.watchingIn === 'guest') {
      combineLatest(this.store.select(selectOnlyOfficeCredentials)).subscribe(
        ([token]) => {
          this.onlyOffice.setAttribute('token', encodeURIComponent(token));
          this.containerOnlyOffice.nativeElement.innerHTML = '';
          this.containerOnlyOffice.nativeElement.appendChild(this.onlyOffice);
          this.fetchDocumentInProcess = false;
          this.onlyofficeVisible.next(true);
        }
      );
    } else {
      this.containerOnlyOffice.nativeElement.innerHTML = '';
      this.containerOnlyOffice.nativeElement.appendChild(this.onlyOffice);
      this.fetchDocumentInProcess = false;
      this.onlyofficeVisible.next(true);
    }
  }

  renderComplete(valor: boolean) {
    this.fetchDocumentInProcess = !valor;
    this.renderCompletePdf.emit(true);
  }

  searchAttachment(id: number) {
    if (id === 0) {
      this.attachment = this.document.attachments.find(
        (element) => element.primary === true
      );
      this.selectedAttachment = this.attachment?.id;
    } else {
      this.attachment = this.document.attachments.find(
        (element) => element.id === id
      );
    }
  }

  displayAttachment() {
    if (this.attachment && this.attachment?.kind === EAttachmentKind.DOCX) {
      this.changeDetector.detectChanges();
      if (this.useAuzureAuth) {
        this.createOnlyOffice(true);
      } else {
        this.createOnlyOffice();
      }
    }

    const is_promissory_note: boolean =
      this.attachment.kind === EAttachmentKind.PROMISSORY_NOTE;

    if (this.attachment?.text) {
      if (is_promissory_note) {
        this.documentData = this.attachment.xml_pdf;
      } else {
        this.documentData = this.attachment.text.split(',')[1];
      }
    }
    if (this.attachment?.xml_pdf && this.isXMLAttachment) {
      this.visualRepresentation = this.attachment.xml_pdf.split(',').pop();
      this.isXMLTemplate = this.attachment.xml_pdf !== '';
    }
  }

  toggleXML(): void {
    this.displayPDF = !this.displayPDF;
    this.changeDetector.detectChanges();
    if (this.xmlCodeElement) {
      hljs.highlightBlock(this.xmlCodeElement.nativeElement);
    }
  }

  validationDocumentWithTemplate(): boolean {
    return (
      this.document.status?.key === EDocumentStatus.DRAFT &&
      this.document.transaction_type === EDocTransactionType.FORM &&
      this.attachment.kind === EAttachmentKind.DOCX &&
      !!this.attachment.file_docx_url &&
      !!this.attachment.file_path
    );
  }
}
