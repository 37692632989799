import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  findRegexIntoCertAttributes,
  openCertificate,
  sign,
} from '@literax/utils/gdx-crypto';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomFormValidator } from '../form-lib/custom-form.validator';
import { AppUtils } from '@literax/utils/app.utils';
import { IValidateRFC } from '@literax/components/configurations/profiles/models/profiles';
import * as profilesActions from '@literax/components/configurations/profiles/states/profiles.actions';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { Actions, ofType } from '@ngrx/effects';
import { getRFCByCurrentUser } from '@literax/store/auth/auth.selectors';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'literax-validate-rfc',
  templateUrl: './validate-rfc.component.html',
  styleUrls: ['./validate-rfc.component.scss'],
})
export class ValidateRfcComponent implements OnInit, OnDestroy {
  form: FormGroup;
  permittedCertificateFiles = ['.cer'];
  permittedKeyFiles = ['.key'];
  backErrors: IValidateRFC = null;

  firstRFCByClient$ = this.store.pipe(
    select(getRFCByCurrentUser),
    untilDestroyed(this)
  );

  constructor(
    public dialogRef: MatDialogRef<ValidateRfcComponent>,
    private formBuilder: FormBuilder,
    private store: Store<IAppState>,
    private actions$: Actions,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.generateForm();
    this.form.get('certificate').valueChanges.subscribe((certificate) => {
      if (certificate?.base64) {
        this.getRFCFromCertificate();
      }
    });
    this.firstRFCByClient$.pipe(take(1)).subscribe((rfc: string) => {
      this.form.get('rfc').setValue(rfc);
    });
  }

  ngOnDestroy(): void {}

  generateForm() {
    this.form = this.formBuilder.group({
      rfc: this.formBuilder.control(null, [
        CustomFormValidator.naturalPersonRFC,
      ]),
      certificate: this.formBuilder.control(null, [
        CustomFormValidator.requiredFileType(...this.permittedCertificateFiles),
        CustomFormValidator.maxFileSize(70),
        Validators.required,
      ]),
      key: this.formBuilder.control(null, [
        CustomFormValidator.requiredFileType(...this.permittedKeyFiles),
        CustomFormValidator.maxFileSize(70),
        Validators.required,
      ]),
      password: this.formBuilder.control(null, [Validators.required]),
    });
  }

  getRFCFromCertificate() {
    const certPEM = openCertificate(this.form.get('certificate').value.base64);
    const rfc = findRegexIntoCertAttributes(certPEM, AppUtils.regexRFC);
    this.form.get('rfc').setValue(rfc);
  }

  validateRFC() {
    const rfc = this.form.get('rfc').value;
    const privateKey = this.form.get('key').value.base64;
    const password = this.form.get('password').value;
    let signature = '';
    try {
      signature = sign(rfc, privateKey, password, true);
      this.onSubmit(signature);
    } catch (e) {
      this.translate
        .get('PROFILE.FORM.ERRORS.REQUIRED_PASSWORD')
        .subscribe((passwordError) => {
          this.backErrors = {
            pass: [passwordError],
          };
        });
    }
  }

  onSubmit(signature: string) {
    this.backErrors = null;
    let dataToValidateRFC: IValidateRFC = {
      cert: this.form.get('certificate').value.base64,
      rfc: this.form.get('rfc').value,
      signature: signature,
    };
    this.store.dispatch(
      profilesActions.sendToValidateRFC({ payload: dataToValidateRFC })
    );
    this.actions$
      .pipe(ofType(profilesActions.sendToValidateRFCSuccess))
      .subscribe(() => {
        this.dialogRef.close();
      });
    this.actions$
      .pipe(ofType(profilesActions.sendToValidateRFCError))
      .subscribe((data) => {
        if (data.payload) {
          this.backErrors = data.payload;
        }
      });
  }
}
