<div class="row py-2 m-0">
  <div class="col-12">
    <div class="col-auto float-left">
      <img
        src="https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white.svg"
        class="img-responsive company-logo"
        alt="Company logo"
      />
    </div>
    <div
      class="col-auto float-right"
      [lang]="lang$ | async"
      (SetLang)="setLang($event)"
    >
      <div id="divButtonTraslate">
        <button
          gdx-button
          matRipple
          [matMenuTriggerFor]="translateMenu"
          class="button--secondary"
        >
          {{ language | uppercase }}
        </button>
      </div>
      <mat-menu #translateMenu="matMenu" xPosition="before">
        <button *ngIf="lang != 'en'" mat-menu-item (click)="setLang('en')">
          ENGLISH
        </button>
        <button *ngIf="lang != 'es'" mat-menu-item (click)="setLang('es')">
          ESPAÑOL
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<div *ngIf="isView" class="banner banner-big info">
  <p>
    <b>{{ 'BANNER-MESSAGES.MESSAGE_NOTICE' | translate }}</b>
    {{ 'BANNER-MESSAGES.MESSAGE_WARNING' | translate }}
  </p>
</div>
