import * as documentActions from '../../../store/document/document.actions';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  selectUserEmail,
  selectUserName,
} from '@literax/store/auth/auth.selectors';

import { BaseService } from '@literax/services/base-config/base.service';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISignerDocument } from '@literax/models/http/api/signature.model';
import { Logout } from '@literax/store/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { selectCurrentLang } from '@literax/store/config/config.selectors';
import { selectDocument } from '@literax/store/document/document.selectors';
import { setLang } from '@literax/store/config/config.actions';
import { tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
@Component({
  selector: 'literax-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.scss'],
})
export class SignaturesComponent implements OnInit, OnDestroy {
  document$ = this.store.pipe(untilDestroyed(this), select(selectDocument));
  email$ = this.store.pipe(select(selectUserEmail));
  lang$ = this.store.pipe(
    select(selectCurrentLang),
    tap((lang: string) => this.updateCurrentLang(lang))
  );
  name$ = this.store.pipe(select(selectUserName));

  activeTab = 0;
  attachments: IAttachment[];
  logoPath =
    'https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white.svg';
  paramsId = 0;
  selectedAttachment: IAttachment;
  visible = [true, false];
  isDigitalSignature = false;
  twoFactorAuth = false;

  signers: ISignerDocument[] = [];

  constructor(
    private baseService: BaseService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAppState>,
    private translate: TranslateService
  ) {
    this.document$.subscribe((document: IDocument) => {
      if (document) {
        this.attachments = document.attachments;
        this.selectedAttachment = this.attachments[0];
        this.generateSigners();
        this.isDigitalSignature =
          document.signing_method === 'digital_signature';
        this.twoFactorAuth =
          document.signing_method === 'electronic_signature_plus';
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(documentActions.CleanSelectedDocumentState());
    this.route.params.subscribe((params) => {
      this.store.dispatch(documentActions.GetDocument({ payload: params.id }));
      this.paramsId = params.id;
    });
  }

  ngOnDestroy(): void {}

  onTabClicked(index: number, attachment: IAttachment) {
    this.activeTab = index;
    this.selectedAttachment = attachment;
    this.generateSigners();
  }

  navigateBack() {
    this.router.navigate([`/platform/documents/${this.paramsId}`]);
  }

  updateCurrentLang(lang: string) {
    if (lang) {
      this.translate.use(lang);
    }
  }

  setLang(e: { event: string; lang: string }) {
    this.store.dispatch(setLang({ payload: e.lang }));
  }

  logout() {
    this.store.dispatch(Logout());
  }

  profile() {
    this.router.navigate(['platform/profile'], { skipLocationChange: true });
  }

  generateSigners() {
    let signers: ISignerDocument[] = [];
    this.selectedAttachment.signatures.forEach((signature) => {
      let signer = signers.filter(
        (signer) =>
          (signer.rfc !== null &&
            signature.rfc !== null &&
            signer.rfc === signature.rfc) ||
          signer.email === signature.email
      );

      if (signer.length > 0) {
        signer[0].companies.push({
          company_name: signature.company_name,
          company_rfc: signature.company_rfc,
          role: signature.role,
        });
      } else {
        let newSigner: ISignerDocument = signature;
        newSigner = {
          ...newSigner,
          companies: [],
        };
        if (signature.company_name !== null) {
          newSigner['companies'].push({
            company_name: signature.company_name,
            company_rfc: signature.company_rfc,
            role: signature.role,
          });
        }
        signers.push(newSigner);
      }
    });
    this.signers = signers;
  }
}
