import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { BaseService } from '@literax/services/base-config/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private base: BaseService) {}
  getDashboard(): Observable<ServiceResponse> {
    return this.base.get(`/dashboard`);
  }

  showDashboard(process: string): Observable<ServiceResponse> {
    return this.base.get(`/dashboard/${process}`);
  }
}
