import {ICreateDocument} from '@literax/models/http/document/create-document.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PromissoryNotePayment} from '@literax/models/promissory-notes.model';
import {ServiceResponse} from '../../interfaces/service-response';
import {SignService} from './sign.service';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsSignService {
  perPage = 20;

  constructor(private base: SignService) {}

  /**
   * Gets all documents from the specified page
   * @param page The current page
   * @param sort The parameter to sort by, divided with a semicolon with the sorting
   * way (asc or desc). Ex: name:asc. Defaults to id:desc
   * @returns An observable of ServiceResponse
   */

  getDocumentDetail(token: string): Observable<ServiceResponse> {
    return this.base.get(
      `/signatories/${environment.apiVersion}/documents`,
      token
    );
  }

  getDocumentUrlForDownload(token: string): Observable<ServiceResponse> {
    return this.base.get(
      `/signatories/${environment.apiVersion}/documents?download=true`,
      token
    );
  }

  createDocument(document: ICreateDocument): Observable<ServiceResponse> {
    return this.base.post(`/signatories/${environment.apiVersion}/documents`, {
      document
    });
  }

  getDocumentAttachment(
    documentId: number,
    attachmenId: number,
    token: string
  ): Observable<ServiceResponse> {
    return this.base.get(
      `/signatories/${environment.apiVersion}/documents/${documentId}/attachments/${attachmenId}?signed_preview=true`,
      token
    );
  }

  deleteDocument(token: string): Observable<ServiceResponse> {
    return this.base.delete(
      `/signatories/${environment.apiVersion}/documents`,
      token
    );
  }

  getBeneficiary(token: string): Observable<ServiceResponse> {
    return this.base.get(
      `/signatories/${environment.apiVersion}/promissory_note_payments/new`,
      token
    );
  }

  getCurrencies(token: string): Observable<ServiceResponse> {
    return this.base.get(
      `/signatories/${environment.apiVersion}/currencies`,
      token
    );
  }
  savePromissoryNotePayment(
    promissoryNotePayment: PromissoryNotePayment,
    token: string
  ) {
    const path = `/signatories/${environment.apiVersion}/promissory_note_payments`;
    return this.base.post(path, promissoryNotePayment, token);
  }
  changeStatusTemplate(state: string, token: string) {
    const path = `/signatories/${environment.apiVersion}/documents/transaction/${state}`;
    return this.base.put(path, {}, token);
  }

  changeStatusReviewer(state: string, token: string) {
    const path = `/signatories/${environment.apiVersion}/sign_requests/transaction/${state}`;
    return this.base.put(path, {}, token);
  }

  documentCancelProcess(
    documentId: number,
    comments: string,
    token: string
  ): Observable<ServiceResponse> {
    const PATH = `/signatories/${environment.apiVersion}/documents/${documentId}/cancellation_reasons`;
    const BODY: Object = {
      cancellation_reason: {
        description: comments
      }
    };
    return this.base.post(PATH, BODY, token);
  }

  checkAttachment(
    documentId: number,
    attachmentId: number,
    token: string
  ): Observable<ServiceResponse> {
    return this.base.post(
      `/signatories/${environment.apiVersion}/documents/${documentId}/attachments/${attachmentId}/check`,
      {},
      token
    );
  }

  getCredentials(token: string): Observable<ServiceResponse> {
    return this.base.get(
      `/signatories/${environment.apiVersion}/documents/credentials`,
      token
    );
  }

  getCommentRejected(documentId: number, token: string): Observable<ServiceResponse> {
    return this.base.get(`/signatories/${environment.apiVersion}/documents/${documentId}/cancellation_reasons`, token);
  }
}
