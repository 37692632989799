import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { IAppState } from '@literax/store';
import {
  IDocument,
  ICancelatioReason,
} from '@literax/models/http/document/document.model';
import { ISignRequest } from '@literax/store/document/document.state';
import { IUser } from '@literax/components/configurations/users/models/users.interface';
import { MatDialog } from '@angular/material/dialog';
import { ShowCommentRejectedComponent } from '../../show-comment-rejected/show-comment-rejected.component';
import { selectCommentRejected } from '@literax/store/document/document.selectors';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-approver',
  templateUrl: './approver.component.html',
  styleUrls: ['./approver.component.scss'],
})
export class ApproverComponent implements OnInit {
  @Input() approver: ISignRequest;
  @Input() status: string;
  @Input() document: IDocument;
  @Input() user: IUser;
  @Input() userDocument: IUser;
  @Input() actionButtonEnable: boolean;
  @Output() actionButton = new EventEmitter();
  @Output() historyButton: EventEmitter<any> = new EventEmitter<any>();

  comment$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCommentRejected)
  );
  comment: ICancelatioReason[];

  constructor(private dialog: MatDialog, private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.comment$
      .pipe(untilDestroyed(this))
      .subscribe((data) => (this.comment = data));
  }

  ngOnDestroy(): void {}

  openCommentDetails(): void {
    if (
      this.approver?.status?.key === 'rejected' ||
      this.approver?.delegate?.status?.key === 'rejected'
    ) {
      const dialogRef = this.dialog.open(ShowCommentRejectedComponent, {
        width: '656px',
        height: 'auto',
        disableClose: true,
        data: {
          comment: this.comment,
          user: this.approver?.delegate
            ? this.approver?.delegate?.name
            : this.approver?.name,
        },
      });
    }
  }
}
