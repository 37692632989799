<gdx-form-field [class.icon]="icon">
  <input
    name="{{ name }}"
    #input
    gdxInput
    class="inline"
    [ngClass]="overlay && hasValue ? 'literax-input-overlay' : ''"
    (input)="transform($event)"
    [type]="type"
    [placeholder]="placeholder"
    (focusout)="onFocusOut($event)"
    [formControl]="control"
    (keyup.enter)="onEnterUp($event)"
    (click)="onClicked()"
    [matAutocomplete]="auto"
    [matAutocompleteDisabled]="!suggestions"
    [disabled]="disabled"
    [value]="value"
    [class.hasIcon]="icon"
  />
  <i [ngClass]="icon"></i>
</gdx-form-field>

<mat-autocomplete
  name="autocomplete"
  class="suggestions"
  autoActiveFirstOption
  #auto="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="onOptionSelected($event)"
>
  <mat-option
    *ngFor="let option of filteredOptions | async"
    [value]="option[optionParameter]"
  >
    {{ option[optionParameter] }}
  </mat-option>
</mat-autocomplete>
