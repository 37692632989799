import { createAction, props } from '@ngrx/store';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { CatalogType, ICatalog } from '@literax/models/catalog.model';

export enum ECatalogActions {
  GetCatalog = '[Catalog] GetCatalog',
  GetCatalogSuccess = '[Catalog] GetCatalogSuccess',
  GetCatalogError = '[Catalog] GetCatalogError'
}

export const getCatalog = createAction(
  ECatalogActions.GetCatalog,
  props<{ payload: CatalogType }>()
);

export const getCatalogSuccess = createAction(
  ECatalogActions.GetCatalogSuccess,
  props<{ payload: ICatalog }>()
);

export const getCatalogError = createAction(
  ECatalogActions.GetCatalogError,
  props<{ payload: IAPIResponseError }>()
);
