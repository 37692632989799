import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ITemplatesFields } from '@literax/models/templates-fields.model';

export interface ITemplatesFieldsState {
  fields: ITemplatesFields[];
  error: IAPIResponseError;
  currentPage: number;
  sortColumn: string;
}

export const initialTemplatesFieldsState: ITemplatesFieldsState = {
  fields: [],
  error: null,
  currentPage: 1,
  sortColumn: 'id:desc'
};
