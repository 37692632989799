import { User } from '@literax/models/http/onboarding/user.model';
import { Department, Field } from '@literax/models/templates-fields.model';
import { IStatus } from '@literax/store/status/status.state';
export interface Template {
  template: ITemplates;
}

export enum statusTemplate {
  published = 'published',
  collection = 'data_collection',
  draft = 'draft',
  workInProgress = 'to_work_in_process',
  unpublished = 'unpublished',
  canEdit = 'work_in_progress',
  inProcess = 'in_process'
}
export interface ITemplateDepartmentAttributes {
  department_id: number;
}
export interface ITemplates {
  id?: number;
  name?: string;
  description?: string;
  form_builder_uid?: string;
  file_url?: string;
  file_pdf_url?: string;
  preview_url?: string;
  created_at?: string;
  id_subscriber?: string;
  updated_at?: string;
  user?: User;
  status?: IStatus;
  template_departments_attributes?: ITemplateDepartmentAttributes[];
  departments?: Department[];
  fields?: Field[];
  tags?: FieldsOnlyOffice[];
  template_fields?: TemplateField[];
  lock?: boolean;
  kind?: string;
  client_user?: User;
  profile_id?: number;
}
export interface FieldsOnlyOffice {
  id?: number;
  field_id: number;
  label: string;
  required: boolean;
  section: string;
  text: string;
  uuid: string;
}
export interface TemplateField {
  id: number;
  template_id: number;
  field_id: number;
  required: boolean;
  onlyoffice_uuid: null;
}

export interface ITemplateResponse {
  template: ITemplates;
  success: boolean;
}

export interface ITemplateKinds {
  document?: string;
  ticket?: string;
  form?: string;
}

export interface ITemplateProfile {
  id: number;
  name: string;
  template_uid: string;
  user_name?: string;
}

export interface ITemplateProfileList {
  profiles: ITemplateProfile[];
}

export interface IProfile {
  id?: number;
  name: string;
  description: string;
  departments?: number[];
  profile_departments_attributes?: any;
  template_uid?: string;
}

export const initialTemplateProfiles: ITemplateProfileList = {
  profiles: []
};
export const initialITemplateKinds: ITemplateKinds = {
  document: '',
  ticket: '',
  form: ''
};

export class ITemplate implements Template {
  template: ITemplates;
  constructor(template: { [k: string]: any }) {
    if (!template.hasOwnProperty('name')) return;
    if (
      !template.hasOwnProperty('department') ||
      template.department.lenght == 0
    )
      return;
    this.template.template_departments_attributes = template.department.map(
      (tda) => {
        department_id: tda;
      }
    );
  }
}

export enum ETemplateTabs {
  document = 'document',
  ticket = 'ticket'
}

export enum ETransactionType {
  document = 'signature',
  ticket = 'request'
}
