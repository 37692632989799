<script src="//mozilla.github.io/pdf.js/build/pdf.js"></script>

<div class="tool-bar">
  <div class="left-container"></div>
  <div class="center-container">
    <div class="zoom">
      <div class="minus" (click)="downZoom()">
        <i class="fal fa-minus"></i>
      </div>
      <div class="current-zoom">{{ zooms[scale] * 100 }}%</div>
      <div class="plus" (click)="upZoom()">
        <i class="fal fa-plus"></i>
      </div>
    </div>
  </div>
  <div class="right-container"></div>
</div>

<div #pdfContainer class="pdfContainer"></div>
<div class="signatures" #signatures>
  <div
    class="signature signer-quote"
    [attr.quote-email]="signatureQuote?.email"
    [hidden]="scale !== 2.0"
    *ngIf="displayQuote"
  >
    <div class="draw"></div>
    <div class="data">
      <div class="name">{{ signatureQuote?.name }}</div>
      <div class="email">{{ signatureQuote?.email }}</div>
      <div class="quote">
        <div
        [innerHtml]="
          signatureQuote?.sign_request_signature_quotes[0]
            ?.signature_quote_text
        "
      ></div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showSigners">
    <div
      *ngFor="let signer of signers | keyvalue"
      [hidden]="scale !== 2.0"
      class="signature signer-info shadow-sm"
      [attr.data-email]="signer.key"
      [style.top]="signer.value?.coordinates?.y"
      [style.left]="signer.value?.coordinates?.x"
      [style.backgroundColor]="signer.value?.color"
      draggable="true"
      (onDragStart)="dragStart($event)"
      (onDragEnd)="dragEnd($event)"
    >
      <div class="draw">
        <span
          class="fal fa-thumbtack circle"
          [style.backgroundColor]="signer.value?.color"
        ></span>
      </div>
      <div class="data" [style.backgroundColor]="signer.value?.color">
        <div class="name">{{ signer.value.name }}</div>
        <div class="email">{{ signer.key }}</div>
      </div>
    </div>
  </ng-container>
</div>
