import * as pdfjsLib from 'pdfjs-dist';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ICoordinates,
  ICoordinatesIndexed,
  IElectroniSignersIndexed,
  ISigner,
  ISignerCoordinatesUpdater,
} from '@literax/models/http/api/client/pdf.model';
import {
  ISignRequest,
  ISignaturePosition,
} from '@literax/store/document/document.state';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'literax-if-pdf',
  templateUrl: './if-pdf.component.html',
  styleUrls: ['./if-pdf.component.scss'],
})
export class IFPDFComponent implements OnInit, OnChanges {
  scrWidth: any;
  private _signRequests: ISignRequest[];
  signers: IElectroniSignersIndexed;

  @ViewChild('pdfContainer') pdfContainer: ElementRef<HTMLDivElement>;

  @ViewChild('signatures') signatures: ElementRef<HTMLDivElement>;

  @Output() renderComplete = new EventEmitter<boolean>();
  @Output() updatePositionEmitter =
    new EventEmitter<ISignerCoordinatesUpdater>();

  @Input() url: string;
  @Input() document: string;
  @Input() documentId: number;
  @Input() showSigners: boolean;

  @Input() signatureQuote: ISignRequest;
  @Input() displayQuote: boolean = false;

  @Input() set signRequests(signRequests: ISignRequest[]) {
    if (signRequests) {
      this._signRequests = signRequests;
      this.signers = signRequests.reduce(this.indexSigners, {});
    }
  }
  get signRequests(): ISignRequest[] {
    return this._signRequests;
  }
  deviceInfo: any;
  viewport: any;
  pdfDoc: any;
  currentPDFBytes = null;
  auxRender = 0;
  currentCard: HTMLDivElement;
  scale = 0;
  zooms = [0.5, 0.75, 1.0, 1.5, 2.0, 3.0];
  isDragElement: boolean;
  isLastPageRendered: boolean = false;
  currentVisiblePage: number = 0;

  @HostListener('window:rezise', ['$event'])
  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
  }

  //#region NGX LifeCycle
  constructor(
    private deviceService: DeviceDetectorService,
    private render2: Renderer2
  ) {
    this.getScreenSize();
  }

  ngOnChanges() {
    if (this.document && this.document !== null) {
      const bytes = this.document?.slice();
      const reloadPDF = this.currentPDFBytes !== atob(bytes);
      this.currentPDFBytes = atob(this.document);
      if (this.pdfContainer && this.pdfContainer.nativeElement && reloadPDF) {
        this.pdfContainer.nativeElement.scrollTop = 0;
      }

      this.setPdf(reloadPDF);
    }
  }

  ngOnInit(): void {
    this.pdfDoc = null;
    if (this.scrWidth >= 420 && this.scrWidth < 768) {
      this.scale = 1;
    }
    if (this.scrWidth >= 768) {
      this.scale = 2;
    }
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
  //#endregion NGX LifeCycle

  //#region Signer functions
  solveCompaniesForValues(currentDataInMap: ISigner, obj: ISignRequest) {
    if (currentDataInMap) {
      return [...currentDataInMap.companies, obj.company_name];
    } else {
      return obj.company_name ? [obj.company_name] : [];
    }
  }

  indexSigners = (
    map: IElectroniSignersIndexed,
    obj: ISignRequest
  ): IElectroniSignersIndexed => {
    const currentDataInMap = map[obj.email];
    const ids = currentDataInMap ? [...currentDataInMap.ids, obj.id] : [obj.id];
    const companies = this.solveCompaniesForValues(currentDataInMap, obj);
    const coordinates = (obj.signature_positions || []).reduce(
      this.signaturePositionsIndexer,
      {}
    );

    const { email, name, color } = obj;

    map[obj.email] = { ids, email, name, color, companies, coordinates };
    return map;
  };

  signaturePositionsIndexer = (
    map: ICoordinatesIndexed,
    currentObject: ISignaturePosition
  ) => {
    const { attachment_id, x, y, page } = currentObject;
    map[`attachment-${attachment_id}`] = { x, y, page };
    return map;
  };

  updateSignerPosition(email: string, coordinates: ICoordinates) {
    this.signers[email].coordinates[this.documentId] = coordinates;
    this.updatePositionEmitter.emit({
      sign_request_id: this.signers[email].ids,
      attachment_id: this.documentId,
      coordinates,
    });
  }
  //#endregion Signer functions

  setPdf(reloadPDF: boolean = true) {
    const loadingTask = pdfjsLib.getDocument({ data: this.currentPDFBytes });
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      './../../../assets/js/pdf.worker.js';

    loadingTask.promise.then((pdf: any) => {
      this.pdfDoc = pdf;
      this.createElement(reloadPDF);
    });
  }

  dragStart(event: any) {
    this.currentCard = event.target as HTMLDivElement;
    this.isDragElement = true;
  }

  dragEnd(_: any) {
    this.isDragElement = false;
  }

  dragEnterEvent(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.target.style.opacity = 0.8;
  }

  dragLeaveEvent(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.target.style.opacity = 1;
  }

  dragOverEvent(event: any) {
    event.preventDefault();
  }

  dropEvent(event: any) {
    event.preventDefault();
    event.target.style.opacity = 1;

    if (event.target.className === 'pdf-page-canvas') {
      const pageRef = event.target.parentElement as HTMLDivElement;
      const email = this.currentCard.getAttribute('data-email');
      const pageNumber = parseInt(pageRef.getAttribute('data-page'), 10);
      let eqX = [0, 0];
      let eqY = 0;
      let coordinates;
      if (this.deviceInfo.browser === 'Firefox') {
        eqX = [210, 200];
        eqY = 10;
        coordinates = {
          x: (event.layerX - eqX[0]) as number,
          y: (event.layerY - eqY) as number,
          page: pageNumber,
        };
      } 
      else {
        coordinates = {
          x: (event.offsetX - eqX[0]) as number,
          y: (event.offsetY - eqY) as number,
          page: pageNumber,
        };
      }
      
      this.updateSignerPosition(email, coordinates);
      coordinates = { ...coordinates, x: event.offsetX - eqX[1] };
      this.addSignatureCardToPage(pageRef, this.currentCard, coordinates);
    }
  }

  addSignatureCardToPage(
    pageRef: HTMLDivElement,
    card: HTMLDivElement,
    coordinates: ICoordinates,
    isQuoteCard: boolean = false
  ) {
    if (isQuoteCard) {
      const { clientHeight: pageHeigth, clientWidth: pageWidth } = pageRef;
      const { clientHeight: canvasHeigth, clientWidth: canvasWidth } =
        pageRef.querySelector('canvas.pdf-page-canvas') as HTMLCanvasElement;
      card.style.width = `${canvasWidth}px`;
    } else {
      const canvasHeight = pageRef.querySelector('canvas').height;
      const canvasWidth = pageRef.querySelector('canvas').width;
      let coorX = coordinates.x;
      coorX +=
        canvasHeight > canvasWidth
          ? this.reSize(280, 6, -15)
          : this.reSize(110, 10, 10);
      if (this.deviceInfo.browser === 'Firefox') {
        coorX = (canvasHeight > canvasWidth ? 183 : 180) + coordinates.x;
      } else if (this.deviceInfo.browser === 'Safari') {
        coorX =
          (canvasHeight > canvasWidth
            ? this.reSize(240, 6, -10)
            : this.reSize(110, 12, 0)) + coordinates.x;
      }
      card.style.top = `${coordinates.y - 15}px`;
      card.style.left = `${coorX}px`;
    }

    this.render2.appendChild(pageRef, card);
  }

  reSize(base: number, custom: number, custom1: number) {
    const controlValueForFullHD =
      window.innerWidth !== 1920
        ? (2000 - window.innerWidth) / custom + custom1
        : 0;
    return (window.innerWidth * base) / 1920 - controlValueForFullHD;
  }

  addListenersToDropContainer(domObject: HTMLDivElement) {
    domObject.addEventListener('dragover', this.dragOverEvent, false);
    domObject.addEventListener('dragenter', this.dragEnterEvent, false);
    domObject.addEventListener('dragleave', this.dragLeaveEvent, false);
    domObject.addEventListener(
      'drop',
      (event: DragEvent) => this.dropEvent(event),
      false
    );
  }

  getSignersForPage(pageNumber: number) {
    return Object.values(this.signers).filter((el) => {
      const coordinates = el.coordinates[`attachment-${this.documentId}`];
      return coordinates && coordinates.page === pageNumber;
    });
  }

  addSignerstToPage(pageContainer: HTMLDivElement, pageNumber: number) {
    const signers = this.getSignersForPage(pageNumber);
    signers.forEach((signer) => {
      const card: HTMLDivElement = this.signatures.nativeElement.querySelector(
        `[data-email='${signer.email}']`
      );
      if (card) {
        this.addSignatureCardToPage(
          pageContainer,
          card,
          signer.coordinates[`attachment-${this.documentId}`]
        );
      }

      if (this.displayQuote) {
        const quoteCard: HTMLDivElement =
          this.signatures.nativeElement.querySelector(
            `[quote-email='${this.signatureQuote?.email}']`
          );
        if (quoteCard) {
          const { clientHeight: canvasHeigth, clientWidth: canvasWidth } =
            pageContainer.querySelector('.pdf-page-canvas');
          const coords: ICoordinates = {
            x: 0,
            y: 0,
            page: pageNumber,
          };
          this.addSignatureCardToPage(pageContainer, quoteCard, coords, true);
        }
      }
    }, this);
  }

  setNewZommToPage(pdfPage: any, pageNumber: number, resolution = 2) {
    const canvas = this.render2.selectRootElement(
      `div.page-${pageNumber} > canvas`
    );
    const printResolution = resolution * window.devicePixelRatio;
    const viewport = pdfPage.getViewport({
      scale: this.zooms[this.scale] * printResolution,
    });
    canvas.style.height = `${Math.ceil(viewport.height / printResolution)}px`;
    canvas.style.width = `${Math.ceil(viewport.width / printResolution)}px`;
    this.reloadSignerCardPositions();
  }

  async preparePage(
    pageNumber: number,
    pageContainer: HTMLDivElement,
    observer: IntersectionObserver,
    resolution = 2
  ) {
    const printResolution = resolution * window.devicePixelRatio;
    const canvas: HTMLCanvasElement = this.render2.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    const viewport = await this.getViewport(resolution);

    canvas.className = 'pdf-page-canvas';
    pageContainer.className = `page-number page-${pageNumber} rendering`;
    pageContainer.setAttribute('data-page', pageNumber.toString());
    this.render2.appendChild(pageContainer, canvas);

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.height = `${Math.ceil(viewport.height / printResolution)}px`;
    canvas.style.width = `${Math.ceil(viewport.width / printResolution)}px`;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#FFFFFF';
    context.fillRect(0, 0, canvas.width, canvas.height);
    observer.observe(pageContainer);
  }

  renderPage(
    page: any,
    pageNumber: number,
    pageContainer: HTMLDivElement,
    resolution = 2
  ) {
    const printResolution = resolution * window.devicePixelRatio;
    const canvas = pageContainer.querySelector('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    const viewport = page.getViewport({
      scale: this.zooms[this.scale] * printResolution,
    });
    canvas.className = 'pdf-page-canvas';
    pageContainer.className = `page-number page-${pageNumber}`;
    pageContainer.setAttribute('style', '');
    pageContainer.setAttribute('data-rendered', 'true');
    this.currentVisiblePage = page._pageIndex + 1;
    this.addListenersToDropContainer(pageContainer);
    if (this.currentVisiblePage === this.pdfDoc.numPages) {
      this.isLastPageRendered = true;
    }

    this.renderSignatureCardsOnLastPage(pageContainer);

    context.clearRect(0, 0, canvas.width, canvas.height);
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.height = `${Math.ceil(viewport.height / printResolution)}px`;
    canvas.style.width = `${Math.ceil(viewport.width / printResolution)}px`;
    context.clearRect(0, 0, canvas.width, canvas.height);
    this.pageRender(page, context, viewport);
  }

  pageRender(page: any, context: CanvasRenderingContext2D, viewport: any) {
    const renderContext = { canvasContext: context, viewport };
    page.render(renderContext).promise.then(() => {
      this.auxRender++;
      if (this.auxRender === this.pdfDoc.numPages) {
        this.auxRender = 0;
        this.renderComplete.emit(true);
      }
    });
  }

  upZoom() {
    if (this.scale + 1 >= this.zooms.length) {
      return;
    }

    this.resizeWithNewZoom(this.scale + 1);
  }

  downZoom() {
    if (this.scale - 1 < 0) {
      return;
    }

    this.resizeWithNewZoom(this.scale - 1);
  }

  reloadSignerCardPositions() {
    for (let i = 1; i <= this.pdfDoc.numPages; i++) {
      this.addSignerstToPage(
        this.pdfContainer.nativeElement.querySelector(`.page-${i}`),
        i
      );
    }
  }

  resizeWithNewZoom(scale: number) {
    this.scale = scale;
    for (let i = 1; i <= this.pdfDoc.numPages; i++) {
      this.pdfDoc.getPage(i).then((page: any) => {
        this.setNewZommToPage(page, i);
      });
    }
  }

  cleanPages() {
    this.viewport = null;
    Array.from(this.pdfContainer.nativeElement.children).forEach((canvas) =>
      this.render2.removeChild(this.pdfContainer.nativeElement, canvas)
    );
  }

  createObserver() {
    const options = {
      root: this.pdfContainer.nativeElement,
      rootMargin: '0px',
      threshold: 0.1,
    };

    return new IntersectionObserver((entries) => {
      const showedEntries = entries.filter(
        (entry: IntersectionObserverEntry) => entry.intersectionRatio >= 0.1
      );

      showedEntries.forEach((entry: IntersectionObserverEntry) => {
        const rendered = entry.target.getAttribute('data-rendered');
        const pageNumber = parseInt(entry.target.getAttribute('data-page'));

        if (!rendered && pageNumber) {
          this.pdfDoc.getPage(pageNumber).then((page: any) => {
            this.renderPage(page, pageNumber, entry.target as HTMLDivElement);
          });
        }
      });
    }, options);
  }

  createElement(reloadPDF = true) {
    if (!reloadPDF) {
      this.reloadSignerCardPositions();
      return true;
    }
    this.currentVisiblePage = 0;
    this.isLastPageRendered = false;
    const observer = this.createObserver();
    this.cleanPages();
    this.renderFirstPage(observer);

    for (let i = 2; i <= this.pdfDoc.numPages; i++) {
      const pageContainer: HTMLDivElement = this.render2.createElement('div');
      this.preparePage(i, pageContainer, observer);
      this.render2.appendChild(this.pdfContainer.nativeElement, pageContainer);
    }
  }

  getPrintResolution(resolution = 2) {
    return resolution * window.devicePixelRatio;
  }

  async getViewport(resolution = 2) {
    if (!this.viewport) {
      const page = await this.pdfDoc.getPage(1);
      const printResolution = this.getPrintResolution(resolution);

      this.viewport = page.getViewport({
        scale: this.zooms[this.scale] * printResolution,
      });
    }

    return this.viewport;
  }

  renderSignatureCardsOnLastPage(pageContainer: HTMLDivElement) {
    this.addSignerstToPage(pageContainer, this.currentVisiblePage);
  }

  renderFirstPage(observer: IntersectionObserver) {
    const pageContainer: HTMLDivElement = this.render2.createElement('div');
    this.preparePage(1, pageContainer, observer).then(async () => {
      const page = await this.pdfDoc.getPage(1);
      this.renderPage(page, 1, pageContainer);
      this.renderComplete.emit(true);
    });

    this.render2.appendChild(this.pdfContainer.nativeElement, pageContainer);
  }
}
