import { IAppState } from '@literax/store/index';
import { createSelector } from '@ngrx/store';
import { IFreeSignatureState } from '@literax/store/free-signature/free-signature.state';
import { IAPIError } from '@literax/models/http/api/error.model';
import { ISignatureState } from '@literax/store/signature/signature.state';

const selectSignatures = (state: IAppState) => state.freeSignatures;

export const selectSignatureResult = createSelector(
  selectSignatures,
  (state: IFreeSignatureState) => state.signatureResult
);

export const selectShowSignatureResult = createSelector(
  selectSignatures,
  (state: IFreeSignatureState) => state.showSignatureResult
);

export const selectSignatureErrors = createSelector(
  selectSignatures,
  (state: IFreeSignatureState) => state.errors
);

export const selectFreeSignatureErrorsForm = createSelector(
  selectSignatures,
  (state: IFreeSignatureState) => {
    if (state.errors !== null && state.errors.detail !== null) {
      const uuidKey = Object.keys(state?.errors?.detail)[0];
      return {
        uuid: uuidKey,
        error: state.errors?.detail[uuidKey]['signatures.sign_data']
      };
    }
  }
);

export const selectSignatureErrorDetails = createSelector(
  selectSignatureErrors,
  (state: IAPIError) => state && state.detail
);

export const selectCertificateErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) => state && state.cert
);

export const selectSignDataErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) => state && state.sign_data
);

export const selectErrorKeyReducer = createSelector(
  selectSignatureErrorDetails,
  (state: any) =>
    state &&
    Object.keys(state).reduce(
      (currentObject, key) => ({ ...currentObject, ...state[key] }),
      {}
    )
);
export const selectFreeTokenErrors = createSelector(
  selectErrorKeyReducer,
  (state: any) => state && state?.expiry
);

export const selectFreeImageError = createSelector(
  selectErrorKeyReducer,
  (state: any) => state && state?.id_image
);

export const selectKeyErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) =>
    state &&
    (state.key || state['signatures.sign_data'] || state['signatures.expiry'])
);
export const selectNameFreeError = createSelector(
  selectErrorKeyReducer,
  (state: any) => state && state?.name
);

export const selectEmailFreeError = createSelector(
  selectErrorKeyReducer,
  (state: any) => state && state?.email
);
