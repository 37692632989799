import * as documentActions from '../../../store/document/document.actions';
import * as usersActions from '@literax/components/configurations/users/states/users.actions';

import { Actions, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ClearStack,
  SetNavNodes,
} from '@literax/store/title-navbar/title-navbar.actions';
import {
  DelegateProcessTo,
  EClientActions,
} from '@literax/store/client/client.actions';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  GetHistoryEmail,
  InitializeSignRequestFormStates,
  SendSignRequestNotification,
} from '@literax/store/sign-request/sign-request.actions';
import {
  GetUsersReceiversActive,
  selectUserPermissions,
} from '@literax/components/configurations/users/states/users.selector';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import {
  attachments,
  selectAllDocumentObserverRequests,
  selectAllSignersRequests,
  selectAttachment,
  selectCurrentAttachment,
  selectDocument,
  selectGetDocumentAttachmentError,
  selectLastAttachmentUploaded,
  selectPendingMessages,
  typePromissoryAttachment,
} from '@literax/store/document/document.selectors';
import {
  checkAttachment,
  checkAttachmentSuccess,
} from '@literax/store/attachment/attachment.actions';
import {
  debounceTime,
  delay,
  distinctUntilKeyChanged,
  filter,
  finalize,
  first,
  map,
  take,
} from 'rxjs/operators';

import { AddModalDateComponent } from './generate/add-modal-date/add-modal-date.component';
import { AddSignerWizardComponent } from '../add-signer-wizard/add-signer-wizard.component';
import { AssignmentDialogComponent } from '@literax/components/shared/assignment-dialog/assignment-dialog.component';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { ButtonAction } from '@literax/utils/buttons-bar-configs';
import { CancelProcessDocumentComponent } from '@literax/components/platform/cancel-process-document/cancel-process-document.component';
import { ConfirmDialogApprovalComponent } from '@literax/components/shared/confirm-dialog-approval/confirm-dialog-approval.component';
import { ConfirmDialogComponent } from '@literax/components/shared/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogDelegateComponent } from '@literax/components/shared/confirm-dialog-delegate/confirm-dialog-delegate.component';
import { DelegateProcessDialogComponent } from '@literax/components/shared/delegate-process-dialog/delegate-process-dialog.component';
import { DeleteRecordDialogComponent } from '@literax/components/platform/delete-record-dialog/delete-record-dialog.component';
import { DocumentSignature } from '@literax/models/document-signature';
import { EAttachmentKind } from '@literax/enums/attachment-kind.enum';
import { EDocTransactionType } from '@literax/enums/document-transaction-type.enum';
import { EDocumentStatus } from '@literax/enums/document-status.enum';
import { HistoryComponent } from './history/history.component';
import { IActions } from '@literax/components/configurations/users/models/users.interface';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IProfile } from '@literax/components/configurations/profiles/models/profiles';
import { ISignRequest } from '@literax/store/document/document.state';
import { ISignatureResponse } from '@literax/models/http/api/signature.model';
import { ISignerCoordinatesUpdater } from '@literax/models/http/api/client/pdf.model';
import { IUpdateDocument } from '../../../models/http/document/document.model';
import { MatIconRegistry } from '@angular/material/icon';
import { MsalService } from '@azure/msal-angular';
import { SignModalComponent } from '../sign-modal/sign-modal.component';
import { SignatureFormComponent } from '@literax/components/shared/signature-form/signature-form.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { removeDuplicates } from '@literax/utils/app.utils';
import { selectCurrentLang } from '@literax/store/config/config.selectors';
import { selectCurrentUser } from '@literax/store/auth/auth.selectors';
import { selectLastEventEmail } from '@literax/store/sign-request/sign-request.selectors';
import { selectSignatureResult } from '@literax/store/signature/signature.selector';
import { signaturePositionActions } from '@literax/store/signature-position/singature-position.actions';
import { statusTemplate } from '@literax/models/templates';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  @ViewChild('xml') xmlCodeElement: ElementRef<HTMLElement>;

  readonly icons = ['info', 'signers', 'comment', 'message', 'pencil'];
  document$ = this.store.pipe(untilDestroyed(this), select(selectDocument));
  currentAttachment$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCurrentAttachment)
  );
  selectAttachmen$ = this.store.pipe(
    untilDestroyed(this),
    select(selectAttachment)
  );
  typePromissoryAttachmen$ = this.store.pipe(
    untilDestroyed(this),
    select(typePromissoryAttachment)
  );
  errorAttachment$ = this.store.pipe(
    untilDestroyed(this),
    select(selectGetDocumentAttachmentError)
  );
  attachmens$ = this.store.pipe(untilDestroyed(this), select(attachments));
  user$ = this.store.pipe(untilDestroyed(this), select(selectCurrentUser));
  signRequests$ = this.store.pipe(
    untilDestroyed(this),
    select(selectAllSignersRequests)
  );

  lastUploadedAttachment$ = this.store.pipe(
    untilDestroyed(this),
    select(selectLastAttachmentUploaded)
  );

  observers$ = this.store.pipe(
    untilDestroyed(this),
    select(selectAllDocumentObserverRequests)
  );

  form: FormGroup;
  hidden = false;
  visible = [true, false];
  visibleInfo: boolean[] = [];
  signatures: { [key: string]: ISignatureResponse } = {};
  dialogInstance: MatDialogRef<AddSignerWizardComponent, any>;
  signDialog: MatDialogRef<SignModalComponent, any>;
  historyDialog: MatDialogRef<HistoryComponent, any>;
  contentVisible = true;

  documentId: number;
  documentBase64: string;
  attachmentNames: string;
  documentData: SafeResourceUrl;
  selectedDocument: IDocument;
  selectedAttachment: number;
  isCommonDocument: boolean;
  userInfo: IProfile;
  hasObservers$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isReview$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isApprove$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSignature$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isApplicant$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCheckReview$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isCheckApprove$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  visualRepresentation: SafeResourceUrl;
  showVisualRepresentation = false;
  isXMLTemplate = false;
  options = ['Attachments', 'Sign', 'Comment', 'Info', 'Edit'];
  images = {
    Info: 'infoSvg',
    Sign: 'signersSvg',
    Comment: 'commentSvg',
    Attachments: 'messageSvg',
    Edit: 'pencilSvg',
  };

  recordId: string;
  templateId: string;
  b2cToken: string;

  lang$ = this.store.pipe(untilDestroyed(this), select(selectCurrentLang));
  isTemplate: boolean;
  isTicket: boolean;
  isCollectData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  validFormTemplate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  visibleEdit = 0;
  userSignerHaveCollection: boolean;
  @ViewChild('chatContainer') chatContainer: ElementRef;
  chatComponent: HTMLElement;
  canInitializeChat = false;
  isGeneratedRequest: string;
  recordFilled: any = false;
  onlyofficeVisible: boolean;

  //#region Permisos de usuario
  showDocument: boolean;
  //#endregion

  //#region  Revision
  _attachment: IAttachment;
  _document: IDocument;
  _user: IProfile;
  isDocxAttachment$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  _reload = false;
  _currentAction: string;
  typeDocVisible$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  viewerAction$: BehaviorSubject<string> = new BehaviorSubject<string>('view');
  forceEnabledSigners$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  //#endregion
  //#region  pagare
  isPormisorryNote = false;

  signatureQuotesValid: Record<string, unknown> = {};

  notificationsValue$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  signatureQuotes$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  userWithSignatureQuote$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  showTemplateMetadata = false;
  showSignatureQuote = false;

  isLegalRepresentativeReject = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<IAppState>,
    private router: Router,
    private dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    private translate: TranslateService,
    private detector: ChangeDetectorRef,
    private actions$: Actions,
    private msalService: MsalService,
    private b2cAuthService: B2CAuthService,
    private sanitizer: DomSanitizer
  ) {
    this.user$.subscribe((user: IProfile) => (this.userInfo = user));

    //#region documentos de tipo docx
    this.route.data.subscribe(({ documetn }) => {
      this.isReview$.next(
        documetn.transaction_type === EDocTransactionType.REVIEW
      );
      this.isApprove$.next(
        documetn.transaction_type === EDocTransactionType.APPROVAL
      );
      this.isApplicant$.next(
        documetn.transaction_type === EDocTransactionType.REQUEST
      );
      this._attachment = documetn?.attachments.find((a) => a.primary === true);
      this._document = documetn;
      this._user = this.userInfo;
      this.userSignerHaveCollection =
        documetn.status.key === EDocumentStatus.DATA_COLLECTION;
      this.isTemplate = this._attachment.form_builder_record_uid !== null;
      this.isTicket = this._attachment.kind === EAttachmentKind.TICKET;
      // si el attachment del documento es de tipo docx cargamos el visor only office
      this.changeSwitchViewer(this._attachment);
      this.getProcess();
    });
    //#endregion

    this.store
      .pipe(untilDestroyed(this), select(selectUserPermissions))
      .subscribe((permissions: IActions) => {
        this.showDocument = permissions?.show_document;
      });
    this.searchAttachment(0);
    this.icons.forEach((icon) => {
      iconRegistry.addSvgIcon(
        `${icon}Svg`,
        sanitizer.bypassSecurityTrustResourceUrl(
          `../../assets/icon/${icon}.svg`
        )
      );
    });

    this.form = this.fb.group({
      email: ['', Validators.email],
    });

    this.translate.onLangChange.subscribe(() => {
      this.getProcess();
      this.store.dispatch(documentActions.UpdateSelectedDocument());
    });

    this.lastUploadedAttachment$
      .pipe(
        untilDestroyed(this),
        filter((attachment) => !!attachment),
        delay(500)
      )
      .subscribe((attachment) => {
        this.selectAttachment(attachment?.id);
      });

    this.signRequests$.subscribe((signers) => {
      signers?.some((signer) => {
        this.isLegalRepresentativeReject =
          signer.status?.key === EDocumentStatus.REJECTED;
      });
    });

    this.document$
      .pipe(filter((document) => document !== null))
      .subscribe((document: IDocument) => {
        this.selectedDocument = document;
        this.hasObservers$.next(this.selectedDocument?.observers?.length > 0);

        this.user$
          .pipe(
            filter((user) => user != null),
            take(1)
          )
          .subscribe((currentUser) => {
            if (this.selectedDocument?.user_signer?.length > 0) {
              this.selectedDocument.user_signer.forEach((signer) => {
                if (
                  this.selectedDocument.status?.key ===
                    EDocumentStatus.IN_PROCESS &&
                  signer.sign_request_signature_quotes.length > 0 &&
                  signer.status.key !== 'signed' &&
                  signer.email === currentUser.email
                ) {
                  this.signatureQuotes$.next(
                    removeDuplicates(
                      [
                        ...this.signatureQuotes$.getValue(),
                        ...signer.sign_request_signature_quotes,
                      ],
                      'template_uid'
                    )
                  );
                  this.validFormTemplate$.next(true);
                }
              });
            }

            if (this.selectedDocument.status?.key === 'signed') {
              this.validateFormTemplate();
            }

            if (
              this.selectedDocument?.status.key ===
              EDocumentStatus.DATA_COLLECTION
            ) {
              this.validFormTemplate$.next(true);
            }
            if (this.isTemplate || this.isTicket) {
              this.validateFormTemplate();
            }
          });

        this.isCollectData$.next(
          document.status.key === EDocumentStatus.DATA_COLLECTION
        );
        this.documentId = document.id;
        this.isCommonDocument =
          ['pdf', 'xml', 'md', 'template', 'ticket', 'docx', 'doc'].indexOf(
            document.attachments[0]?.kind
          ) >= 0;
        this.isReview$.next(
          document.transaction_type === EDocTransactionType.REVIEW
        );
        this.isApprove$.next(
          document.transaction_type === EDocTransactionType.APPROVAL
        );
        this.isApplicant$.next(
          document.transaction_type === EDocTransactionType.REQUEST
        );
        this.isCheckReview$.next(
          document.user_signer[0]?.signatory_role?.name === 'reviewer' &&
            document.user_signer[0]?.status?.key === 'request_sent'
        );
        this.isCheckApprove$.next(
          document.user_signer[0]?.signatory_role?.name === 'approver' &&
            document.user_signer[0]?.status?.key === 'request_sent'
        );
        this.isSignature$.next(
          document.transaction_type === EDocTransactionType.SIGNATURE
        );
        const attachment = document.attachments?.find(
          (element) => element.id === this.selectedAttachment
        );

        this.setChatVisible(document);

        if (
          attachment?.kind === EAttachmentKind.TEMPLATE ||
          attachment?.kind === EAttachmentKind.TICKET ||
          this.isTemplate
        ) {
          this.b2cAuthService
            .getToken()
            .pipe(untilDestroyed(this))
            .subscribe((token) => {
              this.b2cToken = token;
              this.templateId = attachment?.form_builder_template_id;
              this.recordId = attachment?.form_builder_record_uid;
            });
        }
        this.changeSwitchViewer(attachment);

        if (
          (document.status.key !== EDocumentStatus.DRAFT &&
            document?.sign_requests.some(
              (signer) =>
                signer.status.key === 'rejected' ||
                (signer.delegate && signer.delegate.status.key === 'rejected')
            )) ||
          this.isLegalRepresentativeReject
        ) {
          this.store.dispatch(documentActions.GetCancellationReasons());
        }

        const principalDoc = document.attachments.find((doc) => doc.primary);
        if (
          document.transaction_type === EDocTransactionType.SIGNATURE &&
          document.status.key === EDocumentStatus.DRAFT &&
          principalDoc.kind === EAttachmentKind.DOCX &&
          principalDoc.file_docx_url
        ) {
          this.forceEnabledSigners$.next(true);
        }
      });
  }
  // end class constructor

  ngOnInit() {
    if (!document.querySelector('#onlyOfficeScript')) {
      const scriptOnlyOffice = document.createElement('script');
      scriptOnlyOffice.type = 'text/javascript';
      scriptOnlyOffice.src = `${environment.onlyOfficeUrl}`;
      scriptOnlyOffice.setAttribute('defer', '');
      scriptOnlyOffice.setAttribute('id', 'onlyOfficeScript');
      scriptOnlyOffice.setAttribute('lang', this.translate.currentLang);
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(scriptOnlyOffice);
    }

    this.store
      .pipe(untilDestroyed(this), select(selectSignatureResult))
      .subscribe((result: ISignatureResponse) => {
        if (result) {
          this.store.dispatch(documentActions.UpdateSelectedDocument());
        }
      });

    this.document$
      .pipe(
        untilDestroyed(this),
        filter((document) => !!document),
        distinctUntilKeyChanged('status_name')
      )
      .subscribe((item) => {
        this._document = item;
        this._attachment = item?.attachments?.filter(
          (a) => a.primary === true
        )[0];
        this._user = this.userInfo;
        this.setChatVisible(item);
        if (item) {
          if (item.attachments.length > 1 && !item?.user_signer[0]?.signed) {
            this.visibleInfo = [true, false, false, false, false];
          } else {
            this.visibleInfo = [true, false, false, false, false];
          }
        } else {
          this.visibleInfo = [false, false, true, false, false];
        }
        this.selectAttachment(0);

        const isAuthorCollection: boolean =
          (item?.transaction_type === EDocTransactionType.SIGNATURE ||
            item?.transaction_type === EDocTransactionType.REQUEST) &&
          (item?.status?.key === EDocumentStatus.DRAFT ||
            item?.status?.key === EDocumentStatus.DATA_COLLECTION) &&
          item?.user?.id === this._user?.id;

        const isUserSignerCollection: boolean =
          item?.transaction_type === EDocTransactionType.SIGNATURE &&
          item?.status?.key === EDocumentStatus.DATA_COLLECTION &&
          item?.user_signer[0]?.email !== this._user?.email;



        
        const isUserSignerCollectionQuote: boolean =
          (item?.transaction_type === EDocTransactionType.SIGNATURE ||
            item?.transaction_type === EDocTransactionType.FORM) &&
          item?.status?.key === EDocumentStatus.IN_PROCESS &&
          item?.user_signer.length > 0 &&
          item?.user_signer[0].email === this._user?.email &&
          item?.user_signer[0]?.sign_request_signature_quotes.length > 0;

        const isUserSignerLegalRepresentative: boolean =
          item?.transaction_type === EDocTransactionType.SIGNATURE &&
          item?.status?.key === EDocumentStatus.DATA_COLLECTION &&
          item?.user_signer[0]?.email === this._user?.email;

        if (
          isAuthorCollection ||
          isUserSignerCollection ||
          isUserSignerLegalRepresentative
        ) {
          this.showTemplateMetadata = true;
        } else {
          this.showTemplateMetadata = false;
        }

        if (isUserSignerCollectionQuote) {
          this.showSignatureQuote = true;
          this.showTemplateMetadata = true;
        } else {
          this.showSignatureQuote = false;
        }
      });

    this.store
      .pipe(untilDestroyed(this), select(selectPendingMessages))
      .subscribe((pendingMessages) => {
        this.notificationsValue$.next(pendingMessages);
      });
  }

  setChatVisible(document: IDocument) {
    this.canInitializeChat = !!document?.chat;
    if (this.canInitializeChat) {
      this.getPendingMessages(this.selectedDocument);
    }
  }

  // valida que todos los metadatos requeridos de perfiles de hayan llenado
  validateSignatureQuote(event: any): void {
    if (event && event.record) {
      this.signatureQuotesValid[event.record] = event.is_valid;

      this.recordFilled = Object.values(this.signatureQuotesValid).every(
        (isValid) => isValid === true
      );
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(ClearStack());
    const chatScript = document.querySelector('#chatScript');
    if (chatScript) {
      chatScript.parentNode.removeChild(chatScript);
    }
    if (!this.isPormisorryNote) {
      this.store.dispatch(documentActions.CleanSelectedDocumentState());
      this.store.dispatch(documentActions.ClearLastUploadedAttachment());
    }
  }

  executeAction(button: ButtonAction): void {
    const signRequestActions = {
      to_approved: (event) => this.sendToApproved(event),
      to_reviewed: (event) => this.concludeReview(event),
      reviewed_to_approval: (event) => this.reviewToApproval(event),
      signatures: (event) => this.reviewSignatures(),
      download_doc: () => this.downloadFile(),
      delete_doc: (event) => this.deleteFile(event),
      set_delivery_date: (event) => this.saveOpenDialogGenerateModal(event),
      assign: (event) => this.assignmentDialog(),
      sign: (event) => this.openSignature(event),
      approved_to_signature: (event) => this.approveToSignature(event),
      reviewed_to_signature: (event) => this.reviewedToSignature(event),
      edit_promissory_note: () => this.editPromissoryNote(),
      save: () => this.saveAttachment(),
      cancel_review: (event) => this.cancelProcess(event),
      cancel_approval: (event) => this.cancelProcess(event),
      cancel_collection: (event) => this.cancelProcess(event),
      cancel_signature: (event) => this.cancelProcess(event),
      cancel_request_sent: (event) => this.cancelProcess(event),
      delegate_approval: (event) => this.delegateApproval(event),
      delegate_reviewed: (event) => this.delegateReviewed(event),
    };

    if (!Array.isArray(button.action)) {
      this._currentAction = button.action;
      signRequestActions[button.action]
        ? signRequestActions[button.action](button.action)
        : this.markAs(button.action);
    }
  }

  saveAttachment(): void {
    this.forceEnabledSigners$.next(true);
    this.viewerAction$.next('save');
    this._reload = true;
  }

  isRequiredComplete(valid: boolean) {
    this.recordFilled = valid;
  }

  activateInfoTab(tab: number): void {
    this.hideOtherInfo();
    this.visibleInfo[tab] = true;
    this.detector.detectChanges();

    if (
      (tab === 2 && this.isReview$.getValue()) ||
      (tab === 2 && this.isApprove$.getValue()) ||
      (tab === 2 && this.isSignature$.getValue()) ||
      (tab === 2 && this.isApplicant$.getValue())
    ) {
      this.notificationsValue$.next(null);
      this.createChatComponent();
    }
  }

  injectChatScript() {
    if (!document.querySelector('#chatScript')) {
      const chat = document.createElement('script');
      chat.type = 'text/javascript';
      chat.src = `${environment.chatUrl}`;
      chat.setAttribute('defer', '');
      chat.setAttribute('id', 'chatScript');
      document.querySelector('head').appendChild(chat);
    }
  }

  createChatComponent() {
    this.injectChatScript();
    const doc$ = this.document$.pipe(untilDestroyed(this), take(1));
    const token$ = this.b2cAuthService.getToken().pipe(untilDestroyed(this));
    combineLatest([doc$, token$]).subscribe(([document, token]) => {
      this.chatComponent = window.document.createElement('lit-chat');
      this.chatComponent.setAttribute('token', encodeURIComponent(token));
      this.chatComponent.setAttribute('is-azure-auth', 'true');
      this.chatComponent.setAttribute(
        'chatuuid',
        encodeURIComponent(document?.chat)
      );
      this.chatComponent.setAttribute('sender', document?.token_chat?.username);
      this.chatComponent.setAttribute(
        'app-id',
        document?.token_chat?.session?.app
      );
      this.chatComponent.setAttribute('channel', 'LiteraxChannel');
      this.chatContainer.nativeElement.innerHTML = '';
      this.chatContainer.nativeElement.appendChild(this.chatComponent);
    });
  }

  updatePosition(signerCoordinates: ISignerCoordinatesUpdater) {
    this.store.dispatch(
      signaturePositionActions.UpdateSignaturePosition({
        payload: signerCoordinates,
      })
    );
  }

  hideOtherInfo(): void {
    for (let i = 0; i < this.visibleInfo.length; i++) {
      this.visibleInfo[i] = false;
    }
  }

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }

  onAddSignerClicked() {
    this.document$.pipe(take(1)).subscribe((document) => {
      this.store.dispatch(
        InitializeSignRequestFormStates({ payload: document.sign_requests })
      );
    });
    this.router.navigate([
      `platform/documents/${this.documentId}/configuration`,
    ]);
  }

  // TODO: mover navegacion a directiva routerLink
  onAddReviewerClicked() {
    this.router.navigate([`platform/documents/${this.documentId}/reviewers`]);
  }

  // TODO: mover navegacion a directiva routerLink
  onAddApproverClicked() {
    this.router.navigate([`platform/documents/${this.documentId}/approvers`]);
  }

  onSignerAction(signer: ISignRequest, documentId: number) {
    const sendEmailParams = [{ id: signer.id, send_notification_email: true }];
    this.store.dispatch(
      SendSignRequestNotification({
        payload: { documentId, payload: sendEmailParams },
      })
    );
  }

  historyDocumentDialog(sign_request: ISignRequest, id: number) {
    const HistoryParams = {
      id: sign_request.id,
      name: sign_request.name,
      rfc: sign_request.rfc,
      signature_type_id: sign_request.signature_type_id,
    };
    this.store.dispatch(
      GetHistoryEmail({ payload: { id, payload: HistoryParams } })
    );

    setTimeout(() => {
      this.store
        .pipe(
          untilDestroyed(this),
          select(selectLastEventEmail),
          filter((r) => !!r),
          first()
        )
        .subscribe((response) => {
          this.historyDialog = this.dialog.open(HistoryComponent, {
            width: '450px',
            disableClose: false,
            data: { payload: response.last_email_event, signer: sign_request },
          });

          this.historyDialog
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((r) => {
              if (r.data) {
                this.onSignerAction(sign_request, id);
              }
            });
        });
    }, 1000);
  }

  deleteDocumentDialog(): void {
    this.dialog.open(DeleteRecordDialogComponent, {
      width: '480px',
      height: '280px',
      disableClose: true,
      data: {
        callback: (): void =>
          this.store.dispatch(
            documentActions.DeleteDocument({
              payload: { documentId: this.documentId },
            })
          ),
        message: 'DOCUMENTS.CONFIRM_DELETE_MSG',
      },
    });
  }

  downloadFile(): void {
    this.store.dispatch(
      documentActions.DownloadDocument({ payload: { id: this.documentId } })
    );
  }

  selectAttachment(id: number) {
    this.selectedAttachment = id;
    this.searchAttachment(this.selectedAttachment);
  }

  validateFormTemplate() {
    this.user$.pipe(filter((user) => user != null)).subscribe((currentUser) => {
      if (
        this.selectedDocument.status.key === EDocumentStatus.DRAFT &&
        this.selectedDocument.user.id === currentUser.id
      ) {
        this.validFormTemplate$.next(true);
      } else if (this.isValidatedSignatory(currentUser)) {
        this.validFormTemplate$.next(true);
      } else {
        this.validFormTemplate$.next(false);
      }
    });
  }

  isValidatedSignatory(user: IProfile) {
    let signatory = null;
    this.signRequests$.subscribe((signers) => {
      signatory = signers?.find((signer) => {
        return signer.rfc === user.rfc || signer.email === user.email;
      });
    });
    return (
      (signatory &&
        this.selectedDocument.status.key === statusTemplate.collection) ||
      (signatory &&
        signatory.sign_request_signature_quotes.length > 0 &&
        signatory.status?.key !== 'signed' &&
        this.selectedDocument.status.key === statusTemplate.inProcess)
    );
  }

  deleteFile(_: Event) {
    this.dialog.open(DeleteRecordDialogComponent, {
      width: '480px',
      height: '280px',
      disableClose: true,
      data: {
        callback: (): void =>
          this.store.dispatch(
            documentActions.DeleteDocument({
              payload: { documentId: this.documentId },
            })
          ),
        message: 'DOCUMENTS.CONFIRM_DELETE_MSG',
      },
    });
  }

  electronicSignature(document: IDocument) {
    if (document.user_signer.length > 0) {
      let signatureBody: DocumentSignature;
      signatureBody = {
        ...signatureBody,
        signature: {
          name: document.user_signer[0].name,
          email: document.user_signer[0].email,
          two_factor_token: '',
          signature_image: '',
          sign_requests: document.user_signer,
        },
      };
      const dialogRef = this.dialog.open(SignatureFormComponent, {
        disableClose: true,
        panelClass: ['scaleModalSign', 'auto-heigth', 'stroke-modal'],
      });
      if (document.signing_method.includes('plus')) {
        dialogRef.componentInstance.plusSignature = true;
      }
      dialogRef.componentInstance.identification =
        document.user_signer[0].oficial_identification;
      dialogRef.componentInstance.data = signatureBody;
      dialogRef.componentInstance.nameDocument = document.name;
      dialogRef.componentInstance.documentId = document.id;
      dialogRef.afterClosed().subscribe(() => {
        this.validFormTemplate$.next(false);
        this.visibleInfo = [true, false, false, false, false];
      });
    }
  }

  openSignature(_: Event) {
    this.document$
      .pipe(
        filter((document) => document != null),
        take(1)
      )
      .subscribe((document) => {
        if (document.signing_method === 'digital_signature') {
          this.digitalSignature(document);
        } else {
          this.electronicSignature(document);
        }
      });
  }

  digitalSignature(document: IDocument) {
    this.user$.pipe(take(1)).subscribe((user: IProfile) => {
      this.signDialog = this.dialog.open(SignModalComponent, {
        width: '524px',
        id: 'sign-documents',
        disableClose: true,
        data: {
          showCancel: true,
          document,
          currentCertName: user.cert,
        },
        autoFocus: false,
      });
    });

    this.signDialog
      .afterClosed()
      .pipe(debounceTime(3000))
      .subscribe((data) =>
        this.store.dispatch(documentActions.UpdateSelectedDocument())
      );
  }

  markAs(action: string): void {
    if (action.split('_')[0] === 'rejected' || action === 'reject') {
      const dialogRef = this.dialog.open(CancelProcessDocumentComponent, {
        width: '656px',
        height: 'auto',
        disableClose: true,
        data: {
          cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
          acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
          message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
        },
      });
      dialogRef.afterClosed().subscribe((comments) => {
        if (comments.length > 0) {
          this.store.dispatch(
            documentActions.DocumentCancelProcess({ payload: comments })
          );
          this.actions$
            .pipe(
              ofType(documentActions.DocumentCancelProcessSuccess),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.store.dispatch(
                documentActions.DocumentMarkAs({ payload: action })
              );
            });
        }
        if (comments.length === 0) {
          this.store.dispatch(
            documentActions.DocumentMarkAs({ payload: action })
          );
        }
      });
    } else {
      if (action === 'to_generated') {
        this.isGeneratedRequest = 'view';
        this._reload = true;
        this._currentAction = action;
      } else {
        this.dialog.open(DeleteRecordDialogComponent, {
          width: '480px',
          height: '280px',
          disableClose: true,
          data: {
            callback: (): void => {
              if (
                this._attachment?.kind === EAttachmentKind.DOCX &&
                this._document?.transaction_type === EDocTransactionType.REVIEW
              ) {
                this._reload = true;
                this._currentAction = action;
              } else {
                this.store.dispatch(
                  documentActions.DocumentMarkAs({ payload: action })
                );
              }
            },
            cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
            acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
            message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
          },
        });
      }
    }
  }

  afterOnlyofficeReload() {
    const not_markable_actions: string[] = [
      'set_delivery_date',
      'save',
      'download_doc',
      'cancel_review',
      'cancel_approval',
      'cancel_signature',
      'cancel_collection',
      'cancel_request_sent',
      'cancel_in_process',
    ];

    if (
      this._currentAction !== undefined &&
      !not_markable_actions.some((action) => this._currentAction === action)
    ) {
      if (this._currentAction === 'to_reviewed') {
        this.store.dispatch(
          documentActions.DocumentMarkAsReviewed({
            payload: {
              action: this._currentAction,
              sign_request: this.selectedDocument.user_signer[0].id,
            },
          })
        );
      } else {
        this.store.dispatch(
          documentActions.DocumentMarkAs({ payload: this._currentAction })
        );
      }

      this.actions$
        .pipe(
          ofType(documentActions.DocumentMarkAsSuccess),
          untilDestroyed(this)
        )
        .subscribe((data) => {
          this.store.dispatch(
            documentActions.GetDocument({ payload: this.documentId })
          );
        });
    }
    // !Vierificar si el cambio de variable deb ser aqui
    this.store.dispatch(
      documentActions.GetDocument({ payload: this.documentId })
    );
    this.selectAttachment(this.selectedAttachment);
    this._reload = false;

    if (this._document?.transaction_type !== 'review') {
      this.viewerAction$.next('view');
      this.typeDocVisible$.next('pdf');
    } else {
      this.viewerAction$.next('edit');
      this.typeDocVisible$.next('docx');
    }
  }

  concludeReview(action: string) {
    this._currentAction = action;
    this._reload = true;
  }

  cancelProcess(action: string) {
    let message: string;
    switch (action) {
      case 'cancel_request_sent':
        message = 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.CANCEL_REQUEST_SENT';
        break;
      case 'cancel_approval':
        message = 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.CANCEL_APPROVAL';
        break;
      default:
        message = 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.CANCEL_SIGNATURE';
        break;
    }
    this.dialog.open(DeleteRecordDialogComponent, {
      width: '480px',
      height: '280px',
      disableClose: true,
      data: {
        callback: (): void => {
          this.store.dispatch(
            documentActions.DocumentMarkAsList({
              payload: { id: this.documentId, action: action },
            })
          );
        },
        cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
        acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
        message: message,
      },
    });
  }

  reviewToApproval(action: string) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      disableClose: false,
      data: {
        cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
        acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
        message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
        title: `WORKSPACE.FOOTER.CONFIRM_TITLE.${action.toUpperCase()}`,
        acceptCallback: (): void => {
          this.router.navigate([
            'platform',
            'documents',
            this.documentId,
            'reviewers-to-approvers',
          ]);
        },
        cancelCallback: (): void => {
          this.store.dispatch(
            documentActions.DocumentMarkAs({ payload: action })
          );
        },
      },
    });
  }

  sendToApproved(action: string) {
    this.store.dispatch(
      documentActions.DocumentMarkAsApproved({
        payload: {
          action,
          sign_request: this.selectedDocument.user_signer[0].id,
        },
      })
    );
  }

  approveDocReview(action: string) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      disableClose: false,
      data: {
        cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
        acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
        message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
        title: `WORKSPACE.FOOTER.CONFIRM_TITLE.${action.toUpperCase()}`,
        acceptCallback: (): void => {
          this.router.navigate([
            'platform',
            'documents',
            this.documentId,
            'reviewers-to-signers',
          ]);
        },
        cancelCallback: (): void => {
          if (this._attachment.kind === EAttachmentKind.DOCX) {
            this._reload = true;
            this._currentAction = action;
          } else {
            this.store.dispatch(
              documentActions.DocumentMarkAs({ payload: action })
            );
          }
        },
      },
    });
  }

  approveToSignature(action: string) {
    this.dialog.open(ConfirmDialogApprovalComponent, {
      width: '50%',
      disableClose: false,
      data: {
        cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
        acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
        message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
        title: `WORKSPACE.FOOTER.CONFIRM_APPROVAL.TITLE_ADD_CONSTANCY_APPROVAL`,
        acceptCallback: (constancy: boolean, participants: boolean): void => {
          const documentSelect: IUpdateDocument = {
            transaction_type: this.selectedDocument.transaction_type,
            signing_method: this.selectedDocument.signing_method,
            approval_timestamp_sheet: constancy,
          };

          this.store.dispatch(
            documentActions.UpdateDocument({
              payload: {
                documentId: this.documentId,
                document: documentSelect,
              },
            })
          );

          if (participants) {
            this.router.navigate([
              'platform',
              'documents',
              this.documentId,
              'approvers-to-signers',
            ]);
          } else {
            if (this._attachment.kind === EAttachmentKind.DOCX) {
              this._reload = true;
              this._currentAction = action;
            } else {
              this.store.dispatch(
                documentActions.DocumentMarkAs({ payload: action })
              );
            }
          }
        },
        cancelCallback: (): void => {},
      },
    });
  }

  reviewedToSignature(action: string) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      disableClose: false,
      data: {
        cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
        acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
        message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
        title: `WORKSPACE.FOOTER.CONFIRM_TITLE.${action.toUpperCase()}`,
        acceptCallback: (): void => {
          this.router.navigate([
            'platform',
            'documents',
            this.documentId,
            'reviewers-to-signers',
          ]);
        },
        cancelCallback: (): void => {
          if (this._attachment.kind === EAttachmentKind.DOCX) {
            this._reload = true;
            this._currentAction = action;
          } else {
            this.store.dispatch(
              documentActions.DocumentMarkAs({ payload: action })
            );
          }
        },
      },
    });
  }

  delegateApproval(action: string) {
    this.dialog.open(DelegateProcessDialogComponent, {
      width: '385px',
      maxHeight: 'calc(100vh - 150px)',
      height: 'auto',
      disableClose: false,
      data: {
        cancelButton: 'WORKSPACE.FOOTER.CANCEL',
        acceptButton: 'WORKSPACE.FOOTER.DELEGATE',
        message: 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.APPROVAL.DESCRIPTION',
        title: 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.APPROVAL.TITLE',
        userSignerId: this._user.id,
        documentId: this.documentId,
        acceptCallback: (delegate): void => {
          this.store.dispatch(
            DelegateProcessTo({
              payload: {
                to: delegate.id,
              },
            })
          );
        },
        cancelCallback: (): void => {},
      },
    });

    const confirmDialog = this.actions$
      .pipe(
        ofType(EClientActions.DelegateProcessToSuccess),
        take(1),
        finalize(() => confirmDialog.unsubscribe())
      )
      .subscribe((delegate: any) => {
        this.messageConfirmationDelegate(action, delegate.payload);
      });
  }

  delegateReviewed(action: string) {
    this.dialog.open(DelegateProcessDialogComponent, {
      width: '385px',
      maxHeight: 'calc(100vh - 150px)',
      height: 'auto',
      disableClose: false,
      data: {
        cancelButton: 'WORKSPACE.FOOTER.CANCEL',
        acceptButton: 'WORKSPACE.FOOTER.DELEGATE',
        message: 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.REVIEW.DESCRIPTION',
        title: 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.REVIEW.TITLE',
        acceptCallback: (delegate): void => {
          this.store.dispatch(
            DelegateProcessTo({
              payload: {
                to: delegate.id,
              },
            })
          );
        },
        cancelCallback: (): void => {
          console.log('cancelar');
        },
      },
    });

    const confirmDialog = this.actions$
      .pipe(
        ofType(EClientActions.DelegateProcessToSuccess),
        take(1),
        finalize(() => confirmDialog.unsubscribe())
      )
      .subscribe((delegate: any) => {
        this.messageConfirmationDelegate(action, delegate.payload);
      });
  }

  messageConfirmationDelegate(typeProccess: string, delegate: ISignRequest) {
    const titleMessage =
      typeProccess === 'delegate_reviewed'
        ? 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.REVIEW.TITLE'
        : 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.APPROVAL.TITLE';

    this.dialog.open(ConfirmDialogDelegateComponent, {
      width: '672px',
      maxHeight: '95vh',
      disableClose: false,
      data: {
        cancelButton: 'WORKSPACE.FOOTER.CANCEL',
        acceptButton: 'WORKSPACE.FOOTER.AWARE',
        message:
          'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.DESCRIPTION_CONFIRMATION',
        title: titleMessage,
        user: delegate?.name,
        acceptCallback: (): void => {
          console.log('aceptar');
        },
        cancelCallback: (): void => {},
      },
    });
  }

  editPromissoryNote() {
    this.isPormisorryNote = true;
    this.router.navigate([
      'platform',
      'promissorynotes',
      'edit',
      this.documentId,
    ]);
  }

  saveOpenDialogGenerateModal(action: any) {
    const dialogRef = this.dialog.open(AddModalDateComponent, {
      width: '300px',
      disableClose: true,
    });

    dialogRef.componentInstance.title = 'WORKSPACE.GENERATE.MODAL.TITLE';
    dialogRef.componentInstance.acceptButton =
      'WORKSPACE.GENERATE.MODAL.BUTTON_SAVE';
    dialogRef.componentInstance.cancelButton =
      'WORKSPACE.GENERATE.MODAL.BUTTON_CANCEL';
    dialogRef.componentInstance.documentId = this.documentId;
    dialogRef.componentInstance.attachment = this._attachment;

    dialogRef.afterClosed().subscribe((data) => {
      if (data.action === 'reload') {
        this._reload = true;
        this._currentAction = action;
        this.getProcess();
      }
    });
  }

  reviewSignatures() {
    this.router.navigate([
      'platform',
      'documents',
      this.documentId,
      'signatures',
    ]);
  }

  getAttachmentIfEmpty(id: number) {
    this.store.dispatch(
      documentActions.GetDocumentAttachment({
        payload: {
          documentId: this.documentId,
          attachmenId: id,
        },
      })
    );

    this.attachmens$.pipe(take(1)).subscribe((elements: IAttachment[]) => {
      const attachment: IAttachment = elements.find(
        (element) => element.id === id
      );
      this._attachment = attachment;

      if (!attachment?.text) {
        this.store.dispatch(
          documentActions.GetDocumentAttachment({
            payload: { documentId: this.documentId, attachmenId: id },
          })
        );
      } else {
        this.store.dispatch(
          documentActions.ChangeAttachmentSelect({ payload: attachment })
        );

        this.changeSwitchViewer(attachment);
      }
    });
  }

  searchPrimaryAttachment() {
    this.document$
      .pipe(
        filter((document) => document != null),
        filter((document) => document?.attachments?.length > 0),
        take(1)
      )
      .subscribe((document: IDocument) => {
        const attachment = document.attachments.find(
          (element) => element.primary
        );
        this.isTemplate = attachment.form_builder_record_uid !== null;
        this.isTicket = attachment.kind === EAttachmentKind.TICKET;
        this.userSignerHaveCollection =
          document.status.key === EDocumentStatus.DATA_COLLECTION;
        this.selectedDocument = document;
        this.selectedAttachment = attachment?.id;
        if (document.transaction_type === EDocTransactionType.REVIEW) {
          setTimeout(() => {
            this.store.dispatch(
              documentActions.GetDocumentAttachment({
                payload: {
                  documentId: document?.id,
                  attachmenId: attachment?.id,
                },
              })
            );
          }, 10000);
        } else {
          this.store.dispatch(
            documentActions.GetDocumentAttachment({
              payload: {
                documentId: document?.id,
                attachmenId: attachment?.id,
              },
            })
          );
        }
        this.changeSwitchViewer(attachment);
      });
  }

  searchAttachment(id: number) {
    if (id > 0) {
      this.getAttachmentIfEmpty(id);
    } else {
      this.searchPrimaryAttachment();
    }
  }

  getProcess() {
    this.route.paramMap
      .pipe(
        untilDestroyed(this),
        map(() => window.history.state)
      )
      .subscribe((params) => {
        if (params?.process) {
          this.store.dispatch(ClearStack());
          const currentProcess = params.process;
          const processType =
            currentProcess === 'internal_process'
              ? 'sent'
              : currentProcess === 'title'
              ? 'title'
              : 'received';
          const title = 'HOME.' + processType.toUpperCase();
          this.store.dispatch(
            SetNavNodes({
              payload: {
                process_type: {
                  title: title,
                  route:
                    currentProcess === 'title'
                      ? `/platform/home`
                      : `/platform/process/${currentProcess}/typeprocess/${processType}`,
                },
              },
            })
          );
        } else {
          this.store.dispatch(
            SetNavNodes({
              payload: {
                process_type: {
                  title: 'HOME.TITLE',
                  route: `/platform/home`,
                },
              },
            })
          );
        }
      });
    this.document$
      .pipe(filter((document) => !!document))
      .subscribe((document: IDocument) => {
        this.store.dispatch(
          SetNavNodes({
            payload: {
              transaction_type: {
                route: '',
                title: `DOCUMENT-CONFIGURATION.PROCESS.${document?.status?.key?.toUpperCase()}`,
                color: '#616161',
              },
              document_name: {
                route: '',
                title: document?.name,
                color: '#eeeeee',
              },
            },
          })
        );
      });
  }

  editpromissorynote() {
    this.router.navigate(['/platform/promissorynotes/edit/', this.documentId]);
  }

  onRequestNewToken() {
    this.b2cAuthService
      .getToken()
      .pipe(untilDestroyed(this))
      .subscribe((token) => {
        this.b2cToken = token;
      });
  }

  assignmentDialog() {
    this.store.dispatch(usersActions.getAllUsers());

    const listUsers$ = this.store.pipe(select(GetUsersReceiversActive));

    this.dialog.open(AssignmentDialogComponent, {
      width: '320px',
      maxHeight: 'calc(100vh - 150px)',
      height: 'auto',
      disableClose: true,
      data: {
        users: listUsers$,
        acceptCallback: (request: any) => {
          this.sentAssignment(request);
        },
        cancelCallback: (): void => {},
      },
    });
  }

  sentAssignment(request: number) {
    this.store.dispatch(
      documentActions.DocumentCreateAssigment({
        payload: {
          documentId: this.selectedDocument.id,
          sign_request: { client_user_id: request },
        },
      })
    );
  }

  openEditPromissoryNote(): void {
    this.router.navigate([
      'platform',
      'documents',
      this.documentId,
      'promissory-note-configuration',
    ]);
  }

  changeSwitchViewer(attachment: IAttachment, fileType?: string) {
    if (
      (attachment?.file_docx_url === null &&
        attachment?.kind === EAttachmentKind.DOCX) ||
      this._document?.transaction_type === EDocTransactionType.REVIEW
    ) {
      this.viewerAction$.next('edit');
      this.isDocxAttachment$.next(true);
      this.typeDocVisible$.next('docx');
    } else {
      this.viewerAction$.next('view');
      this.typeDocVisible$.next('pdf');
      this.isDocxAttachment$.next(false);
    }
  }

  editAttachment($event: IAttachment) {
    this.isDocxAttachment$.next(true);
    this.viewerAction$.next('edit');
    this.typeDocVisible$.next('docx');
  }

  checkAttachment() {
    this.store.dispatch(
      checkAttachment({
        payload: {
          documentId: this.documentId,
          attachmentId: this.selectedAttachment,
          signRequestId: this.selectedDocument.user_signer[0].id,
        },
      })
    );

    this.actions$
      .pipe(ofType(checkAttachmentSuccess), untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(documentActions.UpdateSelectedDocument());
      });
  }

  getPendingMessages(document: IDocument) {
    const token$ = this.b2cAuthService.getToken();
    token$.subscribe((token) => {
      let app_token = document?.token_chat?.session?.app
        ? document?.token_chat?.session?.app
        : document?.token_chat?.session?.token;
      this.store.dispatch(
        documentActions.GetPendingMessages({
          payload: {
            chat: document.chat,
            app: app_token,
            token: encodeURIComponent(token),
          },
        })
      );
    });
  }
}
