import * as documentActions from '@literax/store/document/document.actions';
import * as signRequestActions from './sign-request.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  IAPISignRequest,
  ICreateSignRequestResponse
} from '@literax/models/http/api/sign-request/sign-request.model';
import {
  catchError,
  concatMap,
  delay,
  map,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { GdxToastrService } from '@interfactura/gdx-angular-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '..';
import { IUser } from '../../components/configurations/users/models/users.interface';
import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { SignRequestService } from '@literax/services/platform/sign-request.service';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { selectDocument } from '../document/document.selectors';

@Injectable()
export class SignRequestEffects {
  getSignRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.GetSignRequests),
      switchMap((action) =>
        this.signRequestService
          .getSignRequests(
            action.payload.id,
            action.payload.page,
            action.payload.sort
          )
          .pipe(
            map((response: ServiceResponse) =>
              signRequestActions.GetSignRequestsSuccess({
                payload: response.sign_requests as IAPISignRequest
              })
            )
          )
      )
    )
  );

  createNaturalPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.createNaturalPerson),
      switchMap((action) =>
        this.signRequestService
          .createNaturalPerson(
            action.payload.documentId,
            action.payload.naturalPerson,
            action.payload.withSignatureQuotes
          )
          .pipe(
            map((response) => signRequestActions.createNaturalPersonSuccess()),
            catchError((error) => {
              if (error.error.error.detail.id.length > 0) {
                this.toastrGdx.error(error.error.error.detail.id[0]);
              } else {
                this.toastrGdx.error(error.error.title);
              }
              return of(
                signRequestActions.createNaturalPersonError({
                  payload: error.error
                })
              )
            })
          )
      )
    )
  );

  updateNaturalPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.UpdateNaturalPerson),
      withLatestFrom(this.store.select(selectDocument)),
      switchMap(([action, document]) =>
        this.signRequestService
          .updateNaturalPerson(document.id, action.payload)
          .pipe(
            map((response) => signRequestActions.UpdateNaturalPersonSuccess()),
            catchError((error) =>
              of(
                signRequestActions.UpdateNaturalPersonError({
                  payload: error.error
                })
              )
            )
          )
      )
    )
  );

  updateSignRequestOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.UpdateSignRequestsOrder),
      withLatestFrom(this.store.select(selectDocument)),
      switchMap(([action, document]) =>
        this.signRequestService
          .updateSignRequestsOrder(document.id, action.payload)
          .pipe(
            map((response) =>
              documentActions.GetDocument({ payload: document.id })
            ),
            catchError((error: HttpErrorResponse) => {
              this.toastr.error('error message', 'order error title');
              return of(null);
            })
          )
      )
    )
  );

  UpdateSignRequestsOrderAfterDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.UpdateSignRequestsOrderAfterDelete),
      withLatestFrom(this.store.select(selectDocument)),
      switchMap(([action, document]) =>
        this.signRequestService
          .updateSignRequestsOrder(document.id, action.payload)
          .pipe(
            map(() => documentActions.UpdateSelectedDocument()),
            catchError((error: HttpErrorResponse) => {
              this.toastr.error('error message', 'order error title');
              return of(null);
            })
          )
      )
    )
  );

  removeSignRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.DeleteSignRequest),
      delay(1000),
      concatMap((action) =>
        this.signRequestService
          .deleteSignRequest(action.payload.documentId, action.payload.payload)
          .pipe(
            map((response: ServiceResponse) =>
              signRequestActions.DeleteSignRequestSuccess({
                payload: {
                  uuid: String(action.payload.documentId),
                  payload: response.sign_request
                }
              })
            ),
            tap(() =>
              this.store.dispatch(
                documentActions.GetDocument({
                  payload: action.payload.documentId
                })
              )
            ),
            catchError((error: HttpErrorResponse) =>
              of(signRequestActions.CreateSignRequestError({ payload: error }))
            )
          )
      )
    )
  );

  removeMultipleSignRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.DeleteMultipleSignRequest),
      delay(1000),
      withLatestFrom(this.store.select(selectDocument)),
      switchMap(([action, document]) =>
        this.signRequestService
          .deleteSignRequest(document.id, action.payload)
          .pipe(
            map((response: ICreateSignRequestResponse) =>
              signRequestActions.DeleteSignRequestSuccess({
                payload: { uuid: String(document.id), payload: response }
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(signRequestActions.CreateSignRequestError({ payload: error }))
            )
          )
      )
    )
  );

  sendSignRequestNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.SendSignRequestNotification),
      switchMap((action) =>
        this.signRequestService
          .sendNotificationEmail(
            action.payload.documentId,
            action.payload.payload
          )
          .pipe(
            map((response: ICreateSignRequestResponse) =>
              signRequestActions.SendSignRequestNotificationSuccess({
                payload: response
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(signRequestActions.UpdateSignRequestsError({ payload: error }))
            )
          )
      )
    )
  );

  changeExpiryAndIdOfficialPN$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.ChangeExpiryAndIdOfficialPN),
      switchMap((action) =>
        this.signRequestService
          .changeExpiryAndOfficialIdPN(
            action.payload.body,
            action.payload.documentId
          )
          .pipe(
            map((response) =>
              signRequestActions.ChangeExpiryAndIdOfficialPNSuccess()
            ),
            catchError((err) =>
              of(signRequestActions.ChangeExpiryAndIdOfficialPNError())
            )
          )
      )
    )
  );

  SendSignRequestNotificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signRequestActions.SendSignRequestNotificationSuccess),
        tap((action) => {
          const isApprovalFlow =
            action.payload.sign_requests[0].signatory_role.name === 'approver';
          const isReviewFlow =
            action.payload.sign_requests[0].signatory_role.name === 'reviewer';
          const isSignatureFlow =
            action.payload.sign_requests[0].signatory_role.name === 'signatory';
          this.toastr.success(
            'TRANSACTIONS.SUCCESS.RESEND_NOTIFICATION',
            `TRANSACTIONS.SUCCESS.RESEND_NOTIFICATION_TITLE.${
              isApprovalFlow
                ? 'APPROVAL'
                : isReviewFlow
                ? 'REVIEW'
                : isSignatureFlow
                ? 'SIGNATURE'
                : 'COMMON'
            }`
          );
        })
      ),
    { dispatch: false }
  );

  addMeToSignRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.AddMeToSigners),
      switchMap((action) =>
        this.signRequestService.addMeToSigners(action.payload).pipe(
          map((response: ICreateSignRequestResponse) =>
            signRequestActions.AddMeToSignersSuccess({
              payload: { request: action.payload, response }
            })
          ),
          catchError((error: HttpErrorResponse) => {
            if (error.error.error.detail.id.length > 0) {
              this.toastrGdx.error(error.error.error.detail.id[0]);
            } else {
              this.toastrGdx.error(error.error.title);
            }
            return of(signRequestActions.AddMeToSignersError({ payload: error }))
          })
        )
      )
    )
  );

  addMetoSignRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        signRequestActions.AddMeToSignersSuccess,
        signRequestActions.SendSignRequestNotificationSuccess,
        signRequestActions.CreateSignRequestSuccess,
        signRequestActions.createNaturalPersonSuccess,
        signRequestActions.UpdateNaturalPersonSuccess,
        signRequestActions.CreateLegalRepresentativesSuccess,
        signRequestActions.UpdateLegalRepresentativesSuccess
      ),
      map(() => documentActions.UpdateSelectedDocument())
    )
  );

  getContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.GetContacts),
      switchMap((action) =>
        this.signRequestService
          .getContacts(action.payload)
          .pipe(
            map((response: ServiceResponse) =>
              signRequestActions.GetContactsResponse({ payload: response })
            )
          )
      )
    )
  );

  refreshDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signRequestActions.CreateSignRequestSuccess),
        tap((action) =>
          this.store.dispatch(documentActions.GetDocuments({ payload: {} }))
        )
      ),
    { dispatch: false }
  );

  refreshAddMeDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signRequestActions.AddMeToSignersSuccess),
        tap((action) =>
          this.store.dispatch(documentActions.GetDocuments({ payload: {} }))
        )
      ),
    { dispatch: false }
  );

  createLegalRepresentative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.CreateLegalRepresentatives),
      withLatestFrom(this.store.select(selectDocument)),
      switchMap(([action, document]) =>
        this.signRequestService
          .createLegalSignRequest(document.id, action.payload)
          .pipe(
            map((response: ICreateSignRequestResponse) => {
              const resourceID = action.payload[0].resource_uuid;
              return signRequestActions.CreateLegalRepresentativesSuccess({
                payload: {
                  uuid: resourceID,
                  payload: response as ICreateSignRequestResponse
                }
              });
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.error.error.detail.id) {
                this.toastrGdx.error(error.error.error.detail.id[0]);
              } else {
                this.toastrGdx.error(error.error.error.title);
              }
              return of(
                signRequestActions.CreateLegalRepresentativesError({
                  payload: error
                })
              )
            })
          )
      )
    )
  );

  updateLegalRepresentatives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.UpdateLegalRepresentatives),
      withLatestFrom(this.store.select(selectDocument)),
      switchMap(([action, document]) =>
        this.signRequestService
          .updateLegalRepresentatives(document.id, action.payload)
          .pipe(
            map((response: ICreateSignRequestResponse) => {
              return signRequestActions.UpdateLegalRepresentativesSuccess({
                payload: response as ICreateSignRequestResponse
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                signRequestActions.UpdateLegalRepresentativesError({
                  payload: error
                })
              )
            )
          )
      )
    )
  );

  getHistoryEmailDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.GetHistoryEmail),
      withLatestFrom(this.store.select(selectDocument)),
      switchMap(([action, document]) =>
        this.signRequestService
          .getHistoryEmails(document.id, action.payload.payload)
          .pipe(
            map((response: ServiceResponse) =>
              signRequestActions.GetHistoryEmailResponse({
                payload: response.sign_request
              })
            )
          )
      )
    )
  );

  getCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signRequestActions.GetCollaborators),
      switchMap((action) =>
        this.signRequestService.getCollaborators(action.payload).pipe(
          pluck('client_users'),
          map((response: IUser[]) =>
            signRequestActions.GetCollaboratorsSuccess({
              payload: response
            })
          )
        )
      )
    )
  );

  constructor(
    private store: Store<IAppState>,
    private signRequestService: SignRequestService,
    private toastr: I18nToastrService,
    private actions$: Actions,
    private toastrGdx: GdxToastrService
  ) {}
}
