import { Component, Input, OnInit } from '@angular/core';
import { ISignerDocument } from '@literax/models/http/api/signature.model';

@Component({
  selector: 'literax-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  @Input() signature: ISignerDocument;
  @Input() digital: boolean;
  @Input() twoFactorAuth: boolean;
  @Input() signatureProcess: string;
  constructor() {}

  ngOnInit() {}
}
