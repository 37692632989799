<div class="tab-title" (click)="toggleTabContent()">
  <h3>{{ 'DOCUMENT-PREVIEW.INFORMATION.TITLE' | translate }}</h3>
  <i
    [ngClass]="
      contentVisible ? 'fal fa-chevron-up icon' : 'fal fa-chevron-down icon'
    "
  ></i>
</div>
<div
  class="tab-component"
  [ngClass]="{ visible: contentVisible }"
  *ngIf="!detailPromissory"
>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.DOCUMENT' | translate
    }}</span>
    <span class="info">{{ document.name }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PUBLICATION-DATE' | translate
    }}</span>
    <span class="info">{{ document.created_at | date: 'dd/MM/yyyy' }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.AUTHOR' | translate
    }}</span>
    <span class="info">{{ document.user.name }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PROCESS' | translate
    }}</span>
    <span class="info">{{ document.status_name }}</span>
  </div>
  <div *ngIf="document.rule" class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.RULE' | translate
    }}</span>
    <span class="info">{{ document.rule }}</span>
  </div>
  <div *ngIf="document.expiry" class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.DEADLINE' | translate
    }}</span>
    <span class="info">{{ document.expiry | date: 'dd/MM/yyyy' }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.MAIN-DOCUMENT' | translate
    }}</span>
    <span class="info">{{ document.name }}</span>
  </div>
  <div
    *ngIf="attachmentNames && document?.attachments.length > 1"
    class="info-item"
  >
    <span class="label"
      >{{ 'DOCUMENT-PREVIEW.INFORMATION.FORM.ATTACHMENTS' | translate }}
      {{ '(' }} {{ attachmentCount }} {{ ')' }}</span
    >
    <span class="info"> {{ attachmentNames }}</span>
  </div>
  <div class="info-item"
    *ngIf='document.transaction_type === "request"'
	>
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.COMMITMENT_DATE' | translate
    }}</span>

    <span *ngIf="!isDateVisible" class="info">{{ delivery_date | date: 'dd/MM/yyyy'}}</span>
    <span *ngIf="isDateVisible" class="info"> ... </span>
  </div>
</div>
<div
  class="tab-component"
  [ngClass]="{ visible: contentVisible }"
  *ngIf="detailPromissory"
>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.BENEFICIARY' | translate
    }}</span>
    <span class="info">{{ beneficiaryName }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.SUBSCRIBER' | translate
    }}</span>
    <span class="info">{{ subscriberName }}</span>
  </div>
  <div class="info-item" *ngFor="let guarantor of arrayGuarantor">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.GUARANTEE' | translate
    }}</span>
    <span class="info">{{ guarantor.name }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.AMOUNT' | translate
    }}</span>
    <span class="info">{{
      balanceAmount | currency: promissoryNoteCicle.currency
    }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.STATUS' | translate
    }}</span>
    <span class="info">{{ document.status_name | translate }}</span>
  </div>
</div>
