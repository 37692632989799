<div class="buttons">
  <div class="left">
    <button
      class="interfirma-button"
      [ngClass]="button?.class"
      matRipple
      [disabled]="button?.disabled"
      [hidden]="button?.hidden"
      *ngFor="let button of buttons.left"
      (click)="action.emit(button)"
    >
      <i *ngIf="button.icon !== ''" [ngClass]="button.icon"></i>
      {{ button.label | uppercase | translate }}
    </button>
  </div>
  <div class="right">
    <ng-container *ngFor="let button of buttons.right">
      <ng-container *ngIf="button?.withActions">
        <mat-menu #menu="matMenu" yPosition="above" class="mt-1 p-1">
          <button
            class="interfirma-button mb-1"
            [ngClass]="btn?.class"
            style="
              display: flex;
              height: 32px;
              align-items: center;
              max-width: 120;
              font-size: 14px;
            "
            *ngFor="let btn of button.action"
            type="button"
            matRipple
            mat-menu-item
            [disabled]="btn?.disabled"
            [hidden]="btn?.hidden"
            (click)="action.emit(btn)"
          >
            <i *ngIf="btn.icon !== ''" [ngClass]="btn.icon" class="mr-1"></i>
            <span>{{ btn.label | uppercase | translate }}</span>
          </button>
        </mat-menu>
        <button
          class="interfirma-button"
          [ngClass]="button?.class"
          type="button"
          matRipple
          [matMenuTriggerFor]="menu"
          [disabled]="button?.disabled"
          [hidden]="button?.hidden"
        >
          <i *ngIf="button.icon !== ''" [ngClass]="button.icon"></i>
          <span>{{ button.label | uppercase | translate }}</span>
        </button>
      </ng-container>

      <button
        *ngIf="!button?.withActions"
        class="interfirma-button"
        [ngClass]="button?.class"
        matRipple
        type="button"
        (click)="action.emit(button)"
        [disabled]="button?.disabled"
        [hidden]="button?.hidden"
        [attr.data-label]="button.label"
        [attr.data-in_status]="button.status | json"
      >
        <i *ngIf="button.icon !== ''" [ngClass]="button.icon"></i>
        <span>{{ button.label | uppercase | translate }}</span>
      </button>
    </ng-container>
  </div>
</div>
