import { ICatalogItem } from '@literax/models/catalog.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';

export interface ICatalogState {
  [key: string]: object;
  catalogError: IAPIResponseError;
}

export const initialCatalogState: ICatalogState = {
  values: {},
  catalogError: null
};
