import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * Base implementation of service calls. Access token management
 * is done here.
 */
export class BaseService {
  private currentLocale = 'es';

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {}

  public set locale(locale: string) {
    this.currentLocale = locale;
  }

  /**
   * Base get call
   * @param url - Url to call
   * @params params
   * @returns An Observable of the response from the server
   */
  get(url: string, params?: HttpParams) {
    return this.httpClient.get<ServiceResponse>(
      `${environment.apiEndpoint}/${environment.apiVersion}${url}`,
      {
        params
      }
    );
  }

  getBlob(url: string): Observable<Blob> {
    return this.httpClient.get(
      `${environment.apiEndpoint}/${environment.apiVersion}${url}`,
      {
        responseType: 'blob'
      }
    );
  }

  getExternalBlob(url: string): Observable<Blob> {
    return this.httpClient.get(url, {
      responseType: 'blob'
    });
  }

  /**
   * Base post call
   * @param url - Url to call
   * @param body - Object to be sent as the post body
   * @returns An Observable of the response from the server
   */
  post(url: string, body: any, options?: any) {
    return this.httpClient.post<ServiceResponse>(
      `${environment.apiEndpoint}/${environment.apiVersion}${url}`,
      body,
      options
    );
  }

  /**
   * Base put call
   * @param url - Url to call
   * @param body - Object to be sent as the put body
   * @returns An Observable of the response from the server
   */
  put(url: string, body: any, options?: any) {
    return this.httpClient.put<ServiceResponse>(
      `${environment.apiEndpoint}/${environment.apiVersion}${url}`,
      body,
      options
    );
  }

  /**
   * Base patch call
   * @param url - Url to call
   * @param body - Object to be sent as the put body
   * @returns An Observable of the response from the server
   */
  patch(url: string, body: any, options?: any) {
    return this.httpClient.patch<ServiceResponse>(
      `${environment.apiEndpoint}/${environment.apiVersion}${url}`,
      body,
      options
    );
  }
  /**
   * Base delete call
   * @param url - Url to call
   * @param body - Object to be sent inside the delete http options
   * @returns An Observable of the response from the server
   */
  delete(url: string, body?: any) {
    return this.httpClient.delete<ServiceResponse>(
      `${environment.apiEndpoint}/${environment.apiVersion}${url}`
    );
  }
}
