<div class="top-divider">
  <div>
    <a href="{{ apiRoot }}">
      <button gdx-button buttonStyle="link" class="interfirma-button mrr-10">
        <span class="text">{{ 'VALIDATOR_UUID.CLOSE' | translate }}</span>
      </button>
    </a>
  </div>
</div>
<div>
  <div class="container-fluid validation-content-title">
    <div class="row">
      <div class="col-lg-6 col-sm-6 col-xs-12 title">
        <a (click)="back()">
          <button gdx-button buttonStyle="link">
            <i class="icon fal fa-chevron-left icon-color"></i>
          </button>
        </a>
        {{ 'VALIDATOR_UUID.TITLE' | translate }}
      </div>
      <div class="col-lg-6 col-sm-6 col-xs-12">
        <div class="date">
          {{ 'VALIDATOR_UUID.DATE' | translate }}
        </div>
        <div class="date-text">
          {{ today | translateMomentDate }}
        </div>
      </div>
    </div>
  </div>
  <mat-card class="mat-elevation-z3">
    <div
      class="container-fluid validation-content"
      *ngIf="signature$ | async as signature"
    >
      <div class="row">
        <div class="col-lg-7 col-sm-6 col-xs-12">
          <div class="validation-label resize">
            {{ 'VALIDATOR_UUID.FORM.DOCUMENT_NAME' | translate }}
          </div>
          <div class="validation-text">
            {{ signature?.name }}
          </div>
        </div>
        <span class="separator-width"></span>
        <div class="col-lg-4 col-sm-6 col-xs-12">
          <div class="validation-label resize">
            {{ 'VALIDATOR_UUID.FORM.FOLIO' | translate }}
          </div>
          <div class="validation-text">
            {{ signature?.uuid }}
          </div>
        </div>
      </div>
      <span class="separator"></span>
      <div class="row">
        <div class="col-lg-3 col-sm-6 col-xs-12">
          <div class="validation-row">
            <div>
              <div class="validation-participants resize">
                {{ 'VALIDATOR_UUID.FORM.PARTICIPANTS' | translate }}
              </div>
              <div *ngIf="signature?.participants?.length > 0" class="limited">
                <div *ngFor="let participant of signature?.participants">
                  <div>
                    <span class="validation-text">{{ participant.name }}</span>
                    -
                    <span class="validation-types">{{
                      'VALIDATOR_UUID.FORM.TRADUCTIONS.PARTICIPANTS.' +
                        participant.role.toUpperCase() | translate
                    }}</span>
                  </div>
                  <div
                    *ngIf="
                      signature.signature_type === 'digital_signature' &&
                      participant.rfc !== null
                    "
                  >
                    <span class="validation-rfc">
                      {{ 'VALIDATOR_UUID.FORM.RFC' | translate }}</span
                    >
                    <span class="validation-value-rfc">{{
                      participant.rfc
                    }}</span>
                  </div>
                  <div
                    *ngIf="
                      signature.signature_type !== 'digital_signature' ||
                      (signature.signature_type === 'digital_signature' &&
                        participant.rfc === null)
                    "
                  >
                    <span class="validation-rfc">
                      {{ 'VALIDATOR_UUID.FORM.EMAIL' | translate }}</span
                    >
                    <span class="validation-value-rfc">{{
                      participant.email
                    }}</span>
                  </div>
                  <div class="pdd-12"></div>
                  <div class="separator-participants"></div>
                  <div class="pdd-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-xs-12">
          <div class="validation-row">
            <div>
              <div class="validation-label resize-types">
                {{ 'VALIDATOR_UUID.FORM.TYPES_OF_SIGNATURES' | translate }}
              </div>
              <div class="validation-text">
                {{
                  'VALIDATOR_UUID.FORM.TRADUCTIONS.TYPES_SIGNS.' +
                    signature?.signature_type.toUpperCase() | translate
                }}
              </div>
              <div class="separator-participants"></div>
              <div class="pdd-12"></div>
              <div class="validation-label">
                {{ 'VALIDATOR_UUID.FORM.DATE' | translate }}
              </div>
              <div class="validation-text">
                {{ signature?.created_at | date: 'dd-MM-yyyy hh:mm' }}
                {{ ' / ' }} {{ signature?.created_at }}
              </div>
              <div class="separator-participants"></div>
              <div class="pdd-12"></div>
              <div class="validation-label">
                {{ 'VALIDATOR_UUID.FORM.STATUS' | translate }}
              </div>
              <div class="validation-text">
                {{
                  'VALIDATOR_UUID.FORM.TRADUCTIONS.STATUS.' +
                    signature?.status.toUpperCase() | translate
                }}
              </div>
            </div>
          </div>
        </div>
        <span class="separator-tablet separator-width"></span>
        <div class="col-lg-5 col-sm-12 col-xs-12">
          <div class="row">
            <div class="validation-uuIDRelated resize">
              {{ 'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.TITLE' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12">
              <div
                *ngIf="signature?.related_documents?.length > 0"
                class="limited-relations row"
              >
                <div
                  *ngFor="let related of signature?.related_documents"
                  class="col-lg-12 col-sm-6"
                >
                  <div class="col-lg-12 col-sm-12">
                    <a
                      class="validation-text-related underline"
                      (click)="onVerification(related.uuid)"
                      >{{ related.name }}</a
                    >
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="row">
                      <div class="col-4">
                        <span class="validation-label-related">
                          {{
                            'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.INFO.CREATE_AT'
                              | translate
                          }}</span
                        >
                      </div>
                      <div class="col-5">
                        <span class="validation-label-related">
                          {{
                            'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.INFO.DOCUMENT_TYPE'
                              | translate
                          }}</span
                        >
                      </div>
                      <div class="col-3">
                        <span class="validation-label-related">
                          {{
                            'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.INFO.STATUS'
                              | translate
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="row">
                      <div class="col-4">
                        <span class="validation-text-related">{{
                          related.created_at | date: 'dd-MM-yyyy'
                        }}</span>
                      </div>
                      <div class="col-5">
                        <span class="validation-text-related capitalize">{{
                          'VALIDATOR_UUID.FORM.TRADUCTIONS.KINDS.' +
                            related?.kind.toUpperCase() | translate
                        }}</span>
                      </div>
                      <div class="col-3">
                        <span class="validation-text-related">
                          {{
                            'VALIDATOR_UUID.FORM.TRADUCTIONS.STATUS.' +
                              related?.status.toUpperCase() | translate
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="pdd-12"></div>
                  <div class="separator-participants"></div>
                  <div class="pdd-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="separator-tablet separator"></span>
    </div>
    <div *ngIf="signature$ | async as signatureType">
      <div *ngIf="signatureType.signature_type === 'digital_signature'">
        <literax-validator-signers></literax-validator-signers>
      </div>
      <div *ngIf="signatureType.signature_type !== 'digital_signature'">
        <literax-electronic-signers></literax-electronic-signers>
      </div>
    </div>
  </mat-card>
  <div class="container footer-content">
    <div class="footer-verification">
      {{ 'VALIDATOR_UUID.FOOTER.VERIFICATION_SIGN' | translate }}
    </div>
    <div class="footer-login">
      <a (click)="onLogin()" class="footer-login">
        {{ 'VALIDATOR_UUID.FOOTER.SIGNER' | translate }}
      </a>
    </div>
    <div class="footer-register">
      <a (click)="onRegister()" class="footer-register">{{
        'VALIDATOR_UUID.FOOTER.REGISTER' | translate
      }}</a>
    </div>
  </div>
</div>
