export const environment = {
  production: false,
  driveAppID: 10,
  apiVersion: 'v2',
  apiEndpoint: 'https://api.literaxstage.origon.app/api',
  sentryDsn:
    'https://58dbf101d8ee4bf0bc4da28f1558f798@o288616.ingest.sentry.io/5355101',
  domain: 'https://literaxstage.origon.cloud',
  guestApiEndpoint: 'https://api.literaxstage.origon.app',
  servicesDomain:'https://services.literaxstage.origon.app',
  paymentWidgetSource:
    'https://mediosdepagoqa.interfactura.com/script/paymentmanager-wd.js',
  paymentClientId: '79422d23-fcd7-40a2-8032-f7a9e930ca6b',
  paymentCustomerId: '',
  urlAnalytics: 'https://www.googletagmanager.com/gtag/js?id=',
  analyticsId: 'UA-174567452-4',
  widgetUrl:
    'https://gendocs.interfactura.com/DynamicForms.Web/Scripts/W3/gdd-widget.js',
  recordUrl: 'https://services.literaxstage.origon.app/fb/v1',
  onlyOfficeUrl:
    'https://texteditor.literaxqa.origon.app/web/js/webcomponent.onlyoffice.min.js',
  sendToMailSuggestions: 'buzonstaging@mailinator.com',
  chatUrl: 'https://front.literaxstage.origon.app/chat_element/lit-chat.min.js',
  driveUrl: 'https://front.literaxqa.origon.app/widget/lit-drive.min.js',
  literax: 'https://www.literax.com/',
  privacy: 'https://www.literax.com/aviso-de-privacidad/',
  terms: 'https://www.literax.com/terminos-y-condiciones/',
  b2cConfig: {
    clientId: '7d7f685b-6265-4630-a440-42ca1351e642',
    authority:
      'https://loginstage.origon.cloud/qaidp.origon.cloud/B2C_1A_signup_signin/',
    knownAuthorities: ['https://loginstage.origon.cloud'],
    scopes: [
      'https://qaidp.origon.cloud/7d7f685b-6265-4630-a440-42ca1351e642/basic',
      'openid',
      'offline_access'
    ]
  },
  literaxUrl: 'https://www.literax.com',
  onboarding365JoinQueryKey: 'azb2Data'
};
