import { IUserProfile, IUserProfileDashboard } from '../types/user-ptofiles';
import { createAction, props } from '@ngrx/store';

import { ServiceResponse } from '@literax/interfaces/service-response';

//#region CREATE
export const CreateUserProfile = createAction(
  '[User Profiles] CreateUserProfile',
  props<{ payload: IUserProfile }>()
);

export const CreateUserProfileSuccess = createAction(
  '[User Profiles] CreateUserProfileSuccess',
  props<{ payload: IUserProfile }>()
);

//#endregion CREATE

//#region READ
export const GetUsersProfiles = createAction('[User Profiles] GetUserProfiles');

export const GetUsersProfilesSuccess = createAction(
  '[User Profiles] GetUsersProfilesSuccess',
  props<{ payload: IUserProfile[] }>()
);

export const GetUsersProfilesDashboard = createAction(
  '[User Profiles] GetUsersProfilesDashboard'
);

export const GetUsersProfilesDashboardSuccess = createAction(
  '[User Profiles] GetUsersProfilesDashboardSuccess',
  props<{ payload: IUserProfileDashboard }>()
);

export const ShowUserProfile = createAction(
  '[User Profiles] ShowUserProfile',
  props<{ payload: { profile_id: number } }>()
);

export const ShowUserProfileSuccess = createAction(
  '[User Profiles] ShowUserProfileSuccess',
  props<{ payload: IUserProfile }>()
);

//#endregion READ

//#region UPDATE
export const EditUserProfile = createAction(
  '[User Profiles] EditUserProfile',
  props<{ payload: Partial<IUserProfile> }>()
);

export const EditUserProfileSuccess = createAction(
  '[User Profiles] EditUserProfileSuccess',
  props<{ payload: IUserProfile }>()
);

//#endregion UPDATE

//#region DELETE
export const DeleteUserProfile = createAction(
  '[User Profiles] DeleteUserProfile',
  props<{ payload: { profile_id: number } }>()
);

export const DeleteUserProfileSuccess = createAction(
  '[User Profiles] DeleteUserProfileSuccess',
  props<{ payload: IUserProfile | null }>()
);
//#endregion DELETE

//#region OTHERS
export const UsersProfileServiceError = createAction(
  '[User Profiles] UsersProfileServiceError',
  props<{ payload: ServiceResponse }>()
);

export const SetUserProfilesSortString = createAction(
  '[User Profiles] SetUserProfilesSortString',
  props<{ payload: string }>()
);
export const SetUserProfilesFilter = createAction(
  '[User Profiles] SetUserProfilesFilter',
  props<{ payload: string }>()
);
//#endregion

// #region Publish
export const SendToNextAction = createAction(
  '[User Profiles] Send to next action',
  props<{ payload: { profileId: number; action: string } }>()
);

export const SendToNextActionSuccess = createAction(
  '[User Profiles] Send to next action success'
);
//#endregion Publish

export const ClearState = createAction('[User Profiles] ClearState');

export const DownloadDocuments = createAction('[User Profiles] DownloadDocuments');

export const ChangeStatusProfile = createAction(
  '[User Profiles] ChangeStatusProfile',
  props<{ payload: { templateId: string; status: string } }>()
);

export const ChangeStatusProfileSuccess = createAction(
  '[User Profiles] ChangeStatusProfileSuccess',
  props<{ payload: IUserProfile }>()
);

export const ChangeStatusProfileError = createAction(
  '[User Profiles] ChangeStatusProfileError',
  props<{ payload: ServiceResponse }>()
);
