import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  ProtectedResourceScopes
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { MsalRewritedInterceptor } from './msal-rewrite.interceptor';

function loggerCallbackResolver(
  level: any,
  message: any,
  containsPii: boolean
) {
  if (containsPii) {
    return;
  }

  switch (level) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
}

function MSALInstanceFactory(): IPublicClientApplication {
  let devConfig = {};

  if (!environment.production) {
    devConfig = {
      system: { loggerOptions: { loggerCallback: loggerCallbackResolver } }
    };
  }

  return new PublicClientApplication({
    auth: {
      clientId: environment.b2cConfig.clientId,
      authority: environment.b2cConfig.authority,
      knownAuthorities: environment.b2cConfig.knownAuthorities,
      redirectUri: `${window.location.origin}`,
      postLogoutRedirectUri: '/platform/home'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false
    },
    ...devConfig
  });
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.b2cConfig.scopes,
      extraQueryParameters: {
        organization: `${localStorage.getItem('organization') || ''}`
      }
    },
    loginFailedRoute: '/auth/login-failed'
  };
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >([
    [
      `${environment.apiEndpoint}/${environment.apiVersion}/signature_validator`,
      [{ httpMethod: 'GET', scopes: null }]
    ],
    [
      `${environment.apiEndpoint}/${environment.apiVersion}/users`,
      [{ httpMethod: 'POST', scopes: null }]
    ],
    [
      `${environment.apiEndpoint}/${environment.apiVersion}/auth`,
      [{ httpMethod: 'POST', scopes: null }]
    ],
    [
      `${environment.apiEndpoint}/${environment.apiVersion}/two_factor_tokens`,
      [{ httpMethod: 'POST', scopes: null }]
    ],
    [
      `${environment.apiEndpoint}/${environment.apiVersion}/plans`,
      [
        { httpMethod: 'POST', scopes: null },
        { httpMethod: 'GET', scopes: null }
      ]
    ],
    [
      `${environment.apiEndpoint}/${environment.apiVersion}/`,
      environment.b2cConfig.scopes
    ]
  ]);

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      extraQueryParameters: {
        organization: `${localStorage.getItem('organization') || ''}`
      }
    },
    protectedResourceMap
  };
}

export const MSAL_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalRewritedInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalBroadcastService,
  MsalGuard
];
