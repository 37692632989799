<div
  *ngIf="
    !(flowKey === 'review' && !permissions?.show_digital_legal_collaboration)
  "
  class="card"
  [class]="isPagare?'pagare': ''"
>
  <div class="color-column" [ngStyle]="{ 'background-color': color }"></div>
  <div
    class="content-column"
    [class.active]="active"
    
  >
    <div class="name">
      {{ flowName }}
    </div>
    <div class="counter">{{ flowCount }}</div>
  </div>
</div>
