import { IProfile } from '@literax/components/configurations/profiles/models/profiles';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IClient } from '@literax/models/promissory-notes.model';
import { createAction, props } from '@ngrx/store';
import { IProfileUser } from '../../models/user.model';

export enum EAuthActions {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  LoginErrors = '[Auth] Login Errors',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  UpdateProfileInfo = '[Auth] Update Profile Info',
  SignUp = '[Auth] SignUp',
  SignUpSuccess = '[Auth] SignUp Success',
  ResetPassword = '[Auth] Reset Password',
  ResetPasswordSuccess = '[Auth] Reset Password Success',
  CurrentUserUpdateEmail = '[CurrentUser] Update Email',
  CurrentUserUpdateEmailSuccess = '[CurrentUser] Update Email Success',
  CurrentUserUpdateEmailError = '[CurrentUser] Update Email Error',
  CurrentUserUpdatePassword = '[CurrentUser] Update Password',
  CurrentUserUpdatePasswordSuccess = '[CurrentUser] Update Password Success',
  CurrentUserUpdatePasswordError = '[CurrentUser] Update Password Error',
  CurrentUserUpdateCertificate = '[CurrentUser] Update Certificate',
  CurrentUserUpdateCertificateSuccess = '[CurrentUser] Update Certificate Success',
  CurrentUserUpdateCertificateError = '[CurrentUser] Update Certificate Error',
  ClearProfilePasswordErrors = '[CurrentUser] Clear Profile Password Errors',
  ClearProfileErrors = '[CurrenUser] Clear Profile Errors',
  GetLoginInfo = '[Login] GetLoginInfo',
  GetLoginInfoSuccess = '[Login] GetLoginInfoSuccess',
  SetCurrentProfile = '[Login] SetCurrentProfile',
  SetCurrentClient = '[Login] SetCurrentClient',

  SetCurrentClientToken = '[Login] SetCurrentClientToken'
}

export const SetCurrentClientToken = createAction(
  EAuthActions.SetCurrentClientToken,
  props<{ payload: { token: string } }>()
);

export const SetCurrentProfile = createAction(
  EAuthActions.SetCurrentProfile,
  props<{ payload: IProfile }>()
);

export const Logout = createAction(EAuthActions.Logout);
export const LogoutSuccess = createAction(EAuthActions.LogoutSuccess);

export const CurrentUserUpdateEmail = createAction(
  EAuthActions.CurrentUserUpdateEmail,
  props<{ payload: IProfileUser }>()
);

export const CurrentUserUpdateEmailSuccess = createAction(
  EAuthActions.CurrentUserUpdateEmailSuccess,
  props<{ payload: IProfile }>()
);

export const UpdateProfileInfo = createAction(
  EAuthActions.UpdateProfileInfo,
  props<{ payload: IProfile }>()
);

export const CurrentUserUpdateEmailError = createAction(
  EAuthActions.CurrentUserUpdateEmailError,
  props<{ payload: IAPIResponseError }>()
);

export const CurrentUserUpdatePassword = createAction(
  EAuthActions.CurrentUserUpdatePassword,
  props<{ payload: IProfileUser }>()
);

export const CurrentUserUpdatePasswordError = createAction(
  EAuthActions.CurrentUserUpdatePasswordError,
  props<{ payload: IAPIResponseError }>()
);

export const CurrentUserUpdatePasswordSuccess = createAction(
  EAuthActions.CurrentUserUpdatePasswordSuccess,
  props<{ payload: IProfile }>()
);

export const CurrentUserUpdateCertificate = createAction(
  EAuthActions.CurrentUserUpdateCertificate,
  props<{ payload: IProfileUser }>()
);

export const CurrentUserUpdateCertificateError = createAction(
  EAuthActions.CurrentUserUpdateCertificateError,
  props<{ payload: IAPIResponseError }>()
);

export const CurrentUserUpdateCertificateSuccess = createAction(
  EAuthActions.CurrentUserUpdateCertificateSuccess,
  props<{ payload: IProfile }>()
);

export const ClearProfilePasswordErrors = createAction(
  EAuthActions.ClearProfilePasswordErrors
);

export const ClearProfileErrors = createAction(EAuthActions.ClearProfileErrors);

export const GetLoginInfo = createAction(EAuthActions.GetLoginInfo);

export const GetLoginInfoSuccess = createAction(
  EAuthActions.GetLoginInfoSuccess,
  props<{ payload: IProfile }>()
);

export const SetCurrentClient = createAction(
  EAuthActions.SetCurrentClient,
  props<{ payload: Partial<IClient> }>()
);
