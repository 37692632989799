import * as freeDocumentActions from '@literax/store/free-document/free-document.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  IFreeDocumentState,
  initialFreeDocumentState
} from './free-document.state';

import { IAttachment } from '@literax/models/http/attachment.model';

function resolveGetAttachmentSuccess(
  state: IFreeDocumentState,
  payload: IAttachment
) {
  if (!state.selectedDocument?.attachments) {
    return state;
  }

  const attachments = state.selectedDocument?.attachments.map(
    (attachment: IAttachment) => {
      return attachment?.id === payload?.id ? payload : attachment;
    }
  );

  const attachmentWithText: IAttachment = { ...payload, text: 'text' };

  return {
    ...state,
    selectAttachment: payload,
    attachments,
    selectedDocument: { ...state.selectedDocument, attachments },
    getAttachmentError: null
  };
}

function updateAttachments(
  attachments: IAttachment[],
  newAttachment: IAttachment
) {
 
  let localAttachmen = {...newAttachment};

  let fileText = attachments?.filter(
    (attach) => attach.id === localAttachmen.id
  )[0]?.text;

  localAttachmen.text = fileText;

  const newArrayAttachment = attachments?.filter(
    (attach) => attach.id !== localAttachmen.id
  ) || [];
  
  newArrayAttachment.push(localAttachmen);
  newArrayAttachment.sort((a: IAttachment, b: IAttachment) => a.id - b.id);
  return newArrayAttachment;
}

const freeDocument = createReducer(
  initialFreeDocumentState,
  on(freeDocumentActions.CleanSelectedDocumentState, (state, action) => ({
    ...state,
    selectedDocument: null
  })),
  on(
    freeDocumentActions.GetDocumentSuccess ||
      freeDocumentActions.UpdateLocalDocument,
    (state, action) => ({ ...state, selectedDocument: action.payload })
  ),
  on(freeDocumentActions.ValidateDocumentSignatureSuccess, (state, action) => ({
    ...state,
    selectedDocument: action.payload.document,
    token: action.payload.token
  })),
  on(freeDocumentActions.GetDocumentError, (state, action) => ({
    ...state,
    getDocumentError: action.payload
  })),
  on(freeDocumentActions.DeleteDocumentError, (state, action) => ({
    ...state,
    deleteDocumentError: action.payload
  })),
  on(freeDocumentActions.GetBeneficiarySuccess, (state, action) => ({
    ...state,
    holder: action.payload
  })),
  on(freeDocumentActions.GetCurrenciesSuccess, (state, action) => ({
    ...state,
    currencies: action.payload
  })),
  on(freeDocumentActions.GetDocumentAttachmentSuccess, (state, action) =>
    resolveGetAttachmentSuccess(state, action.payload)
  ),
  on(freeDocumentActions.ChangeAttachmentSelect, (state, action) => ({
    ...state,
    selectAttachment: action.payload
  })),
  on(freeDocumentActions.CheckAttachmentSuccess, (state, action) => {
    return {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        attachments: updateAttachments(
          state?.selectedDocument?.attachments,
          action.payload
        )
      }
    };
  }),
  on(
    freeDocumentActions.SetFreeDocumentAttachmentBase64Data,
    (state, action) => {
      const attachments: IAttachment[] = [...state.attachments].map(
        (attachment) => {
          if (attachment.id === action.payload.attachmentId) {
            return { ...attachment, text: action.payload.data };
          } else {
            return attachment;
          }
        }
      );

      const selectedAttachment = {
        ...state.selectAttachment,
        text: action.payload.data
      };
      return {
        ...state,
        selectedDocument: {
          ...state.selectedDocument,
          attachments: [...attachments]
        },
        attachments: [...attachments],
        selectAttachment: selectedAttachment
      };
    }
  ),
  on(
    freeDocumentActions.GetFreeDocumentCredentialsSuccess,
    (state, action) => ({
      ...state,
      onlyoffice_token: action.payload.onlyoffice
    })
  ),
  on(freeDocumentActions.GetCancellationReasonsSuccess, (state, action) => {
    return {
      ...state,
      commentRejected: action.payload
    };
  })
);

export function freeDocumentReducers(
  state: IFreeDocumentState,
  action: Action
) {
  return freeDocument(state, action);
}
