import { FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export class CustomFormValidator {
  // tslint:disable-next-line: max-line-length
  static readonly naturalPersonRFCRegExp = /^([A-ZÑ&a-z&]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;
  // tslint:disable-next-line: max-line-length
  static readonly legalPersonRFCRegExp = /^([A-ZÑ&a-z&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Za-z\d]{2})([A\d])$/;
  // tslint:disable-next-line: max-line-length
  static readonly documentNameRegExp = /^[a-zA-Z0-9ÑÀÈÌÒÙÄËÏÖÜÁÉÍÓÚñàèìòùäëïöüáéíóú\.\?\[\(\]\)\*\+¡¿,:}{=~@_-\s]*$/;

  static requiredFileType(...types: string[]) {
    const ext = types.map((i) => i.substring(1));
    return (control: AbstractControl) => {
      const file = control.value && control.value.file;
      if (file != null) {
        const fullFileName = file.name.split('.');
        const extension = fullFileName[fullFileName.length - 1].toLowerCase();
        const index = ext.findIndex((item) => extension === item.toLowerCase());
        if (index < 0) {
          return { requiredFileType: true, types: types.join(', ') };
        }
      }
      return null;
    };
  }

  static maxFileSize(maxSizeInMB: number) {
    return (control: AbstractControl) => {
      const file = control.value && control.value.file;
      if (file != null) {
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > maxSizeInMB) {
          return {
            maxFileSize: true,
            maxSizeInMB: maxSizeInMB.toFixed(0),
            fileSizeInMB: fileSizeInMB.toFixed(0)
          };
        }
        return null;
      }
      return null;
    };
  }

  static naturalPersonRFC(control: AbstractControl) {
    if (
      control.value &&
      !CustomFormValidator.naturalPersonRFCRegExp.test(control.value)
    ) {
      return { rfc: true };
    }
    return null;
  }

  static legalPersonRFC(control: AbstractControl) {
    if (
      control.value &&
      !CustomFormValidator.legalPersonRFCRegExp.test(control.value)
    ) {
      return { rfc: true };
    }
    return null;
  }

  static documentNameFormat(control: AbstractControl) {
    if (
      control.value &&
      !CustomFormValidator.documentNameRegExp.test(control.value)
    ) {
      return { nameFormat: true };
    }
    return null;
  }

  static expiryGTNow(ignoreHour: boolean = false) {
    return (control: AbstractControl) => {
      if (control.value && typeof control.value === 'string') {
        const now = ignoreHour ? moment().startOf('day') : new Date();

        const dateSplit = control.value?.trim()?.split('-');
        const controlDate = new Date(
          parseInt(dateSplit[2], 10),
          parseInt(dateSplit[1], 10) - 1,
          parseInt(dateSplit[0], 10)
        );
        if (now > controlDate) {
          return { expiryGTNow: true, expiry: controlDate };
        }
      }
      return null;
    };
  }

  static matchValues(
    matchTo: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { confirmedValidator: false };
    };
  }
}
