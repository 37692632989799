<div>
  <form [formGroup]="signatureForm" #f="ngForm" (ngSubmit)="onSubmit(f.value)">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h6 class="title title-font">
            {{ 'FORM.SIGNATURE.TITLE' | translate }}
          </h6>
        </div>
      </div>

      <div class="row">
        <div formGroupName="signature" style="width: 100%">
          <div class="col-12">
            <gdx-form-field label="{{ 'FORM.SIGNATURE.NAME' | translate }}">
              <input gdxInput formControlName="name" />
            </gdx-form-field>
            <literax-api-error
              [control]="signatureForm.get('signature.name')"
              [errors]="namesErrors$ | async"
            >
            </literax-api-error>
          </div>
          <div class="col-12">
            <gdx-form-field label="{{ 'FORM.SIGNATURE.EMAIL' | translate }}">
              <input gdxInput formControlName="email" />
            </gdx-form-field>
            <literax-api-error
              [control]="signatureForm.get('signature.email')"
              [errors]="emailsErros$ | async"
            >
            </literax-api-error>
          </div>
          <div class="col-12" *ngIf="identification">
            <literax-file-input
              #fileInput
              formControlName="id_image"
              (changeBase64)="changeFile($event)"
              [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
              [fileTypes]="permittedFiles"
              [labelTag]="'FORM.SIGNATURE.IDENTIFICATION' | translate"
              [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
              [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
            >
            </literax-file-input>
            <literax-api-error
              [control]="signatureForm.get('signature.id_image')"
              [errors]="imagesErrors$ | async"
            >
            </literax-api-error>
          </div>
          <div
            *ngFor="
              let company of sign_requests.controls as compa;
              let i = index
            "
            formArrayName="sign_requests"
            style="width: 100%"
          >
            <div
              *ngIf="company.get('company_name')?.value"
              [formGroupName]="i"
              class="business-info-container"
            >
              <div class="col-12">
                <gdx-form-field
                  [label]="'FORM.SIGNATURE.BUSINESS_NAME' | translate"
                >
                  <input gdxInput formControlName="company_name" />
                </gdx-form-field>
              </div>
              <div class="col-12">
                <gdx-form-field [label]="'FORM.SIGNATURE.ROL' | translate">
                  <input gdxInput formControlName="role" />
                </gdx-form-field>
              </div>
            </div>
          </div>

          <div class="col-12 confirmationCode" *ngIf="plusSignature">
            <gdx-form-field
              [label]="'FORM.SIGNATURE.AUTHENTICATION_CODE' | translate"
            >
              <input gdxInput formControlName="two_factor_token" />
            </gdx-form-field>
            <literax-api-error
              [control]="signatureForm.get('signature.two_factor_token')"
              [errors]="tokenErrors$ | async"
            >
            </literax-api-error>

            <span class="terminos title-font">
              {{ 'FORM.SIGNATURE.AUTENTHICATION_CODE_TEXT' | translate }}
            </span>
            <button
              gdx-button
              buttonStyle="primary"
              class="buttonConfirmationCode"
              (click)="twoFactorToken()"
            >
              {{ 'FORM.SIGNATURE.TWO_FACTOR_TOKEN_BUTTON' | translate }}
            </button>
          </div>
          <div class="col-12">
            <span class="terminos title-font">
              {{
                'FORM.SIGNATURE.TERMS_AND_CONDITIONS'
                  | translate: { name: nameDocument }
              }}
            </span>
            <br />
            <span class="nameSignature title-font mt-2">
              {{ 'FORM.SIGNATURE.DIGITAL_TRACE' | translate }}</span
            >
            <div class="signatureContainer">
              <literax-signature-canvas
                (signatureDone)="saveSignature($event)"
              ></literax-signature-canvas>
            </div>
          </div>
        </div>
        <!-- aqui termina el form group  -->
      </div>

      <div class="row buttons">
        <div class="col-6">
          <button gdx-button buttonStyle="link" (click)="close()">
            {{
              'CONFIGURATIONS_MODULE.USERS.LIST.MODAL_ADD.CANCEL' | translate
            }}
          </button>
        </div>
        <div class="col-6">
          <button
            gdx-button
            buttonStyle="primary"
            type="submit"
            [disabled]="!signatureForm.valid"
          >
            {{ 'VALIDATOR_UUID.FORM.TRADUCTIONS.STATUS.SIGN' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
