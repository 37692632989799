import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { CustomFormValidator } from '@literax/components/shared/form-lib/custom-form.validator';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IPaymentConf } from '@literax/models/http/onboarding/paymentConf.model';
import { Plan, Plans } from '@literax/models/http/onboarding/plan.model';
import { Recurrence } from '@literax/models/http/onboarding/recurrence.model';
import { LoadingService } from '@literax/services/loading/loading.service';
import { OnboardingService } from '@literax/services/onboarding/onboarding.service';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '@literax/store';
import {
  cleanRegistrationState,
  signUp,
  verify,
} from '@literax/store/registration/registration.actions';
import {
  selectSignUpErrors,
  selectSignUpSuccess,
  selectTaxIdError,
  selectUserError,
  selectVerifySuccess,
} from '@literax/store/registration/registration.selectors';
import { AppUtils } from '@literax/utils/app.utils';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'literax-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  form: FormGroup = new FormGroup({
    business_name: new FormControl(''),
    country: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    postal_code: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
    tax_id: new FormControl(''),
    tradename: new FormControl(''),
    user: new FormGroup({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      rfc: new FormControl('', [
        Validators.required,
        CustomFormValidator.naturalPersonRFC,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      password_confirmation: new FormControl(''),
    }),
    legal_person: new FormGroup({
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      rfc: new FormControl('', [CustomFormValidator.naturalPersonRFC]),
    }),
  });

  recurrence: FormGroup = new FormGroup({
    product_id: new FormControl('', [Validators.required]),
    reference: new FormControl(''),
    is_trial: new FormControl(''),
    unit: new FormControl(''),
    period: new FormControl(''),
    business_name: new FormControl(''),
    rfc: new FormControl(''),
    first_name: new FormControl(''),
    last_name: new FormControl(''),
  });

  countries = [];
  plans: Plan[];
  selectedCard: number;
  selectedProduct: string;
  defaultCardColor = '#eeeeee';
  selectedCardColor = '#1976d2';
  selectedCardColorPlanHeader = '#424242';
  selectedCardColorPlanDescription = '#212121';
  newTextColor = '#212121';
  newArrowGray = '#757575';
  recurrenceResponse: Recurrence;
  paymentSuccess: boolean;
  registrationSuccess = false;
  registrationError = false;
  verifySuccess = false;
  verifyError = false;
  legalPerson = false;
  is_trial = false;
  userError = [];
  taxIdError = [];
  passwordError = [];
  emailPattern = AppUtils.emailPattern;
  defaultCountry = 'México';
  isLoggedIn: boolean = false;
  b2cToken$: BehaviorSubject<string> = new BehaviorSubject('');
  domiciledName: string;
  dataFrom365Login: any;
  @ViewChild('registrationForm') formElement: ElementRef<HTMLFormElement>;
  @ViewChild('paymentWidget') paymentWidget: ElementRef<HTMLDivElement>;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private onboardingService: OnboardingService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: I18nToastrService,
    private store: Store<IAppState>,
    private loading: LoadingService,
    private renderer2: Renderer2,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private b2cAuthService: B2CAuthService,
    private msalService: MsalService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  loadPaymentWidgetScript() {
    if (!this._document.querySelector('#payment-widget-script')) {
      const script = this.renderer2.createElement('script');
      script.id = 'payment-widget-script';
      script.type = 'text/javascript';
      script.src = environment.paymentWidgetSource;
      script.text = '';
      this.renderer2.appendChild(this._document.body, script);
    }
  }

  ngOnInit() {
    this.translate
      .get('COUNTRIES')
      .pipe(untilDestroyed(this))
      .subscribe((countriesTranslated) => {
        if (Array.isArray(countriesTranslated)) {
          this.countries = countriesTranslated.map((country: string) => ({
            value: country,
            label: country,
          }));
        }
      });
    this.form.get('country').setValue(this.defaultCountry);
    this.loadPaymentWidgetScript();
    this.onboardingService.getPlans().subscribe((response: Plans) => {
      this.plans = response.plans.sort(function (a, b) {
        return a.precio - b.precio;
      });
    });

    this.store
      .pipe(untilDestroyed(this), select(selectSignUpSuccess))
      .subscribe((success: boolean) => {
        if (success) {
          this.registrationSuccess = true;
          this.msalService.logout();
        }
      });

    this.store
      .pipe(untilDestroyed(this), select(selectSignUpErrors))
      .subscribe((error: IAPIResponseError) => {
        if (error) {
          this.registrationError = true;
        }
      });

    this.store
      .pipe(untilDestroyed(this), select(selectVerifySuccess))
      .subscribe((success: boolean) => {
        if (success) {
          this.verifySuccess = true;
          this.stepper.next();
          this.store.dispatch(cleanRegistrationState());
        }
      });

    this.store
      .pipe(untilDestroyed(this), select(selectUserError))
      .subscribe((error: string[]) => {
        if (error) {
          this.userError = error;
          if (error.length === 2) {
            this.passwordError = [error[1]];
          }
          this.verifyError = true;
          window.scroll(0, 0);
          this.store.dispatch(cleanRegistrationState());
        }
      });

    this.store
      .pipe(untilDestroyed(this), select(selectTaxIdError))
      .subscribe((error: string[]) => {
        if (error) {
          this.taxIdError = error;
          this.verifyError = true;
          window.scroll(0, 0);
          this.store.dispatch(cleanRegistrationState());
        }
      });
    if (this.router.url.includes('active')) {
      this.isLoggedIn = this.b2cAuthService.isLoggedIn();
    }

    if (this.isLoggedIn) {
      this.cleanUserValidation();
      this.b2cAuthService
        .getToken()
        .pipe(untilDestroyed(this))
        .subscribe((token) => {
          this.b2cToken$.next(token);
        });
    }

    if (
      this.route.snapshot.queryParams.hasOwnProperty(
        environment.onboarding365JoinQueryKey
      )
    ) {
      this.dataFrom365Login = JSON.parse(
        atob(
          this.route.snapshot.queryParams[environment.onboarding365JoinQueryKey]
        )
      );
      this.form.get('user').get('email').setValue(this.dataFrom365Login?.email);
      this.form
        .get('user')
        .get('name')
        .setValue(this.dataFrom365Login?.firstname);
      this.form
        .get('user')
        .get('last_name')
        .setValue(this.dataFrom365Login?.lastname);
    }
  }

  cleanUserValidation() {
    const cleanControls: string[] = [
      'email',
      'password',
      'password_confirmation',
    ];
    for (const key in this.form.controls.user['controls']) {
      if (cleanControls.indexOf(key) > -1) {
        this.form.get('user').get(key).setErrors(null);
        this.form.get('user').get(key).clearValidators();
        this.form.get('user').get(key).updateValueAndValidity();
      }
    }
  }

  ngOnDestroy() {
    this.store.dispatch(cleanRegistrationState());
  }

  ngAfterViewChecked() {
    this.stepper._getIndicatorType = () => 'number';
    this.cdr.detectChanges();
  }

  togglePersonType(is_legal: boolean) {
    if (is_legal) {
      this.form.get('user').get('rfc').setErrors(null);
      this.form.get('user').get('rfc').clearValidators();
      this.form.get('user').get('rfc').updateValueAndValidity();
      this.form.get('user').get('name').setErrors(null);
      this.form.get('user').get('name').clearValidators();
      this.form.get('user').get('name').updateValueAndValidity();
      this.form.get('user').get('last_name').setErrors(null);
      this.form.get('user').get('last_name').clearValidators();
      this.form.get('user').get('last_name').updateValueAndValidity();
      this.form
        .get('tax_id')
        .setValidators([
          Validators.required,
          CustomFormValidator.legalPersonRFC,
        ]);
      this.form.get('tax_id').updateValueAndValidity();
      this.form.get('tradename').setValidators([Validators.required]);
      this.form.get('tradename').updateValueAndValidity();
      this.form.get('business_name').setValidators([Validators.required]);
      this.form.get('business_name').updateValueAndValidity();
    } else {
      this.form.get('tax_id').setErrors(null);
      this.form.get('tax_id').clearValidators();
      this.form.get('tax_id').updateValueAndValidity();
      this.form.get('tradename').setErrors(null);
      this.form.get('tradename').clearValidators();
      this.form.get('tradename').updateValueAndValidity();
      this.form.get('business_name').setErrors(null);
      this.form.get('business_name').clearValidators();
      this.form.get('business_name').updateValueAndValidity();
      this.form
        .get('user')
        .get('rfc')
        .setValidators([
          Validators.required,
          CustomFormValidator.naturalPersonRFC,
        ]);
      this.form.get('user').get('rfc').updateValueAndValidity();
      this.form.get('user').get('name').setValidators([Validators.required]);
      this.form.get('user').get('name').updateValueAndValidity();
      this.form
        .get('user')
        .get('last_name')
        .setValidators([Validators.required]);
      this.form.get('user').get('last_name').updateValueAndValidity();
    }

    this.legalPerson = is_legal;
  }

  setPaymentData(confPaymentData: IPaymentConf) {
    this.paymentWidget.nativeElement.setAttribute(
      'data-client-id',
      confPaymentData.clientId
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-client',
      confPaymentData.client
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-reference',
      confPaymentData.reference
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-amount',
      confPaymentData.amount
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-paymentProviders',
      confPaymentData.paymentProviders
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-domiciledName',
      confPaymentData.domiciledName
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-conceptId',
      confPaymentData.conceptId
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-concept',
      confPaymentData.concept
    );
    this.paymentWidget.nativeElement.setAttribute(
      'data-internal-currency',
      confPaymentData.currency
    );
    if (confPaymentData.customerId) {
      this.paymentWidget.nativeElement.setAttribute(
        'data-internal-accessToDomiciliation',
        confPaymentData.accessToDomiciliation
      );
      this.paymentWidget.nativeElement.setAttribute(
        'data-internal-forcedDomiciliation',
        confPaymentData.forcedDomiciliation
      );
      this.paymentWidget.nativeElement.setAttribute(
        'data-internal-customerId',
        confPaymentData.customerId
      );
    }
  }

  loadPaymentWidget() {
    if (window['navigate']) {
      window['navigate']();
      this.paymentWidget.nativeElement.addEventListener('onEvent', (e) => {
        this.paymentSuccess = true;
        this.toastr.success(
          'TRANSACTIONS.PAYMENT_WIDGET.SUCCESS_MSG',
          'TRANSACTIONS.PAYMENT_WIDGET.SUCCESS_TITLE'
        );
        setTimeout(() => {
          this.loading.hide();
          this.onRedirectTime();
        }, 600);
      });
      this.paymentWidget.nativeElement.addEventListener(
        'onEventError',
        (e) => {}
      );
      this.loading.hide();
    }
  }

  onRedirectTime() {
    if (this.paymentSuccess) {
      this.onRegistrationClicked();
      this.stepper.next();
    }
  }

  onCardClicked(index: number, product_id: string, is_trial) {
    this.selectedCard = index;
    this.selectedProduct = product_id;
    this.is_trial = is_trial;
    this.recurrence.get('product_id').setValue(product_id);
  }

  onPaymentClicked() {
    this.loading.show();
    if (this.recurrence.get('product_id').value) {
      if (this.legalPerson) {
        this.recurrence.get('rfc').setValue(this.form.get('tax_id').value);
        this.recurrence
          .get('business_name')
          .setValue(this.form.controls.business_name.value);
      } else {
        this.recurrence
          .get('rfc')
          .setValue(this.form.get('user').get('rfc').value);
        this.recurrence
          .get('first_name')
          .setValue(this.form.get('user').get('name').value);
        this.recurrence
          .get('last_name')
          .setValue(this.form.get('user').get('last_name').value);
        this.recurrence.removeControl('business_name');
      }

      if (this.recurrence.valid) {
        this.onboardingService.postRecurrence(this.recurrence.value).subscribe(
          (response: Recurrence) => {
            this.recurrenceResponse = response;
            if (this.is_trial) {
              this.loading.hide();
              this.onRegistrationClicked();
            } else {
              let infoPlan = this.plans.find(
                (plan) =>
                  plan.productoid === this.recurrence.get('product_id').value
              );
              let confPaymentData: IPaymentConf = {
                clientId: environment.paymentClientId,
                client: response.rfc,
                reference: response.reference,
                amount: response.amount,
                paymentProviders: 'VM',
                accessToDomiciliation: !!environment.paymentCustomerId + '',
                forcedDomiciliation: !!environment.paymentCustomerId + '',
                customerId: environment.paymentCustomerId,
                domiciledName: this.legalPerson
                  ? this.recurrence.get('business_name').value
                  : this.recurrence.get('first_name').value +
                    ' ' +
                    this.recurrence.get('last_name').value,
                conceptId: infoPlan.sku,
                concept: infoPlan.nombre,
                currency: 'MXN',
              };
              this.setPaymentData(confPaymentData);
              this.loadPaymentWidget();
            }
          },
          (err: any) => {
            this.loading.hide();
          }
        );
      }
    }
  }

  verifyInfo() {
    if (this.form.valid) {
      if (this.legalPerson) {
        this.form
          .get('first_name')
          .setValue(this.form.controls.business_name.value);
        this.form.get('last_name').setValue(this.form.controls.tradename.value);
        this.form
          .get('user')
          .get('name')
          .setValue(this.form.controls.business_name.value);
        this.form
          .get('user')
          .get('last_name')
          .setValue(this.form.controls.tradename.value);
      } else {
        this.form
          .get('tax_id')
          .setValue(this.form.get('user').get('rfc').value);
        this.form
          .get('first_name')
          .setValue(this.form.get('user').get('name').value);
        this.form
          .get('last_name')
          .setValue(this.form.get('user').get('last_name').value);
      }
      this.form
        .get('user')
        .get('password_confirmation')
        .setValue(this.form.get('user').get('password').value);
    }

    this.store.dispatch(
      verify({
        payload: {
          Client: this.form.value,
          token: this.b2cToken$.getValue(),
        },
      })
    );
  }

  onRegistrationClicked() {
    if (this.recurrenceResponse && this.form.valid && this.recurrence.valid) {
      if (this.legalPerson) {
        this.form
          .get('first_name')
          .setValue(this.form.controls.business_name.value);
        this.form.get('last_name').setValue(this.form.controls.tradename.value);
        this.form
          .get('user')
          .get('name')
          .setValue(this.form.controls.business_name.value);
        this.form
          .get('user')
          .get('last_name')
          .setValue(this.form.controls.tradename.value);
      } else {
        this.form
          .get('tax_id')
          .setValue(this.form.get('user').get('rfc').value);
        this.form
          .get('first_name')
          .setValue(this.form.get('user').get('name').value);
        this.form
          .get('last_name')
          .setValue(this.form.get('user').get('last_name').value);
      }

      this.recurrence
        .get('reference')
        .setValue(this.recurrenceResponse.reference);
      this.recurrence.get('unit').setValue(this.recurrenceResponse.unit);
      this.recurrence.get('period').setValue(this.recurrenceResponse.period);
      this.recurrence
        .get('is_trial')
        .setValue(this.recurrenceResponse.is_trial);
      this.form
        .get('user')
        .get('password_confirmation')
        .setValue(this.form.get('user').get('password').value);
      this.form.addControl('recurrence', this.recurrence);

      this.store.dispatch(
        signUp({
          payload: {
            Client: this.form.value,
            token: this.b2cToken$.getValue(),
          },
        })
      );
    }
  }

  onCancelClicked() {
    this.router.navigate(['auth/b2c-login']);
  }

  goToInterfirma(): void {
    window.location.href = environment.literaxUrl;
  }

  textTransform(event: Event, field: string) {
    const target = event.target as HTMLInputElement;
    if (target.value && target.value.length > 0 && target.value.trim()) {
      this.form.get(field).setValue(target.value.toUpperCase());
    }
  }
}
