import { IProcess } from '@literax/components/platform/profiles/types/user-ptofiles';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { Action, createReducer, on } from '@ngrx/store';
import { IProfile } from '../../profiles/models/profiles';
import { UserKindEnum } from '../enums/user-kind.enum';
import { IUser, IUserInfo, IUserKindCounts } from '../models/users.interface';
import * as usersActions from '../states/users.actions';

export interface ICLientUsersState {
  users: IUser[];
  userInfo: IUserInfo;
  userIdEdit: number;
  errorLogin: IAPIResponseError;
  errors: {};
  erroRegister: IAPIResponseError;
  currentPage: number;
  sortColumn: string;
  userKind: UserKindEnum;
  userKindCounts: IUserKindCounts;
  profiles: IProfile[];
  dashboard: IProcess;
}
export const initialClientUserState: ICLientUsersState = {
  users: [],
  userInfo: null,
  userIdEdit: null,
  errors: {},
  errorLogin: {
    error: {
      title: '',
      status: null,
      detail: '',
      code: null
    },
    success: null
  },
  erroRegister: {
    error: {
      title: '',
      status: null,
      detail: '',
      code: null
    },
    success: null
  },
  currentPage: 1,
  sortColumn: 'id:desc',
  userKind: null,
  userKindCounts: {
    responsible: 0,
    collaborator: 0
  },
  profiles: [],
  dashboard: null
};

const usersReducer = createReducer(
  initialClientUserState,
  on(usersActions.getAllUsersSucces, (state, action) => {
    const newUsers = [...state.users, ...action.client_users];
    const newPage =
      action.client_users.length > 0
        ? state.currentPage + 1
        : state.currentPage;
    return { ...state, users: newUsers, currentPage: newPage };
  }),
  on(usersActions.ResetPage, (state, action) => ({
    ...state,
    currentPage: 1,
    users: []
  })),
  on(usersActions.updateUsersbyId, (state, action) => ({
    ...state,
    userIdEdit: action.users.id
  })),
  on(usersActions.updateUsersbyIdSucess, (state, action) => ({
    ...state,
    currentPage: 1,
    users: []
  })),
  on(usersActions.saveUsersSucess, (state, action) => ({
    ...state,
    currentPage: 1,
    users: []
  })),
  on(usersActions.deleteUsersSuccess, (state, action) => ({
    ...state,
    currentPage: 1,
    users: []
  })),
  on(usersActions.errorConf, (state, action) => ({
    ...state,
    erroRegister: action.error,
    errors: action?.error?.error?.detail
  })),
  on(usersActions.errorloginConf, (state, action) => ({
    ...state,
    errorLogin: action.error
  })),
  on(usersActions.GetPermissionUserInfoSuccess, (state, action) => ({
    ...state,
    userInfo: action.payload
  })),
  on(usersActions.changeSortColumn, (state, action) => ({
    ...state,
    sortColumn: action.payload,
    currentPage: 1,
    users: []
  })),
  on(usersActions.changeUserKindFilter, (state, action) => ({
    ...state,
    userKind: action.payload,
    currentPage: 1,
    users: []
  })),
  on(usersActions.countUserKindSuccess, (state, action) => ({
    ...state,
    userKindCounts: action.payload
  })),
  on(usersActions.getUserProfilesSuccess, (state, action) => ({
    ...state,
    profiles: action?.payload?.profiles
  })),
  on(usersActions.getUsersDashboardSuccess, (state, action) => {
    const flows = [...action.payload.flows].map((flow) => {
      if (flow.flow_key === UserKindEnum.Responsible) {
        flow = { ...flow, color: '#dc3545' };
      }
      if (flow.flow_key === UserKindEnum.Collaborator) {
        flow = { ...flow, color: '#6c757d' };
      }
      return flow;
    });
    return {
      ...state,
      dashboard: { ...action.payload, flows }
    };
  })
);

export function clientUserReducer(state: ICLientUsersState, action: Action) {
  return usersReducer(state, action);
}
