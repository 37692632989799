import * as templatesActions from '@literax/store/templates/templates.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  ITemplatesState,
  initialTemplatesState
} from '@literax/store/templates/templates.state';

import { ETemplateFlow } from '@literax/enums/template.enum';

const templateReducer = createReducer(
  initialTemplatesState,
  on(templatesActions.indexTemplateSuccess, (state, action) => {
    const templates = [...state.templates, ...action.payload];
    const currentPage =
      action.payload.length > 0 ? state.currentPage + 1 : state.currentPage;
    return { ...state, templates, currentPage };
  }),
  on(templatesActions.CreateTemplateError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(templatesActions.resetPagination, (state, action) => ({
    ...state,
    templates: [],
    currentPage: 1,
    kind: ""
  })),

  on(templatesActions.has_templates_success, (state, action) => ({
    ...state,
    has_available_templates: action.payload
  })),

  on(templatesActions.getFlowsSuccess, (state, action) => ({
    ...state,
    flows: action.payload
  })),
  on(templatesActions.changeFlow, (state, action) => ({
    ...state,
    currentPage: 1,
    templates: [],
    selectedFlow: action.payload
  })),
  on(templatesActions.sortColumn, (state, action) => ({
    ...state,
    sortColumn: action.payload,
    templates: [],
    currentPage: 1
  })),
  on(templatesActions.getTemplateFieldsSuccess, (state, action) => {
    const templateFields = [...state.templateFields, ...action.payload];
    const templateFieldsCurrentPage =
      action.payload.length > 0
        ? state.templateFieldsCurrentPage + 1
        : state.templateFieldsCurrentPage;
    return { ...state, templateFields, templateFieldsCurrentPage };
  }),

  on(templatesActions.clearTemplateFields, (state, action) => ({
    ...state,
    templateFields: [],
    templateFieldsCurrentPage: 1
  })),

  on(templatesActions.searchTemplateFieldsSuccess, (state, action) => ({
    ...state,
    templateFields: action.payload
  })),
  on(templatesActions.showTemplate, (state, _) => ({
    ...state,
    templateUpdate: {}
  })),
  on(templatesActions.showTemplateSuccess, (state, action) => ({
    ...state,
    templateUpdate: {...action.payload},
    fieldsOnlyOffice: action.payload.tags,
    currentPage: 1,
    templates: []
  })),
  on(templatesActions.relationshipTemplateFieldsSuccess, (state, action) => ({
    ...state,
    templateUpdate: action.payload,
    fieldsOnlyOffice: action.payload?.tags
  })),
  on(templatesActions.IndexTemplateFieldsSuccess, (state, action) => ({
    ...state,
    fieldsOnlyOffice: action.payload
  })),
  on(templatesActions.latestSuccess, (state, action) => ({
    ...state,
    latest: action.payload
  })),
  on(templatesActions.changeDepartment, (state, action) => ({
    ...state,
    currentDepartment: action.payload
  })),
  on(templatesActions.changeKind, (state, action) => ({
    ...state,
    currentPage: 1,
    templates: [],
    kind: action.payload
  })),
  on(templatesActions.GetKindsSuccess, (state, action) => ({
    ...state,
    templateKinds: action.payload
  })),
  on(templatesActions.FindTemplateFieldsSuccess, (state, action) => ({
    ...state,
    fieldsOnlyOffice: action.payload
  })),
  on(templatesActions.MetadataTemplateProfileSuccess, (state, action) => {
    const MetadataTemplateProfileCurrentPage =
      action.payload.length > 0
        ? state.metadataTemplateProfilesCurrentPage + 1
        : state.metadataTemplateProfilesCurrentPage;
    return {
      ...state,
      metadataTemplateProfiles: action.payload,
      MetadataTemplateProfileCurrentPage
    };
  }),
  on(templatesActions.ClearMetadataTemplateProfile, (state, action) => ({
    ...state,
    metadataTemplateProfiles: [],
    metadataTemplateProfilesCurrentPage: 1
  })),
  on(templatesActions.GetProfilesSuccess, (state, action) => ({
    ...state,
    profiles: action.payload
  })),
  on(templatesActions.GetTemplateStadisticsSuccess, (state, action) => 
  ({
    ...state,
    templateFieldData: action.payload
  })
  ),
  on(templatesActions.GetCollectorsByTemplateSuccess, (state, action) => ({
    ...state,
    collectors: action.payload
  })),
  on(templatesActions.GetSignersByTemplateSuccess, (state, action) => ({
    ...state,
    signers: action.payload
  })),
  on(templatesActions.ApplyFilter, (state, action) => ({
    ...state,
    templateFieldData: null,
    templateFieldDataActiveFilter: action.filterParams,
    templateFieldDataCurrentPage: 1
  })),
  on(templatesActions.ChangeCurrentSort, (state, action) => ({
    ...state,
    templateFieldDataCurrentSort: action.columnSort,
    templateFieldData: null,
    templateFieldDataCurrentPage: 1
  })),
  on(templatesActions.CleanTemplateStadistics, (state, _) => ({
    ...state,
    templateFieldData: null,
    templateFieldDataCurrentPage: 1
  })),
  on(templatesActions.CreateTemplateSuccess, (state, action) => ({
    ...state,
    templateUpdate: action.payload.template,
  })),
  on(templatesActions.DeleteTemplate, (state, action) => ({
    ...state,
    currentPage: 1,
    templates: [],
    selectedFlow: ETemplateFlow.TEMPLATE_DRAFT
  }))
);

export function templatesReducer(state: ITemplatesState, action: Action) {
  return templateReducer(state, action);
}
