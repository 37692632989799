import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'literax-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss'],
})
export class ApiErrorComponent implements OnInit, OnDestroy {
  errorsArray: string[];
  firstError: string;
  changed = false;

  @Input() hasErrors: boolean;
  @Input() control: FormControl;
  @Input() set errors(errors: string[]) {
    this.changed = false;
    this.errorsArray = Array.isArray(errors) ? errors : null;
    this.firstError = Array.isArray(errors) ? errors[0] : null;
  }

  constructor() {}

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged())
      .subscribe(() => (this.changed = true));
  }

  ngOnDestroy(): void {}
}
