import { ColumnSort, TableHeaders } from '@literax/models/table.model';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'literax-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() headers: TableHeaders[] = [];
  @Input() rowsData: any[] = [];

  @Output() sortChange: EventEmitter<ColumnSort[]> = new EventEmitter();

  activeSortsCount = 0;
  activateScrollX: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.activateScrollX = this.headers.length > 8;
  }

  sortFn = (x, y) => x.sort.order - y.sort.order;

  // TODO: Mejorar código
  changeSort(event: MouseEvent, column: TableHeaders, index: number): void {
    if (column.sort) {
      // * ctrlKey: Windows(control) || metaKey: Mac(command)
      if (event.ctrlKey || event.metaKey) {
        if (column.sort && column.sort.active) {
          if (column.sort.direction === 'desc') {
            // Rearrange order field
            column.sort = {
              ...column.sort,
              active: false,
            };
            this.rearrangeOrder(this.headers);
          } else {
            column.sort = {
              ...column.sort,
              direction: 'desc',
            };
          }
        } else {
          column.sort = {
            columnName: column.sort.columnName,
            direction: 'asc',
            order: this.countSortedCols() + 1,
            active: true,
          };
        }
      } else {
        if (column.sort && column.sort.active) {
          this.cleanSort(index);
          if (column.sort.direction === 'asc') {
            column.sort = {
              ...column.sort,
              direction: 'desc',
            };
          } else {
            this.cleanSort();
            column.sort = {
              ...column.sort,
              direction: 'asc',
            };
          }
        } else {
          this.cleanSort();
          column.sort = {
            columnName: column.sort.columnName,
            direction: 'asc',
            order: this.countSortedCols() + 1,
            active: true,
          };
        }
      }

      const sorts = this.headers
        .filter((x) => x.sort && x.sort.active)
        .sort(this.sortFn)
        .map((x) => x.sort);

      this.activeSortsCount = sorts.length;

      this.sortChange.emit(sorts);
    }
  }

  rearrangeOrder(columns: TableHeaders[]): void {
    const tempArr = columns
      .filter((column) => column.sort && column.sort.active)
      .sort(this.sortFn);
    let index = 1;
    for (let i = 0; i < tempArr.length; i++) {
      if (i === 0 && tempArr[i].sort.order !== 1) {
        index = 1;
      }
      tempArr[i].sort = {
        ...tempArr[i].sort,
        order: index,
      };
      index++;
    }
  }

  countSortedCols(): number {
    return this.headers.filter((x) => x.sort && x.sort.active).length;
  }

  cleanSort(excludeIndex?: number): void {
    this.headers
      .filter((column, index) => column.sort && index !== excludeIndex)
      .forEach((columnFilter) => {
        columnFilter = {
          ...columnFilter,
          sort: { ...columnFilter.sort, active: false },
        };
      });
  }
}
