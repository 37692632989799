<section class="row" id="comment-section">
  <div class="row">
    <div class="comment">
      <div class="user">
        <div class="avatar"><img src="" alt="" />MV</div>
        <div class="author">
          <h4>Mariano Valenzuela</h4>
          <span>Hace una hora</span>
        </div>
      </div>
      <p>Este documento es importante, por favor dar seguimiento diario.</p>
    </div>
  </div>
  <div class="row">
    <div class="comment">
      <div class="user">
        <div class="avatar"><img src="" alt="" />LC</div>
        <div class="author">
          <h4>Leticia Cárdenas</h4>
          <span>Hace 1 minuto</span>
        </div>
      </div>
      <p>De acuerdo.</p>
    </div>
  </div>
  <div class="row">
    <div class="comment my-comment">
      <div class="user">
        <div class="avatar"><img src="" alt="" />DL</div>
        <div class="author">
          <h4>Tú</h4>
          <span>Hace 1 minuto</span>
        </div>
      </div>
      <p>Así lo haré.</p>
    </div>
  </div>
  <div class="row submit-comment">
    <textarea name="" id="" rows="3" placeholder="Comentarios"></textarea>
    <a type="submit" class="btn">
      <i class="material-icons"> send </i>
    </a>
  </div>
</section>
