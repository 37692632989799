import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAPISignRequest } from '@literax/models/http/api/sign-request/sign-request.model';
import { IUser } from '@literax/components/configurations/users/models/users.interface';
import { IAppState } from '@literax/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'literax-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.scss'],
})
export class AssignmentDialogComponent implements OnInit {
  assignment: IAPISignRequest = null;
  form: FormGroup;

  constructor(
    private store: Store<IAppState>,
    private dialogRef: MatDialogRef<AssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      client_user_id: new FormControl('', Validators.required),
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  toAssign(form: FormGroup) {
    if (form.valid) {
      this.data.acceptCallback(form.get('client_user_id').value);
      this.dialogRef.close();
    }
  }
}

export interface ConfirmDialogData {
  acceptCallback: (request: any) => void;
  cancelCallback: () => void;
  users: Observable<IUser[]>;
}
