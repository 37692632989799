import { Component, Input, OnInit } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { EButtonActions } from '@literax/components/shared/compose-attachment-file/enums/button-actions.enum';
import { IAppState } from '@literax/store';
import { IDialogData } from '@literax/components/shared/compose-attachment-file/models/dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { ModalAttachmentComponent } from '@literax/components/shared/compose-attachment-file/components/modal-attachment/modal-attachment.component';
import { Store } from '@ngrx/store';
import { createAttachment } from '@literax/store/attachment/attachment.actions';

@Component({
  selector: 'literax-compose-attachment-file',
  templateUrl: './compose-attachment-file.component.html',
  styleUrls: ['./compose-attachment-file.component.scss'],
})
export class ComposeAttachmentFileComponent implements OnInit {
  @Input()
  visibleButtons: EButtonActions[] = [];

  @Input()
  documentId: number = 0;

  @Input()
  title: string = '';

  EButtonActions = EButtonActions;

  dialogData: IDialogData;
  dialogWidth: string;

  constructor(
    public dialog: MatDialog,
    private store: Store<IAppState>,
    private actions$: Actions
  ) {}

  ngOnInit(): void {}

  buttonClicked(action: EButtonActions): void {
    switch (action) {
      case EButtonActions.TEMPLATE:
        this.templateAction();
        break;
      case EButtonActions.UPLOAD:
        this.uploadAction();
        break;
      case EButtonActions.WRITE:
        this.writeAction();
        break;
    }
  }

  templateAction(): void {}

  uploadAction(): void {
    this.dialogWidth = '450px';
    this.dialogData = {
      title: 'COMPOSE_ATTACHMENT_FILE.MODAL.NEW.UPLOAD',
      action: EButtonActions.UPLOAD,
      callback: (data): void => {
        this.store.dispatch(
          createAttachment({ documentId: this.documentId, data })
        );
      },
    };
    this.openDialog();
  }

  writeAction(): void {
    this.dialogWidth = '360px';
    this.dialogData = {
      title: 'COMPOSE_ATTACHMENT_FILE.MODAL.NEW.WRITE',
      action: EButtonActions.WRITE,
      callback: (data): void => {
        this.store.dispatch(
          createAttachment({ documentId: this.documentId, data })
        );
      },
    };
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalAttachmentComponent, {
      width: this.dialogWidth,
      disableClose: true,
      data: this.dialogData,
    });
  }
}
