<div
  class="title-header"
  (debounceClick)="onBackClicked()"
  *ngIf="document$ | async as document"
>
  <mat-icon>keyboard_arrow_left</mat-icon>
  <h1 class="title">{{ document?.name }}</h1>
</div>
<section id="document-modal" *ngIf="document$ | async as document">
  <div id="document-header">
    <div class="doc"></div>
    <div class="tab">
      <div>
        <mat-icon
          [svgIcon]="images[o]"
          [color]="blue"
          class="tablinks"
          [ngClass]="{ active: visibleInfo[i] }"
          *ngFor="let o of options; let i = index"
          (click)="activateInfoTab(i)"
        ></mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="visible[0]" [ngClass]="{ active: visible[0] }">
    <div id="document-main" class="preview-row">
      <div id="document-viewer">
        <button
          *ngIf="isXMLTemplate"
          type="button"
          class="toggle-xml"
          (click)="toggleXML()"
        >
          {{
            (showVisualRepresentation
              ? 'DOCUMENT-PREVIEW.XML.SHOW_XML_BUTTON'
              : 'DOCUMENT-PREVIEW.XML.SHOW_PDF_BUTTON'
            ) | translate
          }}
        </button>

        <object
          *ngIf="
            document?.attachments[0]?.file_path &&
            document?.attachments[0]?.kind === 'pdf'
          "
          [data]="documentData"
          type="application/pdf"
        >
          <span>{{ 'DOCUMENT-PREVIEW.PDF-LOADING-ERROR' | translate }}</span>
        </object>

        <div
          *ngIf="
            document?.attachments[0]?.plain_text &&
            document?.attachments[0]?.kind === 'md'
          "
          class="markdown-container"
        >
          <div class="markdown-document">
            <markdown
              class="markdown-text"
              [data]="document?.attachments[0]?.plain_text"
            ></markdown>
          </div>
        </div>

        <object
          *ngIf="isXMLTemplate && showVisualRepresentation"
          [data]="visualRepresentation"
          type="application/pdf"
        >
          <span>{{ 'DOCUMENT-PREVIEW.PDF-LOADING-ERROR' | translate }}</span>
        </object>

        <div
          *ngIf="
            document?.attachments[0]?.plain_text &&
            document?.attachments[0]?.kind === 'xml' &&
            !showVisualRepresentation
          "
          class="xml-code-container"
        >
          <div class="xml-code-document">
            <pre><code #xml>{{document?.attachments[0]?.plain_text | prettifyXML}}</code></pre>
          </div>
        </div>
      </div>
      <div id="options-tabs">
        <div
          class="tabcontent info-tab"
          *ngIf="visibleInfo[0]"
          [ngClass]="{ active: visibleInfo[0] }"
        >
          <h3>{{ 'DOCUMENT-PREVIEW.INFORMATION.TITLE' | translate }}</h3>
          <div>
            <div class="info-item">
              <span class="label">{{
                'DOCUMENT-PREVIEW.INFORMATION.FORM.DOCUMENT' | translate
              }}</span>
              <span class="info">{{ document.name }}</span>
            </div>
            <div class="info-item">
              <span class="label">{{
                'DOCUMENT-PREVIEW.INFORMATION.FORM.AUTHOR' | translate
              }}</span>
              <span class="info">{{ document.user.name }}</span>
            </div>
            <div class="info-item">
              <span class="label">{{
                'DOCUMENT-PREVIEW.INFORMATION.FORM.PUBLICATION-DATE' | translate
              }}</span>
              <span class="info">{{
                document.created_at | date: 'dd/MM/yyyy'
              }}</span>
            </div>
            <div class="info-item">
              <span class="label">{{
                'DOCUMENT-PREVIEW.INFORMATION.FORM.STATUS' | translate
              }}</span>
              <span class="info">{{
                document.status.name?.toUpperCase()
              }}</span>
            </div>
            <div
              *ngIf="document?.user_signer?.signature_type_name"
              class="info-item"
            >
              <span class="label">{{
                'DOCUMENT-PREVIEW.INFORMATION.FORM.SIGN-AS' | translate
              }}</span>
              <span class="info">
                {{
                  'API.SIGNATURE_TYPES.' +
                    document?.user_signer?.signature_type_name?.toUpperCase()
                    | translate
                }}
              </span>
            </div>
          </div>
          <ng-container
            *ngIf="
              !!(document$ | async)?.user_signer &&
              !(document$ | async)?.user_signer?.signed
            "
          >
            <h3>{{ 'DOCUMENT-PREVIEW.SIGN.TITLE' | translate }}</h3>
            <literax-sign-document
              [currentCertName]="(user$ | async)?.cert_name"
              [showCancel]="false"
              [document]="document$ | async"
            >
            </literax-sign-document>
          </ng-container>
        </div>

        <div
          class="tabcontent sign-tab"
          *ngIf="visibleInfo[1] && (user$ | async) as user"
          [ngClass]="{ active: visibleInfo[1] }"
        >
          <h3>{{ 'DOCUMENT-PREVIEW.SIGNERS.TITLE' | translate }}</h3>
          <div class="info">
            <p>
              <span *ngIf="document?.user_signer">{{
                'DOCUMENT-PREVIEW.SIGNERS.AUTHOR' | translate
              }}</span>
              {{
                'DOCUMENT-PREVIEW.SIGNERS.SIGNERS'
                  | translate: { signers: document?.sign_requests?.length || 0 }
              }}
            </p>
            <button
              id="add-signers"
              (debounceClick)="onAddSignerClicked()"
              [class.disabled]="
                (document.status.key !== 'uploaded' &&
                  document.status.key !== 'draft') ||
                document.user.id !== user.id
              "
              [disabled]="
                (document.status.key !== 'uploaded' &&
                  document.status.key !== 'draft') ||
                document.user.id !== user.id
              "
            >
              {{ 'DOCUMENT-PREVIEW.SIGNERS.EDIT' | translate }}
            </button>
          </div>

          <literax-signer-info
            *ngFor="let signer of signRequests$ | async"
            [signer]="signer"
            [signature]="signatures[signer.rfc]"
            [labelButton]="
              signer.rfc !== user.rfc
                ? ('DOCUMENT-PREVIEW.SIGNERS.RESEND' | translate)
                : ('DOCUMENT-PREVIEW.SIGNERS.SIGN' | translate)
            "
            (actionButton)="
              onSignerAction(
                $event.signer.rfc === user.rfc,
                $event.signer,
                document.id
              )
            "
          >
          </literax-signer-info>
        </div>
        <div
          class="tabcontent comments-tab"
          *ngIf="visibleInfo[2]"
          [ngClass]="{ active: visibleInfo[2] }"
        >
          <h3>{{ 'DOCUMENT-PREVIEW.COMMENTS.TITLE' | translate }}</h3>
          <comments-section></comments-section>
        </div>
        <div
          class="tabcontent send-tab"
          *ngIf="visibleInfo[3]"
          [ngClass]="{ active: visibleInfo[3] }"
        >
          <h3>{{ 'DOCUMENT-PREVIEW.CONTRACT.TITLE' | translate }}</h3>
          <div>
            <gdx-input
              label="{{ 'DOCUMENT-PREVIEW.CONTRACT.EMAIL' | translate }}"
              [control]="form.get('email')"
            >
            </gdx-input>
            <button
              gdx-button
              buttonStyle="primary"
              className="btn-blue"
              icon="send"
            >
              {{ 'DOCUMENT-PREVIEW.CONTRACT.SEND' | translate }}
            </button>
          </div>
        </div>
        <div class="preview-row">
          <div class="footer-modal">
            <!-- <button gdx-button class="border-radius" matRipple buttonStyle="link">
              <mat-icon>cloud_upload</mat-icon>
              {{'DOCUMENT-PREVIEW.FOOTER.UPLOAD' | translate}}
            </button> -->
            <button
              *ngIf="(document$ | async)?.user?.id === (user$ | async)?.id"
              gdx-button
              class="border-radius"
              matRipple
              buttonStyle="link"
              (debounceClick)="deleteDocumentDialog()"
            >
              <mat-icon>delete</mat-icon>
              {{ 'DOCUMENT-PREVIEW.FOOTER.DELETE' | translate }}
            </button>
            <button
              gdx-button
              class="border-radius"
              matRipple
              buttonStyle="link"
              (debounceClick)="downloadFile()"
            >
              <mat-icon>cloud_download</mat-icon>
              {{ 'DOCUMENT-PREVIEW.FOOTER.DOWNLOAD' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="document-signers"
    *ngIf="visible[1]"
    [ngClass]="{ active: visible[0] }"
  >
    <div class="preview-row"></div>
  </div>
</section>
