import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettifyXML'
})
export class PrettifyXML implements PipeTransform {
  formatXml(xml: string, tab = '\t') {
    if (xml === null || xml === undefined) {
      return '';
    }

    let formatted = '';
    let indent = '';

    const xmlSplitted = xml.split(/>\s*</);

    xmlSplitted.forEach((node: string) => {
      if (node.match(/^\/\w/)) {
        indent = indent.substring(tab.length);
      }

      formatted += indent + '<' + node + '>\r\n';

      if (node.match(/^<?\w[^>]*[^\/]$/)) {
        indent += tab;
      }
    });

    return formatted.substring(1, formatted.length - 3);
  }

  transform(sourceXml: string): string {
    return this.formatXml(sourceXml, '  ');
  }
}
