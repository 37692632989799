import * as promissoryNotesActions from '@literax/store/promissory-notes/promissory-notes.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  initialPromissoryNotesState,
  IPromissoryNotesState
} from './promissory-notes.state';

export const initialState = initialPromissoryNotesState;
const promisoryNotesReducers = createReducer(
  initialState,
  on(promissoryNotesActions.GetDataConfig, (state, action) => ({
    ...state,
    configData: null,
    promissoryNotes: null
  })),
  on(promissoryNotesActions.GetDataConfigSuccess, (state, action) => ({
    ...state,
    configData: action.payload
  })),
  on(
    promissoryNotesActions.has_promissory_templates_success,
    (state, action) => ({
      ...state,
      has_promissory_note_templates: action.payload
    })
  ),
  on(promissoryNotesActions.GetDataConfigError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.GetDataConfigEndoserment, (state, action) => ({
    ...state,
    configData: null,
    promissoryNotes: null
  })),
  on(
    promissoryNotesActions.GetDataConfigEndosermentSuccess,
    (state, action) => ({ ...state, configData: action.payload })
  ),
  on(promissoryNotesActions.GetDataConfigEndosermentError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.SavePromissoryNotesError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.updatePromissoryNotesError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.GetPromissoryNoteSuccess, (state, action) => ({
    ...state,
    selectPromissoryNote: action.payload
  })),
  on(promissoryNotesActions.GetPromissoryNoteError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.getPromissoryView, (state, action) => ({
    ...state,
    promissoryView: action.payload
  })),
  on(promissoryNotesActions.ResetPage, (state, action) => ({
    ...state,
    currentPage: 1,
    promissoryNotes: []
  })),
  on(promissoryNotesActions.getPromissoryFlowsSuccess, (state, action) => ({
    ...state,
    flows: action.payload
  })),
  on(promissoryNotesActions.getRolesSuccess, (state, action) => ({
    ...state,
    roles: action.payload
  })),
  on(promissoryNotesActions.GetCurrencySuccess, (state, action) => ({
    ...state,
    currencys: action.payload
  })),
  on(
    promissoryNotesActions.GetAllPromissoryNotesScrollSuccess,
    (state, action) => {
      const newPromissoryNotes = [...state.promissoryNotes, ...action.payload];
      const newPage =
        action.payload.length > 0 ? state.currentPage + 1 : state.currentPage;
      return {
        ...state,
        promissoryNotes: newPromissoryNotes,
        currentPage: newPage
      };
    }
  ),
  on(promissoryNotesActions.GetCurrencyError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.GetPaymentsSuccess, (state, action) => ({
    ...state,
    payments: action.payload
  })),
  on(promissoryNotesActions.ShowPaymentSuccess, (state, action) => ({
    ...state,
    selectPayment: action.payload
  })),
  on(promissoryNotesActions.ClearSelectPayment, (state, action) => ({
    ...state,
    selectPayment: null
  })),
  on(promissoryNotesActions.ChangeSort, (state, action) => ({
    ...state,
    sortColumn: action.sortColumn,
    promissoryNotes: [],
    currentPage: 1
  })),
  on(
    promissoryNotesActions.GetPromissoryNoteTemplatesSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteTemplates: action.payload
    })
  ),
  on(promissoryNotesActions.ResetPromissoryNoteTemplate, (state, action) => ({
    ...state,
    promissoryNoteTemplates: []
  })),
  on(
    promissoryNotesActions.SavePromissoryNoteTemplateSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteTemplateSelect: action.payload
    })
  ),
  on(
    promissoryNotesActions.GetPromissoryNoteTemplateShowSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteTemplateSelect: action.payload
    })
  ),
  on(
    promissoryNotesActions.GetPromissoryNoteTemplateShowReload,
    (state, action) => ({
      ...state,
      promissoryNoteTemplateSelect: null
    })
  ),
  on(
    promissoryNotesActions.CreatePromissoryNoteWithTemplateSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteWithData: action.payload
    })
  ),
  on(promissoryNotesActions.SetTemplateId, (state, action) => ({
    ...state,
    templateId: action.payload
  })),
  on(promissoryNotesActions.ClearTemplateId, (state, action) => ({
    ...state,
    templateId: 0
  })),
  on(promissoryNotesActions.ClearConfigData, (state, action) => ({
    ...state,
    configData: null,
    promissoryNotes: null
  }))
);
export function promisoryNotesReducer(
  state: IPromissoryNotesState,
  action: Action
) {
  return promisoryNotesReducers(state, action);
}
