import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() searchLabel = 'search';
  @Input() debounceTime = 200;
  @Input() formControl: FormControl = new FormControl('');
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Input() set readonly(disabled: boolean) {
    this.formControl.setValue('');
    this.readOnly = disabled;
  }
  divActive = false;
  readOnly = false;

  constructor() {}

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(this.debounceTime))
      .subscribe((event) => this.valueChanged.emit(event));
  }

  ngOnDestroy() {}

  toggleActive() {
    this.divActive = !this.divActive;
  }
}
