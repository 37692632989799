import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';

import { EButtonActions } from '@literax/components/shared/compose-attachment-file/enums/button-actions.enum';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDialogData } from '@literax/components/shared/compose-attachment-file/models/dialog-data';
import { IDocument } from '@literax/models/http/document/document.model';
import { IUser } from '@literax/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ModalAttachmentComponent } from '@literax/components/shared/compose-attachment-file/components/modal-attachment/modal-attachment.component';
import { SetLastUploadedAttachment } from '@literax/store/document/document.actions';
import { selectLastAttachmentUploaded } from '@literax/store/attachment/attachment.selectors';
import { updateAttachment } from '@literax/store/attachment/attachment.actions';

@Component({
  selector: 'literax-document-attachments-container',
  templateUrl: './document-attachments-container.component.html',
  styleUrls: ['./document-attachments-container.component.scss'],
})
export class DocumentAttachmentsContainerComponent
  implements OnInit, OnChanges
{
  @Input() authUser: IUser;
  @Input() document: IDocument;
  @Input() documentsSecondary: IDocument[];
  @Input() mainDocument: IDocument;
  @Input() typePromissoryNote: boolean;
  @Output() attachmentSelected: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() checkAttachmentId: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() editAttachment = new EventEmitter();
  contentVisible = true;
  showAttachmentAlert = false;

  buttonsAttachment: EButtonActions[] = [
    EButtonActions.UPLOAD,
    EButtonActions.WRITE,
  ];

  dialogData: IDialogData;
  dialogWidth: string;

  constructor(public dialog: MatDialog, private store: Store<IAppState>) {}
  ngOnInit(): void {
    this.setShowAttachmetAlert();
    this.store
      .pipe(select(selectLastAttachmentUploaded))
      .subscribe((attachment) => {
        if (
          attachment &&
          attachment.file_docx_url === null &&
          attachment.kind === 'docx'
        ) {
          this.store.dispatch(
            SetLastUploadedAttachment({ payload: attachment })
          );
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.authUser || changes.document) {
      this.setShowAttachmetAlert();
    }
  }

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }

  private setShowAttachmetAlert() {
    this.showAttachmentAlert =
      (this.document?.attachments?.length > 1 &&
        this.document?.status.key === 'in_process' &&
        this.document.transaction_type !== 'request' &&
        this.document?.sign_requests.find(
          (x) => x.email === this.authUser?.email
        ) !== undefined) ||
      (this.mainDocument?.attachments?.length > 1 &&
        this.mainDocument?.status.key === 'in_process');
  }

  replaceAttachment(attachment: IAttachment): void {
    if (attachment?.kind === 'pdf') {
      this.uploadAction(
        attachment.id,
        attachment?.kind,
        attachment?.name,
        attachment?.primary
      );
    } else {
      this.writeAction(attachment.id, attachment?.kind);
    }
  }

  uploadAction(
    id: number,
    kind: string,
    name?: string,
    primary?: boolean
  ): void {
    this.dialogWidth = '450px';
    this.dialogData = {
      title: primary
        ? 'COMPOSE_ATTACHMENT_FILE.MODAL.EDIT.UPLOAD_PRIMARY'
        : 'COMPOSE_ATTACHMENT_FILE.MODAL.EDIT.UPLOAD',
      action: EButtonActions.UPLOAD,
      attachmentId: id,
      documentId: this.document.id,
      kind,
      name,
      callback: (data, documentId, attachmentId): void => {
        this.store.dispatch(
          updateAttachment({ documentId, attachmentId, data })
        );
      },
    };
    this.openDialog();
  }

  writeAction(id: number, kind: string): void {
    this.dialogWidth = '360px';
    this.dialogData = {
      title: 'COMPOSE_ATTACHMENT_FILE.MODAL.EDIT.WRITE',
      action: EButtonActions.WRITE,
      attachmentId: id,
      documentId: this.document.id,
      callback: (data, documentId, attachmentId): void => {
        this.store.dispatch(
          updateAttachment({ documentId, attachmentId, data })
        );
      },
    };
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalAttachmentComponent, {
      width: this.dialogWidth,
      disableClose: true,
      data: this.dialogData,
    });
  }
}
