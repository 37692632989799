import { IAPIError, IAPIResponseError } from '@literax/models/http/api/error.model';
import {
  IHolder,
  PromissoryNotePayment
} from '@literax/models/promissory-notes.model';
import { createAction, props } from '@ngrx/store';

import { IAttachment } from '@literax/models/http/attachment.model';
import { ICreateDocumentResponse } from '@literax/models/http/document/create-document.model';
import { ICredentials } from '@literax/models/document-signature';
import { IDocument, ICancelatioReason } from '@literax/models/http/document/document.model';

export enum EFreeDocumentActions {
  GetDocumentSuccess = '[Free Document] GetDocumentSuccess',
  GetDocumentError = '[Free Document] GetDocumentError',
  GetDocumentAttachment = '[Free Document] GetDocumentAttachment',
  GetDocumentAttachmentSuccess = '[Free Document] GetDocumentAttachmentSuccess',
  GetDocumentAttachmentError = '[Free Document] GetDocumentAttachmentError',
  UpdateFreeDocument = '[Free Document] Refresh Document',
  CleanSelectedDocumentState = '[Free Document] Clean Selected Document State',
  DeleteDocument = '[Free Document] Delete Document',
  DeleteDocumentSuccess = '[Free Document] Delete Document Success',
  DeleteDocumentError = '[Free Document] Delete Document Error',
  GetFreeDocument = '[Free Document] GetDocument',
  fetDocumentError = '[Free Document] GetDocumentError',
  UpdateLocalDocument = '[Free Document] UpdateLocalDocument',
  GetBeneficiary = '[Free Document] GetBeneficiary',
  GetBeneficiarySuccess = '[Free Document] GetBeneficiarySuccess',
  GetCurrencies = '[Free Document] GetCurrencies',
  GetCurrenciesSuccess = '[Free Document] GetCurrenciesSuccess',
  RegisterPaymentFree = '[Free Document] RegisterPayment',
  RegisterPaymentFreeSuccess = '[Free Document] RegisterPaymentSuccess',
  ValidateDocumentSignature = '[Free Document] ValidateDocumentSignature ',
  ValidateDocumentSignatureSuccess = '[Free Document] ValidateDocumentSignatureSuccess',
  ValidateDocumentSignatureError = '[Free Document] ValidateDocumentSignatureError',
  ChangeStatusTemplate = '[Free Document] Change Status Template',
  ChangeStatusTemplateSuccess = '[Free Document] Change Status Template Success',
  ChangeStatusReviewer = '[Free Document] change status reviewer',

  DocumentCancelProcess = '[Document] Document process cancel',
  DocumentCancelProcessSuccess = '[Document] Document process cancel success',
  DocumentCancelProcessError = '[Document] Document process cancel error',

  CheckAttachment = '[Attachment] CheckAttachment',
  CheckAttachmentSuccess = '[Attachment] CheckAttachmentSuccess',
  CheckAttachmentError = '[Attachment] CheckAttachmentError',

  ChangeAttachmentSelect = '[Free Document] Change select attachment',
  SetFreeDocumentAttachmentBase64Data = '[Free Document] SetFreeDocumentAttachmentBase64Data',

  GetFreeDocumentCredentials = '[Free Document] GetFreeDocumentCredentials',
  GetFreeDocumentCredentialsSuccess = '[Free Document] GetFreeDocumentCredentialsSuccess',
  GetFreeDocumentCredentialsError = '[Free Document] GetFreeDocumentCredentialsError',
  ClearFreeDocumentCredentials = '[Free Document] ClearFreeDocumentCredentials',

  GetCancellationReasons = '[Free Document] Get cancellation reasons',
  GetCancellationReasonsSuccess = '[Free Document] Get cancellation reasons success',
  GetCancellationReasonsError = '[Free Document] Get cancellation reasons error'
}

//#region Free Document Credendials
export const GetFreeDocumentCredentials = createAction(
  EFreeDocumentActions.GetFreeDocumentCredentials,
  props<{ token: string }>()
);

export const GetFreeDocumentCredentialsSuccess = createAction(
  EFreeDocumentActions.GetFreeDocumentCredentialsSuccess,
  props<{ payload: ICredentials }>()
);

export const GetFreeDocumentCredentialsError = createAction(
  EFreeDocumentActions.GetFreeDocumentCredentialsError,
  props<{ error: IAPIError }>()
);

export const ClearFreeDocumentCredentials = createAction(
  EFreeDocumentActions.ClearFreeDocumentCredentials
);
//#endregion Free Document Credendials

export const SetFreeDocumentAttachmentBase64Data = createAction(
  EFreeDocumentActions.SetFreeDocumentAttachmentBase64Data,
  props<{ payload: { attachmentId: number; data: string } }>()
);

export interface IGetDocumentAttachment {
  documentId: number;
  attachmentId: number;
  token: string;
}

export const GetBeneficiary = createAction(
  EFreeDocumentActions.GetBeneficiary,
  props<{ payload: { token: string } }>()
);

export const GetBeneficiarySuccess = createAction(
  EFreeDocumentActions.GetBeneficiarySuccess,
  props<{ payload: IHolder }>()
);

export const GetCurrencies = createAction(
  EFreeDocumentActions.GetCurrencies,
  props<{ payload: { token: string } }>()
);

export const GetCurrenciesSuccess = createAction(
  EFreeDocumentActions.GetCurrenciesSuccess,
  props<{ payload: [] }>()
);

export const RegisterPaymentFree = createAction(
  EFreeDocumentActions.RegisterPaymentFree,
  props<{
    payload: { promissoryNotePayment: PromissoryNotePayment; token: string };
  }>()
);

export const RegisterPaymentFreeSuccess = createAction(
  EFreeDocumentActions.RegisterPaymentFreeSuccess,
  props<{ payload: PromissoryNotePayment }>()
);

export const GetFreeDocument = createAction(
  EFreeDocumentActions.GetFreeDocument
);

export const GetDocumentAttachment = createAction(
  EFreeDocumentActions.GetDocumentAttachment,
  props<{ payload: IGetDocumentAttachment }>()
);

export const GetDocumentAttachmentSuccess = createAction(
  EFreeDocumentActions.GetDocumentAttachmentSuccess,
  props<{ payload: IAttachment }>()
);

export const GetDocumentAttachmentError = createAction(
  EFreeDocumentActions.GetDocumentAttachmentError,
  props<{ payload: IAPIResponseError }>()
);

export const GetDocumentSuccess = createAction(
  EFreeDocumentActions.GetDocumentSuccess,
  props<{ payload: IDocument }>()
);

export const GetDocumentError = createAction(
  EFreeDocumentActions.GetDocumentError,
  props<{ payload: IAPIResponseError }>()
);

export const CleanSelectedDocumentState = createAction(
  EFreeDocumentActions.CleanSelectedDocumentState
);

export const UpdateFreeDocument = createAction(
  EFreeDocumentActions.UpdateFreeDocument,
  props<{ payload: string }>()
);

export const DeleteDocument = createAction(
  EFreeDocumentActions.DeleteDocument,
  props<{ payload: { documentId: string } }>()
);

export const DeleteDocumentSuccess = createAction(
  EFreeDocumentActions.DeleteDocumentSuccess
);

export const DeleteDocumentError = createAction(
  EFreeDocumentActions.DeleteDocumentError,
  props<{ payload: IAPIError }>()
);

export const UpdateLocalDocument = createAction(
  EFreeDocumentActions.UpdateLocalDocument,
  props<{ payload: ICreateDocumentResponse }>()
);

export const ValidateDocumentSignature = createAction(
  EFreeDocumentActions.ValidateDocumentSignature,
  props<{ payload: string }>()
);

export const ValidateDocumentSignatureError = createAction(
  EFreeDocumentActions.ValidateDocumentSignatureError,
  props<{ payload: IAPIResponseError }>()
);

export const ValidateDocumentSignatureSuccess = createAction(
  EFreeDocumentActions.ValidateDocumentSignatureSuccess,
  props<{ payload: { document: IDocument; token: string } }>()
);

export const changeStatusTemplate = createAction(
  EFreeDocumentActions.ChangeStatusTemplate,
  props<{ payload: { state: string; token: string } }>()
);

export const changeStatusReviewer = createAction(
  EFreeDocumentActions.ChangeStatusReviewer,
  props<{ payload: { state: string; token: string } }>()
);

export const changeStatusTemplateSuccess = createAction(
  EFreeDocumentActions.ChangeStatusTemplateSuccess
);

export const DocumentCancelProcess = createAction(
  EFreeDocumentActions.DocumentCancelProcess,
  props<{ payload: { comment: string; token: string } }>()
);

export const DocumentCancelProcessSuccess = createAction(
  EFreeDocumentActions.DocumentCancelProcessSuccess
);

export const DocumentCancelProcessError = createAction(
  EFreeDocumentActions.DocumentCancelProcessError
);

export const CheckAttachment = createAction(
  EFreeDocumentActions.CheckAttachment,
  props<{
    payload: {
      documentId: number;
      attachmentId: number;
      token: string;
    };
  }>()
);

export const CheckAttachmentSuccess = createAction(
  EFreeDocumentActions.CheckAttachmentSuccess,
  props<{ payload: IAttachment }>()
);

export const CheckAttachmentError = createAction(
  EFreeDocumentActions.CheckAttachmentError
);

export const ChangeAttachmentSelect = createAction(
  EFreeDocumentActions.ChangeAttachmentSelect,
  props<{ payload: IAttachment }>()
);

export const GetCancellationReasons = createAction(
  EFreeDocumentActions.GetCancellationReasons,
  props<{ payload: { documentId: number; token: string }; }>()
);

export const GetCancellationReasonsSuccess = createAction(
  EFreeDocumentActions.GetCancellationReasonsSuccess,
  props<{ payload: ICancelatioReason[] }>()
);

export const GetCancellationReasonsError = createAction(
  EFreeDocumentActions.GetCancellationReasonsError
);
