import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'literax-registration-form-dialog',
  templateUrl: './registration-form-dialog.component.html',
  styleUrls: ['./registration-form-dialog.component.scss'],
})
export class RegistrationFormDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RegistrationFormDialogComponent>,
    private router: Router
  ) {}

  ngOnInit() {}

  onCloseClick() {
    this.dialogRef.close();
    this.router.navigate(['/']);
  }
}
