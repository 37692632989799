<div class="compose-attachment-file">
  <div class="title">
    <span>
      {{title | translate}}
    </span>
  </div>
  <div class="buttons">
    <button
      *ngIf='visibleButtons.includes(EButtonActions.WRITE)'
      class="button button--primary"
      (click)="buttonClicked(EButtonActions.WRITE)"
    >
      <i class="fal fa-file-edit"></i> {{'COMPOSE_ATTACHMENT_FILE.BUTTONS.WRITE' | translate}}
    </button>

    <button
      *ngIf='visibleButtons.includes(EButtonActions.TEMPLATE)'
      class="button button--primary"
      (click)="buttonClicked(EButtonActions.TEMPLATE)"
    >
      <i class="fal fa-file-spreadsheet"></i> {{'COMPOSE_ATTACHMENT_FILE.BUTTONS.TEMPLATE' | translate}}
    </button>

    <button
      *ngIf='visibleButtons.includes(EButtonActions.UPLOAD)'
      class="button button--primary"
      (click)="buttonClicked(EButtonActions.UPLOAD)"
    >
      <i class="fal fa-file-upload"></i> {{'COMPOSE_ATTACHMENT_FILE.BUTTONS.UPLOAD' | translate}}
    </button>

  </div>
</div>