<div>
  <div class="top-divider"></div>
  <div class="container-title">
    <span class="title">{{ 'VALIDATOR_UUID.E404.TITLE' | translate }}</span>
  </div>

  <form class="mat-elevation-z6 mat-modal">
    <div class="finish-content">
      <div class="finish-icon"><i class="fal fa-times-circle"></i></div>
      <span class="finish-title">{{
        'VALIDATOR_UUID.E404.MESSAGE_DENIED_TITLE' | translate
      }}</span>
      <span class="finish-message">{{
        'VALIDATOR_UUID.E404.MESSAGE_DENIED' | translate
      }}</span>
      <div class="finish-home">
        <h4 class="finish-invitation">
          {{ 'VALIDATOR_UUID.E404.MESSAGE_INVITATION' | translate }}
        </h4>
        <button
          gdx-button
          class="finish-button"
          buttonStyle="primary"
          class="primary"
          (click)="onBack()"
        >
          {{ 'VALIDATOR_UUID.E404.MESSAGE_BUTTON' | translate }}
        </button>
      </div>
    </div>
  </form>
  <div class="footer-content">
    <div class="footer-verification">
      {{ 'VALIDATOR_UUID.FOOTER.VERIFICATION_SIGN' | translate }}
    </div>
    <div class="footer-login">
      <a class="footer-login" (click)="onLogin()">
        {{ 'VALIDATOR_UUID.FOOTER.SIGNER' | translate }}
      </a>
    </div>
    <div class="footer-register">
      <a class="footer-register" (click)="onRegister()">{{
        'VALIDATOR_UUID.FOOTER.REGISTER' | translate
      }}</a>
    </div>
  </div>
</div>
