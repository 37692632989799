import * as freesignatureActions from '@literax/store/free-signature/free-signature.actions';
import * as lodash from 'lodash';
import * as signatureActions from '@literax/store/signature/signature.actions';

import { Actions, ofType } from '@ngrx/effects';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import {
  selectEmailError,
  selectImageError,
  selectNameError,
  selectTokenErrors,
} from '@literax/store/signature/signature.selector';
import {
  selectEmailFreeError,
  selectFreeImageError,
  selectFreeTokenErrors,
  selectNameFreeError,
} from '@literax/store/free-signature/free-signature.selector';

import { DocumentSignature } from '@literax/models/document-signature';
import { IAppState } from '@literax/store';
import { UpdateFreeDocument } from '@literax/store/free-document/free-document.actions';
import { UpdateSelectedDocument } from '@literax/store/document/document.actions';
import { merge } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'literax-signature-form',
  templateUrl: './signature-form.component.html',
  styleUrls: ['./signature-form.component.scss'],
})
export class SignatureFormComponent implements OnInit {
  data: DocumentSignature;
  documentId: number;
  tokenData: string;
  nameDocument: string;
  typeSignatureSession = true;
  identification = false;
  plusSignature = false;
  signatureForm: FormGroup;
  permittedFiles = ['.pdf', '.jpg', '.png'];
  tokenErrorUser$ = this.store.pipe(select(selectTokenErrors));
  imagesErrorUser$ = this.store.pipe(select(selectImageError));
  imagesErrorFree$ = this.store.pipe(select(selectFreeImageError));
  tokenErrorFree$ = this.store.pipe(select(selectFreeTokenErrors));
  nameError$ = this.store.pipe(select(selectNameError));
  emailError$ = this.store.pipe(select(selectEmailError));
  nameFreError$ = this.store.pipe(select(selectNameFreeError));
  emailFreeError$ = this.store.pipe(select(selectEmailFreeError));
  tokenErrors$ = merge(this.tokenErrorUser$, this.tokenErrorFree$);
  imagesErrors$ = merge(this.imagesErrorUser$, this.imagesErrorFree$);
  namesErrors$ = merge(this.nameError$, this.nameFreError$);
  emailsErros$ = merge(this.emailError$, this.emailFreeError$);

  ngOnInit(): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.position = { right: '24px', top: '34px' };
    this.dialogRef.updatePosition(matDialogConfig.position);
    this.dialogRef.updateSize('352px', '100%');
    this.setCompanies();
    if (this.plusSignature) {
      (this.signatureForm.get('signature') as FormGroup).addControl(
        'two_factor_token',
        new FormControl('', Validators.required)
      );
    }
    if (this.identification != null && this.identification) {
      (this.signatureForm.get('signature') as FormGroup).addControl(
        'id_image',
        new FormControl('', Validators.required)
      );
    }
  }
  onSubmit(data: DocumentSignature) {
    if (this.typeSignatureSession) {
      this.store.dispatch(
        signatureActions.createSimpleSignature({
          payload: { signature: data, document_id: this.documentId },
        })
      );
    } else {
      this.store.dispatch(
        freesignatureActions.createSimpleSignature({
          payload: { signature: data, token: this.tokenData },
        })
      );
    }

    this.actions$
      .pipe(
        ofType(
          freesignatureActions.createSimpleSignatureSuccess,
          signatureActions.createSimpleSignatureSuccess
        ),
        take(1)
      )
      .subscribe(() => {
        if (this.tokenData) {
          this.store.dispatch(UpdateFreeDocument({ payload: this.tokenData }));
        } else {
          this.store.dispatch(UpdateSelectedDocument());
        }
        this.close();
      });
  }
  buildSignRequests(): FormGroup {
    return this.formBuilder.group({
      id: [null, [Validators.required]],
      company_name: [null],
      role: [null],
    });
  }

  saveSignature(event: string) {
    this.signatureForm.get('signature').get('signature_image').setValue(event);
  }
  setCompanies() {
    const items = this.signatureForm
      .get('signature')
      .get('sign_requests') as FormArray;
    this.data.signature.sign_requests.forEach((elemento) => {
      const form = this.buildSignRequests();
      if (elemento.company_name != null) {
        form.get('company_name').setValidators(Validators.required);
        form.get('company_name').updateValueAndValidity();
        form.get('role').setValidators(Validators.required);
        form.get('role').updateValueAndValidity();
      }
      form.patchValue(elemento);
      items.push(form);
    });
    this.signatureForm.patchValue(this.data);
  }
  preventDefault(e) {
    e.preventDefault();
  }

  close() {
    document.body.removeEventListener('touchmove', this.preventDefault);
    this.dialogRef.close();
  }
  get sign_requests(): FormArray {
    return this.signatureForm
      .get('signature')
      .get('sign_requests') as FormArray;
  }
  twoFactorToken() {
    const signRequestId: any = this.data.signature.sign_requests.map(
      (elemento) => lodash.pick(elemento, 'id')
    );
    if (this.typeSignatureSession) {
      this.store.dispatch(
        signatureActions.twoFactorToken({ payload: signRequestId })
      );
    } else {
      this.store.dispatch(
        freesignatureActions.twoFactorToken({ payload: signRequestId })
      );
    }
  }
  changeFile(event: { type: string; value: string }) {
    const data: DocumentSignature = {
      ...this.signatureForm.value,
      signature: {
        ...this.signatureForm.get('signature').value,
        id_image: event.value,
      },
    };
    this.data.signature.id_image = event.value;
    this.signatureForm.patchValue(data);
  }

  createForm() {
    this.signatureForm = this.formBuilder.group({
      signature: this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        name: [null, [Validators.required]],
        signature_image: [null, [Validators.required]],
        sign_requests: this.formBuilder.array([]),
      }),
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<IAppState>,
    private actions$: Actions,
    public dialogRef: MatDialogRef<SignatureFormComponent>
  ) {
    this.createForm();
  }
}
