<div class="modal-body modal-dialog-centered">
  <form
    class="form-ajust"
    #generateForm
    [formGroup]="form"
    (ngSubmit)="saveGenerateDate()"
  >
    <div class="row">
      <div class="col">
        <label class="label-title">{{ title | translate }}</label>
      </div>
      <div (click)="close('close')" class="col-md-2 cursor-poiner">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div class="">
      <div class="">
        <div class="row">
          <div class="col-8">
            <label class="label-top title-calendar" for="">
              {{
                'WORKSPACE.GENERATE.MODAL.TITLE_CONTROL_DATE' | translate
              }}</label
            >
          </div>
          <div class="col-8 calendar row-ajust">
            <literax-input
              [placeholder]="'WORKSPACE.REVIEWERS.FORM.DEADLINE' | translate"
              [icon]="'fal fa-calendar'"
              [control]="form.controls.delivery_date"
              (click)="picker.open()"
            >
            </literax-input>
            <input
              [min]="minDate"
              [matDatepicker]="picker"
              [(ngModel)]="delivery_date"
              (ngModelChange)="setExpiration($event)"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>

        <div class="separator"></div>
      </div>
    </div>
    <hr />
    <div class="row-buttons">
      <div class="buttons">
        <button
          (click)="close('close')"
          mat-button
          class="button button--secondary"
          type="button"
        >
          <i class="icon far fa-times-circle"> </i
          ><span class="block-boton"></span>
          {{ cancelButton | translate }}
        </button>
        <button mat-button class="button button--primary" type="submit">
          <i class="icon far fa-save"> </i><span class="block-boton"></span>
          {{ acceptButton | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
