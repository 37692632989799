import { Pipe, PipeTransform } from '@angular/core';
import { AppUtils } from '@literax/utils/app.utils';

@Pipe({
  name: 'valueFromColumn'
})
export class ValueFromColumn implements PipeTransform {
  transform(data: object, key: string): any {
    if (data) {
      if (key) {
        return AppUtils.getValueOfObjectFromDotNotationString(data, key);
      } else {
        return '';
      }
    }
    return '';
  }
}
