import {
  FieldsOnlyOffice,
  IProfile,
  ITemplateKinds,
  ITemplateProfile,
  ITemplates,
  initialITemplateKinds
} from '@literax/models/templates';
import { FilterParams, TemplateCollector, TemplateFieldData, TemplateSigner } from '@literax/models/templates-reports.model';

import { ColumnSort } from '@literax/models/table.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ITemplatesFields } from '@literax/models/templates-fields.model';
import { Process } from '@literax/models/process.model';

export interface ITemplatesState {
  updated: ITemplates;
  templates: ITemplates[];
  latest: ITemplates[];
  templateFields: ITemplatesFields[];
  fieldsOnlyOffice: FieldsOnlyOffice[];
  templateUpdate: ITemplates;
  error: IAPIResponseError;
  currentPage: number;
  templateFieldsCurrentPage: number;
  flows: Process;
  selectedFlow: string;
  sortColumn: string;
  currentDepartment: number;
  kind: string;
  templateKinds: ITemplateKinds;
  metadataTemplateProfiles: ITemplateProfile[];
  metadataTemplateProfilesCurrentPage: number;
  profiles: IProfile[];
  has_available_templates: boolean;
  templateFieldData: TemplateFieldData;
  templateFieldDataCurrentPage: number;
  templateFieldDataCurrentSort: ColumnSort[];
  templateFieldDataActiveFilter: FilterParams,
  collectors: TemplateCollector[];
  signers: TemplateSigner[];
}

export let initialTemplatesState: ITemplatesState;
initialTemplatesState = {
  updated: {},
  templates: [],
  latest: [],
  templateFields: [],
  fieldsOnlyOffice: [],
  templateUpdate: null,
  error: null,
  currentPage: 1,
  templateFieldsCurrentPage: 1,
  selectedFlow: 'template_draft',
  flows: null,
  sortColumn: 'created_at:desc',
  currentDepartment: null,
  kind: '',
  templateKinds: initialITemplateKinds,
  metadataTemplateProfiles: [],
  metadataTemplateProfilesCurrentPage: 1,
  profiles: [],
  has_available_templates: false,
  templateFieldData: null,
  templateFieldDataCurrentPage: 1,
  templateFieldDataCurrentSort: [],
  templateFieldDataActiveFilter: null,
  collectors: [],
  signers: []
};
