import { IAPIError } from '@literax/models/http/api/error.model';
import {
  IShowSignatureResponse,
  ISignatureResponse
} from '@literax/models/http/api/signature.model';
import { ITemplates } from '@literax/models/templates';

export interface ISignatureState {
  signatureResult: ISignatureResponse;
  showSignatureResult: IShowSignatureResponse;
  errors: IAPIError;
  signatureQuotes: ITemplates[];
}

export const initialSignatureState: ISignatureState = {
  signatureResult: null,
  showSignatureResult: null,
  errors: null,
  signatureQuotes: []
};
