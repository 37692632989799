import * as registrationActions from './registration.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthService } from '@literax/services/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IAppState } from '@literax/store';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

@Injectable()
export class RegistrationEffects {

  constructor(
    private authService: AuthService,
    private action$: Actions,
    private dialog: MatDialog,
    private store: Store<IAppState>
  ) {}

  signup$ = createEffect(() => 
    this.action$.pipe(
      ofType(registrationActions.signUp),
      switchMap(action =>
        this.authService.registration(action.payload.Client, action.payload.token).pipe(
          map(resp =>
            registrationActions.signUpSuccess({ payload: resp.success })
          ),
          catchError((error: HttpErrorResponse) =>
            of(registrationActions.signUpError({ payload: error.error }))
          )
        )
      )
    )
  );

  verify$ = createEffect(() => 
    this.action$.pipe(
      ofType(registrationActions.verify),
      switchMap(action =>
        this.authService.verify(action.payload.Client, action.payload.token).pipe(
          map(resp =>
            registrationActions.verifySuccess({ payload: resp.success })
          ),
          catchError((error: HttpErrorResponse) =>
            of(registrationActions.verifyError({ payload: error.error }))
          )
        )
      )
    )
  );
}
