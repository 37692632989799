import { Component, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
@Component({
  selector: 'literax-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  ngAfterViewInit() {
    this.createScript();
    this.setValueAnalytics();
  }

  createScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = environment.urlAnalytics + environment.analyticsId;
    document.querySelector('head').appendChild(script);
  }

  setValueAnalytics() {
    const script = document.createElement('script');
    script.innerHTML = ` window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config','${environment.analyticsId}');`;
    document.querySelector('head').appendChild(script);
  }
}
