import {
  IAPIError,
  IAPIResponseError,
} from '@literax/models/http/api/error.model';
import {
  ICreateDocument,
  ICreateDocumentResponse,
} from '@literax/models/http/document/create-document.model';
import {
  IDocumentDownload,
  IUpdateDocument,
  IUpdateDocumentSelected,
  ICancelatioReason,
} from './../../models/http/document/document.model';
import { createAction, props } from '@ngrx/store';

import { IAttachment } from '@literax/models/http/attachment.model';
import { IAttachmentDateGenerate } from '../../models/http/attachment.model';
import { IAutocomplete } from '../../models/http/document/document.model';
import { ICreateSignRequestResponse } from '@literax/models/http/api/sign-request/sign-request.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IFilterParams } from './document.state';
import { IFlow } from '@literax/models/flow.model';
import { ISort } from '@literax/interfaces/sort-descriptor.interface';

export enum EDocumentActions {
  GetDocuments = '[Document] GetDocuments',
  GetDocumentsSuccess = '[Document] GetDocumentsSuccess',
  GetDocument = '[Document] GetDocument',
  GetDocumentSuccess = '[Document] GetDocumentSuccess',
  GetDocumentAttachment = '[Document] GetDocumentAttachment',
  GetDocumentAttachmentSuccess = '[Document] GetDocumentAttachmentSuccess',
  GetDocumentAttachmentError = '[Document] GetDocumentAttachmentError',
  ChangeAttachmentSelect = '[Document] ChangeAttachmentSelect',
  CreateDocument = '[Document] CreateDocument',
  CreateDocumentSuccess = '[Document] CreateDocumentSuccess',
  CreateDocumentError = '[Document] CreateDocumentError',
  CleanDocumentFormStates = '[Document] CleanDocumentFormStates',
  CleanDocumentsState = '[Document] CleanDocumentsState',
  UpdateFilterState = '[Document] UpdateFilterState',
  UpdateSelectedDocument = '[Document] Refresh Selected Document',
  UpdateSelectedDocumentHierarchy = '[Document] update Selected Document',
  UpdateFreeDocument = '[Document] Refresh Free Document',
  UpdateDocument = '[Document] UpdateDocument',
  UpdateLocalDocument = '[Document] UpdateLocalDocument',
  CleanSelectedDocumentState = '[Document] Clean Selected Document State',
  DeleteDocument = '[Document] Delete Document',
  DeleteDocumentSuccess = '[Document] Delete Document Success',
  DeleteDocumentError = '[Document] Delete Document Error',
  GetFreeDocument = '[Document] GetFreeDocument',
  GetDocumentError = '[Document] GetDocumentError',
  DocumentMarkAs = '[Document] MarkAs',
  DocumentMarkAsSuccess = '[Document] MarkAsSuccess',
  DocumentMarkAsError = '[Document] MarkAsError',
  DownloadDocuments = '[Document] DownloadDocuments',
  DownloadDocumment = '[Document] DownloadDocument',
  SaveBlobDocument = '[Document] SaveBlobDocument',
  UpdateSignRequestSuccess = '[SignRequest] Update SR Success',
  UpdateDocumentSelected = '[Document] UpdateDocumentSelected',
  UpdateDocumentSelectedSuccess = '[Document] UpdateDocumentSelectedSuccess',
  DeleteDocumentList = '[Document] Delete document from list',
  DeleteDocumentListSuccess = '[Document] Delete document from list success',
  ChangeSort = '[Document] Change sort column',
  GetFlowsByProcessType = '[Document] GetFlowsByProcessType',
  GetFlowsByProcessTypeSuccess = '[Document] GetFlowsByProcessTypeSuccess',
  GetFlowsByProcessTypeError = '[Document] GetFlowsByProcessTypeError',
  GetNavbarDocument = '[Document] GetNavbarDocument',
  GetNavbarDocumentSuccess = '[Document] GetNavbarDocumentSuccess',
  GetNavbarDocumentError = '[Document] GetNavbarDocumentError',
  DocumentMarkAsList = '[Document] MarkAsList',
  DocumentMarkAsReviewed = '[Document] MarkAsReviewed',
  SetOnlyOfficeKey = '[Document] Set onlyoffice key',
  DocumentsHidden = '[Document] DocumentsHidden',
  DocumentsHiddenSuccess = '[Document] DocumentsHiddenSuccess',
  DocumentHiddenType = '[Document] DocumentHiddenType',
  DocumentHiddenTypeSuccess = '[Document] DocumentHiddenTypeSuccess',
  DocumentHiddenTypeError = '[Document] DocumentHiddenTypeError',
  DocumentCurrentPageInit = '[Document] DocumentCurrentPageInit',
  DocumentMarkAsApproved = '[Document] MarkAsApproved',
  DocumentSetMinApprovers = '[Document] SetMinApprovers',

  DocumentCancelProcess = '[Document] Document process cancel',
  DocumentCancelProcessSuccess = '[Document] Document process cancel success',
  DocumentCancelProcessError = '[Document] Document process cancel error',

  GetCancellationReasons = '[Document] Get cancellation reasons',
  GetCancellationReasonsSuccess = '[Document] Get cancellation reasons success',
  GetCancellationReasonsError = '[Document] Get cancellation reasons error',

  SaveDateGenerate = '[Document] SaveDateGenerate',
  SaveDateGenerateSuccess = '[Document] SaveDateGenerateSuccess',
  SaveDateGenerateError = '[Document] SaveDateGenerateError',
  DocumentCreateAssigment = '[Document] Create assigment',

  SetDocumentAttachmentBase64Data = '[Document] SetDocumentAttachmentBase64Data',
  ClearDocumentAttachmentsBase64Data = '[Document] ClearDocumentAttachmentsBase64Data',
  SetLastUploadedAttachment = '[Document] SetLastUploadedAttachment',
  ClearLastUploadedAttachment = '[Document] ClearLastUploadedAttachment',

  GetPendingMessages = '[Chat] GetPendingMessages',
  GetPendingMessagesSuccess = '[Chat] GetPendingMessagesSuccess',
  GetPendingMessagesError = '[Chat] GetPendingMessagesError',
}

export const ClearLastUploadedAttachment = createAction(
  EDocumentActions.ClearLastUploadedAttachment
);

export const ClearDocumentAttachmentsBase64Data = createAction(
  EDocumentActions.ClearDocumentAttachmentsBase64Data
);

export const SetLastUploadedAttachment = createAction(
  EDocumentActions.SetLastUploadedAttachment,
  props<{ payload: IAttachment }>()
);

export const SetDocumentAttachmentBase64Data = createAction(
  EDocumentActions.SetDocumentAttachmentBase64Data,
  props<{ payload: { attachmentId: number; data: string } }>()
);

export const DocumentSetMinApprovers = createAction(
  EDocumentActions.DocumentSetMinApprovers,
  props<{ payload: number }>()
);

export const SetOnlyOfficeKey = createAction(
  EDocumentActions.SetOnlyOfficeKey,
  props<{ payload: string }>()
);

export const UpdateSignRequestsSuccess = createAction(
  EDocumentActions.UpdateSignRequestSuccess,
  props<{ payload: ICreateSignRequestResponse }>()
);

export const CleanDocumentFormStates = createAction(
  EDocumentActions.CleanDocumentFormStates
);
export const CleanDocumentsState = createAction(
  EDocumentActions.CleanDocumentsState
);
export const DocumentMarkAs = createAction(
  EDocumentActions.DocumentMarkAs,
  props<{ payload: string }>()
);
export const DocumentMarkAsSuccess = createAction(
  EDocumentActions.DocumentMarkAsSuccess,
  props<{ payload: IDocument }>()
);
export const DocumentMarkAsError = createAction(
  EDocumentActions.DocumentMarkAsError,
  props<{ payload: IAPIError }>()
);
export const DocumentMarkAsReviewed = createAction(
  EDocumentActions.DocumentMarkAsReviewed,
  props<{ payload: { action: string; sign_request: number } }>()
);
export const UpdateFilterState = createAction(
  EDocumentActions.UpdateFilterState,
  props<{ payload: IFilterParams }>()
);
export const GetDocuments = createAction(
  EDocumentActions.GetDocuments,
  props<{ payload: { filterParams?: IFilterParams } }>()
);
export const DownloadDocuments = createAction(
  EDocumentActions.DownloadDocuments
);
export const SaveBlobDocument = createAction(
  EDocumentActions.SaveBlobDocument,
  props<{ payload: IDocumentDownload }>()
);
export const DownloadDocument = createAction(
  EDocumentActions.DownloadDocumment,
  props<{ payload: { id: number } }>()
);
export const GetDocumentsSuccess = createAction(
  EDocumentActions.GetDocumentsSuccess,
  props<{ payload: IDocument[] }>()
);
export const GetDocument = createAction(
  EDocumentActions.GetDocument,
  props<{ payload: number | string }>()
);
export const GetFreeDocument = createAction(
  EDocumentActions.GetFreeDocument,
  props<{ payload: number | string }>()
);
export const GetDocumentSuccess = createAction(
  EDocumentActions.GetDocumentSuccess,
  props<{ payload: IDocument }>()
);
export const GetDocumentError = createAction(
  EDocumentActions.GetDocumentError,
  props<{ payload: IAPIResponseError }>()
);
export const CreateDocument = createAction(
  EDocumentActions.CreateDocument,
  props<{ payload: ICreateDocument }>()
);
export const CreateDocumentSuccess = createAction(
  EDocumentActions.CreateDocumentSuccess,
  props<{ payload: ICreateDocumentResponse }>()
);
export const CreateDocumentError = createAction(
  EDocumentActions.CreateDocumentError,
  props<{ payload: IAPIResponseError }>()
);
export const CleanSelectedDocumentState = createAction(
  EDocumentActions.CleanSelectedDocumentState
);
export const UpdateSelectedDocument = createAction(
  EDocumentActions.UpdateSelectedDocument
);
export const UpdateFreeDocument = createAction(
  EDocumentActions.UpdateFreeDocument,
  props<{ payload: string }>()
);
export const UpdateLocalDocument = createAction(
  EDocumentActions.UpdateLocalDocument,
  props<{ payload: ICreateDocumentResponse }>()
);

export const GetNavbarDocumentSuccess = createAction(
  EDocumentActions.GetNavbarDocumentSuccess,
  props<{ payload: IAutocomplete }>()
);
export const GetNavbarDocumentError = createAction(
  EDocumentActions.GetNavbarDocumentError,
  props<{ payload: IAPIResponseError }>()
);
export const GetNavbarDocument = createAction(
  EDocumentActions.GetNavbarDocument,
  props<{ payload: string }>()
);

export const DocumentMarkAsList = createAction(
  EDocumentActions.DocumentMarkAsList,
  props<{ payload: { id: number; action: string } }>()
);

export const UpdateDocument = createAction(
  EDocumentActions.UpdateDocument,
  props<{ payload: { documentId: number; document: IUpdateDocument } }>()
);

export const UpdateSelectedDocumentHierarchy = createAction(
  EDocumentActions.UpdateSelectedDocumentHierarchy,
  props<{ payload: { documentId: number; hierarchy: boolean } }>()
);

export const DeleteDocument = createAction(
  EDocumentActions.DeleteDocument,
  props<{ payload: { documentId: number } }>()
);

export const DeleteDocumentSuccess = createAction(
  EDocumentActions.DeleteDocumentSuccess
);

export const DeleteDocumentError = createAction(
  EDocumentActions.DeleteDocumentError,
  props<{ payload: IAPIError }>()
);

export const DeleteDocumentList = createAction(
  EDocumentActions.DeleteDocumentList,
  props<{ payload: { documentId: number } }>()
);

export const DeleteDocumentListSuccess = createAction(
  EDocumentActions.DeleteDocumentListSuccess
);

export const GetDocumentAttachment = createAction(
  EDocumentActions.GetDocumentAttachment,
  props<{ payload: { documentId: number; attachmenId: number } }>()
);

export const GetDocumentAttachmentSuccess = createAction(
  EDocumentActions.GetDocumentAttachmentSuccess,
  props<{ payload: IAttachment }>()
);
export const GetDocumentAttachmentError = createAction(
  EDocumentActions.GetDocumentAttachmentError,
  props<{ payload: IAPIResponseError }>()
);

export const ChangeAttachmentSelect = createAction(
  EDocumentActions.ChangeAttachmentSelect,
  props<{ payload: IAttachment }>()
);

export const UpdateDocumentSelected = createAction(
  EDocumentActions.UpdateDocumentSelected,
  props<{
    payload: { documentId: number; document: IUpdateDocumentSelected };
  }>()
);

export const UpdateDocumentSelectedSuccess = createAction(
  EDocumentActions.UpdateDocumentSelectedSuccess
);

export const ChangeSort = createAction(
  EDocumentActions.ChangeSort,
  props<{ payload: string }>()
);

export const ChangeCurrentSort = createAction(
  EDocumentActions.ChangeSort,
  props<{ payload: ISort[] }>()
);

export const GetFlowsByProcessType = createAction(
  EDocumentActions.GetFlowsByProcessType,
  props<{ currentProcess: string; processType: string }>()
);

export const GetFlowsByProcessTypeSuccess = createAction(
  EDocumentActions.GetFlowsByProcessTypeSuccess,
  props<{ payload: IFlow[] }>()
);

export const GetFlowsByProcessTypeError = createAction(
  EDocumentActions.GetFlowsByProcessTypeError,
  props<{ payload: IAPIResponseError }>()
);

export const DocumentsHidden = createAction(
  EDocumentActions.DocumentsHidden,
  props<{ payload: boolean }>()
);

export const DocumentsHiddenSuccess = createAction(
  EDocumentActions.DocumentsHiddenSuccess,
  props<{ payload: IDocument[] }>()
);

export const DocumentsHiddenType = createAction(
  EDocumentActions.DocumentHiddenType,
  props<{ payload: number }>()
);

export const DocumentsHiddenTypeSuccess = createAction(
  EDocumentActions.DocumentHiddenTypeSuccess
);
export const DocumentsHiddenTypeError = createAction(
  EDocumentActions.DocumentHiddenTypeError
);

export const DocumentCurrentPageInit = createAction(
  EDocumentActions.DocumentCurrentPageInit
);

export const DocumentMarkAsApproved = createAction(
  EDocumentActions.DocumentMarkAsApproved,
  props<{ payload: { action: string; sign_request: number } }>()
);

export const DocumentCancelProcess = createAction(
  EDocumentActions.DocumentCancelProcess,
  props<{ payload: string }>()
);

export const DocumentCancelProcessSuccess = createAction(
  EDocumentActions.DocumentCancelProcessSuccess
);

export const DocumentCancelProcessError = createAction(
  EDocumentActions.DocumentCancelProcessError
);

export const GetCancellationReasons = createAction(
  EDocumentActions.GetCancellationReasons
);

export const GetCancellationReasonsSuccess = createAction(
  EDocumentActions.GetCancellationReasonsSuccess,
  props<{ payload: ICancelatioReason[] }>()
);

export const GetCancellationReasonsError = createAction(
  EDocumentActions.GetCancellationReasonsError
);

export const SaveDateGenerate = createAction(
  EDocumentActions.SaveDateGenerate,
  props<{
    payload: {
      documentId: number;
      attachmentId: number;
      attachment: IAttachmentDateGenerate;
    };
  }>()
);

export const SaveDateGenerateSuccess = createAction(
  EDocumentActions.SaveDateGenerateSuccess
);

export const SaveDateGenerateError = createAction(
  EDocumentActions.SaveDateGenerateError,
  props<{ payload: IAPIResponseError }>()
);

export const DocumentCreateAssigment = createAction(
  EDocumentActions.DocumentCreateAssigment,
  props<{
    payload: { documentId: number; sign_request: { client_user_id: number } };
  }>()
);

export const GetPendingMessages = createAction(
  EDocumentActions.GetPendingMessages,
  props<{ payload: { chat: string; app: string; token: string } }>()
);

export const GetPendingMessagesSuccess = createAction(
  EDocumentActions.GetPendingMessagesSuccess,
  props<{ payload: number }>()
);

export const GetPendingMessagesError = createAction(
  EDocumentActions.GetPendingMessagesError,
  props<{ payload: IAPIResponseError }>()
);
