import * as dashboardActions from './dashboard.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { IDashboardState, initialDashboardState } from './dashboard.state';

const dashboardReducer = createReducer(
  initialDashboardState,
  on(dashboardActions.getDashboardSuccess, (state, action) => ({ ...state, dashboard: action.payload })),
  on(dashboardActions.getDashboardError, (state, action) => ({ ...state, errorResponse: action.payload })),
  on(dashboardActions.showDashboardSuccess, (state, action) => ({ ...state, showDashboard: action.payload })),
  on(dashboardActions.showDashboardError, (state, action) => ({ ...state, errorResponse: action.payload })),
  on(dashboardActions.getExternalProcessSuccess, (state, action) => ({ ...state, external_process: action.payload })),
  on(dashboardActions.getInternalProcessSuccess, (state, action) => ({ ...state, internal_process: action.payload })),
  on(dashboardActions.getPromissoryNotesProcessSuccess, (state, action) => ({ ...state, promissory_note: action.payload })),
  on(dashboardActions.getSharedProcessSuccess, (state, action) => ({ ...state, shared_process: action.payload }))
);

export function dashboardReducers(state: IDashboardState | undefined, action: Action) {
  return dashboardReducer(state, action);
}
