import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'literax-wizard-buttons',
  templateUrl: './wizard-buttons.component.html',
  styleUrls: ['./wizard-buttons.component.scss'],
})
export class WizardButtonsComponent {
  @Input() note: string;
  @Input() back?: string;
  @Input() cancel?: string;
  @Input() next?: string;
  @Input() skip?: string;
  @Input() backDisabled = false;
  @Input() cancelDisabled = false;
  @Input() nextDisabled = false;
  @Input() skipDisabled = false;
  @Output() backEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() nextEvent = new EventEmitter();
  @Output() skipEvent = new EventEmitter();

  constructor() {}

  backAction(event: Event) {
    if (!this.backDisabled) {
      this.backEvent.emit(event);
    }
  }

  skipAction(event: Event) {
    if (!this.skipDisabled) {
      this.skipEvent.emit(event);
    }
  }

  cancelAction(event: Event) {
    if (!this.cancelDisabled) {
      this.cancelEvent.emit(event);
    }
  }

  nextAction(event: Event) {
    if (!this.nextDisabled) {
      this.nextEvent.emit(event);
    }
  }
}
