import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeleteDialogData {
  callback: () => void;
  message: string;
  cancelButton: string;
  acceptButton: string;
}

@Component({
  selector: 'literax-delete-record-dialog',
  templateUrl: './delete-record-dialog.component.html',
  styleUrls: ['./delete-record-dialog.component.scss'],
})
export class DeleteRecordDialogComponent {
  cancelEvent() {
    this.dialogRef.close();
  }

  deleteEvent() {
    this.data.callback();
    this.cancelEvent();
  }

  constructor(
    private dialogRef: MatDialogRef<DeleteRecordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogData
  ) {}
}
