import { Action, createReducer, on } from '@ngrx/store';
import * as templatesFieldsActions from '@literax/store/template-fields/templates-fields.actions';
import {
  initialTemplatesFieldsState,
  ITemplatesFieldsState
} from '@literax/store/template-fields/templates-fields.state';

const templateFieldReducer = createReducer(
  initialTemplatesFieldsState,
  on(templatesFieldsActions.getAllFieldsSuccess, (state, action) => {
    const fields = [...state.fields, ...action.payload];
    const currentPage =
      action.payload.length > 0 ? state.currentPage + 1 : state.currentPage;
    return { ...state, fields, currentPage };
  }),
  on(templatesFieldsActions.sortColumn, (state, action) => ({
    ...state,
    sortColumn: action.payload,
    fields: [],
    currentPage: 1
  })),
  on(templatesFieldsActions.createFieldSuccess, (state, action) => ({
    ...state,
    fieldResult: action.payload,
    error: null,
    currentPage: 1,
    fields: []
  })),
  on(templatesFieldsActions.createFieldError, (state, action) => ({
    ...state,
    createError: action.payload
  })),
  on(templatesFieldsActions.cleanFieldsState, (state, _) => ({
    ...state,
    fields: [],
    currentPage: 1
  }))
);

export function templatesFieldsReducer(
  state: ITemplatesFieldsState,
  action: Action
) {
  return templateFieldReducer(state, action);
}
