import { IAppState } from '@literax/store/index';
import { ISignRequestState } from './sign-request.state';
import { createSelector } from '@ngrx/store';

const selectSignRequests = (state: IAppState) => state.signRequests;

export const selectCreateNaturalPersonFormErrors = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.createNaturalPersonErrors
);

export const selectUpdateNaturalPersonFormErrors = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.updateNaturalPersonErrors
);

export const selectCreateLegalRepresentativesFormErrors = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.createLegalRepresentativeErrors
);

export const selectUpdateLegalRepresentativesFormErrors = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.updateLegalRepresentativeErrors
);

export const selectRequests = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.signRequests
);

export const selectSignRequestNotificationSuccess = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.successSignRequestNotificationSuccess
);

export const selectAddMeStatus = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.addMeStatus
);

export const selectContacts = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.contacts
);

export const selectLastEventEmail = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.signRequest
);

export const selectCollaborators = createSelector(
  selectSignRequests,
  (state: ISignRequestState) => state.collaborators
);
