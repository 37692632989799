import { Action, createReducer, on } from '@ngrx/store';
import * as signatureActions from './signature.actions';
import { initialSignatureState, ISignatureState } from './signature.state';
// case ESignatureActions.CreateSignatureSuccess
const signatureReducers = createReducer(
  initialSignatureState,
  on(signatureActions.CreateSignatureSuccess, (state, action) => ({
    ...state,
    signatureResult: action.payload,
    errors: null
  })),
  on(signatureActions.CreateSignatureError, (state, action) => ({
    ...state,
    signatureResult: null,
    errors: action.payload.error.error
  })),
  on(signatureActions.createSimpleSignatureError, (state, action) => ({
    ...state,
    signatureResult: null,
    errors: action.payload.error.error
  })),
  on(signatureActions.ClearSignatureResult, (state, action) => ({
    ...state,
    signatureResult: null,
    errors: null
  })),
  on(signatureActions.ShowSignatureSuccess, (state, action) => ({
    ...state,
    showSignatureResult: action.payload,
    errors: null
  })),
  on(signatureActions.ShowSignatureError, (state, action) => ({
    ...state,
    showSignatureResult: null,
    errors: action.payload.error.error
  })),
  on(signatureActions.GetSignatureQuoteSuccess, (state, action) => ({
    ...state,
    signatureQuotes: action.payload
  }))
);
export function signatureReducer(state: ISignatureState, action: Action) {
  return signatureReducers(state, action);
}
