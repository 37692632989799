import { TitleNavbarConfig } from '@literax/models/title-navbar-config.model';

export interface ITitleNavbarState {
  stack: TitleNavbarConfig[];
  nav_nodes: Partial<{
    process_type: TitleNavbarConfig;
    transaction_type: TitleNavbarConfig;
    document_name: TitleNavbarConfig;
  }>;
}

export const initialTitleNavbarState: ITitleNavbarState = {
  stack: [],
  nav_nodes: {
    process_type: null,
    transaction_type: null,
    document_name: null
  }
};
