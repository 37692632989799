import { ICompanyList } from '@literax/models/company.model';
import { IClient } from '@literax/models/http/api/client/client.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import {
  Client,
  SignatureConfig,
} from '@literax/models/http/onboarding/client.model';
import {
  IUserActiveDelegate,
  IUserActiveDelegateDeparment,
  IUserActiveDelegateItem,
} from '@literax/models/user.model';
import { createAction, props } from '@ngrx/store';
import { ISignRequest } from '../document/document.state';

export enum EClientActions {
  GetClients = '[Client] GetClients',
  GetClientsSuccess = '[Client] GetDocuments Success',
  SetCurrentClient = '[Client] SetCurrentClient',
  SetCurrentClientSuccess = '[Client] SetCurrentClientSuccess',
  GetClientsError = '[Client] GetClientsError',
  GetClient = '[Client] GetClient',
  GetClientSuccess = '[Client] GetClientsSuccess',
  GetClientError = '[Client] GetClientError',
  GetRepresentative = '[Client] GetRepresentative',
  GetRepresentativeSuccess = '[Representative] GetRepresentativeSuccess',
  GetRepresentativeError = '[Representative] GetRepresentativeError',
  UpdateClient = '[Client] UpdateClient',
  UpdateClientSuccess = '[Client] UpdateClientSuccess',
  UpdateClientError = '[Client] UpdateClientError',
  UpdateSignatureConfigs = '[Client] UpdateSignatureConfigs',
  UpdateSignatureConfigsSuccess = '[Client] UpdateSignatureConfigsSuccess',
  UpdateSignatureConfigsError = '[Client] UpdateSignatureConfigsError',

  //TODO: Action user active delegate
  GetClientActiveDelegate = '[Client] GetClientActiveDelegate',
  GetClientActiveDelegateSuccess = '[Client] GetClientActiveDelegateSuccess',
  GetClientActiveDelegateError = '[Client] GetClientActiveDelegateError',

  DelegateProcessTo = '[Client] DelegateProcessTo',
  DelegateProcessToSuccess = '[Client] DelegateProcessToSuccess',
  DelegateProcessToError = '[Client] DelegateProcessToError',
}

export const DelegateProcessTo = createAction(
  EClientActions.DelegateProcessTo,
  props<{ payload: { to: number } }>()
);

export const DelegateProcessToSuccess = createAction(
  EClientActions.DelegateProcessToSuccess,
  props<{ payload: ISignRequest }>()
);

export const DelegateProcessToError = createAction(
  EClientActions.DelegateProcessToError,
  props<{ payload: IAPIResponseError }>()
);

export const GetClients = createAction(EClientActions.GetClients);
export const GetClientsSucccess = createAction(
  EClientActions.GetClientsSuccess,
  props<{ payload: IClient[] }>()
);
export const GetClientsError = createAction(
  EClientActions.GetClientsError,
  props<{ payload: IAPIResponseError }>()
);
export const SetCurrentClient = createAction(
  EClientActions.SetCurrentClient,
  props<{ payload: IClient }>()
);
export const GetClient = createAction(EClientActions.GetClient);
export const GetClientSuccess = createAction(
  EClientActions.GetClientSuccess,
  props<{ payload: Client }>()
);
export const GetClientError = createAction(
  EClientActions.GetClientError,
  props<{ payload: IAPIResponseError }>()
);
export const GetRepresentative = createAction(EClientActions.GetRepresentative);
export const GetRepresentativeSuccess = createAction(
  EClientActions.GetRepresentativeSuccess,
  props<{ payload: ICompanyList[] }>()
);
export const UpdateClient = createAction(
  EClientActions.UpdateClient,
  props<{ payload: Client }>()
);
export const UpdateClientSuccess = createAction(
  EClientActions.UpdateClientSuccess,
  props<{ payload: Client }>()
);
export const UpdateClientError = createAction(
  EClientActions.UpdateClientError,
  props<{ payload: IAPIResponseError }>()
);
export const UpdateSignatureConfigs = createAction(
  EClientActions.UpdateSignatureConfigs,
  props<{
    payload: { clientId: number; signatureConfigs: SignatureConfig[] };
  }>()
);
export const UpdateSignatureConfigsSuccess = createAction(
  EClientActions.UpdateSignatureConfigsSuccess,
  props<{ payload: {} }>()
);
export const UpdateSignatureConfigsError = createAction(
  EClientActions.UpdateSignatureConfigsError,
  props<{ payload: IAPIResponseError }>()
);
//TODO: Action user active delegate
export const GetClientActiveDelegate = createAction(
  EClientActions.GetClientActiveDelegate
);
export const GetClientActiveDelegateSuccess = createAction(
  EClientActions.GetClientActiveDelegateSuccess,
  props<{ payload: IUserActiveDelegateItem[] }>()
);
export const GetClientActiveDelegateError = createAction(
  EClientActions.GetClientActiveDelegateError,
  props<{ payload: IAPIResponseError }>()
);
