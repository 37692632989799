<div class="modal-container">
  <div class="header">
    <h3>{{ 'DOCUMENT-PREVIEW.SIGN.TITLE' | translate }}</h3>
  </div>
  <div class="form">
    <literax-sign-document
      [currentCertName]="data.currentCertName"
      [showCancel]="data.showCancel"
      [document]="data.document"
      (cancelClicked)="closeModal()"
      [token]="data?.token"
      [requiredCertificated]="data?.document.required_legal_certificate"
    ></literax-sign-document>
  </div>
  <div class="footer"></div>
</div>
