import {
  ISignature,
  ISignatureResponse,
  IShowSignatureResponse
} from '@literax/models/http/api/signature.model';
import {
  IAPIResponseErrors,
  IAPIResponseError,
  IAPIError
} from '@literax/models/http/api/error.model';

export interface IFreeSignatureState {
  signatureResult: any;
  showSignatureResult: IShowSignatureResponse;
  errors: IAPIError;
}

export const initialFreeSignatureState: IFreeSignatureState = {
  signatureResult: null,
  showSignatureResult: null,
  errors: null
};
