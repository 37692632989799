import * as UsersActions from '@literax/components/configurations/users/states/users.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CurrentUserUpdateCertificate,
  CurrentUserUpdateCertificateError,
  CurrentUserUpdateCertificateSuccess,
  CurrentUserUpdateEmail,
  CurrentUserUpdateEmailError,
  CurrentUserUpdateEmailSuccess,
  CurrentUserUpdatePassword,
  CurrentUserUpdatePasswordError,
  CurrentUserUpdatePasswordSuccess,
  EAuthActions,
  GetLoginInfo,
  GetLoginInfoSuccess,
  Logout,
  LogoutSuccess
} from '@literax/store/auth/auth.actions';
import {
  catchError,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap
} from 'rxjs/operators';

import { AuthService } from '@literax/services/auth/auth.service';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '@literax/store';
import { IUserInfo } from '@literax/components/configurations/users/models/users.interface';
import { Injectable } from '@angular/core';
import { ProfilesService } from '@literax/components/configurations/profiles/services/profiles.service';
import { Router } from '@angular/router';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { Store } from '@ngrx/store';
import { UsersServices } from '@literax/components/configurations/users/services/users.services';
import { of } from 'rxjs';

@Injectable()
export class AuthEffects {
  currentUserUpdateEmail$ = createEffect(() =>
    this.action$.pipe(
      ofType(CurrentUserUpdateEmail),
      switchMap((action) =>
        this.authService.updateProfile(action.payload).pipe(
          map((response: ServiceResponse) =>
            CurrentUserUpdateEmailSuccess({ payload: response.user })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CurrentUserUpdateEmailError({ payload: error.error }))
          )
        )
      )
    )
  );

  currentUserUpdateCert$ = this.action$.pipe(
    ofType(CurrentUserUpdateCertificate),
    switchMap((action) =>
      this.authService.updateProfile(action.payload).pipe(
        map((response: ServiceResponse) =>
          CurrentUserUpdateCertificateSuccess({ payload: response.user })
        ),
        catchError((error: HttpErrorResponse) =>
          of(CurrentUserUpdateCertificateError({ payload: error.error }))
        )
      )
    )
  );

  currentUserUpdateCertToast$ = createEffect(() => 
    this.action$.pipe(
      ofType(CurrentUserUpdateCertificateSuccess),
      tap((action) =>
        this.toastr.success(
          'TRANSACTIONS.SUCCESS.UPDATE_CERT_MSG',
          'TRANSACTIONS.SUCCESS.UPDATE_PROFILE_TITLE'
        )
      )
    ),
    { dispatch: false }
  );

  currentUserUpdatePassword$ = createEffect(() =>
    this.action$.pipe(
      ofType(CurrentUserUpdatePassword),
      switchMap((action) =>
        this.authService.updateProfile(action.payload).pipe(
          map((response: ServiceResponse) =>
            CurrentUserUpdatePasswordSuccess({ payload: response.user })
          ),
          catchError((error: HttpErrorResponse) =>
            of(CurrentUserUpdatePasswordError({ payload: error.error }))
          )
        )
      )
    )
  );

  currentUserUpdatePasswordToast$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(CurrentUserUpdatePasswordSuccess),
        tap(() =>
          this.toastr.success(
            'TRANSACTIONS.SUCCESS.UPDATE_PASSWORD_MSG',
            'TRANSACTIONS.SUCCESS.UPDATE_PROFILE_TITLE'
          )
        )
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.action$.pipe(
      ofType(Logout),
      switchMap(() => {
        return this.b2cAuthService.logout();
      }),
      switchMap(() => of(LogoutSuccess()))
    )
  );

  getUserInfo$ = createEffect(() =>
    this.action$.pipe(
      ofType(EAuthActions.GetLoginInfoSuccess),
      mergeMap((action) =>
        this.usersService.getUserInfo().pipe(
          pluck('user'),
          map((response: IUserInfo) => {
            return UsersActions.GetPermissionUserInfoSuccess({
              payload: response
            });
          }),
          catchError((error) => of(UsersActions.errorConf({ error })))
        )
      )
    )
  );

  getLoginInfo$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetLoginInfo),
      mergeMap((action) =>
        this.profilesServices.getAllProfiles().pipe(
          pluck('user'),
          map((response) => {
            return GetLoginInfoSuccess({ payload: response });
          })
        )
      )
    )
  );

  constructor(
    private toastr: I18nToastrService,
    private authService: AuthService,
    private b2cAuthService: B2CAuthService,
    private action$: Actions,
    private router: Router,
    private usersService: UsersServices,
    private profilesServices: ProfilesService,
    private store: Store<IAppState>
  ) {}
}
