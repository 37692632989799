<div class="signers-content" *ngIf="signature$ | async as signature">
  <div class="pdd-12 validation-participants">
    {{ 'VALIDATOR_UUID.FORM.SIGNS.TITLE' | translate }}
  </div>
  <div *ngIf="signers?.length > 0">
    <div *ngFor="let signatures of signers">
      <div class="row">
        <div class="col">
          <mat-accordion>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>
                    <div class="signers">
                      <span class="signers">
                        <i class="icon-signers fal fa-pen-fancy"></i>
                        {{ signatures.name }}</span
                      >
                      <span class="signers">
                        {{ ' | ' }}
                      </span>
                      <span class="signers">
                        {{ signatures?.rfc }}
                      </span>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="container-fluid" style="width: 100%">
                <div class="row">
                  <div class="validation-signers-right">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SERIE_NUMBER'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.serial_number }}
                      </div>
                    </div>
                  </div>
                  <div class="separator-width"></div>
                  <div class="validation-signers-left">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.DATE_OCSP'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.psc_timestamp }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="row">
                  <div class="validation-signers-right">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.HOUR'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.created_at }}
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="signatures?.client_ip"
                    class="separator-width"
                  ></div>
                  <div class="validation-signers-left">
                    <div *ngIf="signatures?.client_ip">
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.GEO'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures?.client_ip }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="signatures?.sign_request_signature_quotes.length > 0"
                  class="separator"
                ></div>
                <div
                  *ngIf="signatures?.sign_request_signature_quotes.length > 0"
                  class="legen-color"
                >
                  <div class="row legen-color">
                    <div class="col">
                      <div class="legen validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.LEGEN'
                            | translate
                        }}
                      </div>
                      <div class="legen-text validation-text">
                        <div
                          *ngFor="
                            let quote of signatures?.sign_request_signature_quotes
                          "
                        >
                          <div [innerHtml]="quote.signature_quote_text"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="validation-signers">
                    <div>
                      <div
                        *ngIf="signature.signature_type === 'digital_signature'"
                        class="validation-label"
                      >
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS.DIGITAL'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.sign_data }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="separator"></div>

                <div class="separator"></div>
                <div
                  class="row"
                  *ngIf="signatures?.signature_type_name !== 'natural_person'"
                >
                  <div class="validation-signers-right">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS-DIGITAL.SOCIAL_REASON'
                            | translate
                        }}
                      </div>
                      <div
                        class="validation-text"
                        *ngFor="let company of signatures.companies"
                      >
                        {{ company?.company_name }}
                      </div>
                    </div>
                  </div>
                  <div class="separator-width"></div>
                  <div class="validation-signers-left">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS-DIGITAL.RFC'
                            | translate
                        }}
                      </div>
                      <div
                        class="validation-text"
                        *ngFor="let company of signatures.companies"
                      >
                        {{ company?.company_rfc }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="row">
                  <div class="validation-signers-right">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.DATE_CERT'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.psc_timestamp }}
                      </div>
                    </div>
                  </div>
                  <div class="separator-width"></div>
                  <div class="validation-signers-left">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.PSC_POLICY'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.policy }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="row">
                  <div class="validation-signers-right">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.FINGER_PRINT'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.finger_print }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div class="row">
                  <div class="validation-signers-psc">
                    <div>
                      <div class="validation-label">
                        {{
                          'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGN_PSC'
                            | translate
                        }}
                      </div>
                      <div class="validation-text">
                        {{ signatures.psc_signature }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="separator"></div>
                <div
                  class="row"
                  *ngIf="signatures?.signature_type_name !== 'natural_person'"
                >
                  <literax-legal-signers></literax-legal-signers>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
