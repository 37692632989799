import { Component, OnInit, Input } from '@angular/core';
import { IAttachment } from '@literax/models/http/attachment.model';

@Component({
  selector: 'literax-attachment-signature',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit {
  @Input() attachment: IAttachment;
  @Input() active: boolean;

  constructor() {}

  ngOnInit() {}
}
