import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'comments-section',
  templateUrl: './comments-section.component.html',
  styleUrls: ['./comments-section.component.scss']
})
export class CommentsSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
