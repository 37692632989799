<div class="validate-rfc">
  <div class="form-title d-flex justify-content-between">
    <h5 class="mx-3 mt-3 title">
      {{ 'PROFILE.FORM.VALIDATE_RFC' | translate }}
    </h5>
    <button class="mx-3" type="button" (click)="dialogRef.close()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="form-body paddings">
    <div class="input">
      <gdx-form-field>
        <input
          type="text"
          gdxInput
          class="inline"
          [formControl]="form?.get('rfc')"
          [placeholder]="'FORM.FILE_INPUT.RFC' | translate"
          readonly
        />
      </gdx-form-field>
      <literax-api-error
        [control]="form.controls.rfc"
        [errors]="backErrors?.rfc"
      >
      </literax-api-error>
    </div>

    <div class="file-drop">
      <literax-file-input
        #cert
        [formControl]="form.get('certificate')"
        [fileTypes]="permittedCertificateFiles"
        [labelTag]="'PROFILE.FORM.CERT' | translate"
        [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
        [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
        [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
        [returnBase64]="true"
      >
      </literax-file-input>
      <literax-api-error
        [control]="form.controls.certificate"
        [errors]="backErrors?.cert"
      >
      </literax-api-error>

      <div class="file-drop">
        <literax-file-input
          [formControl]="form.get('key')"
          [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
          [fileTypes]="permittedKeyFiles"
          [labelTag]="'FORM.FILE_INPUT.LABEL_KEY' | translate"
          [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
          [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
          [returnBase64]="true"
        >
        </literax-file-input>
        <literax-api-error [control]="form?.controls?.certificate">
        </literax-api-error>

        <div class="input">
          <gdx-form-field>
            <input
              type="password"
              gdxInput
              class="inline"
              [formControl]="form?.get('password')"
              [placeholder]="'FORM.FILE_INPUT.PASSWORD' | translate"
            />
          </gdx-form-field>
          <literax-api-error
            [control]="form?.controls?.password"
            [errors]="backErrors?.pass"
          >
          </literax-api-error>
        </div>
      </div>
    </div>
  </div>
  <div class="form-footer paddings">
    <button class="button button--secundary" (click)="dialogRef.close()">
      {{ 'FORM.FILE_INPUT.CANCEL' | translate }}
    </button>
    <button
      class="button button--primary"
      [disabled]="!form.valid"
      (click)="validateRFC()"
    >
      {{ 'FORM.FILE_INPUT.VALIDATE' | translate }}
    </button>
  </div>
</div>
