import { state } from '@angular/animations';
import * as departmentActions from '@literax/store/department/department.actions';
import {
  IDepartmentState,
  initialDepartmentState
} from '@literax/store/department/department.state';
import { Action, createReducer, on } from '@ngrx/store';

const reducers = createReducer(
  initialDepartmentState,
  on(departmentActions.GetDepartmentSuccess, (state, action) => {
    const departments = [...state.departments, ...action.payload];
    const currentPage =
      action.payload.length > 0 ? state.currentPage + 1 : state.currentPage;
    return { ...state, departments, currentPage };
  }),
  on(departmentActions.ChangeSortColumn, (state, action) => ({
    ...state,
    sortColumn: action.payload,
    currentPage: 1,
    departments: []
  })),
  on(departmentActions.CreateDepartmentSuccess, (state) => ({
    ...state,
    currentPage: 1,
    departments: []
  })),
  on(departmentActions.DeleteDepartmentSuccess, (state) => ({
    ...state,
    currentPage: 1,
    departments: []
  })),
  on(departmentActions.UpdateDepartmentSuccess, (state) => ({
    ...state,
    currentPage: 1,
    departments: []
  })),
  on(departmentActions.ClearDepartments, () => ({
    ...state,
    ...initialDepartmentState
  }))
);

export function departmentReducers(state: IDepartmentState, action: Action) {
  return reducers(state, action);
}
