import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { IClient } from '@literax/models/http/api/client/client.model';
import { IResult } from '@literax/models/http/document/document.model';
import { MenuItem } from '@literax/models/menu-item.model';
import { SetCurrentClient } from '@literax/store/client/client.actions';
import {
  selectClientList,
  selectCurrentClient,
} from '@literax/store/client/client.selectors';
import * as documentActions from '@literax/store/document/document.actions';
import {
  searchDocument,
  searchDocumentFirstSuggestion,
} from '@literax/store/document/document.selectors';
import { select, Store } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, filter, map } from 'rxjs/operators';
@Component({
  selector: 'literax-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() logoUrl: string;
  @Input() opened: boolean;

  @Input() lang: string;
  @Input() currentClient: IClient;
  @Input() clients: IClient[];
  @Input() menuItems?: {
    top: MenuItem[];
    bottom: MenuItem[];
  };
  @Output() Logout = new EventEmitter();
  @Output() Profile = new EventEmitter();
  @Output() SetLang = new EventEmitter();
  @Output() changeClient = new EventEmitter();

  myControl = new FormControl();
  options = [];
  filteredOptions = this.store.pipe(
    select(searchDocument),
    filter((data) => data != null)
  );
  suggestion = this.store.pipe(select(searchDocumentFirstSuggestion));
  _name: string;
  _email: string;
  _profileImage: string;

  currentClient$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCurrentClient),
    filter((data) => data !== null)
  );

  clients$ = this.store.pipe(untilDestroyed(this), select(selectClientList));

  constructor(private router: Router, private store: Store) {
    this.myControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((data) => {
        this.filter(data);
      });
  }

  @Input() set name(value) {
    this._name = value;
  }
  @Input() set email(value) {
    this._email = value;
  }
  @Input() set profileImage(value) {
    this._profileImage = value;
  }

  clearSearch() {
    this.myControl.setValue('');
  }

  setLang(lang: string) {
    this.SetLang.emit({ event: 'set lang', lang });
  }

  logout() {
    this.Logout.emit({ event: 'Logout clicked event' });
  }

  profile() {
    this.Profile.emit({ event: 'Profile clicked event' });
  }

  change(event, client): void {
    if (event.type === 'click') {
      this.clients$
        .pipe(
          filter((clients) => clients.length > 0),
          map((clients) =>
            clients.find((store_client) => store_client.uuid === client.uuid)
          )
        )
        .subscribe((findclient: IClient) => {
          this.store.dispatch(SetCurrentClient({ payload: findclient }));
        });
    }
  }

  ngOnInit() {}

  displayOptionName(value: IResult | string) {
    if (value && typeof value === 'string') {
      return value;
    }

    if (value && typeof value !== 'string') {
      return value.name;
    }
  }

  sendToDocument(e: any, value: IResult | string) {
    if (typeof value === 'string') {
      this.myControl.setValue(value);
    } else {
      setTimeout(() => {
        this.router.navigate(['/platform', 'documents', value.id]);
      }, 1000);
    }
  }

  filter(val: string) {
    if(val !== ''){
      this.store.dispatch(documentActions.GetNavbarDocument({ payload: val }));
    }
    
  }

  ngOnDestroy(): void {}
}
