import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IUser } from '@literax/models/user.model';
import { IAppState } from '@literax/store';
import { attachments } from '@literax/store/document/document.selectors';
import {
  ButtonAction,
  CONFIG_BUTTONS,
  FLOW,
} from '@literax/utils/buttons-bar-configs';
import { select, Store } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'literax-buttons-bar-v2',
  templateUrl: './buttons-bar-v2.component.html',
  styleUrls: ['./buttons-bar-v2.component.scss'],
})
export class ButtonsBarV2Component implements OnInit, OnChanges, OnDestroy {
  @Input() document: IDocument;
  @Input() user: IUser;
  @Input() attachment: IAttachment;
  @Input() recordFilled: boolean;
  @Input() showBack: boolean;

  @Input() onlyofficePresent: boolean = false;

  @Output() action: EventEmitter<ButtonAction> = new EventEmitter();

  currentDocumentTransaction: string;
  currentDocumentStatus: string;
  currentDocumentActions: string[];

  buttons: {
    left: ButtonAction[];
    right: ButtonAction[];
  } = { left: [], right: [] };

  constructor(private store: Store<IAppState>) {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes?.document?.currentValue !== undefined) {
      this.generateButtons(this.document);
    }

    if (changes && changes?.attachment?.currentValue !== undefined) {
      this.generateButtons(this.document);
    }

    if (changes && changes?.recordFilled?.currentValue !== undefined) {
      this.generateButtons(this.document);
    }

    if (changes && changes?.showBack?.currentValue !== undefined) {
      this.generateButtons(this.document);
    }
    if (changes && changes?.recordFilled?.currentValue !== undefined) {
      this.generateButtons(this.document);
    }

    if (changes && changes?.onlyofficePresent?.currentValue !== undefined) {
      this.generateButtons(this.document);
    }
  }

  ngOnInit(): void {}

  generateButtons(document: IDocument): void {
    if (document) {
      this.currentDocumentTransaction = document?.transaction_type;
      this.currentDocumentStatus = document?.status.key;

      let buttons = [
        ...FLOW[this.currentDocumentTransaction],
        ...CONFIG_BUTTONS,
      ].filter((flowAction) => {
        if (Array.isArray(flowAction.status)) {
          return flowAction.status.some(
            (status) => status === this.currentDocumentStatus || status === '*'
          );
        } else {
          return (
            flowAction.status === this.currentDocumentStatus ||
            flowAction.status === '*'
          );
        }
      });
      if (buttons.length >= 1) {
        this.buttons = { left: [], right: [] };
        buttons.forEach((button) => {
          if (Array.isArray(button.action)) {
            button['withActions'] = true;
            Array.prototype.forEach.call(button.action, (btn) => {
              this.evalConditions(btn);
            });
          }

          this.appendToButtons(button);
        });
      }
    }
  }

  evalConditions(button: ButtonAction): ButtonAction {
    if (button.conditions) {
      this.store
        .pipe(
          untilDestroyed(this),
          select(attachments),
          filter((attachments) => attachments.length > 0)
        )
        .subscribe((attachments) => {
          Object.entries(button.conditions).map(([key, func]) =>
            func(
              { ...this.document, attachments: attachments },
              this.attachment,
              this.user,
              button,
              {
                isConfig: this.showBack,
                isRecordFilled: this.recordFilled,
                isOnlyOfficeVisible: this.onlyofficePresent,
              }
            )
          );
        });
    }
    return button;
  }

  appendToButtons(button: ButtonAction): void {
    this.evalConditions(button);
    this.buttons[button.position].push(button);
    this.buttons.right = this.movePrimarysToEnd(this.buttons.right);
  }

  movePrimarysToEnd(buttons: ButtonAction[]): ButtonAction[] {
    const buttonsPrimary = buttons.filter(
      (button) => button.class && button.class === 'primary'
    );
    const buttonsSecondary = buttons.filter(
      (button) => !button.hasOwnProperty('class')
    );
    return [...buttonsSecondary, ...buttonsPrimary];
  }
}
