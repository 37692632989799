<div class="main-container">
  <div class="header">
    <i class="fal fa-chevron-left icon back" (click)="navigateBack()"></i>
    <div class="title">{{ 'SIGNATURES.TITLE' | translate }}</div>
  </div>
  <section
    id="document-modal"
    *ngIf="document$ | async as document"
    class="mat-elevation-z3"
  >
    <div id="modal-content">
      <div id="document-main" class="preview-row">
        <div id="viewer">
          <mat-accordion>
            <mat-expansion-panel
              *ngFor="let signature of signers"
              hideToggle="false"
              multi="true"
              class="custom-color"
            >
              <mat-expansion-panel-header>
                <i class="fal fa-pen-fancy"></i>
                <div class="info">
                  <span class="signer-info"
                    >{{ signature.name }}
                    <span *ngIf="signature.rfc" class="pipe">|</span>
                    {{ signature.rfc }}</span
                  >
                </div>
              </mat-expansion-panel-header>

              <literax-signature
                [signatureProcess]="selectedAttachment.kind"
                [signature]="signature"
                [digital]="isDigitalSignature"
                [twoFactorAuth]="twoFactorAuth"
              >
              </literax-signature>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div id="options-tabs">
          <div id="title">{{ 'SIGNATURES.DOCUMENTS_LIST' | translate }}</div>
          <div id="items">
            <literax-attachment-signature
              *ngFor="let attachment of attachments; let i = index"
              [attachment]="attachment"
              [active]="activeTab == i ? true : false"
              (click)="onTabClicked(i, attachment)"
            >
            </literax-attachment-signature>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
