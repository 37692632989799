<div class="tab-title" (click)="toggleTabContent()">
  <h3 *ngIf="!hasObservers">
    {{ 'DOCUMENT-PREVIEW.SIGNERS.PARTICIPANTS' | translate }}
  </h3>
  <h3 *ngIf="hasObservers">
    {{ 'DOCUMENT-PREVIEW.SIGNERS.OBSERVERS' | translate }}
  </h3>
  <i
    [ngClass]="
      contentVisible ? 'fal fa-chevron-up icon' : 'fal fa-chevron-down icon'
    "
  ></i>
</div>
<div class="tab-component" [ngClass]="{ visible: contentVisible }">
  <div *ngIf="participantsPromissoryNote.length <= 0">
    <ng-container *ngFor="let signer of signerRequest">
      <literax-signers
        [status]="document.status.key"
        [actionButtonEnable]="signer.email !== user.email"
        [signer]="signer"
        [typePromissoryNote]="typePromissoryNote"
        [hasObservers]="hasObservers"
        [user]="user"
        [userDocument]="document.user"
        [labelButton]="'DOCUMENT-PREVIEW.SIGNERS.RESEND' | translate"
        (actionButton)="signatureAction.emit($event)"
        (historyButton)="historyButton.emit($event)"
        [userLoginObservable]="isUserLoginObserver"
        [comment]="comment"
      >
      </literax-signers>
    </ng-container>
  </div>

  <div *ngIf="participantsPromissoryNote.length > 0">
    <ng-container *ngFor="let signer of participantsPromissoryNote">
      <literax-signers
        [status]="document.status.key"
        [actionButtonEnable]="signer.email !== user.email"
        [signer]="signer"
        [typePromissoryNote]="typePromissoryNote"
        [hasObservers]="hasObservers"
        [user]="user"
        [userDocument]="document.user"
        [labelButton]="'DOCUMENT-PREVIEW.SIGNERS.RESEND' | translate"
        (actionButton)="signatureAction.emit($event)"
        (historyButton)="historyButton.emit($event)"
        [userLoginObservable]="isUserLoginObserver"
        [comment]="comment"
      >
      </literax-signers>
    </ng-container>
  </div>
</div>
<div class="info" *ngIf="!typePromissoryNote && !hasObservers">
  <button
    gdx-button
    class="setting-button"
    (debounceClick)="addSigner.emit()"
    [class.disabled]="
      (document.status.key !== 'uploaded' && document.status.key !== 'draft') ||
      !(this.document.allow_add_signers || this.forceEnabledButton) ||
      document.user.id !== user.id
    "
    [disabled]="
      (document.status.key !== 'uploaded' && document.status.key !== 'draft') ||
      !(this.document.allow_add_signers || this.forceEnabledButton) ||
      document.user.id !== user.id
    "
  >
    {{ 'DOCUMENT-PREVIEW.SIGNERS.CONFIG' | translate }}
  </button>
</div>
<div class="info" *ngIf="typePromissoryNote && !hasObservers">
  <button
    gdx-button
    class="setting-button"
    *ngIf="document.status.key === 'draft'"
    (debounceClick)="configPromissoryNote.emit()"
  >
    {{ 'DOCUMENT-PREVIEW.SIGNERS.CONFIG-PROMISSORY-NOTE' | translate }}
  </button>
</div>
