import * as UsersActions from './users.actions';
import * as profilesActions from '../../../configurations/profiles/states/profiles.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IUser, IUserInfo, IUserKindCounts } from '../models/users.interface';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { AuthService } from '@literax/services/auth/auth.service';
import { GdxToastrService } from '@interfactura/gdx-angular-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '@literax/store';
import { IFilterParams } from '@literax/interfaces/filter-param.interface';
import { IProcess } from '@literax/components/platform/profiles/types/user-ptofiles';
import { IProfile } from '../../profiles/models/profiles';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserKindEnum } from '../enums/user-kind.enum';
import { UsersServices } from '../services/users.services';
import { of } from 'rxjs';

@Injectable()
export class UsersEffect {
  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private usersServices: UsersServices,
    private authServices: AuthService,
    private toastr: GdxToastrService,
    private toastrReg: I18nToastrService,
    private router: Router
  ) {}

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UsersActions.getAllUsers,
        UsersActions.updateUsersbyIdSucess,
        UsersActions.deleteUsersSuccess,
        UsersActions.saveUsersSucess,
        UsersActions.changeSortColumn,
        UsersActions.changeUserKindFilter
      ),
      withLatestFrom(this.store.select((state) => state.users.currentPage)),
      withLatestFrom(this.store.select((state) => state.users.sortColumn)),
      withLatestFrom(this.store.select((state) => state.users.userKind)),
      distinctUntilChanged(),
      switchMap(([[[action, page], sort], userKind]) => {
        const filterParms: IFilterParams = {
          user_kind: userKind
        };
        return this.usersServices.getAll(page, sort, filterParms).pipe(
          pluck('client_users'),
          map((response: IUser[]) =>
            UsersActions.getAllUsersSucces({ client_users: response })
          ),
          catchError((error) => of(UsersActions.errorConf({ error })))
        );
      })
    )
  );

  getDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getUsersDashboard),
      switchMap(() =>
        this.usersServices.getDashboard().pipe(
          map((response) =>
            UsersActions.getUsersDashboardSuccess({
              payload: response.process as IProcess
            })
          ),
          catchError((error) =>
            of(UsersActions.getUsersDashboardError({ payload: error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.updateUsersbyId),
      mergeMap((action) =>
        this.usersServices.update(action.users).pipe(
          map(() => UsersActions.updateUsersbyIdSucess()),
          finalize(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.UPDATE_SUCCESS');
          }),
          catchError((err: HttpErrorResponse) => {
            this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  registerUserConfirmations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.registerClientUsersConfirmations),
      mergeMap((action) => {
        return this.usersServices.userConfirm(action.user, action.token).pipe(
          map((e) => {
            return UsersActions.registerClientUsersConfirmationsSucess();
          }),
          tap(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.SAVE_SUCCESS');
          }),
          catchError((error) => {
            this.setErrorMessage(error);
            return of(UsersActions.errorConf({ error: error.error }));
          })
        );
      })
    )
  );

  loginClientUserConfirmations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.loginClientUsersConfirmations),
      mergeMap((action) =>
        this.usersServices.userLoginConfirm(action.token, action.b2cToken).pipe(
          map(() => UsersActions.loginClientUsersConfirmationsSucess()),
          catchError((response) =>
            of(UsersActions.errorloginConf({ error: response.error }))
          )
        )
      )
    )
  );

  loginClientUserSucess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersActions.loginClientUsersConfirmationsSucess),
        tap((action) => this.router.navigate(['platform', 'home']))
      ),
    { dispatch: false }
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.deleteUsers),
      mergeMap((action) =>
        this.usersServices.delete(action.userId).pipe(
          map(() => UsersActions.deleteUsersSuccess()),
          finalize(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.DELETE_SUCCESS');
          }),
          catchError((error) => of(UsersActions.errorConf({ error })))
        )
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.saveUsers),
      mergeMap((action) =>
        this.usersServices.save(action.user).pipe(
          map(() => UsersActions.saveUsersSucess()),
          finalize(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.SAVE_SUCCESS');
          }),
          catchError((err: HttpErrorResponse) => {
            this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  getUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UsersActions.GetPermissionUserInfo,
        profilesActions.sendToValidateRFCSuccess
      ),
      exhaustMap((action) =>
        this.usersServices.getUserInfo().pipe(
          pluck('user'),
          map((response: IUserInfo) =>
            UsersActions.GetPermissionUserInfoSuccess({ payload: response })
          ),
          catchError((error) => {
            return of(UsersActions.errorConf({ error }));
          })
        )
      )
    );
  });

  getUserProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getUserProfiles),
      mergeMap(() =>
        this.usersServices.getUserProfiles().pipe(
          map((response) =>
            UsersActions.getUserProfilesSuccess({
              payload: { profiles: response.profiles as IProfile[] }
            })
          ),
          catchError((err: HttpErrorResponse) => {
            this.evaluateErrorCode(err);
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  setDefaultUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.SetDefaultUser),
      mergeMap((action) =>
        this.usersServices.setDefaultUser(action.payload).pipe(
          map(() => UsersActions.SetDefaultUserSuccess()),
          finalize(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.SAVE_SUCCESS');
          }),
          catchError((err: HttpErrorResponse) => {
            this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  countKind$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UsersActions.countUserKind,
        UsersActions.saveUsersSucess,
        UsersActions.deleteUsersSuccess
      ),
      withLatestFrom(
        this.store.select(
          (state) => state.users?.userInfo?.actions?.collaboration_flow
        )
      ),
      filter(([action, flowCollaborator]) => flowCollaborator),
      distinctUntilChanged(),
      switchMap(() => {
        return this.usersServices.getAll().pipe(
          pluck('client_users'),
          map((response: IUser[]) => {
            return response.filter((user) => user.role.id !== 7);
          }),
          map((response: IUser[]) => {
            const payload: IUserKindCounts = {
              responsible: response.filter(
                (x) => x.user_kind === UserKindEnum.Responsible
              ).length,
              collaborator: response.filter(
                (x) => x.user_kind === UserKindEnum.Collaborator
              ).length
            };
            return UsersActions.countUserKindSuccess({ payload });
          }),
          catchError((error) => of(UsersActions.errorConf({ error })))
        );
      })
    )
  );

  setErrorMessage(error: any) {
    let message = 'TOAST_NOTIFICATIONS.MESSAGE_GENERIC_SAVE';
    this.toastrReg.error(message, '');
  }

  disableTaxMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DisableTaxMessage),
      switchMap(() =>
        this.usersServices.disableTaxMessage().pipe(
          map(() => UsersActions.DisableTaxMessageSuccess()),
          catchError((error) =>
            of(UsersActions.DisableTaxMessageError({ payload: error }))
          )
        )
      )
    )
  );

  evaluateErrorCode(error: HttpErrorResponse) {
    const Error = error.error.error;
    const StatusCodeHttp = Error.status;
    const Message = Error.title;
    switch (StatusCodeHttp) {
      case 401:
        console.error(Message, Error);
        break;
      case 500:
        this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
        break;
      default:
        console.error(Message, Error);
        break;
    }
  }
}
