import { Component, OnDestroy, OnInit } from '@angular/core';
import { ISignerDocument } from '@literax/models/http/api/signature.model';
import { IAppState } from '@literax/store';
import { selectShowSignatureResult } from '@literax/store/validator/validator.selector';
import { select, Store } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-validator-signers',
  templateUrl: './validator-signers.component.html',
  styleUrls: ['./validator-signers.component.scss'],
})
export class ValidatorSignersComponent implements OnInit, OnDestroy {
  signature$ = this.store.pipe(
    untilDestroyed(this),
    select(selectShowSignatureResult)
  );
  signers: ISignerDocument[] = [];
  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.generateSigners();
  }
  ngOnDestroy() {}

  generateSigners() {
    this.signature$.subscribe((signatures) => {
      let signers: ISignerDocument[] = [];

      signatures.signatures.forEach((signature) => {
        let signer = signers.filter(
          (signer) =>
            signer.email === signature.email || signer.rfc === signature.rfc
        );
        if (signer.length > 0) {
          signer[0].companies.push({
            company_name: signature.company_name,
            company_rfc: signature.company_rfc,
            role: signature.role,
          });
        } else {
          let newSigner: ISignerDocument = signature;
          newSigner = {
            ...newSigner,
            companies: [],
          };
          if (signature?.company_name !== null) {
            newSigner['companies'].push({
              company_name: signature.company_name,
              company_rfc: signature.company_rfc,
              role: signature.role,
            });
          }

          signers.push(newSigner);
        }
      });

      this.signers = signers;
    });
  }
}
