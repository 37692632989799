<div class="ir-breadcrumbs">
  <ul class="ir-breadcrumbs-list">
    <!-- start: navigation-nodes [element for display navigation nodes] -->
    <li
      class="ir-breadcrumbs-list-item"
      *ngFor="let node of navNodes"
      [routerLink]="node.link"
    >
      <p>{{ node.text }}</p>
    </li>
    <!-- start: navigation-nodes [element for display navigation nodes] -->

    <li
      class="ir-breadcrumbs-list-item status"
      [ngStyle]="{ 'background-color': color }"
    >
      <p>{{ documentStatus }}</p>
    </li>
    <li class="ir-breadcrumbs-list-item document">
      <p>
        <i
          *ngIf="documentAttachments.length > 1"
          class="icon fal fa-paperclip"
        ></i
        ><span>{{ documentName }}</span>
      </p>
    </li>
  </ul>
</div>
