import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { UpdateFreeDocument } from '@literax/store/free-document/free-document.actions';
import * as signatureActions from '@literax/store/signature/signature.actions';
import * as freeSignatureActions from '@literax/store/free-signature/free-signature.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
@Component({
  selector: 'literax-sign-modal',
  templateUrl: './sign-modal.component.html',
  styleUrls: ['./sign-modal.component.scss'],
})
export class SignModalComponent implements OnInit {
  ngOnInit(): void {
    this.actions$
      .pipe(
        ofType(
          signatureActions.CreateSignatureSuccess,
          freeSignatureActions.CreateSignatureSuccess
        ),
        take(1)
      )
      .subscribe(() => {
        if (this.data.token) {
          this.store.dispatch(UpdateFreeDocument({ payload: this.data.token }));
        }
        this.closeModal();
      });
  }

  closeModal() {
    this.dialog.closeAll();
  }

  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
