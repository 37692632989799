<canvas
  id="canvas"
  class="signature"
  (mousedown)="drawSignature()"
  (mousemove)="traceSignatureMouse($event)"
  (mouseup)="redibujarTrazado()"
  (touchstart)="drawSignature()"
  (touchmove)="traceSignatureTouch($event)"
  (touchend)="redibujarTrazado()"
></canvas>
<div class="clean">
  <span (click)="cleanSignature()" class="title-font" *ngIf="clean"
    >Limpiar</span
  >
</div>
