<div class="signers-content" *ngIf="electronicSignature$ | async as signature">
  <div class="pdd-12 validation-participants">
    {{ 'VALIDATOR_UUID.FORM.SIGNS.TITLE' | translate }}
  </div>
  <div *ngIf="signers?.length > 0">
    <div *ngFor="let signatures of signers">
      <div class="row">
        <mat-accordion>
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div>
                  <div class="signers">
                    <span class="signers"
                      ><i class="icon-signers fal fa-pen-fancy"></i>
                      {{ signatures?.name }}</span
                    >
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container-fluid" style="width: 100%">
              <div class="row">
                <div class="validation-signers-right-info">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS-ELECTRONICS.FULLNAME'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures?.name }}
                    </div>
                  </div>
                </div>
                <div class="separator-width"></div>
                <div class="validation-signers-left">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS-ELECTRONICS.EMAIL'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures?.email }}
                    </div>
                  </div>
                </div>
                <div class="separator-width"></div>
                <div class="validation-signers-left">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS-ELECTRONICS.DIGITAL_TRACE'
                          | translate
                      }}
                    </div>
                    <div class="validation-text-plus">
                      <div class="row center">
                        <div class="position-digitaltrace">
                          <img
                            *ngIf="signatures?.signature_image_url"
                            [src]="signatures?.signature_image_url"
                          />
                          <br />
                          <i
                            *ngIf="
                              signature.signature_type ===
                              'electronic_signature_plus'
                            "
                            style="margin: 0px 0px 3px 18px"
                            class="far fa-shield-check"
                          >
                            {{ 'VALIDATOR_UUID.PLUS' | translate }}</i
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="row">
                <div class="validation-signers-right">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.HOUR'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures.created_at }}
                    </div>
                  </div>
                </div>
                <div class="separator-width"></div>
                <div class="validation-signers-left">
                  <div *ngIf="signatures?.client_ip">
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.GEO'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures?.client_ip }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="signatures?.sign_request_signature_quotes.length > 0"
                class="separator"
              ></div>
              <div
                *ngIf="signatures?.sign_request_signature_quotes.length > 0"
                class="legen-color"
              >
                <div class="row legen-color">
                  <div class="col">
                    <div class="legen validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.LEGEN'
                          | translate
                      }}
                    </div>
                    <div class="legen-text validation-text">
                      <div
                        *ngFor="
                          let quote of signatures?.sign_request_signature_quotes
                        "
                      >
                        <div [innerHtml]="quote.signature_quote_text"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                class="row"
                *ngIf="signatures?.signature_type_name !== 'natural_person'"
              >
                <div class="validation-signers-right">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS-ELECTRONICS.SOCIAL_REASON'
                          | translate
                      }}
                    </div>
                    <div
                      class="validation-text"
                      *ngFor="let company of signatures.companies"
                    >
                      {{ company?.company_name }}
                    </div>
                  </div>
                </div>
                <div class="separator-width"></div>
                <div class="validation-signers-left">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS-ELECTRONICS.POSITION'
                          | translate
                      }}
                    </div>
                    <div
                      class="validation-text"
                      *ngFor="let company of signatures.companies"
                    >
                      {{ company?.role }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="row">
                <div class="validation-signers-right">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.DATE_CERT'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures?.psc_timestamp }}
                    </div>
                  </div>
                </div>
                <div class="separator-width"></div>
                <div class="validation-signers-left">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.PSC_POLICY'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures?.policy }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="row">
                <div class="validation-signers-right">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.FINGER_PRINT'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures?.finger_print }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="row">
                <div class="validation-signers-psc">
                  <div>
                    <div class="validation-label">
                      {{
                        'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGN_PSC'
                          | translate
                      }}
                    </div>
                    <div class="validation-text">
                      {{ signatures?.psc_signature }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="separator"></div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
