import {
  IAPIError,
  IAPIResponseError,
} from '@literax/models/http/api/error.model';
import {
  IAutocomplete,
  IDocument,
  ICancelatioReason
} from '@literax/models/http/document/document.model';

import { IAttachment } from '@literax/models/http/attachment.model';
import { IFlow } from '@literax/models/flow.model';
import { ILastEmailEvent } from '@literax/models/http/api/sign-request/last-email-event';
import { ISignerCoordinatesUpdater } from '@literax/models/http/api/client/pdf.model';
import { ISort } from '@literax/interfaces/sort-descriptor.interface';
import { IStatus } from '../status/status.state';

export interface IUser {
  id: number;
  name: string;
}

export interface IFilterParams {
  name?: string;
  status_id?: number[];
  user_name?: string;
  signer?: string;
  created_at?: string;
  flow?: string;
  process?: string;
}

export interface ISignatureRole {
  id: number;
  name: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface ISignaturePosition {
  x: number;
  y: number;
  page: number;
  attachment_id: number;
}

export interface ISignatureRole {
  id: number;
  name: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface ISignRequest {
  id?: number;
  date_signed?: Date;
  is_author?: boolean;
  signatory_role?: ISignatureRole;
  expiry?: Date | string;
  signed_date?: Date;
  email?: string;
  name?: string;
  rfc?: string;
  role?: string;
  signed?: boolean;
  approved?: boolean;
  reviewed?: boolean;
  signature_type_id?: number;
  company_rfc?: string;
  company_name?: string;
  color?: string;
  status?: IStatus;
  signature_positions?: ISignaturePosition[];
  document_id?: number;
  created_at?: Date;
  updated_at?: Date;
  oficial_identification?: boolean;
  signature_type_name?: string;
  auth?: string;
  order?: number;
  can_send_notification?: boolean;
  legal_representatives?: Partial<ISignRequest>[];
  last_email_event?: ILastEmailEvent;
  sign_request_signature_quotes?: ISignRequestQuote[];
  approval_required?: boolean;
  holder_status?: string;
  delegate?: ISignRequest;
  is_delegate?: boolean;
}

export type ISignRequestQuote = {
  template_uid?: string;
  record_uid?: string;
  signature_quote_text?: string;
};

export const nullIDocument: IDocument = {
  id: 0,
  created_at: new Date(),
  name: '',
  sign_requests: [],
  status: { id: 0, name: '', color: '', key: null },
  status_name: '',
  updated_at: new Date(),
  expiry: new Date(),
  user: null,
  signatures: [],
  observers: [],
  attachments: [
    {
      id: 0,
      name: '',
      file_path: null,
      created_at: new Date(),
      updated_at: new Date(),
      kind: 'pdf',
      primary: null,
      multilanguage: null,
    },
  ],
  process_name: '',
  rule: '',
  signing_method: null,
};

export interface IDocumentState {
  documents: IDocument[];
  selectedDocument: Partial<IDocument>;
  signerCoordinates: ISignerCoordinatesUpdater;
  selectAttachment: IAttachment;
  attachments: IAttachment[];
  lastDocument: IDocument;
  uploadErrors: IAPIResponseError;
  currentPage: number;
  activeFilter: IFilterParams;
  deleteDocumentError: IAPIError;
  getDocumentError: IAPIResponseError;
  getAttachmentError: IAPIResponseError;
  sortColumn: string;
  flows: IFlow[];
  navbarDocument: IAutocomplete;
  onlyoffice_key: string;
  hidden: boolean;
  currentSort: ISort[];
  commentRejected: ICancelatioReason[];
  lastUploadedAttachment: IAttachment;
  pending_seend: number;
}

export const initialDocumentState: IDocumentState = {
  documents: [],
  selectedDocument: null,
  selectAttachment: null,
  attachments: [],
  lastDocument: null,
  uploadErrors: null,
  currentPage: 1,
  activeFilter: null,
  deleteDocumentError: null,
  getDocumentError: null,
  getAttachmentError: null,
  signerCoordinates: null,
  sortColumn: 'status.order:asc',
  flows: null,
  navbarDocument: null,
  onlyoffice_key: null,
  hidden: false,
  currentSort: [{ field: 'status.order', dir: 'asc' }],
  commentRejected: [],
  lastUploadedAttachment: null,
  pending_seend: 0,
};

export interface IReviewerToSignerList {
  resource_uuid?: string;
  approval_required?: boolean;
  document_id: number;
  signature_type_id: number;
  signature_type_name?: string;
  company_name: string;
  company_rfc: string;
  name: string;
  rfc: string;
  email: string;
  expiry: string | Date;
  is_approver: boolean;
}
