<div class="wrapper">
  <div class="sign-row">
    <div *ngIf="digital" class="div-wrapper-medium" id="serial-div">
      <div class="title">{{ 'SIGNATURES.INFO.SERIAL_NUMBER' | translate }}</div>
      <div class="data">{{ signature?.serial_number }}</div>
    </div>

    <div *ngIf="!digital" class="div-wrapper-medium" id="nam-div">
      <div class="title">{{ 'SIGNATURES.INFO.NAME' | translate }}</div>
      <div class="data">
        {{ signature?.name }}
        <a
          *ngIf="signature?.id_image_url"
          id="signature-image-link"
          [href]="signature?.id_image_url"
          target="_blank"
          ><i class="fal fa-id-card"></i
        ></a>
      </div>
    </div>

    <div *ngIf="digital" class="div-wrapper-medium" id="nam-div">
      <div class="title">&nbsp;</div>
      <div class="data">
        <a
          *ngIf="signature?.id_image_url"
          class="ml-3"
          id="signature-image-link"
          [href]="signature?.id_image_url"
          target="_blank"
          ><i class="fal fa-id-card"></i
        ></a>
      </div>
    </div>

    <div *ngIf="!digital" class="div-wrapper-medium mrlr" id="email-div">
      <div class="title">{{ 'SIGNATURES.INFO.EMAIL' | translate }}</div>
      <div class="data">{{ signature?.email }}</div>
    </div>
    <div *ngIf="!digital" class="div-wrapper-medium" id="digital-trace-div">
      <div class="title">{{ 'SIGNATURES.INFO.DIGITAL_TRACE' | translate }}</div>
      <div class="data">
        <img [src]="signature?.signature_image_url" alt="digital trace" />
      </div>
    </div>
    <div
      *ngIf="digital"
      class="div-wrapper-medium mrlr"
      id="ocsp-timestamp-div"
    >
      <div class="title" style="margin: 0px 0px 0px 16px">
        {{ 'SIGNATURES.INFO.OCSP_TIMESTAMP' | translate }}
      </div>
      <div style="margin: 0px 0px 0px 16px" class="data">
        {{ signature?.created_at | date: 'dd-MM-yyyy HH:mm a' }} /
        {{ signature?.created_at }}
      </div>
    </div>
  </div>
  <div class="sign-row">
    <div class="div-wrapper" id="time-div">
      <div class="title">{{ 'SIGNATURES.INFO.TIME' | translate }}</div>
      <div class="data">
        {{ signature?.created_at | date: 'dd-MM-yyyy HH:mm a' }} /
        {{ signature?.created_at }}
      </div>
    </div>
    <div *ngIf="signature?.client_ip" class="div-wrapper-medium mrlr"></div>
    <div *ngIf="signature?.client_ip" class="div-wrapper-medium" id="geo-div">
      <div style="margin: 0px 0px 0px -42px" class="title">
        {{ 'SIGNATURES.INFO.GEO' | translate }}
      </div>
      <div style="margin: 0px 0px 0px -42px" class="data">
        {{ signature?.client_ip }}
      </div>
    </div>
    <div *ngIf="!digital && twoFactorAuth" class="div-wrapper" id="2fa-div">
      <div class="data two-factor-auth">
        <i class="fal fa-shield-check"></i>
        <span class="title">2FA</span>
      </div>
    </div>
  </div>

  <div class="sign-row" *ngIf="signature?.companies?.length > 0">
    <div class="div-wrapper" id="time-div">
      <div class="title">{{ 'SIGNATURES.INFO.COMPANY_NAME' | translate }}</div>
      <div class="data" *ngFor="let company of signature?.companies">
        {{ company?.company_name }}
      </div>
    </div>
    <div class="div-wrapper" id="time-div">
      <div class="title">{{ 'SIGNATURES.INFO.ROLE' | translate }}</div>
      <div class="data" *ngFor="let company of signature?.companies">
        {{ company?.role }}
      </div>
    </div>
  </div>

  <div *ngIf="digital" class="sign-row">
    <div class="div-wrapper" id="signature-div">
      <div class="title">{{ 'SIGNATURES.INFO.SIGNATURE' | translate }}</div>
      <div class="data">{{ signature?.sign_data }}</div>
    </div>
  </div>

  <div
    *ngIf="signature?.sign_request_signature_quotes.length > 0"
    class="sign-row"
  >
    <div class="div-wrapper" id="signature-div">
      <div class="title">
        {{ 'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.LEGEN' | translate }}
      </div>
      <div class="data">
        <div *ngFor="let quote of signature?.sign_request_signature_quotes">
          <div [innerHtml]="quote.signature_quote_text"></div>
        </div>
      </div>
    </div>
  </div>

  <br />
  <div class="title-nom">NOM 151</div>

  <div class="sign-row">
    <div class="div-wrapper-medium" id="timestamp-div">
      <div class="title">{{ 'SIGNATURES.INFO.TIMESTAMP' | translate }}</div>
      <div class="data">
        {{ signature?.psc_timestamp | date: 'dd-MM-yyyy HH:mm a' }} /
        {{ signature?.psc_timestamp }}
      </div>
    </div>

    <div class="div-wrapper-medium mrlr"></div>
    <div class="div-wrapper-medium mrlr" id="psc-policy-div">
      <div style="margin: 0px 0px 0px 16px" class="title">
        {{ 'SIGNATURES.INFO.PSC_POLICY' | translate }}
      </div>
      <div style="margin: 0px 0px 0px 16px" class="data">
        {{ signature?.policy }}
      </div>
    </div>
  </div>
  <div class="sign-row">
    <div class="div-wrapper" id="fingerprint-div">
      <div class="title">{{ 'SIGNATURES.INFO.FINGER_PRINT' | translate }}</div>
      <div class="data">{{ signature?.finger_print }}</div>
    </div>
  </div>
  <div class="sign-row">
    <div class="div-wrapper" id="psc-signature-div">
      <div class="title">{{ 'SIGNATURES.INFO.PSC_SIGNATURE' | translate }}</div>
      <div class="data">{{ signature?.psc_signature }}</div>
    </div>
  </div>

  <div *ngIf="signatureProcess !== 'promissory_note'">
    <div *ngIf="digital" class="sign-row">
      <div
        *ngIf="signature?.legal_signatures?.length > 0"
        class="div-wrapper"
        id="person_legal"
      >
        <div class="title-representation">En representación de:</div>
      </div>
    </div>
    <div *ngIf="digital">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let legal_signer of signature?.legal_signatures"
          hideToggle="false"
          multi="true"
          class="custom-color"
        >
          <mat-expansion-panel-header>
            <i class="fal fa-pen-fancy"></i>
            <div class="info">
              <span class="signer-info"
                >{{ legal_signer.company_name }}
                <span *ngIf="legal_signer.rfc" class="pipe">|</span>
                {{ legal_signer.company_rfc }}</span
              >
            </div>
          </mat-expansion-panel-header>
          <div class="sign-row">
            <div class="div-wrapper-medium" id="serial-div">
              <div class="title">
                {{ 'SIGNATURES.INFO.SERIAL_NUMBER' | translate }}
              </div>
              <div class="data">{{ legal_signer?.serial_number }}</div>
            </div>
            <div class="div-wrapper-medium mrlr" id="email-div"></div>
            <div class="div-wrapper-medium" id="ocsp-timestamp-div">
              <div class="title">
                {{ 'SIGNATURES.INFO.OCSP_TIMESTAMP' | translate }}
              </div>
              <div class="data">{{ legal_signer?.ocsp_request_date }}</div>
            </div>
          </div>
          <div class="sign-row">
            <div class="div-wrapper" id="signature-div">
              <div class="title">
                {{ 'SIGNATURES.INFO.SIGNATURE' | translate }}
              </div>
              <div class="data">{{ legal_signer?.sign_data }}</div>
            </div>
            <div class="div-wrapper-medium" id="geo-divmoral">
              <div style="margin: 0px 0px 0px 17px" class="title">
                {{ 'SIGNATURES.INFO.GEO' | translate }}
              </div>
              <div style="margin: 0px 0px 0px 17px" class="data">
                {{ signature?.client_ip }}
              </div>
            </div>
          </div>
          <br />
          <div class="title-nom">NOM 151</div>
          <div class="sign-row">
            <div class="div-wrapper-medium" id="timestamp-div">
              <div class="title">
                {{ 'SIGNATURES.INFO.TIMESTAMP' | translate }}
              </div>
              <div class="data">
                {{ legal_signer?.psc_timestamp | date: 'dd-MM-yyyy HH:mm a' }} /
                {{ legal_signer?.psc_timestamp }}
              </div>
            </div>

            <div class="div-wrapper-medium mrlr"></div>
            <div class="div-wrapper-medium mrlr" id="psc-policy-div">
              <div style="margin: 0px 0px 0px 16px" class="title">
                {{ 'SIGNATURES.INFO.PSC_POLICY' | translate }}
              </div>
              <div style="margin: 0px 0px 0px 16px" class="data">
                {{ legal_signer?.policy }}
              </div>
            </div>
          </div>
          <div class="sign-row">
            <div class="div-wrapper" id="fingerprint-div">
              <div class="title">
                {{ 'SIGNATURES.INFO.FINGER_PRINT' | translate }}
              </div>
              <div class="data">{{ legal_signer?.finger_print }}</div>
            </div>
          </div>
          <div class="sign-row">
            <div class="div-wrapper" id="psc-signature-div">
              <div class="title">
                {{ 'SIGNATURES.INFO.PSC_SIGNATURE' | translate }}
              </div>
              <div class="data">{{ legal_signer?.psc_signature }}</div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
