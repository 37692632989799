import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ICLientUsersState } from './users.reducer';

export const getUserState = createFeatureSelector<ICLientUsersState>('users');
export const getSelectAll = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.users
);

export const getErrorLogin = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.errorLogin && state.errorLogin.error && state.errorLogin.error.title
);

export const getErrorRegistro = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.erroRegister &&
    state.erroRegister.error &&
    state.erroRegister.error.title
);

export const getErrorRegisterTitle = createSelector(
  getUserState,
  (state: ICLientUsersState) => {
    let errorsDetail: string[] = [];
    errorsDetail.push(state?.erroRegister?.error?.title);
    for (const errorsDetailKey in state?.erroRegister?.error.detail) {
      errorsDetail = [
        ...errorsDetail,
        state?.erroRegister?.error.detail[errorsDetailKey]
      ];
    }
    return errorsDetail;
  }
);

/*
wip: preparacion para modificacion de endpoint
export const getFormErrors = createSelector(getUserState, (state:ICLientUsersState) => state.errors);
:wip preparacion para modificacion de endpoint
*/
export const getErrorRegisterDetail = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.erroRegister &&
    state.erroRegister.error &&
    state.erroRegister.error.detail
);
export const getErrorLoginDetail = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.errorLogin && state.errorLogin.error && state.errorLogin.error.detail
);

export const selectEmailError = createSelector(
  getErrorRegisterDetail,
  (state: any) => state && state.email
);

export const selectPasswordError = createSelector(
  getErrorRegisterDetail,
  (state: any) => state && state.password
);

export const selectRFCError = createSelector(
  getErrorRegisterDetail,
  (state: any) => state && state.rfc
);

export const selectLoginEmailError = createSelector(
  getErrorLoginDetail,
  (state: any) => state && state.email
);

export const selectLoginPasswordError = createSelector(
  getErrorLoginDetail,
  (state: any) => state && state.password
);

export const selectUserPermissions = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state && state.userInfo && state.userInfo.actions
);

export const selectUserMainProfile = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.userInfo?.main_profile
);

export const selectUserProfiles = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.userInfo?.profiles
);

export const selectUserPermissionsRole = createSelector(
  getUserState,
  (state: any) => state && state.userInfo
);

export const isFirstLogin = createSelector(
  getUserState,
  (state: any) => !!state?.userInfo?.welcome_tour
);

export const getClientSignatureType = createSelector(
  getUserState,
  (state: any) => state?.userInfo?.client_signature_type_id
);
export const isRoleColaborator = createSelector(
  getUserState,
  (state: any) => state?.userInfo?.actions?.show_client_user
);

export const isProfileVisible = createSelector(
  getUserState,
  (state) => state?.userInfo?.actions?.show_user_profile
);

export const isSignVisible = createSelector(
  getUserState,
  (state) => state?.userInfo?.actions?.share_document
);

export const isCompanyVisible = createSelector(
  getUserState,
  (state) => state?.userInfo?.actions?.update_client_profile
);

export const getUserDefault = createSelector(
  getUserState,
  (state: any) => state && state.userInfo && state.userInfo.default_client
);

export const userKindCount = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.userKindCounts
);

export const userKind = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.userKind
);

export const GetCompanyPermissions = createSelector(
  getUserState,
  (state: ICLientUsersState) => {
    const permissions = {
      createCompany: state.userInfo.actions.create_company,
      updateCompany: state.userInfo.actions.update_company,
      deleteCompany: state.userInfo.actions.delete_company,
      showCompany: state.userInfo.actions.show_company
    };
    return permissions;
  }
);

export const isApiIntegratorVisible = createSelector(
  getUserState,
  (state) => state?.userInfo?.actions?.show_config_api
);

export const GetUsersReceiversActive = createSelector(getUserState, (state) => {
  const users = [];
  state.users.forEach((user) => {
    if (
      user.status === 'active' &&
      user.receive_documents &&
      user.role_id !== 7
    ) {
      users.push({
        value: user.id,
        label: user.full_name,
        department: user.department.name
      });
    }
  });
  return users;
});

export const GetDefaultExpiryDays = createSelector(
  getUserState,
  (state) => state?.userInfo?.default_expiry_days || 30
);

export const selectDefaultUser = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.userInfo?.default_client
);

export const selectUsersDashboard = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.dashboard?.flows
);
