<form>
  <div class="ajust-container">
    <h6>
      {{ 'WORKSPACE.FOOTER.CONFIRM_APPROVAL.TITLE_MODAL' | translate }}
    </h6>
    <hr />

    <div class="dialog">
      <div class="message-container">
        <div class="row">
          <div class="col-md-9">
            <p class="title">
              {{
                'WORKSPACE.FOOTER.CONFIRM_APPROVAL.TITLE_ADD_CONSTANCY_APPROVAL'
                  | translate
              }}
            </p>
            <p class="message">
              {{
                'WORKSPACE.FOOTER.CONFIRM_APPROVAL.SUBTITLE_ADD_CONSTANCY_APPROVAL'
                  | translate
              }}
            </p>
          </div>
          <div class="col">
            <literax-toggle-switch [form]="constancy"> </literax-toggle-switch>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <p class="title">
              {{
                'WORKSPACE.FOOTER.CONFIRM_APPROVAL.TITLE_ADD_PARTICIPANTS_EXIST'
                  | translate
              }}
            </p>
            <p class="message">
              {{
                'WORKSPACE.FOOTER.CONFIRM_APPROVAL.SUBTITLE_ADD_PARTICIPANTS_EXIST'
                  | translate
              }}
            </p>
          </div>
          <div class="col">
            <literax-toggle-switch [form]="participants">
            </literax-toggle-switch>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="row-buttons">
      <div class="buttons">
        <button
          mat-button
          class="btn-cancel"
          [class.disabled]="cancelDisabled"
          (debounceClick)="cancelAction($event)"
        >
          {{ 'WORKSPACE.FOOTER.CONFIRM_APPROVAL.BUTTON_CANCEL' | translate }}
        </button>
        <button
          mat-button
          class="btn-next"
          [class.disabled]="backDisabled"
          (debounceClick)="nextAction($event)"
        >
          {{ 'WORKSPACE.FOOTER.CONFIRM_APPROVAL.BUTTON_CONTINUE' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
