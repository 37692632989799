<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="row message">
          <div class="col-12 text-center">
            <h2>{{ 'HTTP.ERRORS.SIGNED.TITLE' | translate }}</h2>
            <h4>{{ 'HTTP.ERRORS.SIGNED.MESSAGE' | translate }}</h4>
            <h4>
              {{ 'HTTP.ERRORS.SIGNED.INVITATION' | translate }}
              <a [href]="link">{{
                'HTTP.ERRORS.SIGNED.COMPANY' | translate
              }}</a>
            </h4>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
