import { createAction, props } from '@ngrx/store';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import {
  CreateFieldResponse,
  Field,
  ITemplatesFields
} from '@literax/models/templates-fields.model';

export enum ETemplatesActions {
  GetAllFields = '[Templates Fields] GetAllFields',
  GetAllFieldsSucess = '[Templates Fields] GetAllFieldsSucess',
  ErrorFields = '[Templates Fields]  ErrorFields',
  SortColumn = '[Templates Fields] SortColumn',
  CreateField = '[Templates Fields] CreateField',
  CreateFieldSuccess = '[Templates Fields] CreateFieldSuccess',
  CreateFieldError = '[Templates Fields] CreateFieldError',
  UpdateField = '[Templates Fields] UpdateField',
  UpdateFieldSuccess = '[Templates Fields] UpdateFieldSuccess',
  UpdateFieldError = '[Templates Fields] UpdateFieldError',
  DeleteField = '[Templates Fields] DeleteField',
  DeleteFieldSuccess = '[Templates Fields] DeleteFieldSuccess',
  DeleteFieldError = '[Templates Fields] DeleteFieldError',
  CleanFieldsState = '[Templates Fields] CleanFieldsState'
}

export const getAllFields = createAction(ETemplatesActions.GetAllFields);

export const getAllFieldsSuccess = createAction(
  ETemplatesActions.GetAllFieldsSucess,
  props<{ payload: ITemplatesFields[] }>()
);

export const errorFields = createAction(
  ETemplatesActions.ErrorFields,
  props<{ payload: IAPIResponseError }>()
);

export const sortColumn = createAction(
  ETemplatesActions.SortColumn,
  props<{ payload: string }>()
);

export const createField = createAction(
  ETemplatesActions.CreateField,
  props<{ payload: Field }>()
);

export const createFieldSuccess = createAction(
  ETemplatesActions.CreateFieldSuccess,
  props<{ payload: Field }>()
);

export const createFieldError = createAction(
  ETemplatesActions.CreateFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const updateField = createAction(
  ETemplatesActions.UpdateField,
  props<{ payload: Field }>()
);

export const updateFieldSuccess = createAction(
  ETemplatesActions.UpdateFieldSuccess,
  props<{ payload: Field }>()
);

export const updateFieldError = createAction(
  ETemplatesActions.UpdateFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const deleteField = createAction(
  ETemplatesActions.DeleteField,
  props<{ payload: number }>()
);

export const deleteFieldSuccess = createAction(
  ETemplatesActions.DeleteFieldSuccess
);

export const deleteFieldError = createAction(
  ETemplatesActions.DeleteFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const cleanFieldsState = createAction(
  ETemplatesActions.CleanFieldsState
);
