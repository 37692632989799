import * as Sentry from '@sentry/browser';

import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import { GDX_ERRORS_MAP, GdxInputModule } from '@interfactura/gdx-angular-ui';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { metaReducers, reducers } from '@literax/store';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthEffects } from '@literax/store/auth/auth.effects';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { AuthService } from './services/auth/auth.service';
import { B2CAuthModule } from './b2c-auth/b2c-auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CompanyEffects } from '@literax/store/company/company.effects';
import { DocumentsService } from './services/platform/documents.service';
import { EffectsModule } from '@ngrx/effects';
import { ErrorResetPasswordComponent } from './components/auth/error-password/error-password.component';
import { FileUploadModule } from 'ng2-file-upload';
import { GDX_ERRORS } from './utils/gdx-ui-errors.utils';
import { HttpLoaderFactory } from '@literax/services/translate/http-loader.factory';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoginClientusersComponent } from './components/auth/login-clientusers/login-clientusers.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { OnboardingComponent } from './components/auth/onboarding/onboarding.component';
import { OnboardingService } from './services/onboarding/onboarding.service';
import { ProfilesEffect } from './components/configurations/profiles/states/profiles.effects';
import { RegisterClientusersComponent } from './components/auth/register-clientusers/register-clientusers.component';
import { RegistrationEffects } from './store/registration/registration.effects';
import { RegistrationFormDialogComponent } from './components/auth/registration-form-dialog/registration-form-dialog.component';
import { ReportEffects } from '@literax/store/report/report.effects';
import { SharedModule } from '@literax/components/shared/shared.module';
import { SignRequestEffects } from './store/sign-request/sign-request.effects';
import { SignaturesModule } from './components/signatures/signatures.module';
import { StatusEffects } from './store/status/status.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UsersEffect } from './components/configurations/users/states/users.effects';
import { ValidatorModule } from './components/validator/validator.module';
import { environment } from '@environments/environment';
import { ReportPromissoryNoteEffects } from './store/report/report-promissory-note.effects';

if (!environment.production) {
  Sentry.init({
    dsn: environment.sentryDsn,
  });
}
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    console.error(error);
  }
}

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    RegistrationFormDialogComponent,
    ErrorResetPasswordComponent,
    OnboardingComponent,
    LoginClientusersComponent,
    RegisterClientusersComponent,
  ],
  imports: [
    SharedModule,
    MatIconModule,
    BrowserModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FileUploadModule,
    SignaturesModule,
    ValidatorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AuthEffects,
      RegistrationEffects,
      StatusEffects,
      SignRequestEffects,
      UsersEffect,
      ProfilesEffect,
      ReportEffects,
      CompanyEffects,
      ReportPromissoryNoteEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'Interfirma UI',
      maxAge: 25,
      logOnly: environment.production,
    }),
    AppRoutingModule,
    ToastrModule.forRoot({ maxOpened: 5 }),
    NgbModule,
    GdxInputModule,
    B2CAuthModule,
  ],
  providers: [
    MatIconRegistry,
    AuthService,
    DocumentsService,
    OnboardingService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: GDX_ERRORS_MAP, useValue: GDX_ERRORS, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [RegistrationFormDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    private translate: TranslateService,
    public matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }
}
