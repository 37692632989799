import { Action, createReducer, on } from '@ngrx/store';
import * as catalogActions from './catalog.actions';
import { ICatalogState, initialCatalogState } from './catalog.state';

const reducers = createReducer(
  initialCatalogState,
  on(catalogActions.getCatalogSuccess, (state, action) => {
    return {
      ...state,
      values: action.payload
    };
  })
);

export function catalogReducers(state: ICatalogState, action: Action) {
  return reducers(state, action);
}
