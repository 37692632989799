import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IDashboard } from '@literax/models/http/api/dashboard.model';
import { Process } from '@literax/models/process.model';

export const getDashboard = createAction('[Dashboard] GetDashboard');

export const getDashboardSuccess = createAction(
  '[Dashboard] GetDashboard Success',
  props<{ payload: IDashboard }>()
);

export const getDashboardError = createAction(
  '[Dashboard] GetDashboard Error',
  props<{ payload: IAPIResponseError }>()
);

export const showDashboard = createAction(
  '[Dashboard] ShowDashboard',
  props<{ payload: string }>()
);

export const showDashboardSuccess = createAction(
  '[Dashboard] ShowDashboard Success',
  props<{ payload: Process }>()
);

export const showDashboardError = createAction(
  '[Dashboard] ShowDashboard Error',
  props<{ payload: IAPIResponseError }>()
);

export const getExternalProcess = createAction('[Dashboard] GetExternalProcess');

export const getExternalProcessSuccess = createAction(
  '[Dashboard] GetExternalProcess Success',
  props<{ payload: Process }>()
);

export const getInternalProcess = createAction('[Dashboard] GetInternalProcess');

export const getInternalProcessSuccess = createAction(
  '[Dashboard] GetInternalProcess Success',
  props<{ payload: Process }>()
);

export const getPromissoryNotesProcess = createAction('[Dashboard] GetPromissoryNotesProcess');

export const getPromissoryNotesProcessSuccess = createAction(
  '[Dashboard] GetPromissoryNotesProcess Success',
  props<{ payload: Process }>()
);

export const getSharedProcess = createAction('[Dashboard] GetSharedProcess');

export const getSharedProcessSuccess = createAction(
  '[Dashboard] GetSharedProcess Success',
  props<{ payload: Process }>()
);
