import { IAPIResponseError } from '@literax/models/http/api/error.model';

import {
  IReviewer,
  IReviewerToSigner,
  IReviewRequest
} from '@literax/models/reviewer.model';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IReviewerToSignerList,
  ISignRequest
} from '@literax/store/document/document.state';

export enum EReviewRequestActions {
  CreateReviewRequest = '[ReviewRequest] Create Review request',
  CreateReviewRequestSucces = '[ReviewRequest] Create Review Request Success',
  CreateReviewRequestError = '[ReviewRequest] Create Review Reqest Error',

  UpdateReviewrequest = '[Review Request] Update Review Request',
  UpdateReviewrequestSuccess = '[Review Request] Update Review Request Success',
  UpdateReviewrequestError = '[Review Request] Update Review Request Error',

  DeleteReviewrequest = '[Review Request] Delete Review Request',
  DeleteReviewrequestSuccess = '[Review Request] Delete Review Request',
  DeleteReviewrequestError = '[Review Request] Delete Review Request',

  ApproveReviewRequest = '[ReviewRequest] Approve review request',
  ApproveReviewRequestSucces = '[ReviewRequest] Approve review request success',
  ApproveReviewRequestError = '[ReviewRequest] Approve review request error',

  SendReviewersToSigners = '[ReviewRequest] Send reviewers to signers',
  SendReviewersToSignersSuccess = '[ReviewRequest] Send reviewers to signers success',
  SendReviewersToSignersError = '[ReviewRequest] Send reviewers to signers error',

  SendReviewersToApprovers = '[ReviewRequest] Send reviewers to Approvers',
  SendReviewersToApproversSuccess = '[ReviewRequest] Send reviewers to Approvers success',
  SendReviewersToApproversError = '[ReviewRequest] Send reviewers to Approvers error'
}

/**
 * Action para la creacion de una solicitud de revision
 */
export const CreateReviewRequest = createAction(
  EReviewRequestActions.CreateReviewRequest,
  props<{ payload: { documentId: number; reviewRequest: IReviewRequest } }>()
);

/**
 * Action para los errors de creacion de solicitud de revision
 */
export const CreateReviewRequestSucces = createAction(
  EReviewRequestActions.CreateReviewRequestSucces,
  props<{ payload: IReviewer }>()
);

/**
 * Action para los errors de creacion de solicitud de revision
 */
export const CreateReviewRequestError = createAction(
  EReviewRequestActions.CreateReviewRequestError,
  props<{ payload: IAPIResponseError }>()
);

/**
 * Action para la actualizacion de una solicitud de revision
 */
export const UpdateReviewRequest = createAction(
  EReviewRequestActions.UpdateReviewrequest,
  props<{ payload: { documentId: number; reviewRequest: IReviewRequest } }>()
);

export const UpdateReviewRequestSuccess = createAction(
  EReviewRequestActions.UpdateReviewrequestSuccess,
  props<{ payload: boolean }>()
);

export const DeleteReviewrequest = createAction(
  EReviewRequestActions.DeleteReviewrequest,
  props<{ payload: { documentId: number; reviewRequest: IReviewRequest } }>()
);

export const SendReviewersToSigners = createAction(
  EReviewRequestActions.SendReviewersToSigners,
  props<{
    payload: {
      participants: IReviewerToSignerList[];
      action: string;
      route: string;
    };
  }>()
);

export const SendReviewersToSignersSuccess = createAction(
  EReviewRequestActions.SendReviewersToSignersSuccess,
  props<{ payload: { action: string; route: string } }>()
);

export const SendReviewersToSignersError = createAction(
  EReviewRequestActions.SendReviewersToSignersError,
  props<{ payload: HttpErrorResponse }>()
);

export const DeleteReviewrequestSucces = createAction(
  EReviewRequestActions.DeleteReviewrequestSuccess
);

export const DeleteReviewrequestError = createAction(
  EReviewRequestActions.DeleteReviewrequestError
);

export const ApproveReviewRequest = createAction(
  EReviewRequestActions.ApproveReviewRequest
);
