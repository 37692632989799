import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogData } from '@literax/components/shared/compose-attachment-file/models/dialog-data';
import { EButtonActions } from '@literax/components/shared/compose-attachment-file/enums/button-actions.enum';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomFormValidator } from '@literax/components/shared/form-lib/custom-form.validator';
import { ICreateAttachment } from '@literax/models/http/Attachment/create-attachment.model';
import { EFileTypes } from '@literax/components/platform/wizard-v3/enums/file-types.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'literax-modal-attachment',
  templateUrl: './modal-attachment.component.html',
  styleUrls: ['./modal-attachment.component.scss'],
})
export class ModalAttachmentComponent implements OnInit, OnDestroy {
  EButtonActions = EButtonActions;

  attachmentForm: FormGroup;
  permittedFiles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([
    '.pdf',
    '.docx',
  ]);

  fileType: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalAttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {
    this.initForm();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  initForm(): void {
    this.typePermittedFiles();
    switch (this.data?.action) {
      case EButtonActions.WRITE:
        this.attachmentForm = this.formBuilder.group({
          name: new FormControl(null, [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(70),
            CustomFormValidator.documentNameFormat,
          ]),
        });
        break;
      case EButtonActions.UPLOAD:
        this.attachmentForm = this.formBuilder.group({
          name: new FormControl(this.data?.name, [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(70),
            CustomFormValidator.documentNameFormat,
          ]),
          file: new FormControl(null, [
            Validators.required,
            CustomFormValidator.requiredFileType(...this.permittedFiles$.value),
            CustomFormValidator.maxFileSize(70),
          ]),
        });
        break;
    }
  }

  typePermittedFiles(): void {
    if (this.data?.kind === 'pdf') {
      this.permittedFiles$.next(['.pdf']);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveAction(): void {
    if (
      !this.data.hasOwnProperty('documentId') &&
      !this.data.hasOwnProperty('attachmentId')
    ) {
      this.data.callback(this.generateBodyCreateAttachment());
    } else {
      this.data.callback(
        this.generateBodyCreateAttachment(),
        this.data.documentId,
        this.data.attachmentId
      );
    }
    this.closeDialog();
  }

  private generateBodyCreateAttachment(): ICreateAttachment {
    let body: ICreateAttachment = {
      name: this.attachmentForm.value.name,
      kind: 'docx',
    };
    if (this.data?.action === EButtonActions.UPLOAD) {
      body.file = this.attachmentForm.value.file;
      body.kind = this.fileType === EFileTypes.pdf ? 'pdf' : 'docx';
    }
    return body;
  }
}
