import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

import { IAppState } from '@literax/store';
import { RemoveFromStack } from '@literax/store/title-navbar/title-navbar.actions';
import { Router } from '@angular/router';
import { TitleNavbarConfig } from '@literax/models/title-navbar-config.model';
import { selectStack } from '@literax/store/title-navbar/title-navbar.selector';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-title-navbar',
  templateUrl: './title-navbar.component.html',
  styleUrls: ['./title-navbar.component.scss'],
})
export class TitleNavbarComponent implements OnInit, OnDestroy {
  cards$ = this.store
    .pipe<IAppState, TitleNavbarConfig[]>(
      untilDestroyed(this),
      select(selectStack)
    )
    .pipe(
      filter((stack) => stack.length > 0),
      map((stack) => {
        return stack.map((el) => ({
          ...el,
          textColor: this.getContrastYIQ(el?.color),
        }));
      })
    );
  defaultColor = '#424242';
  constructor(private store: Store<IAppState>, private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  getContrastYIQ(hexcolor) {
    if (!hexcolor) {
      return;
    }
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split('')
        .map(function (hex) {
          return hex + hex;
        })
        .join('');
    }
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }

  onCardClick(config: TitleNavbarConfig, index: number, isCurrent: boolean) {
    if (!isCurrent) {
      if (config.route) {
        this.router.navigate([config.route]);
        this.store.dispatch(RemoveFromStack({ payload: index }));
      }
    }
  }
}
