import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { IDocument } from '@literax/models/http/document/document.model';
import { DocumentsSignService } from '@literax/services/sign/documents-sign.service';
import { IAppState } from '@literax/store';
import { ValidateDocumentSignatureSuccess } from '@literax/store/free-document/free-document.actions';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ValidateTokenDocumentFreeGuard implements CanActivate {
  constructor(
    private store: Store<IAppState>,
    private documentService: DocumentsSignService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = next.paramMap.get('token');
    return this.isValid(token);
  }

  isValid(token: string) {
    return this.documentService.getDocumentDetail(token).pipe(
      map((response) => {
        this.store.dispatch(
          ValidateDocumentSignatureSuccess({
            payload: { document: response.document as IDocument, token }
          })
        );
        return true;
      }),
      catchError((error) => {
        if (error.status === 403) {
          this.router.navigate(['/signed']);
        } else if (error.status === 401) {
          this.router.navigate(['/e404-token']);
        }
        return of(error);
      })
    );
  }
}
