import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

export interface HisotryDialogData {
  payload: any;
  signer: any;
}

@Component({
  selector: 'literax-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  history: any[];
  sendEmail: boolean = false;
  histoyIcons = {
    processed: 'fal fa-check neutro fa-stack-1x',
    dropped: 'fal fa-exclamation-triangle fa-stack-1x',
    deferred: 'fal fa-exclamation-triangle fa-stack-1x',
    bounce: 'fal fa-exclamation-triangle fa-stack-1x',
    delivered: 'fal fa-check neutro fa-stack-1x',
    opened: 'fal fa-check green fa-stack-1x',
    clicked: 'fal fa-check green fa-stack-1x',
    unsubscribed: 'fal fa-exclamation-triangle fa-stack-1x', //no implementado
    spam_report: 'fal fa-exclamation-triangle fa-stack-1x',
  };

  secondHistoyIcons = {
    processed: 'fa fa-circle neutro fa-stack-2x',
    dropped: 'fa fa-circle yellow fa-stack-2x',
    deferred: 'fa fa-circle yellow fa-stack-2x',
    bounce: 'fa fa-circle yellow fa-stack-2x',
    delivered: 'fa fa-circle neutro fa-stack-2x',
    opened: 'fa fa-circle green fa-stack-2x',
    clicked: 'fa fa-circle green fa-stack-2x',
    unsubscribed: 'fa fa-circle yellow fa-stack-2x', //no implementado
    spam_report: 'fa fa-circle yellow fa-stack-2x',
  };

  isDisabled: boolean;

  constructor(
    private dialogRef: MatDialogRef<HistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HisotryDialogData
  ) {}

  ngOnInit() {
    this.data;
    this.history = this.data.payload?.history;
    this.sendEmail = false;
    this.isDisabled = this.data.signer?.status === 'Signed';
  }

  sendMailClicked() {
    this.isDisabled ? (this.sendEmail = false) : (this.sendEmail = true);
    this.onCloseClick();
  }

  onCloseClick() {
    this.dialogRef.close({ data: this.sendEmail });
  }

  formatDate(time: any) {
    return !!time ? moment(time).format('DD-MM-YYYY, hh:mm A') : '';
  }
}
