import { ISignRequest } from './../../../../store/document/document.state';
import { IUser } from './../../../../models/user.model';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { IDocument } from '@literax/models/http/document/document.model';
import { IAttachment } from '@literax/models/http/attachment.model';

@Component({
  selector: 'literax-buttons-bar',
  templateUrl: './buttons-bar.component.html',
  styleUrls: ['./buttons-bar.component.scss'],
})
export class ButtonsBarComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  private _user: IUser;
  // tslint:disable-next-line: variable-name
  private _document: IDocument;
  isSigner: boolean;
  isOwner: boolean;
  filteredActions = [];
  canSign: boolean;
  isDraftReview: boolean;
  isReview: boolean;
  typeDocument: string;
  documentStatus: string;
  isTemplate: boolean;
  isDocReview = false;
  isDocApprove = false;
  isReviewed: boolean;
  isApproved: boolean;
  isReviewDoc: boolean;
  transaction_type: string;

  @Input() disabledSignButton = false;
  @Input() disabledSendToSignButton = true;
  @Input() isCollectData: boolean;
  @Input() isCheckReview: boolean;
  @Input() isCheckApprove: boolean;

  @Output() markAsClick = new EventEmitter();
  @Output() backClick = new EventEmitter();
  @Output() deleteFileClick = new EventEmitter();
  @Output() signatureClick = new EventEmitter();
  @Output() downloadClick = new EventEmitter();
  @Output() cancelClick = new EventEmitter();
  @Output() rejectSignatureClick = new EventEmitter();
  @Output() shareDocumentClick = new EventEmitter(); // TODO: implement button
  @Output() showSignatureClick = new EventEmitter();
  @Output() sendToSignClick = new EventEmitter();
  @Output() sendToCollectClick = new EventEmitter();
  @Output() signaturesClick = new EventEmitter();
  @Output() editPaymentclick = new EventEmitter();
  @Output() sendToReview = new EventEmitter();
  @Output() sendToReviewed = new EventEmitter();
  @Output() approve = new EventEmitter();
  @Output() cancelReview = new EventEmitter();
  @Output() sendToApproved = new EventEmitter();
  @Output() reviewToApproval = new EventEmitter();
  @Output() concludeReview = new EventEmitter();
  @Output() approveToSignature = new EventEmitter();
  @Output() rejectedApproval = new EventEmitter();

  @Output() saveData = new EventEmitter();

  @Input() isDigital = true;
  @Input() configButtons = false;
  @Input() set document(document: IDocument) {
    this._document = document;
    this.isSigner = false;
    this.isOwner = false;
    this.filteredActions = this.filterActions(document?.next_actions);
    this.updateSignStatusButton(document);
    this.detectTemplate(document);
    this.canSign =
      document?.status.key === 'in_process' &&
      this.canUserSign(document?.user_signer) &&
      this.canOrder(this.document);
    if (this.user) {
      this.isSigner = document?.user_signer.length > 0;
      this.isOwner = document?.user.id === this.user.id;
    }
    this.isDocReview = document?.transaction_type === 'review';
    this.isDocApprove = document?.transaction_type === 'approval';
    this.isReviewed = document?.status.key === 'reviewed';
    this.isApproved = document?.status.key === 'approved';
    this.documentStatus = document?.status.key;
    this.transaction_type = document?.transaction_type;

    if (!this.isTemplate && this.isDigital) {
      this.disabledSendToSignButton = false;
    }

    this.isReview = document?.status.key === 'review';
    this.isDraftReview =
      document?.status.key == 'draft' &&
      document?.transaction_type === 'review';
  }
  get document(): IDocument {
    return this._document;
  }
  @Input() set user(user: IUser) {
    this._user = user;
    this.isSigner = false;
    this.isOwner = false;
    this.canSign =
      this.document?.status.key === 'in_process' &&
      this.canUserSign(this.document?.user_signer) &&
      this.canOrder(this.document) &&
      this.document.current_order === this.document?.user_signer[0]?.order;
    if (this.document && user) {
      this.isSigner = this.document?.user_signer.length > 0;
      this.isOwner = this.document?.user.id === user.id;
    }
  }

  @Input() set currentAttachments(currentAttachments: any) {
    if (currentAttachments !== null) {
      this.typeDocument = currentAttachments.kind;
    }
  }

  get user(): IUser {
    return this._user;
  }

  filterActions(nextActions: string[]): string[] {
    const fobiddenActions = [
      'sign',
      'reject',
      'cancel_signature',
      'send_notification',
      'mark_as_in_process',
      'send_collection',
      'send_review',
      'reviewed_to_signature',
    ];
    return nextActions?.filter((e) => fobiddenActions.indexOf(e) < 0);
  }

  canOrder(document: IDocument) {
    return document.current_order === this.document.user_signer[0].order;
  }

  canUserSign(userSigners: ISignRequest[]) {
    if (userSigners) {
      return !userSigners.reduce(
        (state, signer) => state && signer.signed,
        true
      );
    } else {
      return false;
    }
  }

  ngOnInit() {}

  updateSignStatusButton(document: IDocument) {
    const unreadAttachments = document?.attachments?.filter(
      (element: IAttachment) => !element?.text
    );
    this.disabledSignButton = unreadAttachments?.length > 0;
  }

  detectTemplate(document: IDocument) {
    this.isTemplate = document?.attachments?.some(
      (obj) => obj.kind === 'template'
    );
  }

  constructor() {}
}
