import { Injectable } from '@angular/core';
import { DocumentSignature } from '@literax/models/document-signature';
import { SingRequestForm } from '@literax/models/http/api/signature.model';
import { Observable } from 'rxjs';
import { KEYUTIL } from 'jsrsasign';
import { ServiceResponse } from '../../interfaces/service-response';
import { BaseService } from '../base-config/base.service';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  readonly prefixes = {
    cer: '-----BEGIN CERTIFICATE-----\r\n',
    key: '-----BEGIN ENCRYPTED PRIVATE KEY-----\r\n'
  };

  readonly suffixes = {
    cer: '\r\n-----END CERTIFICATE-----\r\n',
    key: '\r\n-----END ENCRYPTED PRIVATE KEY-----\r\n'
  };

  constructor(private base: BaseService) {}

  /**
   * Send signature to specific document
   * @param documentId The document id to sign
   * @param params The parameter to send specified in ISignature model
   * @returns An observable of ServiceResponse
   */
  sendSignature(
    documentId: number,
    params: SingRequestForm
  ): Observable<ServiceResponse> {
    const path = `/documents/${documentId}/signatures`;
    return this.base.post(path, params);
  }

  openKey(privateKey: string, password: string) {
    try {
      const pemKey = this.formatFile(privateKey, 'key');
      return KEYUTIL.getKeyFromEncryptedPKCS8PEM(pemKey, password);
    } catch (error) {
      return null;
    }
  }

  private formatFile(fileBase64: string, fileType: string) {
    const fileString = fileBase64.split(',')[1];
    return (
      this.getFilePrefix(fileType) +
      this.formatPEM(fileString) +
      this.getFileSuffix(fileType)
    );
  }

  private getFilePrefix(fileType: string): string {
    return this.prefixes[fileType] || '';
  }

  private getFileSuffix(fileType: string): string {
    return this.suffixes[fileType] || '';
  }

  private formatPEM(pem: string) {
    return pem.match(/.{1,64}/g).join('\r\n');
  }
  twoFactorToken(signRequestIds: number[]) {
    const path = `/two_factor_tokens`;
    const body = {
      two_factor_token: signRequestIds
    };
    return this.base.post(path, body);
  }
  createSimpleSignature(
    documentID: number,
    simpleSignature: DocumentSignature
  ): Observable<ServiceResponse> {
    const path = `/documents/${documentID}/signatures`;
    return this.base.post(path, simpleSignature);
  }

  showSignature(hashId: string): Observable<ServiceResponse> {
    const path = `/signature_validator/${hashId}`;
    return this.base.get(path);
  }

  getSignatureQuote(searchValue: string) {
    return this.base.get(`/signature_quotes?rfc_or_email=${searchValue}`);
  }
}
