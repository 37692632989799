import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AttachmentEffects } from '@literax/store/attachment/attachment.effects';
import { DocumentEffects } from '@literax/store/document/document.effects';
import { SignaturePositionEffects } from '@literax/store/signature-position/signature-position.effects';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { AttachmentComponent } from './attachment/attachment.component';
import { SignatureComponent } from './signature/signature.component';
import { SignaturesComponent } from './signatures/signatures.component';

@NgModule({
  declarations: [AttachmentComponent, SignatureComponent, SignaturesComponent],
  imports: [
    SharedModule,
    EffectsModule.forFeature([
      DocumentEffects,
      AttachmentEffects,
      SignaturePositionEffects
    ])
  ],
  exports: [
    CommonModule,
    AttachmentComponent,
    SignatureComponent,
    SignaturesComponent
  ],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SignaturesModule {}
