import { Injectable } from '@angular/core';
import { ICreateAttachment } from '@literax/models/http/Attachment/create-attachment.model';
import { IFilterParams } from '@literax/store/document/document.state';
import { serialize } from '@literax/utils/json-to-form-data';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../../interfaces/service-response';
import { BaseService } from '../base-config/base.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {
  perPage = 20;

  constructor(private base: BaseService) {}

  /**
   * Gets all documents from the specified page
   * @param page The current page
   * @param sort The parameter to sort by, divided with a semicolon with the sorting
   * way (asc or desc). Ex: name:asc. Defaults to id:desc
   * @returns An observable of ServiceResponse
   */
  getAttachments(
    page: number,
    filterParams?: IFilterParams,
    sort = 'id:desc'
  ): Observable<ServiceResponse> {
    let path = `/documents?sort=${sort}&page=${page}&per_page=${this.perPage}`;
    if (filterParams) {
      for (const key in filterParams) {
        if (filterParams.hasOwnProperty(key)) {
          const element = filterParams[key];
          if (element instanceof Array) {
            element.forEach((id) => {
              path = path.concat(
                `&filter_params[${key}][]=${encodeURIComponent(id)}`
              );
            });
          } else if (key === 'created_at') {
            path = path.concat(
              `&daterange_params[${key}]=${encodeURIComponent(element)}`
            );
          } else {
            switch (key) {
              case 'process': {
                path = path.concat(`&process=${encodeURIComponent(element)}`);
                break;
              }
              case 'flow': {
                path = path.concat(`&flow=${encodeURIComponent(element)}`);
                break;
              }
              default: {
                path = path.concat(
                  `&search_params[${key}]=${encodeURIComponent(element)}`
                );
                break;
              }
            }
          }
        }
      }
    }
    return this.base.get(path);
  }

  getAttachmentDetail(
    documentId: number | string,
    id: number | string
  ): Observable<ServiceResponse> {
    return this.base.get(
      `/documents/${documentId}/attachments/${id}?signed_preview=true`
    );
  }

  getAttachmentUrlForDownload(documentId: number): Observable<ServiceResponse> {
    return this.base.get(`/documents/${documentId}?download=true`);
  }

  createAttachment(
    documentId: number | string,
    attachment: ICreateAttachment
  ): Observable<ServiceResponse> {
    return this.base.post(
      `/documents/${documentId}/attachments/`,
      serialize({ attachment })
    );
  }

  updateAttachment(
    documentId: number | string,
    attachmentId: number | string,
    attachment: ICreateAttachment
  ): Observable<ServiceResponse> {
    return this.base.put(
      `/documents/${documentId}/attachments/${attachmentId}`,
      serialize({ attachment })
    );
  }

  deleteAttachment(
    documentId: number | string,
    id: number | string
  ): Observable<ServiceResponse> {
    return this.base.delete(`/documents/${documentId}/attachments/${id}`);
  }

  checkAttachment(
    documentId: number,
    attachmentId: number,
    signRequestId: number
  ): Observable<ServiceResponse> {
    return this.base.post(
      `/documents/${documentId}/attachments/${attachmentId}/check`,
      {
        sign_request_id: signRequestId
      }
    );
  }
}
