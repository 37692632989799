import {
  IAPIError,
  IAPIResponseError,
} from '@literax/models/http/api/error.model';
import { IClient } from '@literax/models/promissory-notes.model';
import { IProfile } from '../../components/configurations/profiles/models/profiles';

export interface AuthState {
  currentUser: IProfile;
  clients: Partial<IClient>[];
  loginError: IAPIError;
  updateProfileError: IAPIResponseError;
  updatePasswordSuccess: boolean;
  accessToken: string;
  permissions: object;
}

export const initialAuthState: AuthState = {
  currentUser: null,
  clients: [],
  loginError: null,
  updateProfileError: null,
  updatePasswordSuccess: null,
  accessToken: null,
  permissions: {},
};
