import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { Process } from '@literax/models/process.model';
import {
  IFolder,
  IPromissoryNotes,
  IPromissoryNoteTemplate,
  IRoles,
  PromissoryNotePayment
} from '@literax/models/promissory-notes.model';

export interface IPromissoryNotesState {
  promissoryNotes: IFolder[];
  selectPromissoryNote: IFolder;
  currencys: [];
  payments: PromissoryNotePayment[];
  selectPayment: PromissoryNotePayment;
  configData: IPromissoryNotes;
  error: IAPIResponseError;
  flows: Process;
  roles: IRoles[];
  currentPage: number;
  sortColumn: string;
  promissoryNoteTemplates: IPromissoryNoteTemplate[];
  promissoryNoteTemplateSelect: IPromissoryNoteTemplate;
  promissoryNoteWithData: IPromissoryNotes;
  templateId: number;
  has_promissory_note_templates: boolean;
}

export const initialPromissoryNotesState: IPromissoryNotesState = {
  promissoryNotes: [],
  selectPromissoryNote: null,
  currencys: [],
  payments: [],
  selectPayment: null,
  configData: null,
  error: null,
  flows: {
    name: null,
    key: null,
    flows: []
  },
  roles: [],
  currentPage: 1,
  sortColumn: 'created_at:desc',
  templateId: 0,
  promissoryNoteTemplates: [],
  promissoryNoteTemplateSelect: null,
  promissoryNoteWithData: null,
  has_promissory_note_templates: false
};
