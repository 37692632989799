import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAppState } from '@literax/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'literax-confirm-dialog-delegate',
  templateUrl: './confirm-dialog-delegate.component.html',
  styleUrls: ['./confirm-dialog-delegate.component.scss'],
})
export class ConfirmDialogDelegateComponent implements OnInit {
  constructor(
    private store: Store<IAppState>,
    private dialogRef: MatDialogRef<ConfirmDialogDelegateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.data.cancelCallback();
    this.dialogRef.close();
  }

  submitForm() {
    this.data.acceptCallback();
    this.dialogRef.close();
  }
}

export interface ConfirmDialogData {
  acceptCallback: () => void;
  cancelCallback: () => void;
  addThis: () => void;
  acceptButton: string;
  cancelButton: string;
  message: string;
  title: string;
  clientUuid: string;
  user: string;
}
