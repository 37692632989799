import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[validateSignatureType]'
})
export class ValidateSignatureTypeDirective implements OnInit, OnChanges {
  @Input('validateSignatureType') electronicSignature: boolean;
  constructor(private element: ElementRef, private renderer: Renderer2) {}
  ngOnInit() {
    this.changeStyle();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.changeStyle();
  }
  changeStyle() {
    if (!this.electronicSignature) {
      this.renderer.addClass(this.element.nativeElement, 'digital');
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'digital');
    }
  }
}
