import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@literax/components/shared/shared.module';
import { MsalModule } from '@azure/msal-angular';
import { LOGIN_COMPONENTS } from './components';
import { MSAL_PROVIDERS } from './providers/msal.providers';

@NgModule({
  declarations: [...LOGIN_COMPONENTS],
  imports: [SharedModule, MsalModule, RouterModule],
  providers: [...MSAL_PROVIDERS],
  exports: [...LOGIN_COMPONENTS]
})
export class B2CAuthModule {}
