<div class="viewer-container">
  <div
    *ngIf="
      fetchDocumentInProcess &&
      (attachment?.kind === 'pdf' ||
        attachment?.kind === 'template' ||
        attachment?.kind === 'ticket' ||
        attachment?.kind === 'support' ||
        attachment?.kind === 'docx')
    "
    class="mifirma-loading-message"
  >
    <div class="container">
      <i class="material-icons rotating">autorenew</i>
      <p>{{ 'DOCUMENTS.DOCUMENTS_LOADING' | translate }}</p>
    </div>
  </div>

  <div
    *ngIf="fetchDocumentsError && !fetchDocumentInProcess"
    class="mifirma-error-message"
  >
    <div class="container">
      <i class="material-icons">refresh</i>
      <p>{{ 'DOCUMENTS.DOCUMENTS_ERROR' | translate }}</p>
    </div>
  </div>

  <button
    *ngIf="isXMLAttachment"
    type="button"
    class="toggle-xml"
    (click)="toggleXML()"
  >
    {{
      (displayPDF
        ? 'DOCUMENT-PREVIEW.XML.SHOW_XML_BUTTON'
        : 'DOCUMENT-PREVIEW.XML.SHOW_PDF_BUTTON'
      ) | translate
    }}
  </button>

  <!-- start: Visor PDF documentos comunes -->
  <div
    *ngIf="displayPDF"
    class="pdfViewerContainer"
    [hidden]="fetchDocumentInProcess"
  >
    <literax-if-pdf
      [document]="documentData"
      [documentId]="selectedAttachment"
      [url]="docUrl"
      [showSigners]="
        document?.status?.key === 'draft' &&
        document?.signing_method !== 'digital_signature'
      "
      [signRequests]="signRequest"
      [signatureQuote]="haveSignatureQuote"
      (updatePositionEmitter)="updatePositionEmitter.emit($event)"
      (renderComplete)="renderComplete($event)"
      [displayQuote]="displayQuote"
    ></literax-if-pdf>
  </div>
  <!-- end: Visor PDF documentos comunes -->

  <!-- start: Visor de markdown -->
  <div
    *ngIf="attachment?.plain_text && attachment?.kind === 'md'"
    class="markdown-container"
  >
    <div class="markdown-document">
      <markdown
        class="markdown-text"
        [data]="attachment?.plain_text"
      ></markdown>
    </div>
  </div>
  <!-- end: Visor de markdown -->

  <!-- start: Visor XML -->
  <div *ngIf="!displayPDF && isXMLAttachment" class="xml-code-container">
    <div class="xml-code-document">
      <pre><code #xml>{{attachment?.plain_text | prettifyXML}}</code></pre>
    </div>
  </div>
  <!-- end: Visor XML -->

  <!-- start: Visor DOCX -->
  <div *ngIf="displayOnlyOffice" class="onlyoffice">
    <div #onlyOffice class="pdfViewerContainer"></div>
  </div>
  <!-- end: Visor DOCX -->
</div>
