<div class="dialog">
  <div class="message-container">
    <p class="title">
      {{ data.title | translate }}
    </p>
    <p class="message">
      {{ data.message | translate }}
    </p>
  </div>
  <literax-wizard-buttons
    cancel="{{ data.cancelButton || 'DOCUMENTS.BUTTONS.CANCEL' | translate }}"
    next="{{ data.acceptButton || 'DOCUMENTS.BUTTONS.DELETE' | translate }}"
    (cancelEvent)="cancelEvent()"
    (nextEvent)="acceptEvent()"
  >
  </literax-wizard-buttons>
</div>
