export enum EDocumentStatus {
  DRAFT = 'draft',
  REVIEW = 'review',
  REVIEWED = 'reviewed',
  APPROVAL = 'approval',
  REJECTED_APPROVAL = 'rejected_approval',
  CANCEL_COLLECTION = 'cancel_collection',
  DATA_COLLECTION = 'data_collection',
  IN_PROCESS = 'in_process',
  GENERATED = 'generated',
  CANCEL_SIGNATURE = 'cancel_signature',
  CANCEL_REVIEW = 'cancel_review',
  CANCEL_APPROVAL = 'cancel_approval',
  CANCEL_REQUEST = 'cancel_request',
  CANCEL_REQUEST_SENT = 'cancel_request_sent',
  REJECTED= 'rejected'
}

export enum SetClientStrategy {
  FIRST_LOGIN = 'first_login',
  CLIENT_SELECTED = 'client_selected',
}
