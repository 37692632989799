import { IAPIError, IAPIResponseError } from '@literax/models/http/api/error.model';

export interface IRegistrationState {
  signUpSuccess: boolean;
  signUpErrors: IAPIResponseError;
  verifySuccess: boolean;
  verifyErrors: IAPIResponseError;
}

export const initialRegistrationState: IRegistrationState = {
  signUpSuccess: null,
  signUpErrors: null,
  verifySuccess: null,
  verifyErrors: null
};
