<div class="new-document">
  <div class="form-title d-flex justify-content-between">
    <h5 class="mx-3 mt-3 title">
      {{ data.title | translate }}
    </h5>
    <button class="mx-3" type="button" (click)="closeModal()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="container">
        <div class="row">
          <div class="col-4 offset-4 text-center">
            <i class="fal fa-check-circle text-success fa-5x"></i>
          </div>
          <div class="col-12 text-center-description">
            {{ data.message | translate }}
          </div>
          <div class="col text-center-user">
            {{ data.user }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row-buttons">
      <div class="buttons">
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <button
              mat-button
              class="button button--primary"
              type="submit"
              (click)="submitForm()"
            >
              {{ data.acceptButton | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
