import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { selectDocument } from '@literax/store/document/document.selectors';
import { MatDialogRef } from '@angular/material/dialog';
import { GetDocument } from '@literax/store/document/document.actions';
import { map, tap, take } from 'rxjs/operators';
import { CleanSignRequestFormStates } from '@literax/store/sign-request/sign-request.actions';
import * as documentActions from '../../../store/document/document.actions';
@Component({
  selector: 'literax-add-signer-wizard',
  templateUrl: './add-signer-wizard.component.html',
  styleUrls: ['./add-signer-wizard.component.scss'],
})
export class AddSignerWizardComponent implements OnInit {
  document$ = this.store.pipe(select(selectDocument));
  selectedProcess = 'signature';
  processes = ['signature'];
  processIcons = {
    revision: 'fa-glasses',
    approval: 'fa-check-double',
    signature: 'fa-pen-fancy',
  };

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(CleanSignRequestFormStates());
      this.document$.pipe(take(1)).subscribe((document) => {
        this.store.dispatch(
          documentActions.GetDocument({ payload: document.id })
        );
      });
    });
  }

  onFinish() {
    this.dialogRef.close();
  }

  selectProcess(process: string) {
    this.selectedProcess = process;
  }

  constructor(
    private store: Store<IAppState>,
    private dialogRef: MatDialogRef<AddSignerWizardComponent>
  ) {}
}
