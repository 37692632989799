import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  GdxButtonModule,
  GdxExpansionPanelModule,
  GdxFormFieldModule,
  GdxNavigationTabsModule,
  GdxRowModule,
  GdxSearchBarModule,
  GdxSelectModule,
  GdxTableModule,
  GdxVirtualScrollModule
} from '@interfactura/gdx-angular-ui';

@NgModule({
  declarations: [],
  exports: [
    GdxSearchBarModule,
    GdxButtonModule,
    GdxTableModule,
    GdxVirtualScrollModule,
    GdxSelectModule,
    GdxRowModule,
    MatSlideToggleModule,
    GdxFormFieldModule,
    GdxExpansionPanelModule,
    GdxNavigationTabsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GdxModule {}
