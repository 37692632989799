import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store, select } from '@ngrx/store';

import { BaseService } from '@literax/services/base-config/base.service';
import { IAppState } from '@literax/store';
import {
  IDocument,
  ICancelatioReason,
} from '@literax/models/http/document/document.model';
import { ISignRequest } from '@literax/store/document/document.state';
import { ISignatureResponse } from '@literax/models/http/api/signature.model';
import { IUser } from '@literax/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { selectCommentRejected } from '@literax/store/document/document.selectors';
import { selectPromissoryNote } from '@literax/store/promissory-notes/promissory-notes.selector';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-signed-list-container',
  templateUrl: './signed-list-container.component.html',
  styleUrls: ['./signed-list-container.component.scss'],
})
export class SignedListContainerComponent implements OnInit, OnDestroy {
  @Input() document: IDocument;
  @Input() signerRequest: ISignRequest[];
  @Input() typePromissoryNote: boolean;
  @Input() hasObservers: boolean;
  @Output() addSigner: EventEmitter<void> = new EventEmitter<void>();
  @Output() signatureAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() historyButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() configPromissoryNote: EventEmitter<any> = new EventEmitter<any>();
  @Input() user: IUser;
  @Input() forceEnabledButton: boolean;
  contentVisible = true;
  signatures: { [key: string]: ISignatureResponse } = {};

  isUserLoginObserver = false;
  participantsPromissoryNote: ISignRequest[] = [];

  promissoryNote$ = this.store.pipe(
    untilDestroyed(this),
    select(selectPromissoryNote)
  );

  comment$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCommentRejected)
  );
  comment: ICancelatioReason[];

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    if (this.document && this.document.signatures) {
      this.document.signatures.forEach((s) => (this.signatures[s.rfc] = s));
    }
    this.finUserObserver(this.document.observers);

    if (
      this.typePromissoryNote &&
      (this.document.status.key === 'signed' ||
        this.document.status.key === 'in_effect' ||
        this.document.status.key === 'expired' ||
        this.document.status.key === 'extinc')
    ) {
      this.getPromissoryNoteParticipant();
    }
    this.comment$
      .pipe(untilDestroyed(this))
      .subscribe((reason) => (this.comment = reason));
  }

  ngOnDestroy(): void {}

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }

  finUserObserver(observers) {
    observers.find((observer) => {
      this.isUserLoginObserver = observer.email === this.user.email;
    });
  }

  getPromissoryNoteParticipant() {
    this.promissoryNote$.pipe().subscribe((data) => {
      if (data) {
        this.participantsPromissoryNote = data?.participants;
      }
    });
  }
}
