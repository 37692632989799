<div class="col-auto p-0 navbar-button">
  <button
    gdx-button
    matRipple
    [matMenuTriggerFor]="translateMenu"
    buttonStyle="primary"
    class="nav-button mr-3"
  >
    {{ lang | uppercase }}
  </button>
  <mat-menu #translateMenu="matMenu">
    <button *ngIf="lang != 'en'" mat-menu-item (click)="setLang('en')">
      ENGLISH
    </button>
    <button *ngIf="lang != 'es'" mat-menu-item (click)="setLang('es')">
      ESPAÑOL
    </button>
  </mat-menu>
</div>
