<div class="mat-dialog-legal-container">
  <h1 mat-dialog-title>{{ 'AUTH.DIALOG.TITLE' | translate }}</h1>
  <div mat-dialog-content>
    <p>{{ 'AUTH.DIALOG.BODY-1' | translate }}</p>
    <p>{{ 'AUTH.DIALOG.BODY-2' | translate }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button
      gdx-button
      buttonStyle="primary"
      class="interfirma-button mrr-10 primary"
      (click)="onCloseClick()"
    >
      {{ 'AUTH.DIALOG.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
