<router-outlet></router-outlet>
<div *ngIf="showDocument">
  <div class="title-header-ajust">
    <div class="title-header" *ngIf="document$ | async as document">
      <literax-title-navbar></literax-title-navbar>
    </div>
  </div>

  <!-- START: workspace -->
  <section
    id="document-modal"
    *ngIf="document$ | async as document"
    class="mat-elevation-z3"
  >
    <!-- START: workspace-header [version, icons] -->
    <div id="document-header">
      <div class="doc">
        <div class="version">
          {{ 'DOCUMENT-PREVIEW.INFORMATION.VERSION' | translate }} -
          <span *ngIf="(currentAttachment$ | async)?.kind === 'docx'">{{
            (currentAttachment$ | async)?.updated_at
              | date: 'dd/MM/yyyy - h:mm a '
          }}</span>
          <span *ngIf="(currentAttachment$ | async)?.kind !== 'docx'">{{
            document.created_at | date: 'dd/MM/yyyy - h:mm a '
          }}</span>
          <i class="fal fa-chevron-down icon"></i>
        </div>
        <div></div>
      </div>
      <div class="tab">
        <div class="container">
          <mat-icon
            [hidden]="i === 4 && !(validFormTemplate$ | async)"
            [svgIcon]="images[o]"
            color="blue"
            class="tablinks"
            [ngClass]="{
              active: visibleInfo[i],
              'icon-badge-custom': i == 2 && canInitializeChat,
              'd-none': i === 2 && !canInitializeChat
            }"
            *ngFor="let o of options; let i = index"
            (click)="activateInfoTab(i)"
            [ngbTooltip]="
              'WORKSPACE.UI.TABICONS.' + o.toLocaleUpperCase() | translate
            "
            [container]="'body'"
            [tooltipClass]="'literax-tooltip'"
            [placement]="'bottom-left'"
          >
            <span
              *ngIf="
                i === 2 && (notificationsValue$ | async) as numberNotifications
              "
              class="badge-custom"
              >{{ numberNotifications }}</span
            >
          </mat-icon>
        </div>
      </div>
    </div>
    <!-- END: workspace-header [version, icons] -->

    <!-- START: workspace-viewr -->
    <div *ngIf="visible[0]" [ngClass]="{ active: visible[0] }">
      <div id="document-main" class="preview-row">
        <div class="pdfViewerContainer">
          <literax-viewer
            id="viewer"
            [document]="document$ | async"
            [selectedAttachment]="(currentAttachment$ | async)?.id"
            [attachment]="currentAttachment$ | async"
            [signRequest]="signRequests$ | async"
            (updatePositionEmitter)="updatePosition($event)"
            [user]="user$ | async"
            [watchingIn]="'user'"
            [reload]="_reload"
            [typeDocVisible]="typeDocVisible$ | async"
            [action]="viewerAction$ | async"
            (reloadCompleted)="afterOnlyofficeReload()"
            (onlyofficeVisible)="onlyofficeVisible = $event"
          ></literax-viewer>
        </div>

        <!-- START: workspace-tabs-content -->

        <div id="options-tabs">
          <div
            class="tabcontent info-tab"
            *ngIf="visibleInfo[0]"
            [ngClass]="{ active: visibleInfo[0] }"
          >
            <literax-document-attachments-container
              [authUser]="user$ | async"
              [document]="document$ | async"
              (attachmentSelected)="selectAttachment($event)"
              (editAttachment)="editAttachment($event)"
              [typePromissoryNote]="!isCommonDocument"
              (checkAttachmentId)="checkAttachment()"
            >
            </literax-document-attachments-container>
          </div>

          <div
            start
            document
            class="tabcontent send-tab"
            *ngIf="visibleInfo[1] && (user$ | async) as user"
            [ngClass]="{ active: visibleInfo[1] }"
          >
            <literax-signed-list-container
              *ngIf="
                !(isReview$ | async) &&
                !(isApprove$ | async) &&
                !(isApplicant$ | async)
              "
              [document]="document$ | async"
              [signerRequest]="signRequests$ | async"
              [typePromissoryNote]="!isCommonDocument"
              [user]="user"
              [forceEnabledButton]="forceEnabledSigners$ | async"
              (addSigner)="onAddSignerClicked()"
              (configPromissoryNote)="openEditPromissoryNote()"
              (signatureAction)="onSignerAction($event.signer, document.id)"
              (historyButton)="
                historyDocumentDialog($event.signer, document.id)
              "
            ></literax-signed-list-container>

            <literax-reviewers-list-container
              *ngIf="isReview$ | async"
              [document]="document$ | async"
              [reviewerRequest]="signRequests$ | async"
              [user]="user"
              (addReviewer)="onAddReviewerClicked()"
              (historyButton)="
                historyDocumentDialog($event.signer, document.id)
              "
              (actionButton)="onSignerAction($event.signer, document.id)"
            >
            </literax-reviewers-list-container>

            <literax-approvers-list-container
              *ngIf="isApprove$ | async"
              [document]="document$ | async"
              [ApproverRequest]="signRequests$ | async"
              [user]="user"
              (addApprover)="onAddApproverClicked()"
              (historyButton)="
                historyDocumentDialog($event.signer, document.id)
              "
              (actionButton)="onSignerAction($event.signer, document.id)"
            >
            </literax-approvers-list-container>

            <literax-applicants-list-container
              *ngIf="isApplicant$ | async"
              [document]="document$ | async"
              [applicantRequest]="signRequests$ | async"
              [user]="user"
            >
            </literax-applicants-list-container>

            <div *ngIf="hasObservers$ | async">
              <literax-signed-list-container
                [document]="document$ | async"
                [signerRequest]="observers$ | async"
                [typePromissoryNote]="!isCommonDocument"
                [user]="user"
                [hasObservers]="hasObservers$ | async"
                (signatureAction)="onSignerAction($event.signer, document.id)"
                (historyButton)="
                  historyDocumentDialog($event.signer, document.id)
                "
              >
              </literax-signed-list-container>
            </div>
          </div>

          <div
            class="tabcontent sign-tab"
            *ngIf="visibleInfo[3]"
            [ngClass]="{ active: visibleInfo[3] }"
          >
            <literax-document-detail-information
              [document]="document$ | async"
              [contentVisible]="visibleInfo[3]"
            >
            </literax-document-detail-information>
          </div>

          <div
            class="tabcontent info-tab p-2"
            [hidden]="!(visibleInfo[4] && (validFormTemplate$ | async))"
            [ngClass]="{ active: visibleInfo[4] }"
          >
            <ng-container *ngIf="showTemplateMetadata || isTemplate">
              <div class="p-0" [hidden]="!showSignatureQuote">
                <gdx-record
                  *ngFor="let quote of signatureQuotes$ | async"
                  [isB2C]="false"
                  [token]="b2cToken"
                  [templateId]="quote?.template_uid"
                  [recordId]="quote?.record_uid"
                  [lang]="(lang$ | async) || 'es'"
                  (valid)="validateSignatureQuote($event)"
                >
                </gdx-record>
              </div>
              <div class="p-0" [hidden]="showSignatureQuote">
                <gdx-record
                  [isB2C]="true"
                  [token]="b2cToken"
                  [templateId]="templateId"
                  [recordId]="recordId"
                  [lang]="(lang$ | async) || 'es'"
                  (valid)="validateSignatureQuote($event)"
                  [reverseFieldsOrder]="true"
                >
                </gdx-record>
              </div>
            </ng-container>
          </div>

          <div
            class="tabcontent info-tab p-2"
            *ngIf="
              (visibleInfo[2] && (isReview$ | async)) ||
              (visibleInfo[2] && (isApprove$ | async)) ||
              (visibleInfo[2] && (isSignature$ | async)) ||
              (visibleInfo[2] && (isApplicant$ | async))
            "
            [ngClass]="{ active: visibleInfo[2] }"
            #chatContainer
          ></div>
        </div>

        <!-- START: workspace-tabs-content -->
      </div>
      <div
        id="document-signers"
        *ngIf="visible[2]"
        [ngClass]="{ active: visible[1] }"
      >
        <div class="preview-row"></div>
      </div>
    </div>
    <!-- START: workspace-viewr -->
  </section>
  <!-- START: workspace -->
  <footer *ngIf="document$ | async">
    <literax-buttons-bar-v2
      [document]="document$ | async"
      [user]="user$ | async"
      [attachment]="currentAttachment$ | async"
      [recordFilled]="recordFilled"
      [onlyofficePresent]="onlyofficeVisible"
      (action)="executeAction($event)"
    ></literax-buttons-bar-v2>
  </footer>
</div>
<div *ngIf="!showDocument">
  <literax-not-hired></literax-not-hired>
</div>
