<div class="row-buttons">
  <div class="note">
    {{ note }}
  </div>
  <div class="buttons">
    <button
      *ngIf="cancel"
      mat-button
      class="btn-cancel"
      [class.disabled]="cancelDisabled"
      (debounceClick)="cancelAction($event)"
    >
      {{ cancel }}
    </button>
    <button
      *ngIf="back"
      mat-button
      class="btn-back"
      [class.disabled]="backDisabled"
      (debounceClick)="backAction($event)"
    >
      {{ back }}
    </button>
    <button
      *ngIf="skip"
      mat-button
      class="btn-skip"
      [class.disabled]="skipDisabled"
      (debounceClick)="skipAction($event)"
    >
      {{ skip }}
    </button>
    <button
      *ngIf="next"
      mat-button
      class="btn-next"
      [class.disabled]="nextDisabled"
      (debounceClick)="nextAction($event)"
    >
      {{ next }}
    </button>
  </div>
</div>
