import * as documentActions from '../../../../store/document/document.actions';

import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  InitializeSignRequestFormStates,
  SendSignRequestNotification,
} from '@literax/store/sign-request/sign-request.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { debounceTime, take } from 'rxjs/operators';
import {
  selectAllDocumentSignRequests,
  selectDocument,
} from '@literax/store/document/document.selectors';

import { AddSignerWizardComponent } from '../../add-signer-wizard/add-signer-wizard.component';
import { DeleteRecordDialogComponent } from '@literax/components/platform/delete-record-dialog/delete-record-dialog.component';
import { DocumentsService } from '@literax/services/platform/documents.service';
import { HttpClient } from '@angular/common/http';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISignatureResponse } from '@literax/models/http/api/signature.model';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { ServiceResponse } from '@literax/interfaces/service-response';
import hljs from 'highlight.js';
import { selectCurrentUser } from '@literax/store/auth/auth.selectors';
import { selectSignatureResult } from '@literax/store/signature/signature.selector';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'literax-document-preview-modal',
  templateUrl: './document-preview-modal.component.html',
  styleUrls: ['./document-preview-modal.component.scss'],
})
export class DocumentPreviewModalComponent implements OnInit, OnDestroy {
  @ViewChild('xml') xmlCodeElement: ElementRef<HTMLElement>;

  readonly icons = ['info', 'signers', 'comment', 'message'];
  document$ = this.store.pipe(untilDestroyed(this), select(selectDocument));
  user$ = this.store.pipe(untilDestroyed(this), select(selectCurrentUser));
  signRequests$ = this.store.pipe(
    untilDestroyed(this),
    select(selectAllDocumentSignRequests)
  );

  form: FormGroup;
  hidden = false;
  visible = [true, false];
  visibleInfo = [true, false, false, false];
  signatures: { [key: string]: ISignatureResponse } = {};
  dialogInstance: MatDialogRef<AddSignerWizardComponent, any>;

  documentId: number;
  documentBase64: string;
  documentData: SafeResourceUrl;

  visualRepresentation: SafeResourceUrl;
  showVisualRepresentation = false;
  isXMLTemplate = false;
  options = ['Info', 'Sign'];
  images = {
    Info: 'infoSvg',
    Sign: 'signersSvg',
    Comment: 'commentSvg',
    Message: 'messageSvg',
  };

  activateInfoTab(tab: number): void {
    this.hideOtherInfo();
    this.visibleInfo[tab] = true;
  }

  hideOtherInfo(): void {
    for (let i = 0; i < this.visibleInfo.length; i++) {
      this.visibleInfo[i] = false;
    }
  }

  onBackClicked() {
    this.location.back();
  }

  onAddSignerClicked() {
    this.document$.pipe(take(1)).subscribe((document) => {
      this.store.dispatch(
        InitializeSignRequestFormStates({ payload: document.sign_requests })
      );
    });
    this.dialogInstance = this.dialog.open(AddSignerWizardComponent, {
      width: '1024px',
      id: 'edit-sign-request',
      disableClose: true,
    });
  }

  onSignerAction(isSelf, signer, documentId) {
    if (isSelf) {
      this.activateInfoTab(0);
    } else {
      this.store.dispatch(
        SendSignRequestNotification({
          payload: {
            documentId,
            payload: [
              {
                id: signer.id,
                send_notification_email: true,
              },
            ],
          },
        })
      );
    }
  }

  updateDocumentFile(document: IDocument) {
    if (document) {
      this.documentId = document.id;
      if (document.attachments.length === 0) {
        return;
      }
      if (document.attachments[0].kind === 'pdf') {
        this.httpClient
          .get(document.attachments[0].file_path.toString(), {
            responseType: 'blob' as 'json',
          })
          .subscribe((file: File) => this.updatePDFBase64(file));
      } else {
        this.documentBase64 = document.attachments[0].plain_text;
      }
    }
  }

  updatePDFBase64(file: File) {
    if (file === null) {
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => {
      this.documentBase64 = reader.result.toString().split(',')[1];
    };

    reader.onerror = (error) => {};
  }

  deleteDocumentDialog(): void {
    this.dialog.open(DeleteRecordDialogComponent, {
      width: '480px',
      height: '280px',
      disableClose: true,
      data: {
        callback: (): void =>
          this.store.dispatch(
            documentActions.DeleteDocument({
              payload: { documentId: this.documentId },
            })
          ),
        message: 'DOCUMENTS.CONFIRM_DELETE_MSG',
      },
    });
  }

  toggleXML(): void {
    if (this.showVisualRepresentation) {
      setTimeout(
        () => hljs.highlightBlock(this.xmlCodeElement.nativeElement),
        10
      );
    }

    this.showVisualRepresentation = !this.showVisualRepresentation;
  }

  downloadFile(): void {
    this.documentsService
      .getDocumentUrlForDownload(this.documentId)
      .pipe(debounceTime(500))
      .subscribe((response: ServiceResponse) => {
        window.open(response.document.url);
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(documentActions.CleanSelectedDocumentState());
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.store.dispatch(documentActions.GetDocument({ payload: params.id }));
    });

    this.store
      .pipe(untilDestroyed(this), select(selectSignatureResult))
      .subscribe((result: ISignatureResponse) => {
        if (result) {
          this.store.dispatch(documentActions.UpdateSelectedDocument());
        }
      });
  }

  setVariableForNewAttachment(attachment: IAttachment) {
    if (attachment.file_path) {
      this.documentData = this.sanitizer.bypassSecurityTrustResourceUrl(
        attachment.file_path.toString()
      );
    }

    if (attachment.xml_pdf && attachment.kind === 'xml') {
      this.visualRepresentation = this.sanitizer.bypassSecurityTrustResourceUrl(
        attachment.xml_pdf
      );
      this.isXMLTemplate =
        attachment.xml_pdf.replace('data:application/pdf;base64,', '') != '';
      setTimeout(
        () => hljs.highlightBlock(this.xmlCodeElement.nativeElement),
        10
      );
    }
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<IAppState>,
    private router: Router,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private documentsService: DocumentsService,
    iconRegistry: MatIconRegistry,
    private location: Location,
    private sanitizer: DomSanitizer,
    private detector: ChangeDetectorRef
  ) {
    this.icons.forEach((icon) => {
      iconRegistry.addSvgIcon(
        `${icon}Svg`,
        sanitizer.bypassSecurityTrustResourceUrl(
          `../../assets/icon/${icon}.svg`
        )
      );
    });

    this.form = this.fb.group({
      email: ['', Validators.email],
    });

    this.document$.subscribe((document: IDocument) => {
      if (document && document.attachments && document.attachments[0]) {
        this.setVariableForNewAttachment(document.attachments[0]);
      }

      if (document && document.signatures) {
        document.signatures.forEach((s) => (this.signatures[s.rfc] = s));
      }

      this.updateDocumentFile(document);
    });
  }
}
