import { Component } from '@angular/core';
import { environment } from '@environments/environment';

/**
 * Not found component displayed when the user enters an invalid url request
 */
@Component({
  selector: 'literax-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.scss'],
})
export class SignedComponent {
  link = environment.domain + '/auth/registration';
}
