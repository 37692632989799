import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'literax-confirm-dialog-approval',
  templateUrl: './confirm-dialog-approval.component.html',
  styleUrls: ['./confirm-dialog-approval.component.scss'],
})
export class ConfirmDialogApprovalComponent implements OnInit {
  @Input() note: string;
  @Input() back?: string;
  @Input() cancel?: string;
  @Input() next?: string;
  @Input() skip?: string;
  @Input() backDisabled = false;
  @Input() cancelDisabled = false;
  @Input() nextDisabled = false;
  @Input() skipDisabled = false;
  @Output() backEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() nextEvent = new EventEmitter();
  @Output() skipEvent = new EventEmitter();

  constancy = new FormGroup({
    enabled: new FormControl(false),
  });

  participants = new FormGroup({
    enabled: new FormControl(false),
  });

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  ngOnInit(): void {}

  cancelAction(event: Event) {
    this.data.cancelCallback();
    this.dialogRef.close();
  }

  nextAction(event: Event) {
    this.data.acceptCallback(
      this.constancy.value.enabled,
      this.participants.value.enabled
    );
    this.dialogRef.close();
  }
}

export interface ConfirmDialogData {
  acceptCallback: (constancy: boolean, participants: boolean) => void;
  cancelCallback: () => void;
  acceptButton: string;
  cancelButton: string;
  message: string;
  title: string;
}
