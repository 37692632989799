import { HostListener } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILastEmailEvent } from '@literax/models/http/api/sign-request/last-email-event';
import * as moment from 'moment';

@Component({
  selector: 'literax-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  emailDelivered: boolean;
  lastEventEmail: ILastEmailEvent;
  screenHeight: any;
  posicion: any = 'down';
  isDisabled: boolean;

  @ViewChild('envelop') element: ElementRef<HTMLElement>;

  @Input() signer;
  @Output() historyButton = new EventEmitter();
  @Output() actionButton = new EventEmitter();
  @Input() set last_event_email(last_event_email: ILastEmailEvent) {
    this.lastEventEmail = last_event_email;
    this.emailDelivered =
      ['delivered', 'openend', 'clicked'].indexOf(last_event_email?.status) >=
      0;
  }

  histoyIcons = {
    processed: 'fal fa-check neutro fa-stack-1x',
    dropped: 'fal fa-exclamation-triangle fa-stack-1x',
    deferred: 'fal fa-exclamation-triangle fa-stack-1x',
    bounce: 'fal fa-exclamation-triangle fa-stack-1x',
    delivered: 'fal fa-check neutro fa-stack-1x',
    opened: 'fal fa-check green fa-stack-1x',
    clicked: 'fal fa-check green fa-stack-1x',
    unsubscribed: 'fal fa-exclamation-triangle fa-stack-1x', //no implementado
    spam_report: 'fal fa-exclamation-triangle fa-stack-1x',
  };

  secondHistoyIcons = {
    processed: 'fa fa-circle neutro fa-stack-2x',
    dropped: 'fa fa-circle yellow fa-stack-2x',
    deferred: 'fa fa-circle yellow fa-stack-2x',
    bounce: 'fa fa-circle yellow fa-stack-2x',
    delivered: 'fa fa-circle neutro fa-stack-2x',
    opened: 'fa fa-circle green fa-stack-2x',
    clicked: 'fa fa-circle green fa-stack-2x',
    unsubscribed: 'fa fa-circle yellow fa-stack-2x', //no implementado
    spam_report: 'fa fa-circle yellow fa-stack-2x',
  };

  @Input()
  statusDocument: string = '';

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.element.nativeElement.contains(event.target)) {
      if (this.element.nativeElement.hasAttribute('open')) {
        this.element.nativeElement.removeAttribute('open');
      }
    }
  }

  constructor() {}

  ngOnInit() {
    this.posicion = 'down';
    this.isDisabled = this.signer.status?.key === 'Signed';
  }

  actionClicked(event: Event) {
    if (!this.isDisabled) {
      this.actionButton.emit({ type: 'signerInfoAction', signer: this.signer });
    }
  }

  showHistory() {
    this.historyButton.emit({ type: 'GetHistoryEmail', signer: this.signer });
  }

  formatDate(time: any) {
    return !!time ? moment(time).format('DD-MM-YYYY, hh:mm A') : '';
  }

  getposition() {
    this.screenHeight = window.innerHeight;
    var bounds = this.element.nativeElement.getBoundingClientRect();
    this.screenHeight - 278 < bounds.top
      ? (this.posicion = 'up')
      : (this.posicion = 'down');

    this.closeDetails();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenHeight = window.innerHeight;
    var bounds = this.element.nativeElement.getBoundingClientRect();
    this.screenHeight - 278 < bounds.top
      ? (this.posicion = 'up')
      : (this.posicion = 'down');
  }

  closeDetails() {
    // Fetch all the details element.
    const details = document.querySelectorAll('details');

    // Add the onclick listeners.
    details.forEach((targetDetail) => {
      targetDetail.addEventListener('click', () => {
        // Close all the details that are not targetDetail.
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute('open');
          }
        });
      });
    });
  }
}
