import { createAction, props } from '@ngrx/store';
import {
  ISignatureResponse,
  IShowSignatureResponse,
  SingRequestForm
} from '@literax/models/http/api/signature.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentSignature } from '@literax/models/document-signature';

export enum EFreeSignatureActions {
  CreateSignature = '[Signature] Create Free Signature',
  CreateSignatureSuccess = '[Signature] Create Signature Success',
  CreateSignatureError = '[Signature] Create Signature Error',
  ClearSignatureResult = '[Signature] Clear Signature Result',
  ShowSignature = '[Signature] Show Signature',
  ShowSignatureSuccess = '[Signature] Show Signature Success',
  ShowSignatureError = '[Signature] Show Signature Error',
  createSimpleSignature = '[Signature] Free Create Simple Signature',
  createSimpleSignatureSuccess = '[Signature] Free Create Simple Signature Success',
  createSimpleSignatureError = '[Signature] Free Create Simple Signature Error',
  twoFactorToken = '[Signature] Free thoFactorToken',
  twoFactorTokenSuccess = '[Signature] Free twoFactorTokenSuccess'
}

export const CreateSignature = createAction(
  EFreeSignatureActions.CreateSignature,
  props<{ payload: { token: string; params: SingRequestForm } }>()
);

export const CreateSignatureSuccess = createAction(
  EFreeSignatureActions.createSimpleSignatureSuccess,
  props<{ payload: ISignatureResponse }>()
);

export const CreateSignatureError = createAction(
  EFreeSignatureActions.createSimpleSignatureError,
  props<{ payload: HttpErrorResponse }>()
);

export const ClearSignatureResult = createAction(
  EFreeSignatureActions.ClearSignatureResult
);

export const ShowSignature = createAction(
  EFreeSignatureActions.ShowSignature,
  props<{ payload: string }>()
);

export const ShowSignatureSuccess = createAction(
  EFreeSignatureActions.ShowSignatureSuccess,
  props<{ payload: IShowSignatureResponse }>()
);

export const ShowSignatureError = createAction(
  EFreeSignatureActions.ShowSignatureError,
  props<{ payload: HttpErrorResponse }>()
);

export const createSimpleSignature = createAction(
  EFreeSignatureActions.createSimpleSignature,
  props<{ payload: { signature: DocumentSignature; token: string } }>()
);

export const createSimpleSignatureSuccess = createAction(
  EFreeSignatureActions.createSimpleSignatureSuccess,
  props<{ payload: DocumentSignature }>()
);

export const createSimpleSignatureError = createAction(
  EFreeSignatureActions.createSimpleSignatureError,
  props<{ payload: HttpErrorResponse }>()
);

export const twoFactorToken = createAction(
  EFreeSignatureActions.twoFactorToken,
  props<{ payload: number[] }>()
);

export const twoFactorTokenSuccess = createAction(
  EFreeSignatureActions.twoFactorTokenSuccess
);
