import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { Client } from '@literax/models/http/onboarding/client.model';
import { IProfileUser } from '@literax/models/user.model';
import { Observable } from 'rxjs';
import { BaseService } from '../base-config/base.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Handles all authorization and registration calls.
 */
export class AuthService {
  constructor(
    private base: BaseService
  ) //private tokenService: AngularTokenService
  {}

  /**
   * This method logs a user with the given username and password.
   * @param username - The username
   * @param password - The password
   * @returns An observable of the response from the server.
   */

  /**
   * Registers a user with the data from a {@link AuthService#RegistrationData}.
   * @param userData A {@link AuthService#RegistrationData} instance with the complete d.netata to send
   * @returns An observable of the response from the server.
   */
  registration(clientData: Client, token: string): Observable<any> {
    return this.base.post(`/auth`, { client: clientData, token });
  }

  verify(clientData: Client, token: string): Observable<any> {
    return this.base.post(`/auth`, {
      client: clientData,
      validate: true,
      token: token
    });
  }

  /**
   * Update profile data of a user with the data from a {@link AuthService#UpdateProfile}.
   * @param userData A {@link AuthService#UpdateProfile} instance with the complete d.netata to send
   * @returns An observable of the response from the server.
   */
  updateProfile(userData: IProfileUser): Observable<ServiceResponse> {
    return this.base.put(`/users`, { user: userData });
  }

  passwordRecovery(
    email: string,
    redirectUrl: string
  ): Observable<ServiceResponse> {
    return this.base.post('/auth/password', {
      email,
      redirect_url: redirectUrl
    });
  }

  passwordReset(password: string, passwordConfirmation: string) {
    // this.tokenService.currentAuthData
    return this.base.put('/auth/password', {
      password,
      password_confirmation: passwordConfirmation
    });
  }
}
