import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IDashboard } from '@literax/models/http/api/dashboard.model';
import { Process } from '@literax/models/process.model';

export interface IDashboardState {
  dashboard: IDashboard;
  showDashboard: Process;
  errorResponse: IAPIResponseError;
  internal_process: Process;
  external_process: Process;
  promissory_note: Process;
  shared_process: Process;
}

export const initialDashboardState: IDashboardState = {
  dashboard: {
    documents: {},
    process: [],
    user_config: null,
    helpdesk: ''
  },
  showDashboard: {
    key: '',
    name: '',
    flows: []
  },
  errorResponse: null,
  internal_process: {
    key: '',
    name: '',
    flows: []
  },
  external_process: {
    key: '',
    name: '',
    flows: []
  },
  promissory_note: {
    key: '',
    name: '',
    flows: []
  },
  shared_process: {
    key: '',
    name: '',
    flows: []
  }
};
